import ListComponent, { iListComponent } from '../frameWork/ListComponent';
import iBuildColourNote from '../../types/build/iBuildColourNote';
import React from 'react';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import BuildColourNoteService from '../../services/build/BuildColourNoteService';
import BuildColourNoteEditPopupBtn from './BuildColourNoteEditPopupBtn';
import { getCreateIconBtn } from '../common/PageTitleWithCreateBtn';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import UtilsService from '../../services/UtilsService';
import Checkbox from '../frameWork/Checkbox';
import EntityStatusLozenge from '../entityStatus/EntityStatusLozenge';
import * as _ from 'lodash';
import EmailPopupBtn from '../message/EmailPopupBtn';
import Button from '../frameWork/Button';
import Flex from '../frameWork/Flex';
import Icons from '../frameWork/Icons';
import AddressHelper from '../../helpers/AddressHelper';
import iBuild from '../../types/build/iBuild';

export type iBuildColourNoteList = Omit<
  iListComponent<iBuildColourNote>,
  'componentName' | 'getFn' | 'getColumns' | 'renderCreateBtn'
> & {
  build: iBuild;
  perPage?: number;
};

const BuildColourNoteList = ({
  build,
  allowEdit,
  perPage,
  ...props
}: iBuildColourNoteList) => {
  const [selectedNotes, setSelectedNotes] = React.useState<iBuildColourNote[]>(
    [],
  );

  const getEditPopBtn = (
    props: ReturnType<typeof useListCrudHook<iBuildColourNote>>,
    data?: iBuildColourNote,
  ) => {
    return (
      <BuildColourNoteEditPopupBtn
        isDisabled={allowEdit === false}
        buildId={build.id}
        buildColourNote={data}
        onSaved={(saved, isCreated) => {
          if (isCreated) {
            props.onRefreshWhenCreated();
          } else {
            props.onRefresh();
          }
        }}
        renderBtn={(onClick) => {
          const id = `${data?.id || ''}`.trim();
          if (id === '') {
            return getCreateIconBtn({
              onClick,
              testId: 'create-btn',
            });
          }
          return (
            <a
              onClick={onClick}
              className={'cursor-pointer'}
              data-testid={`edit-btn-${data?.id || ''}`}
            >
              {data?.name || ''}
            </a>
          );
        }}
      />
    );
  };

  const isRowSelected = (data: iBuildColourNote) => {
    return selectedNotes.some((x) => x.id === data.id);
  };

  const getEmailBody = () =>
    `<div>${selectedNotes
      .map((selectedNote) => {
        return `<p><b>${selectedNote.name}</b><div>Cost: ${UtilsService.formatIntoCurrency(selectedNote.costToClient)}</div><div><small>${selectedNote.notes || ''}</small></div></p>`;
      })
      .join('</div><div>')}</div>`;

  const getSubject = () =>
    `COLOUR NOTES for: ${AddressHelper.getFullAddress(build.Address)}`;

  return (
    <ListComponent<iBuildColourNote>
      perPage={perPage}
      allowEdit={allowEdit}
      componentName={'BuildColourNoteList'}
      selectiveColumnKey={SelectiveColKeys.BUILD_COLOUR_NOTE_LIST}
      {...props}
      deactivateFn={async (data: iBuildColourNote) =>
        BuildColourNoteService.deactivate(data.id)
      }
      renderCreateBtn={(props) => getEditPopBtn(props)}
      getFn={(params) =>
        BuildColourNoteService.getAll({
          where: JSON.stringify({
            isActive: true,
            buildId: build.id,
            ...(params?.filter || {}),
          }),
          sort: 'createdAt:DESC',
          include:
            'CreatedBy,UpdatedBy,ApprovalStatus.Category,Status.Category',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        })
      }
      getActions={() => (
        <Flex className={'gap-1'}>
          <EmailPopupBtn
            showLogs
            buildId={build.id}
            emailBody={getEmailBody()}
            emailSubject={getSubject()}
            emailRecipients={_.uniq(
              [
                build.Supervisor1?.email || '',
                build.Supervisor2?.email || '',
              ].filter((email) => `${email || ''}`.trim() !== ''),
            )}
            renderBtn={(onClick) => {
              return (
                <Button
                  onClick={onClick}
                  iconBefore={Icons.SendIcon}
                  isDisabled={selectedNotes.length <= 0}
                >
                  Email ({selectedNotes.length}) Approval
                </Button>
              );
            }}
          />
          <EmailPopupBtn
            showLogs
            buildId={build.id}
            emailBody={getEmailBody()}
            emailSubject={getSubject()}
            emailRecipients={_.uniq(
              [
                build.SalesPerson?.email || '',
                build.SalesManager?.email || '',
              ].filter((email) => `${email || ''}`.trim() !== ''),
            )}
            renderBtn={(onClick) => {
              return (
                <Button
                  onClick={onClick}
                  iconBefore={Icons.SendIcon}
                  isDisabled={selectedNotes.length <= 0}
                >
                  Email ({selectedNotes.length}) Sales
                </Button>
              );
            }}
          />
        </Flex>
      )}
      getColumns={(params) => {
        return [
          {
            key: 'select-box',
            isDefault: true,
            header: () => {
              return (
                <Checkbox
                  className={'cursor-pointer'}
                  isChecked={
                    (params.state.data.data || []).filter(
                      (note) => !isRowSelected(note),
                    ).length === 0
                  }
                  onChange={(e) => {
                    setSelectedNotes(
                      _.unionBy(
                        e.target.checked ? params.state.data.data || [] : [],
                        'id',
                      ),
                    );
                  }}
                />
              );
            },
            cell: ({ data }) => {
              return (
                <Checkbox
                  className={'cursor-pointer'}
                  isChecked={isRowSelected(data)}
                  onChange={(e) => {
                    setSelectedNotes(
                      e.target.checked
                        ? [...selectedNotes, data]
                        : selectedNotes.filter((x) => x.id !== data.id),
                    );
                  }}
                />
              );
            },
          },
          {
            key: 'name',
            header: 'Name',
            isDefault: true,
            cell: ({ data }) => {
              return (
                <>
                  <div>{getEditPopBtn(params, data)}</div>
                  <small>
                    <i>{data.notes || ''}</i>
                  </small>
                </>
              );
            },
          },
          {
            key: 'approvalStatus',
            header: 'Address To',
            isDefault: true,
            cell: ({ data }) => {
              return <EntityStatusLozenge status={data.ApprovalStatus} />;
            },
          },
          {
            key: 'Status',
            header: 'Status',
            isDefault: true,
            cell: ({ data }) => {
              return <EntityStatusLozenge status={data.Status} />;
            },
          },
          {
            key: 'costToClient',
            header: 'Cost to Client',
            isDefault: true,
            cell: ({ data }) => {
              return UtilsService.formatIntoCurrency(data.costToClient);
            },
          },
        ];
      }}
    />
  );
};

export default BuildColourNoteList;
