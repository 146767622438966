import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import { iComponentWithPageHeader } from '../common/ComponentWithPageHeader';
import styled from 'styled-components';
import DeveloperGuidesList from '../developerGuides/DeveloperGuidesList';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import MathHelper from '../../helpers/MathHelper';
import React, { useState } from 'react';
import DeveloperGuideEditPopupBtn from '../developerGuides/DeveloperGuideEditPopupBtn';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import Flex from '../frameWork/Flex';
import DeveloperGuideCategoryService from '../../services/developerGuideCategory/DeveloperGuideCategoryService';
import { iSetShowingModalFn } from '../common/PopupBtn';
import EntityEditPopupBtn from '../form/EntityEditPopupBtn';
import Badge from '../frameWork/Badge';

const Wrapper = styled.div``;

export type iDeveloperGuideCategoryDetailsPanel = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowEdit?: boolean;
  allowCreate?: boolean;
  onSaved?: (saved: iDeveloperGuideCategory) => void;
  onDeleted?: (deleted: iDeveloperGuideCategory) => void;
  category: iDeveloperGuideCategory;
  testId?: string;
  className?: string;
};
const DeveloperGuideCategoryDetailsPanel = ({
  allowDelete,
  allowCreate,
  allowEdit,
  onDeleted,
  onSaved,
  category,
  testId,
  className,
  headerProps,
}: iDeveloperGuideCategoryDetailsPanel) => {
  const componentName = 'DeveloperGuideCategoryDetailsPanel';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [forceReloadList, setForceReloadList] = useState(0);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);

  const handlePopupClose = (setModelShowing: iSetShowingModalFn) => {
    setModelShowing(false);
    setShowDeletePopup(false);
    setShowEditPopup(false);
  };

  const getCreateBtn = () => {
    if (!allowCreate) {
      return null;
    }
    return (
      <DeveloperGuideEditPopupBtn
        developerGuide={undefined}
        category={category}
        onSaved={() => setForceReloadList(MathHelper.add(forceReloadList, 1))}
        renderBtn={(onClick) => {
          return getCreateIconBtn({ onClick });
        }}
      />
    );
  };

  const getTitle = () => {
    return (
      <>
        {category.name} <Badge>{category.code}</Badge>
      </>
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <DeveloperGuidesList
        allowEdit={allowEdit}
        allowDelete={allowEdit}
        category={category}
        forceReload={forceReloadList}
        headerProps={{
          ...headerProps,
          bottomBar: <>{category.description || null}</>,
          actions: (
            <Flex className={'gap-1 align-items-center'}>
              <DropdownMenu
                placement={'bottom-end'}
                shouldRenderToParent
                trigger={({ triggerRef, ...props }) => {
                  return (
                    <IconButton
                      {...props}
                      icon={Icons.MoreIcon}
                      ref={triggerRef}
                      label={''}
                      isSelected={false}
                    />
                  );
                }}
              >
                <DropdownItemGroup>
                  {allowEdit === true && (
                    <DropdownItem onClick={() => setShowEditPopup(true)}>
                      Edit {category.name || ''}
                    </DropdownItem>
                  )}
                  {allowDelete === true && (
                    <DropdownItem onClick={() => setShowDeletePopup(true)}>
                      Delete {category.name || ''}
                    </DropdownItem>
                  )}
                </DropdownItemGroup>
              </DropdownMenu>

              <EntityEditPopupBtn<iDeveloperGuideCategory>
                forceShown={showEditPopup}
                isDisabled={allowEdit === false}
                entityName={'DeveloperGuideCategory'}
                entity={category}
                onSaved={(saved) => {
                  onSaved && onSaved(saved);
                }}
                updateFn={(id, newData) => {
                  return DeveloperGuideCategoryService.update(id, newData);
                }}
                onCancel={handlePopupClose}
                onClose={handlePopupClose}
                renderBtn={() => null}
                getFormFields={({ entity, isDisabled }) => [
                  {
                    fieldName: 'name',
                    label: 'Name',
                    isDisabled,
                    isRequired: true,
                    value: entity?.name || '',
                    testId: 'DeveloperGuideCategory-name',
                  },
                  {
                    fieldName: 'code',
                    label: 'Code',
                    isDisabled,
                    isRequired: true,
                    value: entity?.code || '',
                    testId: 'DeveloperGuideCategory-code',
                  },
                  {
                    fieldName: 'description',
                    label: 'Description',
                    isDisabled,
                    value: entity?.description || '',
                    testId: 'DeveloperGuideCategory-description',
                  },
                ]}
              />

              <DeleteConfirmPopupBtn
                onClose={handlePopupClose}
                onCancel={handlePopupClose}
                forceShown={showDeletePopup}
                titleId={`delete-${category.id}`}
                deleteFnc={() =>
                  DeveloperGuideCategoryService.deactivate(category.id)
                }
                renderBtn={() => null}
                onDeleted={(deleted) => onDeleted && onDeleted(deleted)}
              />
            </Flex>
          ),
          children:
            allowCreate === true ? (
              <PageTitleWithCreateBtn
                createBtn={getCreateBtn()}
                title={getTitle()}
              />
            ) : (
              getTitle()
            ),
        }}
      />
    </Wrapper>
  );
};

export default DeveloperGuideCategoryDetailsPanel;
