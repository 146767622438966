import styled from 'styled-components';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ProductService from '../../services/product/ProductService';
import iProduct from '../../types/product/iProduct';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import DynamicTableHelper, {
  iCellParams,
} from '../../helpers/DynamicTableHelper';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { URL_PRODUCT_DETAILS } from '../../helpers/UrlMap';
import UtilsService from '../../services/UtilsService';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';
import Flex from '../frameWork/Flex';
import ProductCreatePopupBtn from './ProductCreatePopupBtn';

const Wrapper = styled.div``;

export type iProductListPanel = iComponentWithPageHeader & {
  className?: string;
  testId?: string;
  allowDelete?: boolean;
  allowCreate?: boolean;
  productTypeId?: string;
};

const ProductList = ({
  className,
  productTypeId,
  testId,
  allowCreate,
  allowDelete,
  headerProps,
}: iProductListPanel) => {
  const testIdStr = `${testId || ''}-product-list`;
  const navigate = useNavigate();
  const { renderDataTable, renderDeleteBtn, onSetFilter } =
    useListCrudHook<iProduct>({
      sort: `productCode:ASC`,
      getFn: (params) =>
        ProductService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(`${productTypeId || ''}`.trim() === ''
              ? {}
              : { typeId: `${productTypeId || ''}`.trim() }),
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,ProductType',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getColumns = () => [
    {
      key: 'productCode',
      header: 'Product Code / SKU',
      isDefault: true,
      isSortable: true,
      cell: ({ data }: iCellParams<iProduct>) => (
        <>
          <Link to={URL_PRODUCT_DETAILS.replace(':id', data.id)}>
            {data?.productCode || 'EMPTY PC'}
          </Link>
          <div>
            <small>{data.name || ''}</small>
          </div>
        </>
      ),
    },
    {
      key: 'productType',
      header: 'Type',
      isDefault: `${productTypeId || ''}`.trim() === '',
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        `${data.ProductType?.name || ''}`,
    },
    {
      key: 'unitPrice',
      header: 'Unit Price',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        UtilsService.formatIntoCurrency(data.unitPrice),
    },
    {
      key: 'isForSell',
      header: 'For Sell?',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        DynamicTableHelper.getCheckedIcon(data.isForSell),
    },
    {
      key: 'isForPurchase',
      header: 'For Purchase?',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        DynamicTableHelper.getCheckedIcon(data.isForPurchase),
    },
    {
      key: 'isForBuild',
      header: 'For Build?',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        DynamicTableHelper.getCheckedIcon(data.isForBuild),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iProduct>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iProduct>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => ProductService.deactivate(data.id),
                    getDisplayName: (data) => data.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];
  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={
              allowCreate === true ? (
                <ProductCreatePopupBtn
                  productTypeId={productTypeId}
                  onSaved={(saved) =>
                    navigate(URL_PRODUCT_DETAILS.replace(':id', saved.id))
                  }
                  renderBtn={(onClick) =>
                    getCreateIconBtn({
                      onClick,
                      testId: `${testIdStr}-create-btn`,
                    })
                  }
                />
              ) : (
                <></>
              )
            }
            title={headerProps?.children}
          />
        ),
        actions: (
          <Flex className={'gap-05 align-items-center'}>
            <SearchTextField
              testId={`${testIdStr}-search`}
              placeholder={'Searching name, product code ...'}
              onSearch={(searchText: string) => {
                onSetFilter({
                  [OP_OR]: [
                    { name: { [OP_LIKE]: `%${searchText}%` } },
                    { productCode: { [OP_LIKE]: `%${searchText}%` } },
                  ],
                });
              }}
            />
            {headerProps?.actions}
          </Flex>
        ),
      }}
    >
      <Wrapper
        data-testid={testIdStr}
        className={`product-list-panel-wrapper ${className || ''}`}
      >
        {renderDataTable({
          selectiveColumnKey: SelectiveColKeys.PRODUCT_LIST,
          columns: getColumns(),
          showPageSizer: true,
          tblProps: {
            testId: testIdStr,
          },
        })}
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default ProductList;
