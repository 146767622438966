import React from 'react';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import PopupBtn, { iPopupBtn } from '../../common/PopupBtn';
import { getFooterWithBtns } from '../../common/PopupModal';

type iWorkflowValidator = Omit<iPopupBtn, 'titleId'> & {
  state: iWFDiagramState;
};
const WorkflowValidator = ({ state, ...props }: iWorkflowValidator) => {
  if (state.errors.length <= 0) {
    return null;
  }

  return (
    <PopupBtn
      {...props}
      titleId={`errors-in-workflow`}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: 'Errors in your workflow',
        onClose: () => setShowingModal(false),
        body: (
          <div>
            <ul>
              {state.errors.map((errorMsg, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <li key={`error-${index}`}>{errorMsg}</li>;
              })}
            </ul>
          </div>
        ),
        footer: getFooterWithBtns({
          actionBtnProps: {
            btnText: 'OK',
            onClick: () => setShowingModal(false),
          },
          cancelBtn: <></>,
        }),
      })}
    />
  );
};

export default WorkflowValidator;
