import ServiceHelper from '../helper/ServiceHelper';
import IEstatePlanNoteCategory from '../../types/estate/iEstatePlanNoteCategory';

const endPoint = '/estatePlanNoteCategory';
const EstatePlanNoteCategoryService = {
  getAll: ServiceHelper.getPaginatedAll<IEstatePlanNoteCategory>(endPoint),
  get: ServiceHelper.getById<IEstatePlanNoteCategory>(endPoint),
  create: ServiceHelper.create<IEstatePlanNoteCategory>(endPoint),
  update: ServiceHelper.updateById<IEstatePlanNoteCategory>(endPoint),
  deactivate: ServiceHelper.deactivateById<IEstatePlanNoteCategory>(endPoint),
};

export default EstatePlanNoteCategoryService;
