import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iDeveloperGuides from '../../types/developerGuide/iDeveloperGuide';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { iErrorMap } from '../form/FormError';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import DeveloperGuidesService from '../../services/developerGuides/DeveloperGuidesService';
import Toaster, { TOAST_TYPE_ERROR } from '../common/Toaster';
import DeveloperGuideEditPanel from './DeveloperGuideEditPanel';
import iEstate from '../../types/estate/iEstate';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import { iValidationRule } from '../../helpers/ValidationRuleHelper';
import { AttributeTypes } from '../../types/attribute/iAttribute';
import StringHelper from '../../helpers/StringHelper';

export type iDeveloperGuideEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  estate?: iEstate;
  category?: iDeveloperGuideCategory;
  isDisabled?: boolean;
  developerGuide?: iDeveloperGuides;
  onSaved?: (saved: iDeveloperGuides, isCreated: boolean) => void;
  onClose?: (setModelShowing: iSetShowingModalFn) => void;
};

const DeveloperGuideEditPopupBtn = ({
  estate,
  category,
  developerGuide,
  onSaved,
  onClose,
  isDisabled = false,
  ...props
}: iDeveloperGuideEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const developerGuideId = `${developerGuide?.id || ''}`.trim();

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
    onClose && onClose(setModelShowing);
  };

  const preSave = () => {
    const data = {
      ...(estate ? { estateId: estate.id } : {}),
      ...(category ? { categoryId: category.id } : {}),
      ...(developerGuide || {}),
      ...editingData,
    };
    const errors: iErrorMap = {}; // Perform validation here

    if (`${data.attributeId || ''}`.trim() === '') {
      errors.attributeId = ['Attribute is required'];
    }
    if (`${data.categoryId || ''}`.trim() === '') {
      errors.categoryId = ['Category is required'];
    }
    if (`${data.estateId || ''}`.trim() === '') {
      errors.estateId = ['Estate is required'];
    }
    const rules: iValidationRule = data.validationRules || {};
    if (AttributeTypes.INPUT_NUMBER in rules) {
      const rule = rules[AttributeTypes.INPUT_NUMBER] || {};
      const min = `${rule.min || ''}`.trim();
      const max = `${rule.max || ''}`.trim();
      if (min !== '' && !StringHelper.isNumeric(min)) {
        errors[`rules_${AttributeTypes.INPUT_NUMBER}_min`] = [
          'Min value needs to be a number',
        ];
      }
      if (max !== '' && !StringHelper.isNumeric(max)) {
        errors[`rules_${AttributeTypes.INPUT_NUMBER}_max`] = [
          'Max value needs to be a number',
        ];
      }
      if (min !== '' && max !== '' && Number(min) > Number(max)) {
        errors[`rules_${AttributeTypes.INPUT_NUMBER}_min`] = [
          'Min value needs to be less than max value',
        ];
      }
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      const errMessageStr = Object.values(errorMap)
        .reduce(
          (arr: string[], errMsgs) => [
            ...arr,
            ...(Array.isArray(errMsgs) ? errMsgs : [errMsgs]),
          ],
          [],
        )
        .join(', ')
        .trim();
      Toaster.showToast(
        errMessageStr === '' ? 'Error, please check the form' : errMessageStr,
        TOAST_TYPE_ERROR,
      );
      return;
    }

    const submittingData = {
      ...(estate ? { estateId: estate?.id } : {}),
      ...(category ? { categoryId: category?.id } : {}),
      ...editingData,
    };
    setIsSaving(true);

    const getFnc = () =>
      developerGuideId !== ''
        ? DeveloperGuidesService.update(developerGuideId, submittingData)
        : DeveloperGuidesService.create(submittingData);

    getFnc()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, developerGuideId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'DeveloperGuideEditPopupBtn'}
      testId={'DeveloperGuideEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        width: '70rem', // Adjust width as needed
        title:
          `${developerGuide?.id || ''}`.trim() === '' ? (
            <>
              Creating a new Developer Guide under {''}
              <span className="large">{estate?.name || ''}</span>
            </>
          ) : (
            <>Editing Developer Guide</>
          ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText:
              `${developerGuide?.id || ''}`.trim() === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <DeveloperGuideEditPanel
            estate={estate}
            developerGuideCategory={category}
            developerGuide={{
              ...(developerGuide as iDeveloperGuides),
              ...editingData,
            }}
            isDisabled={isSaving || isDisabled}
            useAsForm={{
              errorMap,
              onFieldChange: (fieldName, newValue) => {
                setEditingData({ ...editingData, [fieldName]: newValue });
              },
            }}
          />
        ),
      })}
    />
  );
};

export default DeveloperGuideEditPopupBtn;
