import React from 'react';
import useWorkflowDiagramHook from './diagram/useWorkflowDiagramHook';
import WorkflowDiagramHelper, {
  WFDiagramIds,
} from './diagram/WorkflowDiagramHelper';
import WorkflowDiagram from './diagram/WorkflowDiagram';
import {
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
} from '../frameWork/ReactFlowRenderer';
import WorkflowEditLayout from './components/WorkflowEditLayout';
import WorkflowEditButtons from './components/WorkflowEditButtons';
import PageInit from '../../pages/PageInit';
import WorkflowEditCreateButtons from './components/WorkflowEditCreateButtons';
import WorkflowEditRightMenu from './components/WorkflowEditRightMenu';
import WorkflowValidator from './components/WorkflowValidator';
import Button from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import WorkflowTransitionEditPopBtn from './components/WorkflowTransitionEditPopBtn';
import EmptyState from '../frameWork/EmptyState';
import WorkflowService from '../../services/system/WorkflowService';
import EntityNames from '../../helpers/EntityNames';
import Toaster from '../common/Toaster';
import { useNavigate } from 'react-router-dom';

type iWorkflowEdit = {
  entityStatusTypeId: string;
  onCancel: () => void;
  testId?: string;
  className?: string;
};
const WorkflowEdit = ({
  entityStatusTypeId,
  onCancel,
  testId,
  className,
}: iWorkflowEdit) => {
  const navigate = useNavigate();
  const [isCreatingWF, setIsCreatingWF] = React.useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { state, setErrors } = useWorkflowDiagramHook(
    entityStatusTypeId,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setNodes,
    setEdges,
  );

  const validateDiagram = () => {
    setErrors(WorkflowDiagramHelper.validateGraph(nodes, edges));
  };

  const onPreSaveWorkFlow = () => {
    const errors = WorkflowDiagramHelper.validateGraph(nodes, edges);
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const getEditEdgePanel = () => {
    const selectedEdges = (edges || []).filter(
      (edge) =>
        edge.selected === true && edge?.id !== WFDiagramIds.EDGE_ID_START_EDGE,
    );
    if (selectedEdges.length <= 0) {
      return null;
    }
    return (
      <WorkflowTransitionEditPopBtn
        onCancel={() =>
          setEdges(edges.map((edge) => ({ ...edge, selected: false })))
        }
        forceShown={true}
        onClose={() => edges.map((edge) => ({ ...edge, selected: false }))}
        state={state}
        edge={selectedEdges[0]}
        renderBtn={() => null}
      />
    );
  };

  const handleOnCancel = () => {
    if (!state.entityStatusType) {
      return;
    }
    onCancel();
  };

  const doCreateWF = () => {
    setIsCreatingWF(true);
    WorkflowService.create({
      entityId: entityStatusTypeId,
      entityName: EntityNames.EntityStatusType,
    })
      .then(() => {
        setIsCreatingWF(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsCreatingWF(false);
        Toaster.showApiError(error);
      });
  };

  if (state.isLoading === true) {
    return <PageInit />;
  }

  if (!state.entityStatusType) {
    return null;
  }

  if (!state.workflow) {
    return (
      <EmptyState
        header={'No workflow found'}
        description={
          <>
            Opps, it looks like there is no workflow for{' '}
            <b>{state.entityStatusType.entityName}</b>.
          </>
        }
        secondaryAction={
          <Button
            appearance={'subtle'}
            iconBefore={Icons.ArrowLeftIcon}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        }
        primaryAction={
          <Button
            isLoading={isCreatingWF}
            appearance={'primary'}
            iconBefore={Icons.SendIcon}
            onClick={() => {
              doCreateWF();
            }}
          >
            Create Workflow
          </Button>
        }
      />
    );
  }

  return (
    <ReactFlowProvider>
      <WorkflowEditLayout
        testId={testId}
        className={className}
        entityStatusType={state.entityStatusType}
        createButtons={
          <WorkflowEditCreateButtons state={state} className={'create-btn'} />
        }
        rightSideBar={<WorkflowEditRightMenu state={state} />}
        errorDisplay={
          <WorkflowValidator
            state={state}
            renderBtn={(onClick) => (
              <Button
                appearance={'subtle'}
                color={'danger'}
                onClick={onClick}
                iconBefore={Icons.ErrorIcon}
              >
                Errors in workflow
              </Button>
            )}
          />
        }
        updateBtns={
          <WorkflowEditButtons
            state={state}
            onCancel={handleOnCancel}
            onPreCheck={onPreSaveWorkFlow}
          />
        }
      >
        <>
          <WorkflowDiagram
            setEdges={setEdges}
            setNodes={setNodes}
            onNodesChange={(...props) => {
              validateDiagram();
              onNodesChange(...props);
            }}
            onEdgesChange={(...props) => {
              validateDiagram();
              onEdgesChange(...props);
            }}
            nodes={nodes}
            edges={edges}
          />
          {getEditEdgePanel()}
        </>
      </WorkflowEditLayout>
    </ReactFlowProvider>
  );
};

export default WorkflowEdit;
