import ServiceHelper from '../helper/ServiceHelper';
import iContactCompanyCategory from '../../types/contact/iContactCompanyCategory';

const endPoint = '/contactCompanyCategory';

const ContactCompanyCategoryService = {
  getAll: ServiceHelper.getPaginatedAll<iContactCompanyCategory>(endPoint),
  get: ServiceHelper.getById<iContactCompanyCategory>(endPoint),
  create: ServiceHelper.create<iContactCompanyCategory>(endPoint),
  update: ServiceHelper.updateById<iContactCompanyCategory>(endPoint),
  deactivate: ServiceHelper.deactivateById<iContactCompanyCategory>(endPoint),
};

export default ContactCompanyCategoryService;
