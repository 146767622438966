import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';
import styled from 'styled-components';
import React, { useState } from 'react';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import InlineEdit, { ViewWrapper } from '../frameWork/InlineEdit';
import { iConfigParams } from '../../services/AppService';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import Toaster from '../common/Toaster';
import Toggle from '../frameWork/Toggle';
import Flex from '../frameWork/Flex';
import { Label } from '../frameWork/Form';
import tokens from '../frameWork/Tokens';
import InlineTextArea from '../frameWork/InlineTextArea';
import Tabs from '../frameWork/Tabs';
import BuildList from '../build/BuildList';
import ContactList from './ContactList';
import Heading from '../frameWork/Heading';
import ContactCompanyCategorySelector from './ContactCompanyCategorySelector';
import { iOptionWithData } from '../frameWork/Select';
import iContactCompanyCategory from '../../types/contact/iContactCompanyCategory';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import ContactCompanyHelper from '../../helpers/ContactCompanyHelper';
import AddressEditPopupBtn from '../address/AddressEditPopupBtn';
import AddressHelper from '../../helpers/AddressHelper';
import FormField from '../frameWork/FormField';
import iAddress from '../../types/system/iAddress';

export type iContactCompanyDetailsPanel = iComponentWithPageHeader & {
  contactCompany: iContactCompany;
  onSaved?: (saved: iContactCompany) => void;
  onDeleted?: (saved: iContactCompany) => void;
  allowDelete?: boolean;
  testId?: string;
};

const Wrapper = styled.div`
  .details-col {
    max-width: 100%;
    width: 300px;
  }

  form > div {
    margin-block-start: 0;
  }

  label {
    margin-block-start: ${tokens('space.100', '8px')};
  }

  .comment-div {
    width: 620px;
    max-width: 100%;
  }

  .address-div {
    .address-col {
      max-width: 100%;
      width: 300px;
    }
  }

  .modified-div {
    width: 460px;
    max-width: 100%;

    .details-col {
      width: 300px;
    }
  }

  .activities {
    margin-top: ${tokens('space.200', '1rem')};
    min-height: 10rem;
  }
`;

const ContactCompanyDetailsPanel = ({
  contactCompany,
  onSaved,
  headerProps,
  testId,
  allowDelete,
  onDeleted,
}: iContactCompanyDetailsPanel) => {
  const testIdStr = `contactCompanyDetails-${testId || ''}`;
  const [isSaving, setIsSaving] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const typeName = ContactCompanyService.getHumanReadableType(
    contactCompany.type || '',
  );

  const handleUpdated = (newDataMap: iConfigParams) => {
    const filteredMap: iConfigParams = Object.keys(newDataMap)
      .filter(
        (key) =>
          newDataMap[key] !== undefined &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          newDataMap[key] !== contactCompany[key],
      )
      .reduce((map, key) => {
        return {
          ...map,
          [key]: newDataMap[key],
        };
      }, {});
    if (Object.keys(filteredMap).length <= 0) {
      return;
    }

    setIsSaving(true);
    ContactCompanyService.update(contactCompany.id, filteredMap)
      .then((resp) => {
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getCompanyDetails = () => {
    if (!contactCompany.isCompany) {
      return null;
    }

    return (
      <>
        {[
          {
            key: 'abn',
            label: 'A.B.N.',
            value: `${contactCompany.abn || ''}`.trim(),
            placeholder: `The ABN of this ${typeName}`,
          },
          {
            key: 'acn',
            label: 'ACN',
            value: `${contactCompany.acn || ''}`.trim(),
            placeholder: `The ACN of this ${typeName}`,
          },
        ].map((item) => {
          return (
            <div key={item.key} className={'details-col'}>
              <InlineEdit
                readViewFitContainerWidth
                isDisabled={isSaving}
                testId={item.key}
                label={item.label}
                placeHolder={item.placeholder}
                value={item.value}
                onConfirm={(value) => handleUpdated({ [item.key]: value })}
              />
            </div>
          );
        })}
      </>
    );
  };

  const handlePopupClose = () => {
    setShowDeletePopup(false);
  };

  const getOptions = () => {
    if (allowDelete !== true) {
      return <></>;
    }
    return (
      <>
        <DropdownMenu
          placement={'bottom-end'}
          shouldRenderToParent
          testId={`${testIdStr}-options-dropdown`}
          trigger={({ triggerRef, ...props }) => {
            return (
              <IconButton
                {...props}
                icon={Icons.MoreIcon}
                ref={triggerRef}
                label={''}
                isSelected={false}
              />
            );
          }}
        >
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => setShowDeletePopup(true)}
              testId={`${testIdStr}-delete-btn`}
            >
              Delete {contactCompany.name || ''}
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
        <DeleteConfirmPopupBtn
          testId={`${testIdStr}-delete-popup`}
          onClose={handlePopupClose}
          onCancel={() => {
            setShowDeletePopup(false);
          }}
          forceShown={showDeletePopup}
          titleId={`delete-${contactCompany.id}`}
          deleteFnc={() => ContactCompanyService.deactivate(contactCompany.id)}
          renderBtn={() => null}
          onDeleted={(deleted) => onDeleted && onDeleted(deleted)}
        />
      </>
    );
  };

  const getAddressEditPopup = (
    label: string,
    fieldName: string,
    address?: iAddress | null,
  ) => {
    const fullAddress = AddressHelper.getFullAddress(address);
    return (
      <FormField
        label={label}
        render={() => (
          <AddressEditPopupBtn
            testId={fieldName}
            address={address || undefined}
            getPopupTitle={() => `Edit ${label}`}
            onSaved={(saved, isCreated) => {
              if (isCreated === true) {
                handleUpdated({ [fieldName]: saved.id });
                return;
              }
              onSaved && onSaved(contactCompany);
            }}
            renderBtn={(onClick) => (
              <ViewWrapper
                onClick={onClick}
                data-testid={`${fieldName}-address-edit`}
                className={fullAddress === '' ? 'default-view' : ''}
              >
                {fullAddress === '' ? 'Click here ...' : fullAddress}
              </ViewWrapper>
            )}
          />
        )}
      />
    );
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        actions: getOptions(),
      }}
    >
      <Wrapper data-testid={testIdStr}>
        <Flex className={'gap-1 flex-wrap'}>
          {[
            {
              key: 'name',
              label: 'Name',
              isRequired: true,
              value: contactCompany.name,
              placeholder: `The name of this ${typeName}`,
            },
            {
              key: 'email',
              label: 'Email',
              value: `${contactCompany.email || ''}`.trim(),
              placeholder: `The email of this ${typeName}`,
            },
            {
              key: 'contactNumber',
              label: 'Contact Number',
              value: `${contactCompany.contactNumber || ''}`.trim(),
              placeholder: `The phone number or mobile number`,
            },
          ].map((item) => {
            return (
              <div key={item.key} className={'details-col'}>
                <InlineEdit
                  readViewFitContainerWidth
                  isDisabled={isSaving}
                  testId={item.key}
                  label={item.label}
                  isRequired={item.isRequired}
                  placeHolder={item.placeholder}
                  value={item.value}
                  onConfirm={(value) => handleUpdated({ [item.key]: value })}
                />
              </div>
            );
          })}
        </Flex>

        <Flex className={'gap-1 flex-wrap address-div'}>
          <div className={'address-col'}>
            {getAddressEditPopup(
              'Address',
              'addressId',
              contactCompany.Address,
            )}
          </div>
          <div className={'address-col'}>
            {getAddressEditPopup(
              'Postal Address',
              'postalAddressId',
              contactCompany.PostalAddress,
            )}
          </div>
        </Flex>

        <Flex className={'gap-1 flex-wrap'}>
          {ContactCompanyHelper.showCategory(
            contactCompany.type as ContactCompanyTypes,
          ) ? (
            <div style={{ minWidth: '200px' }}>
              <div>
                <Label htmlFor={''}>Category</Label>
              </div>
              <ContactCompanyCategorySelector
                isClearable
                testId={'categoryId'}
                value={contactCompany.categoryId}
                appearance={'subtle'}
                isDisabled={isSaving}
                contactCompanyType={contactCompany.type as ContactCompanyTypes}
                onChange={(
                  selected: iOptionWithData<iContactCompanyCategory>,
                ) => {
                  handleUpdated({
                    categoryId: selected ? selected.value : null,
                  });
                }}
              />
            </div>
          ) : null}
          <div>
            <Toggle
              separatedLines
              testId={'isCompany'}
              label={`Is a company?`}
              isChecked={contactCompany.isCompany === true}
              onChange={() =>
                handleUpdated({
                  isCompany: contactCompany.isCompany === true ? false : true,
                })
              }
            />
          </div>
          {getCompanyDetails()}
        </Flex>

        <Flex className={'gap-1 flex-wrap'}>
          <div className={'comment-div'}>
            <InlineTextArea
              editViewProps={{
                maxHeight: '10rem',
              }}
              readViewStyle={{
                maxHeight: '10rem',
                minHeight: '10rem',
                overflowY: 'auto',
              }}
              readViewFitContainerWidth
              isDisabled={isSaving}
              testId={'comments'}
              label={'Comments'}
              placeHolder={`Some comments for this ${typeName}`}
              value={contactCompany.comments || ''}
              onConfirm={(value) => handleUpdated({ comments: value })}
            />
          </div>
        </Flex>

        <Tabs
          className={'activities'}
          tabs={[
            ...(contactCompany.type === ContactCompanyTypes.CLIENT
              ? [
                  {
                    eventKey: 'builds',
                    title: 'Builds',
                    tabContent: (
                      <BuildList
                        clientId={contactCompany.id}
                        allowDelete={false}
                        allowCreate={false}
                        showHeader={false}
                      />
                    ),
                  },
                ]
              : []),
            ...(contactCompany.isCompany === true
              ? [
                  {
                    eventKey: 'contact',
                    title: 'Contact',
                    tabContent: (
                      <ContactList
                        contactCompanyId={contactCompany.id}
                        headerProps={{
                          children: <Heading size={'small'}>Contacts</Heading>,
                        }}
                      />
                    ),
                  },
                ]
              : []),
            {
              eventKey: 'history',
              title: 'History',
              tabContent: <div>All history logs</div>,
            },
          ]}
        />
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default ContactCompanyDetailsPanel;
