import iBuildVariation from '../../types/buildVariation/iBuildVariation';
import ListComponent, {
  BaseListComponentProps,
} from '../frameWork/ListComponent';
import iBuildVariationItem from '../../types/buildVariation/iBuildVariationItem';
import BuildVariationItemService from '../../services/build/BuildVariationItemService';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import { iTableColumn } from '../../helpers/DynamicTableHelper';
import UtilsService from '../../services/UtilsService';
import { iEntityFormField } from '../form/EntityEditPanel';
import TextArea from '../frameWork/TextArea';
import React from 'react';
import TextField from '../frameWork/TextField';
import StringHelper from '../../helpers/StringHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';

export type iBuildVariationItemList = Omit<
  BaseListComponentProps<iBuildVariationItem>,
  'getFn' | 'getColumns' | 'componentName' | 'deactivateFn'
> & {
  buildVariation: iBuildVariation;
  allowDelete?: boolean;
  onSaved?: (data: iBuildVariationItem, isCreated: boolean) => void;
};
const BuildVariationItemList = ({
  buildVariation,
  ...props
}: iBuildVariationItemList) => {
  const getEditBtn = (
    params: ReturnType<typeof useListCrudHook<iBuildVariationItem>>,
    data?: iBuildVariationItem,
  ) => {
    return params.renderEntityEditPopBtn({
      editingEntity: data,
      entityName: 'Build Variation Item',
      createFn: (data) =>
        BuildVariationItemService.create({
          ...data,
          buildVariationId: buildVariation.id,
        }).then((data) => {
          if (props.onSaved) {
            props.onSaved(data, true);
          }
          return data;
        }),
      updateFn: (id, data) =>
        BuildVariationItemService.update(id, data).then((data) => {
          if (props.onSaved) {
            props.onSaved(data, false);
          }
          return data;
        }),
      getPopupTitle: (data?: iBuildVariationItem) => {
        if (`${data?.id || ''}`.trim() == '') {
          return `Creating a new BV Item under: ${buildVariation.variationNumber}`;
        }
        return `Editing Build Variation Item: ${data?.name || ''}`;
      },
      renderEditBtn: ({ entity, onClick }) => {
        return (
          <a
            onClick={onClick}
            className={'cursor-pointer'}
            data-testid={`edit-btn-${entity?.id || ''}`}
          >
            {data?.name || ''}
          </a>
        );
      },
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] => [
        {
          fieldName: 'name',
          label: 'Name',
          testId: 'name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
        },
        {
          fieldName: 'description',
          label: 'Description',
          testId: 'description',
          isDisabled,
          value: entity?.description || '',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextArea
                {...props}
                {...errorProps}
                placeholder={'Description'}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.value);
                }}
              />
            );
          },
        },
        {
          fieldName: 'price',
          testId: 'price',
          label: 'Price',
          isDisabled,
          isRequired: true,
          value: entity?.price || '',
          isValid: (value: string) => {
            const valStr = `${value || ''}`
              .trim()
              .replaceAll('$', '')
              .replaceAll(',', '');
            if (valStr !== '' && !StringHelper.isNumeric(valStr)) {
              return {
                isValid: false,
                errMessages: ['Price must be a number'],
              };
            }
            return { isValid: true, errMessages: [] };
          },
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextField
                {...props}
                {...errorProps}
                elemBeforeInput={<span className={'padding-left-sm'}>$</span>}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(
                    fieldName,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    event.target.value || '',
                  );
                }}
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (
    params: ReturnType<typeof useListCrudHook<iBuildVariationItem>>,
  ): iTableColumn<iBuildVariationItem>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }) => (
        <>
          <div>{getEditBtn(params, data)}</div>
          <small className={'ellipsis'}>
            <i>{data.description || ''}</i>
          </small>
        </>
      ),
    },
    {
      key: 'price',
      header: 'Price',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }) => UtilsService.formatIntoCurrency(data.price),
    },
  ];

  return (
    <ListComponent<iBuildVariationItem>
      selectiveColumnKey={SelectiveColKeys.BUILD_VARIATION_ITEM_LIST}
      {...props}
      componentName={'BuildVariationItemList'}
      getFnExtra={{
        sort: 'createdAt:DESC',
      }}
      getColumns={getColumns}
      deactivateFn={async (data) =>
        BuildVariationItemService.deactivate(data.id)
      }
      renderCreateBtn={getEditBtn}
      getFn={(params) => {
        return BuildVariationItemService.getAll({
          where: JSON.stringify({
            isActive: true,
            buildVariationId: buildVariation.id,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        });
      }}
    />
  );
};

export default BuildVariationItemList;
