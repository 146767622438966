import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iProduct from '../../types/product/iProduct';
import LeftAndRightPanel from '../layout/LeftAndRightPanel';
import { useState } from 'react';
import ProductService from '../../services/product/ProductService';
import { iConfigParams } from '../../services/AppService';
import Toaster from '../common/Toaster';
import Toggle from '../frameWork/Toggle';
import styled from 'styled-components';
import Tabs from '../frameWork/Tabs';
import ProductDetailsPanelHelper from './ProductDetailsPanelHelper';
import UtilsService from '../../services/UtilsService';
import FormField from '../frameWork/FormField';
import Flex from '../frameWork/Flex';
import { DatePicker } from '../frameWork/DateTimePicker';
import moment from 'moment';
import Lozenge from '../frameWork/Lozenge';

const Wrapper = styled.div`
  .flag-switch {
    label:first-child {
      font-weight: 500;
      font-size: 16px;
      margin-right: 2rem;
    }
  }
  .unit-price-wrapper {
    margin-bottom: 2rem;
    .unit-price {
      padding-top: 0.7rem;
      font-size: 24px;
    }
  }
`;

export type iProductDetailsPanel = iComponentWithPageHeader & {
  product: iProduct;
  isDisabled?: boolean;
  testId?: string;
  onSaved?: (saved: iProduct) => void;
  className?: string;
};

const ProductDetailsPanel = ({
  headerProps,
  product,
  onSaved,
  testId,
  className,
  isDisabled,
}: iProductDetailsPanel) => {
  const testIdStr = `${testId || ''}-product-details`;
  const [isSaving, setIsSaving] = useState(false);
  const tabItemsMap = ProductDetailsPanelHelper.getTabItemsMap({
    product,
    onSaved,
    isDisabled,
  });
  const [selectedKey, setSelectedKey] = useState<string>(
    Object.keys(tabItemsMap)[0] || '',
  );

  const handleUpdate = (data: iConfigParams) => {
    setIsSaving(true);
    ProductService.update(product.id, data)
      .then((resp) => {
        setIsSaving(false);
        onSaved && onSaved(resp);
      })
      .catch((error) => {
        setIsSaving(false);
        Toaster.showApiError(error);
      });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <Flex className={'gap-05 align-items-end'}>
            {product.name}
            {product.isDiscontinued && (
              <Lozenge appearance={'removed'}>Only view, no action</Lozenge>
            )}
          </Flex>
        ),
      }}
    >
      <Wrapper>
        <LeftAndRightPanel
          className={`product-details ${className || ''}`}
          testId={testIdStr}
          rightPanel={
            <>
              <DatePicker
                appearance={'subtle'}
                label={'Discontinue Date'}
                value={
                  product.discontinueDate
                    ? moment(product.discontinueDate).toISOString()
                    : undefined
                }
                onChange={(newDate) => {
                  handleUpdate({
                    discontinueDate: newDate
                      ? moment(newDate).endOf('day').utc().toISOString()
                      : null,
                  });
                }}
              />

              {product.isForSell ? (
                <FormField
                  className={'unit-price-wrapper'}
                  label={'Unit Price (Ex.)'}
                  render={() => {
                    return (
                      <div className={'unit-price'}>
                        {UtilsService.formatIntoCurrency(product.unitPrice)}
                      </div>
                    );
                  }}
                />
              ) : null}

              <Toggle
                testId={'isForSell'}
                className={'flag-switch'}
                label={'We sell this product'}
                helperMsg={'Change your selling prices'}
                isDisabled={isSaving || isDisabled || product.isDiscontinued}
                isChecked={product.isForSell}
                onChange={() =>
                  handleUpdate({
                    isForSell: !product.isForSell,
                  })
                }
              />
              <Toggle
                testId={'isForPurchase'}
                className={'flag-switch'}
                label={'We purchase this product'}
                helperMsg={'Click here to change purchase details'}
                isDisabled={isSaving || isDisabled || product.isDiscontinued}
                isChecked={product.isForPurchase}
                onChange={() =>
                  handleUpdate({
                    isForPurchase: !product.isForPurchase,
                  })
                }
              />
              <Toggle
                testId={'isForBuild'}
                className={'flag-switch'}
                label={'We use this product to build'}
                helperMsg={'Product can be used in the building process'}
                isDisabled={isSaving || isDisabled || product.isDiscontinued}
                isChecked={product.isForBuild}
                onChange={() =>
                  handleUpdate({
                    isForBuild: !product.isForBuild,
                  })
                }
              />
            </>
          }
        >
          <Tabs
            appearance={'tabs'}
            tabs={Object.values(tabItemsMap)}
            onSelect={setSelectedKey}
            activeKey={selectedKey}
          />
        </LeftAndRightPanel>
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default ProductDetailsPanel;
