import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import iFinanceType from '../../types/finance/iFinanceType';
import FinanceTypeService from '../../services/finance/FinanceTypeService';

export type iFinanceTypeSelector = iPreloadAsyncSelectProps;
const FinanceTypeSelector = (props: iFinanceTypeSelector) => {
  return (
    <PreloadedAsyncSelector<iFinanceType>
      placeholder={'Select a Finance Type ...'}
      {...props}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return FinanceTypeService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        FinanceTypeService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default FinanceTypeSelector;
