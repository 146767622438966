import { ReactNode, useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import iUser from '../../types/system/iUser';
import UserService from '../../services/system/UserService';
import styled from 'styled-components';
import StringHelper from '../../helpers/StringHelper';
import UserHelper from '../../helpers/UserHelper';

const Wrapper = styled.div``;

export type iUserDiv = {
  id: string;
  testId?: string;
  className?: string;
  render?: (user: iUser) => ReactNode;
};
const UserDiv = ({ testId, id, className, render }: iUserDiv) => {
  const componentName = 'User-div';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [user, setUser] = useState<iUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (`${id || ''}`.trim() === '' || !StringHelper.isUUID(id)) {
      setUser(null);
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    UserService.get(id)
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setUser(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [id]);

  const getContent = () => {
    if (!StringHelper.isUUID(id)) {
      return id;
    }

    if (!user || isLoading) {
      return null;
    }

    if (render) {
      return render(user);
    }

    return <div>{UserHelper.getFullName(user)}</div>;
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default UserDiv;
