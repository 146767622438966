import styled from 'styled-components';
import ComponentPropsHelper from './ComponentPropsHelper';
import { ReactNode } from 'react';
import Icons from './Icons';
import Tokens from './Tokens';

const DefaultAdvBtnWrapper = styled.a`
  display: flex;
  flex-wrap: wrap;
  gap: ${Tokens('space.050')};
  align-items: start;
  justify-content: start;
`;

type iGetDefaultAdvBtn = {
  onClick: () => void;
  isShowingAdv?: boolean;
  className?: string;
};
export const getDefaultAdvBtn = ({
  onClick,
  className,
  isShowingAdv = false,
}: iGetDefaultAdvBtn) => {
  return (
    <DefaultAdvBtnWrapper
      onClick={onClick}
      data-testid={'adv-search-btn'}
      className={`cursor-pointer ${className || ''}`}
      title={`${isShowingAdv === true ? 'hide' : 'show'} advanced search options`}
    >
      Adv.
      {isShowingAdv ? (
        <Icons.ChevronUpIcon label={''} />
      ) : (
        <Icons.ChevronDownIcon label={''} />
      )}
    </DefaultAdvBtnWrapper>
  );
};

const Wrapper = styled.div`
  padding: ${Tokens('space.200', '1rem')};
  border: 1px solid ${Tokens('color.background.accent.gray.subtle', '#eee')};
  border-radius: ${Tokens('space.075')};
  position: relative;
  .title-div {
    position: absolute;
    left: 0.75rem;
    top: -0.75rem;
    background-color: white;
    padding: 0px ${Tokens('space.100', '0.5rem')};
  }
`;

type iAdvanceSearchPanel = {
  className?: string;
  testId?: string;
  title?: ReactNode;
  children: ReactNode;
};
const AdvanceSearchPanel = ({
  className,
  testId,
  children,
  title,
}: iAdvanceSearchPanel) => {
  const props = ComponentPropsHelper.getWrapperProps({
    componentName: 'adv-search-panel',
    className,
    testId,
  });

  const getTitle = () => {
    if (title) {
      return title;
    }
    return <>Adv. Search</>;
  };

  return (
    <Wrapper className={props.classNameStr} data-testid={props.testIdStr}>
      <div className={'title-div'}>{getTitle()}</div>
      {children}
    </Wrapper>
  );
};

export default AdvanceSearchPanel;
