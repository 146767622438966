import iAddress from '../types/system/iAddress';

const getFullAddress = (
  address?: iAddress | null,
  showStreetNo = true,
  showLotNo = true,
  showStreet = true,
): string => {
  if (!address) {
    return '';
  }

  const suburb = `${address.suburb || ''}`.trim();
  const state = `${address.state || ''}`.trim();
  const lotNo = `${address.lotNo || ''}`.trim();

  return [
    address.name || '',
    showStreetNo === true ? address.streetNo || '' : '',
    showLotNo === true && lotNo !== ''
      ? showStreetNo === true
        ? `[Lot ${lotNo}]`
        : `Lot ${lotNo}`
      : '',
    showStreet === true ? address.street || '' : '',
    suburb === '' ? '' : `${suburb.toUpperCase()},`,
    state === '' ? '' : `${state.toUpperCase()}`,
    address.postcode,
  ]
    .map((str) => `${str || ''}`.trim())
    .filter((str) => str !== '')
    .join(' ')
    .trim();
};

const getFullStreetAddress = (address?: iAddress | null): string => {
  return getFullAddress(address, true, false);
};

const getFullLotAddress = (address?: iAddress | null): string => {
  return getFullAddress(address, false, true);
};

const AddressHelper = {
  getFullAddress,
  getFullStreetAddress,
  getFullLotAddress,
};

export default AddressHelper;
