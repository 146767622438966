import React, { useState } from 'react';
import iEntityStatus from '../../../types/status/iEntityStatus';
import iWorkflow from '../../../types/system/iWorkflow';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import EntityStatusService from '../../../services/status/EntityStatusService';
import WorkflowService from '../../../services/system/WorkflowService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../../common/Toaster';
import WorkflowDiagramHelper, {
  WFDiagramIds,
} from '../diagram/WorkflowDiagramHelper';
import { iNode, useEdges, useNodes } from '../../frameWork/ReactFlowRenderer';
import Button from '../../frameWork/Button';

export type iWorkflowEditButtons = {
  onPreCheck?: () => boolean;
  onSaveSuccessfully?: (newWorkflow: iWorkflow) => void;
  state: iWFDiagramState;
  onCancel: () => void;
};
const WorkflowEditButtons = ({
  state,
  onCancel,
  onSaveSuccessfully,
  onPreCheck,
}: iWorkflowEditButtons) => {
  const [isSaving, setIsSaving] = useState(false);
  const nodes = useNodes();
  const edges = useEdges();

  if (!state.workflow) {
    return null;
  }

  const handleSave = async () => {
    if (!state.workflow) {
      return;
    }

    if (onPreCheck && onPreCheck() !== true) {
      return;
    }
    setIsSaving(true);
    try {
      let newEdges = [...edges];
      const usedStatusIds: string[] = [];
      // update and insert new statuses
      const newNodes = await Promise.all(
        nodes.map(async (node: iNode) => {
          if (!('status' in node.data)) {
            return node;
          }
          if (
            `${node.data.status.id || ''}`.startsWith(
              WFDiagramIds.NODE_ID_NEW_NODE,
            )
          ) {
            const newStatus = await EntityStatusService.create({
              name: node.data?.status.name,
              code: node.data?.status.code,
              description: node.data?.status.description,
              entityStatusCategoryId: node.data?.status.entityStatusCategoryId,
              entityStatusTypeId: state.entityStatusTypeId,
            });
            usedStatusIds.push(newStatus.id);
            const newNode = WorkflowDiagramHelper.getStatusNode(
              newStatus,
              node.position,
            );
            newEdges = [
              ...newEdges.map((edge) => ({
                ...edge,
                source: edge.source === node.id ? newNode.id : edge.source,
                target: edge.target === node.id ? newNode.id : edge.target,
              })),
            ];
            return newNode;
          }
          const newStatus = await EntityStatusService.update(
            node.data?.status.id,
            node.data?.status,
          );
          usedStatusIds.push(newStatus.id);
          return WorkflowDiagramHelper.getStatusNode(newStatus, node.position);
        }),
      );

      // delete every other statuses
      await Promise.all(
        state.entityStatuses
          .filter((status) => usedStatusIds.indexOf(status.id) < 0)
          .map((status: iEntityStatus) => {
            return EntityStatusService.deactivate(status.id);
          }),
      );
      const updatedWorkflow = await WorkflowService.update(state.workflow.id, {
        wf: WorkflowDiagramHelper.formatDiagramForWorkFlow(
          newNodes,
          newEdges,
          state,
        ),
      });

      Toaster.showToast('Workflow saved successfully.', TOAST_TYPE_SUCCESS);
      setIsSaving(false);
      if (onSaveSuccessfully) {
        onSaveSuccessfully(updatedWorkflow);
      } else {
        window.location.reload();
      }
    } catch (err) {
      Toaster.showApiError(err);
      setIsSaving(false);
    }
  };

  return (
    <>
      <Button
        appearance={'primary'}
        onClick={handleSave}
        isLoading={isSaving === true}
        testId={'save-btn'}
        isDisabled={state.errors.length > 0}
      >
        Update workflow
      </Button>
      <Button
        appearance={'subtle'}
        onClick={() => onCancel()}
        isDisabled={isSaving === true}
      >
        Discard changes
      </Button>
    </>
  );
};

export default WorkflowEditButtons;
