import styled from 'styled-components';
import EntityNames from '../../../../../helpers/EntityNames';
import RelationTypes from '../../../../../helpers/RelationTypes';
import iBuild from '../../../../../types/build/iBuild';
import AppointmentList from './AppointmentList';
import BuildCheckList from './BuildCheckList';
import ColourSheetList from './ColourSheetList';
import tokens from '../../../../frameWork/Tokens';

type iBuildDetailsColoursOverview = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};
const Wrapper = styled.div`
  .space-bottom {
    margin-bottom: ${tokens('space.200', '1rem')};
  }
`;

const BuildDetailsColoursOverview = ({
  build,
}: iBuildDetailsColoursOverview) => {
  return (
    <Wrapper>
      <ColourSheetList buildId={build.id} />
      <div className="space-bottom" />
      <BuildCheckList
        buildId={build.id}
        type={RelationTypes.BuildCheck_Colours}
        allowDelete
        allowEdit
      />
      <div className="space-bottom" />
      <AppointmentList
        entityId={build.id}
        entityName={EntityNames.Build}
        type={RelationTypes.Build_Colours_Appointments}
        allowDelete
        allowEdit
      />
    </Wrapper>
  );
};

export default BuildDetailsColoursOverview;
