import iBuildVariation from '../../types/buildVariation/iBuildVariation';
import styled from 'styled-components';
import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import { iOptionWithData } from '../frameWork/Select';
import iUser from '../../types/system/iUser';
import UserSelector from '../user/UserSelector';
import { iUseAsForm } from '../form/EntityEditPanel';
import TextField from '../frameWork/TextField';
import TextArea from '../frameWork/TextArea';
import Flex from '../frameWork/Flex';
import { getErrorProps } from '../form/FormError';
import FormField from '../frameWork/FormField';
import UtilsService from '../../services/UtilsService';
import { ViewWrapper } from '../frameWork/InlineEdit';

const Wrapper = styled.div``;

export type iBuildVariationEditPanelProps = WrapperProps & {
  buildVariation?: iBuildVariation;
  buildId: string;
  isDisabled?: boolean;
  useAsForm?: iUseAsForm;
};
const BuildVariationEditPanel = ({
  testId,
  className,
  buildVariation,
  isDisabled,
  useAsForm,
}: iBuildVariationEditPanelProps) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'BuildVariationEditPanel',
    testId,
    className,
  });

  return (
    <Wrapper data-testid={testIdStr} className={classNameStr}>
      <Flex className={'gap-05'}>
        <UserSelector
          label={'Approved By'}
          testId={`approvedById`}
          isClearable
          isDisabled={isDisabled}
          {...getErrorProps({
            error: useAsForm?.errorMap,
            fieldName: 'approvedById',
          })}
          value={buildVariation?.approvedById}
          onChange={(selected: iOptionWithData<iUser>) => {
            useAsForm?.onFieldChange('approvedById', selected?.value || null);
          }}
        />
        <TextField
          testId={`payableAt`}
          label={'Payable At'}
          isDisabled={isDisabled}
          {...getErrorProps({
            error: useAsForm?.errorMap,
            fieldName: 'payableAt',
          })}
          value={buildVariation?.payableAt || ''}
          onChange={(event) => {
            useAsForm?.onFieldChange(
              'payableAt',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              event.target.value || '',
            );
          }}
        />
        <FormField
          label={'Total Amount'}
          render={() => (
            <ViewWrapper
              className={'cursor-auto text-right'}
              data-testid={`totalAmt`}
            >
              <b>
                {UtilsService.formatIntoCurrency(buildVariation?.totalAmt || 0)}
              </b>
            </ViewWrapper>
          )}
        />
      </Flex>
      <TextArea
        testId={`notes`}
        label={'Notes'}
        isDisabled={isDisabled}
        value={buildVariation?.notes || ''}
        onChange={(event) => {
          useAsForm?.onFieldChange('notes', event.target.value || '');
        }}
      />
    </Wrapper>
  );
};
export default BuildVariationEditPanel;
