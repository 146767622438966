import React from 'react';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import Lozenge from '../frameWork/Lozenge';
import iBaseType from '../../types/iBaseType';
import { DELETE } from './Warehouse.constants';
import WarehouseService from '../../services/warehouseLocation/WarehouseLocationService';

type iRenderDeleteBtn<T extends iBaseType> = {
  deletingModel: T;
  getDisplayName: (data: T) => string;
  onDeleted?: () => void;
  showDeletedMsg?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteFn?: () => Promise<any>;
};
const DeleteBtn = <T extends iBaseType>({
  deletingModel,
  getDisplayName,
  onDeleted,
  deleteFn = () => WarehouseService.deactivate(deletingModel.id),
}: iRenderDeleteBtn<T>) => {
  return (
    <DeleteConfirmPopupBtn
      testId={`delete-btn-${deletingModel.id}`}
      titleId={`delete-btn-${deletingModel.id}`}
      message={
        <>
          You are about to delete
          <Lozenge>{getDisplayName(deletingModel)}</Lozenge>.
        </>
      }
      deleteFnc={deleteFn}
      btnTxt={DELETE}
      onDeleted={() => {
        Toaster.showToast(
          <>
            <b>
              <u>{getDisplayName(deletingModel)}</u>
            </b>
            {' Deleted'}
          </>,

          TOAST_TYPE_SUCCESS,
        );
        onDeleted && onDeleted();
      }}
    />
  );
};

export default DeleteBtn;
