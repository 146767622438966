import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import iBalRate from '../../types/system/iBalRate';
import BalRateService from '../../services/system/BalRateService';

export type iBalRateSelector = iPreloadAsyncSelectProps;
const BalRateSelector = (props: iBalRateSelector) => {
  return (
    <PreloadedAsyncSelector<iBalRate>
      placeholder={'Select a Bal Rate ...'}
      {...props}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return BalRateService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        BalRateService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default BalRateSelector;
