import ProtectedPage from '../../layouts/ProtectedPage';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { URL_PRODUCT_TYPES } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';
import iProductType from '../../types/product/iProductType';
import ProductTypeService from '../../services/product/ProductTypeService';
import ProductTypeDetailsPanel from '../../components/productType/ProductTypeDetailsPanel';

const ProductTypeDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [updatedProductType, setUpdatedProductType] = useState<
    iProductType | undefined
  >(undefined);
  const navigate = useNavigate();

  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iProductType>
        emptyStateProps={{
          description: `Couldn't find requested Product Type with id: ${idStr}.`,
        }}
        updatedModel={updatedProductType}
        getDataFn={() =>
          ProductTypeService.get(idStr, {
            include: 'CreatedBy,UpdatedBy',
          })
        }
      >
        {({ data, getDataFn }) => (
          <ProductTypeDetailsPanel
            productType={data}
            onSaved={() =>
              getDataFn().then((resp) => setUpdatedProductType(resp))
            }
            allowDelete
            allowEdit
            allowCreate
            headerProps={{
              children: data.name,
              breadcrumbItems: [
                {
                  text: `Product Types`,
                  href: URL_PRODUCT_TYPES,
                },
                {
                  text: data.name,
                  component: () => (
                    <InlineFlex className={'align-items-center'}>
                      {data.name}
                    </InlineFlex>
                  ),
                },
              ],
            }}
            onDeleted={() => navigate(URL_PRODUCT_TYPES)}
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default ProductTypeDetailsPage;
