import styled from 'styled-components';
import { ReactNode } from 'react';
import Spinner from './Spinner';
import ComponentPropsHelper from './ComponentPropsHelper';

const Wrapper = styled.div``;

export type iComponentWrapper = {
  componentName: string;
  children: ReactNode | ReactNode[];
  testId?: string;
  className?: string;
  isLoading?: boolean;
};
const ComponentWrapper = ({
  componentName,
  testId,
  className,
  children,
  isLoading = false,
}: iComponentWrapper) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName,
    testId,
    className,
  });
  return (
    <Wrapper data-testid={testIdStr} className={classNameStr}>
      {isLoading === true ? <Spinner /> : children}
    </Wrapper>
  );
};
export default ComponentWrapper;
