import iBaseType from '../iBaseType';
import iAddress from '../system/iAddress';
import iContactCompanyCategory from './iContactCompanyCategory';

export enum ContactCompanyTypes {
  CLIENT = 'CLIENT',
  SUPPLIER = 'SUPPLIER',
  GAS_AUTHORITY = 'GAS_AUTHORITY',
  WATER_AUTHORITY = 'WATER_AUTHORITY',
  ELECTRICITY_SUPPLIER = 'ELECTRICITY_SUPPLIER',
  SUB_CONTRACTORS = 'SUB_CONTRACTORS',
  BROKERS = 'BROKERS',
}

type iContactCompany = iBaseType & {
  name: string;
  isCompany: boolean;
  type: string | null;
  abn?: string | null;
  acn?: string | null;
  addressId: string | null;
  postalAddressId: string | null;
  contactNumber: string | null;
  categoryId: string | null;
  email: string | null;
  comments: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
  Address?: iAddress | null;
  PostalAddress?: iAddress | null;
  Category?: iContactCompanyCategory | null;
};

export default iContactCompany;
