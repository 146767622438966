import iEntityStatusType from '../../types/status/iEntityStatusType';
import { WrapperProps } from '../frameWork/ComponentPropsHelper';
import WorkflowEdit from '../workflow/WorkflowEdit';

type iEntityStatusTypeDetailsPanel = WrapperProps & {
  entityStatusType: iEntityStatusType;
  onCancel: () => void;
};

const EntityStatusTypeDetailsPanel = ({
  entityStatusType,
  onCancel,
  testId,
  className,
}: iEntityStatusTypeDetailsPanel) => {
  return (
    <WorkflowEdit
      testId={testId}
      className={className}
      entityStatusTypeId={entityStatusType.id}
      onCancel={onCancel}
    />
  );
};

export default EntityStatusTypeDetailsPanel;
