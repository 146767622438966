import ServiceHelper from '../helper/ServiceHelper';
import iBuildColourNote from '../../types/build/iBuildColourNote';

const endPoint = '/buildColourNote';
const BuildColourNoteService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildColourNote>(endPoint),
  get: ServiceHelper.getById<iBuildColourNote>(endPoint),
  create: ServiceHelper.create<iBuildColourNote>(endPoint),
  update: ServiceHelper.updateById<iBuildColourNote>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildColourNote>(endPoint),
};

export default BuildColourNoteService;
