enum EntityNames {
  Address = 'Address',
  Appointment = 'Appointment',
  User = 'User',
  Build = 'Build',
  BuildPack = 'BuildPack',
  BuildCheck = 'BuildCheck',
  BuildStyle = 'BuildStyle',
  BuildStylePrice = 'BuildStylePrice',
  Product = 'Product',
  OrderPageTemplate = 'OrderPageTemplate',
  BalRate = 'BalRate',
  Bank = 'Bank',
  BuildPlan = 'BuildPlan',
  BuildPlanChange = 'BuildPlanChange',
  Message = 'Message',
  Attribute = 'Attribute',
  Temporary = 'Temporary',
  Estate = 'Estate',
  Finance = 'Finance',
  BuildChangeItem = 'BuildChangeItem',
  EntityStatusType = 'EntityStatusType',
  BuildSurveyItem = 'BuildSurveyItem',

  BuildColourNote_ApprovalStatus = 'BuildColourNote_ApprovalStatus',
  BuildColourNote_Status = 'BuildColourNote_Status',
}

export default EntityNames;
