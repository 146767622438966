import ServiceHelper from '../helper/ServiceHelper';
import iFinance from '../../types/system/iFinance';

const endPoint = '/finance';

const FinanceService = {
  getAll: ServiceHelper.getPaginatedAll<iFinance>(endPoint),
  get: ServiceHelper.getById<iFinance>(endPoint),
  create: ServiceHelper.create<iFinance>(endPoint),
  update: ServiceHelper.updateById<iFinance>(endPoint),
  deactivate: ServiceHelper.deactivateById<iFinance>(endPoint),
};

export default FinanceService;
