import ProtectedPage from '../../layouts/ProtectedPage';
import ContactCompanyCategoryList from '../../components/contact/ContactCompanyCategoryList';
import { useParams } from 'react-router-dom';
import { ContactCompanyTypes } from '../../types/contact/iContactCompany';
import ContactCompanyService from '../../services/contact/ContactCompanyService';

const ContactCompanyCategoriesPage = () => {
  const { type } = useParams();

  const contactCompanyType = `${type || ''}`.trim() as ContactCompanyTypes;
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `${ContactCompanyService.getHumanReadableType(contactCompanyType)} Categories:`,
      }}
    >
      {(headerProps) => (
        <ContactCompanyCategoryList
          allowDelete
          allowCreate
          headerProps={headerProps}
          contactCompanyType={contactCompanyType}
        />
      )}
    </ProtectedPage>
  );
};

export default ContactCompanyCategoriesPage;
