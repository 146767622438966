import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { iGetDefaultBtn } from '../common/PopupModal';
import React, { useState } from 'react';
import iMessage from '../../types/message/iMessage';
import Tabs from '../frameWork/Tabs';
import MessageList from './MessageList';
import EmailSendingPanel from './component/EmailSendingPanel';
import MathHelper from '../../helpers/MathHelper';

type iEmailPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  buildId?: string;
  attributeId?: string;
  title?: string;
  testId?: string;
  showLogs?: boolean;
  onCancel?: (setModelShowing: iSetShowingModalFn) => void;
  actionBtnProps?: Omit<iGetDefaultBtn, 'onClick'>;
  cancelBtnProps?: Omit<iGetDefaultBtn, 'onClick'>;
  onSent?: (sent: iMessage, setModelShowing: iSetShowingModalFn) => void;
  emailBody?: string;
  emailSubject?: string;
  emailRecipients?: string[];
  emailCCs?: string[];
  emailBCCs?: string[];
};

const EmailPopupBtn = ({
  title,
  buildId,
  attributeId,
  testId,
  onCancel,
  actionBtnProps,
  cancelBtnProps,
  onSent,
  showLogs = false,
  emailBody,
  emailSubject,
  emailRecipients,
  emailCCs,
  emailBCCs,
  ...props
}: iEmailPopupBtn) => {
  const ComponentName = 'EmailPopupBtn';
  const testIdStr = `${testId || ''}-${ComponentName}`;
  const [resetForm, setResetForm] = useState(0);

  const reloadForm = () => {
    setResetForm(MathHelper.add(resetForm, 1));
  };

  const getSendMsgBody = (setModelShowing: iSetShowingModalFn) => {
    return (
      <EmailSendingPanel
        emailBody={emailBody}
        emailSubject={emailSubject}
        emailRecipients={emailRecipients}
        emailCCs={emailCCs}
        emailBCCs={emailBCCs}
        attributeId={attributeId}
        buildId={buildId}
        forceResetForm={resetForm}
        actionBtnProps={actionBtnProps}
        cancelBtnProps={cancelBtnProps}
        onCancel={() => {
          reloadForm();
          if (onCancel) {
            onCancel(setModelShowing);
            return;
          }
          setModelShowing(false);
        }}
        onSent={(msg: iMessage) => {
          reloadForm();
          if (onSent) {
            onSent(msg, setModelShowing);
            return;
          }
          setModelShowing(false);
        }}
      />
    );
  };

  const getBody = (setModelShowing: iSetShowingModalFn) => {
    if (!showLogs) {
      return getSendMsgBody(setModelShowing);
    }
    return (
      <Tabs
        appearance={'tabs'}
        tabs={[
          {
            eventKey: 'sending',
            title: 'Sending',
            tabContent: getSendMsgBody(setModelShowing),
          },
          {
            eventKey: 'logs',
            title: 'Logs',
            tabContent: (
              <MessageList buildId={buildId} attributeId={attributeId} />
            ),
          },
        ]}
      />
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={testIdStr}
      modalProps={(setModelShowing) => ({
        width: '90%',
        maxWidth: '800px',
        ...props.modalProps,
        onClose: () => {
          reloadForm();
          if (onCancel) {
            onCancel(setModelShowing);
            return;
          }
          setModelShowing(false);
        },
        title: title || 'Email',
        shouldScrollInViewport: true,
        body: getBody(setModelShowing),
        footer: <div />,
      })}
    />
  );
};

export default EmailPopupBtn;
