import ServiceHelper from '../helper/ServiceHelper';
import iBuildVariationItem from '../../types/buildVariation/iBuildVariationItem';

const endPoint = '/buildVariationItem';
const BuildVariationItemService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildVariationItem>(endPoint),
  get: ServiceHelper.getById<iBuildVariationItem>(endPoint),
  create: ServiceHelper.create<iBuildVariationItem>(endPoint),
  update: ServiceHelper.updateById<iBuildVariationItem>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildVariationItem>(endPoint),
};

export default BuildVariationItemService;
