import iWorkflow from '../../../types/system/iWorkflow';
import iEntityStatusType from '../../../types/status/iEntityStatusType';
import iEntityStatusCategory from '../../../types/status/iEntityStatusCategory';
import iEntityStatus from '../../../types/status/iEntityStatus';
import { iEdge, iNode } from '../../frameWork/ReactFlowRenderer';

export type iWFDiagramState = {
  isLoading: boolean;
  entityStatusTypeId: string;
  workflow: iWorkflow | null;
  entityStatusType: iEntityStatusType | null;
  entityStatusCategories: iEntityStatusCategory[];
  entityStatuses: iEntityStatus[];
  initials: { nodes: iNode[]; edges: iEdge[] };
  errors: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  setNodes: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setEdges: Function;
};

export const WKDiagramInitialState: iWFDiagramState = {
  isLoading: false,
  initials: { nodes: [], edges: [] },
  entityStatusTypeId: '',
  workflow: null,
  entityStatusType: null,
  entityStatusCategories: [],
  entityStatuses: [],
  errors: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNodes: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEdges: () => {},
};

export enum WKDiagramActionKind {
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Update = 'UPDATE',
}
export type WKDiagramAction = {
  type: WKDiagramActionKind;
  payload?: iWFDiagramState;
};

const WorkflowDiagramReducer = (
  state: iWFDiagramState,
  action: WKDiagramAction,
) => {
  switch (action.type) {
    case WKDiagramActionKind.Loading:
      return {
        ...state,
        isLoading: true,
      };
    case WKDiagramActionKind.Loaded:
      return {
        ...state,
        isLoading: false,
      };
    case WKDiagramActionKind.Update:
      return {
        ...state,
        ...(action.payload || {}),
        isLoading: false,
      };
    default:
      throw new Error();
  }
};

export default WorkflowDiagramReducer;
