import iUser from '../types/system/iUser';
import iContact from '../types/contact/iContact';

const getFullName = (user?: iUser | iContact | null) => {
  return [`${user?.firstName || ''}`.trim(), `${user?.lastName || ''}`.trim()]
    .join(' ')
    .trim();
};

const UserHelper = {
  getFullName,
};

export default UserHelper;
