import ServiceHelper from '../helper/ServiceHelper';
import iProductPrice from '../../types/product/iProductPrice';

const endPoint = '/productPrice';

const ProductPriceService = {
  getAll: ServiceHelper.getPaginatedAll<iProductPrice>(endPoint),
  get: ServiceHelper.getById<iProductPrice>(endPoint),
  create: ServiceHelper.create<iProductPrice>(endPoint),
  update: ServiceHelper.updateById<iProductPrice>(endPoint),
  deactivate: ServiceHelper.deactivateById<iProductPrice>(endPoint),
};

export default ProductPriceService;
