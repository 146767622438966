import ListComponent, { iListComponent } from '../frameWork/ListComponent';
import iBuildVariation from '../../types/buildVariation/iBuildVariation';
import BuildVariationService from '../../services/build/BuildVariationService';
import DynamicTableHelper, {
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import UserHelper from '../../helpers/UserHelper';
import UtilsService from '../../services/UtilsService';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildVariationEditPopupBtn from './BuildVariationEditPopupBtn';
import { getCreateIconBtn } from '../common/PageTitleWithCreateBtn';

export type iBuildVariationList = Omit<
  iListComponent<iBuildVariation>,
  'getFn' | 'getColumns' | 'componentName' | 'deactivateFn'
> & {
  buildId: string;
};
const BuildVariationList = ({ buildId, ...props }: iBuildVariationList) => {
  const getEditBtn = (
    props: ReturnType<typeof useListCrudHook<iBuildVariation>>,
    data?: iBuildVariation,
  ) => {
    return (
      <BuildVariationEditPopupBtn
        buildId={buildId}
        buildVariation={data}
        onSaved={(saved, isCreated) => {
          if (isCreated === true) {
            props.onRefreshWhenCreated();
            return;
          }
          props.onRefresh();
        }}
        renderBtn={(onClick) => {
          if (`${data?.id || ''}`.trim() === '') {
            return getCreateIconBtn({
              onClick,
              testId: 'bv-create-btn',
            });
          }
          return (
            <a
              onClick={onClick}
              className={'cursor-pointer'}
              data-testid={`edit-btn-${data?.id || ''}`}
            >
              {data?.variationNumber || ''}
            </a>
          );
        }}
      />
    );
  };

  const getColumns = (
    props: ReturnType<typeof useListCrudHook<iBuildVariation>>,
  ): iTableColumn<iBuildVariation>[] => [
    {
      key: 'variationNumber',
      header: 'Variation',
      isDefault: true,
      cell: ({ data }) => (
        <>
          <div>{getEditBtn(props, data)}</div>
          <small className={'ellipsis'}>
            <i>{data.notes}</i>
          </small>
        </>
      ),
    },
    {
      key: 'requestedAt',
      header: 'Requested At',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => DynamicTableHelper.displayDateCell(data.requestedAt),
    },
    {
      key: 'approvedBy',
      header: 'Approved By',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => UserHelper.getFullName(data.ApprovedBy),
    },
    {
      key: 'payableAt',
      header: 'Payable At',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => data.payableAt || '',
    },
    {
      key: 'totalAmt',
      header: 'Total Amt',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => UtilsService.formatIntoCurrency(data.totalAmt),
    },
  ];

  return (
    <ListComponent<iBuildVariation>
      selectiveColumnKey={SelectiveColKeys.BUILD_VARIATION_LIST}
      renderCreateBtn={getEditBtn}
      {...props}
      deactivateFn={(data) => BuildVariationService.deactivate(data.id)}
      componentName={'BuildVariationList'}
      getColumns={getColumns}
      getFnExtra={{
        sort: 'createdAt:DESC',
      }}
      getFn={(params) => {
        return BuildVariationService.getAll({
          where: JSON.stringify({
            buildId,
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,ApprovedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        });
      }}
    />
  );
};

export default BuildVariationList;
