import ServiceHelper from '../helper/ServiceHelper';
import iBuildVariation from '../../types/buildVariation/iBuildVariation';

const endPoint = '/buildVariation';
const BuildVariationService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildVariation>(endPoint),
  get: ServiceHelper.getById<iBuildVariation>(endPoint),
  create: ServiceHelper.create<iBuildVariation>(endPoint),
  update: ServiceHelper.updateById<iBuildVariation>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildVariation>(endPoint),
};

export default BuildVariationService;
