import ProtectedPage from '../../layouts/ProtectedPage';
import { useParams } from 'react-router-dom';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';
import WarehouseLocationService from '../../services/warehouseLocation/WarehouseLocationService';
import WarehouseLocationDetails from '../../components/warehouseLocation/WarehouseLocationDetails';
import { URL_WAREHOUSE_LIST } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';
import React, { useState } from 'react';
import { BreadcrumbsItemProps } from '../../components/frameWork/Breadcrumbs';
import Lozenge from '../../components/frameWork/Lozenge';
import MathHelper from '../../helpers/MathHelper';

const WarehouseLocationDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [version, setVersion] = useState(0);

  const getListBreadCrumbs = (
    warehouseLocation: iWarehouseLocation,
  ): BreadcrumbsItemProps | null => {
    const name = `${warehouseLocation.name || ''}`.trim();
    if (name === '') {
      return null;
    }

    return {
      text: `${WarehouseLocationService.getHumanReadableType(name)} List`,
      href: URL_WAREHOUSE_LIST.replace(':name', name),
    };
  };

  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iWarehouseLocation>
        forceReload={version}
        emptyStateProps={{
          description: `Couldn't find requested Warehouse Location with id: ${idStr}.`,
        }}
        getDataFn={() =>
          WarehouseLocationService.get(idStr, {
            include: 'CreatedBy,UpdatedBy',
          })
        }
      >
        {({ data: warehouseLocation }) => {
          const listBreadCrumbs = getListBreadCrumbs(warehouseLocation);
          const breadcrumbItems =
            listBreadCrumbs === null
              ? []
              : [
                  listBreadCrumbs,
                  {
                    text: `${warehouseLocation.name}`,
                    component: () => (
                      <InlineFlex className={'align-items-center'}>
                        {warehouseLocation.name}
                      </InlineFlex>
                    ),
                  },
                ];
          return (
            <WarehouseLocationDetails
              warehouseLocation={warehouseLocation}
              onSaved={() => setVersion(MathHelper.add(version, 1))}
              headerProps={{
                children: (
                  <>
                    {warehouseLocation.name}{' '}
                    <Lozenge>{warehouseLocation.name}</Lozenge>
                  </>
                ),
                breadcrumbItems,
              }}
            />
          );
        }}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default WarehouseLocationDetailsPage;
