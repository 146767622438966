import ServiceHelper from '../helper/ServiceHelper';
import iBuildChangeItem from '../../types/build/iBuildChangeItem';

const endPoint = '/buildChangeItem';
const BuildChangeItemService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildChangeItem>(endPoint),
  get: ServiceHelper.getById<iBuildChangeItem>(endPoint),
  create: ServiceHelper.create<iBuildChangeItem>(endPoint),
  update: ServiceHelper.updateById<iBuildChangeItem>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildChangeItem>(endPoint),
};

export default BuildChangeItemService;
