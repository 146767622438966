import React from 'react';
import useListCrudHook from '../../../../hooks/useListCrudHook/useListCrudHook';
import AppointmentService from '../../../../../services/build/AppointmentService';
import iAppointment from '../../../../../types/build/iAppointment';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../../../../helpers/DynamicTableHelper';
import Flex from '../../../../frameWork/Flex';
import TextArea from '../../../../frameWork/TextArea';
import EntityStatusSelector from '../../../../entityStatus/EntityStatusSelector';
import { iOptionWithData } from '../../../../frameWork/Select';
import { iEntityFormField } from '../../../../form/EntityEditPanel';
import Icons from '../../../../frameWork/Icons';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../../../../common/PageTitleWithCreateBtn';
import moment from 'moment';
import DateTimePicker, {
  formatDate,
} from '../../../../frameWork/DateTimePicker';
import ComponentWithPageHeader from '../../../../common/ComponentWithPageHeader';
import Heading from '../../../../frameWork/Heading';
import iEntityStatus from '../../../../../types/status/iEntityStatus';
import EntityNames from '../../../../../helpers/EntityNames';
import EntityStatusLozenge from '../../../../entityStatus/EntityStatusLozenge';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';

type iAppointmentList = {
  entityId: string;
  entityName: string;
  type: string;
  allowEdit: boolean;
  allowDelete: boolean;
  isDisabled?: boolean;
  onDeleted?: (deleted: iAppointment) => void;
  onSaved?: (saved: iAppointment, isCreated: boolean) => void;
};

const AppointmentList = ({
  entityId,
  entityName,
  type,
  allowEdit,
  allowDelete,
  isDisabled,
  onDeleted,
  onSaved,
}: iAppointmentList) => {
  const testIdStr = 'appointment-list';

  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iAppointment>({
      sort: 'scheduledAt:DESC',
      getFn: (params) =>
        AppointmentService.getAll({
          where: JSON.stringify({
            entityId,
            entityName,
            type,
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,Status.Category',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getColumns = (): iTableColumn<iAppointment>[] => [
    {
      key: 'scheduledAt',
      header: 'Scheduled At',
      isDefault: true,
      cell: ({ data }: iCellParams<iAppointment>) =>
        formatDate(data.scheduledAt, 'DD/MM/YYYY HH:mm'),
    },
    {
      key: 'status',
      header: 'Status',
      isDefault: true,
      cell: ({ data }: iCellParams<iAppointment>) => (
        <EntityStatusLozenge status={data.Status} />
      ),
    },
    {
      key: 'notes',
      header: 'Notes',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAppointment>) => data?.notes || '',
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iAppointment>(),
    {
      key: 'btns',
      header: '',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iAppointment>) => {
        return (
          <Flex className={'justify-content-end gap-025'}>
            {allowEdit && getEditBtn(data)}
            {allowDelete &&
              renderDeleteBtn({
                deletingModel: data,
                deleteFnc: async () => AppointmentService.deactivate(data.id),
                onDeleted,
              })}
          </Flex>
        );
      },
    },
  ];

  const getEditBtn = (appointment?: iAppointment) => {
    return renderEntityEditPopBtn<iAppointment>({
      editingEntity: appointment,
      entityName: 'Appointment',
      isDisabled,
      onSaved,
      createFn: (data) =>
        AppointmentService.create({
          ...data,
          entityId,
          entityName,
          type,
        }),
      updateFn: (id, data) => AppointmentService.update(id, data),
      renderEditBtn: ({ entity, onClick }) =>
        getCreateIconBtn({
          onClick: onClick,
          appearance: 'subtle',
          isTooltipDisabled: false,
          testId: `edit-btn-${entity.id}`,
          label: 'update',
          icon: () => <Icons.EditFilledIcon size="small" label={''} />,
        }),
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] => [
        {
          fieldName: 'scheduledAt',
          label: 'Scheduled At',
          isDisabled: isDisabled,
          isRequired: true,
          value: entity?.scheduledAt || '',
          testId: 'Appointment-scheduledAt',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <DateTimePicker
                {...props}
                {...errorProps}
                value={props.value as string}
                onChange={(selected) => {
                  useAsForm.onFieldChange(
                    fieldName,
                    `${selected || ''}`.trim() === ''
                      ? null
                      : moment(`${selected || ''}`.trim()).toISOString(),
                  );
                }}
              />
            );
          },
        },
        ...(!appointment
          ? []
          : [
              {
                fieldName: 'statusId',
                label: 'Status',
                isDisabled,
                isRequired: true,
                value: entity?.statusId || '',
                testId: 'Appointment-statusId',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                renderComponent: (fProps, useAsForm, errorProps) => {
                  const { fieldName, ...props } = fProps;
                  return (
                    <EntityStatusSelector
                      {...props}
                      {...errorProps}
                      entityStatusTypeName={EntityNames.Appointment}
                      onChange={(
                        option: iOptionWithData<iEntityStatus> | null,
                      ) => {
                        useAsForm.onFieldChange(fieldName, option?.value || '');
                      }}
                    />
                  );
                },
              },
            ]),
        {
          fieldName: 'notes',
          label: 'Notes',
          isDisabled,
          value: entity?.notes || '',
          testId: 'Appointment-notes',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextArea
                {...props}
                {...errorProps}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.value);
                }}
              />
            );
          },
        },
      ],
    });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={<Heading size={'small'}>Appointments</Heading>}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.APPOINTMENT_LIST,
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default AppointmentList;
