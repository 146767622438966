import styled from 'styled-components';

export const statusCategoryAny = {
  color: '#ffffff',
  'border-color': '#42526e',
  'background-color': 'rgb(61, 75, 102)',
};

export const statusCategoryNew = {
  color: '#42526e',
  'border-color': '#42526e',
  'background-color': '#dfe1e6',
};

export const statusCategoryInProgress = {
  color: '#0747a6',
  'border-color': '#0747a6',
  'background-color': '#deebff',
};

export const statusCategoryFinished = {
  color: '#006644',
  'border-color': '#006644',
  'background-color': '#e3fcef',
};

const WorkflowDiagramWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 56px);

  .react-flow__handle {
    background: transparent;
    border: none;
  }

  .react-flow__node {
    width: auto;
    min-width: 150px;

    &.start-node {
      background: rgb(61, 75, 102);
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      :before {
        display: block;
        content: 'Start';
        color: white;
        text-transform: uppercase;
        font-size: 9px;
        margin-left: -4px;
        margin-top: 3px;
      }
    }

    &.any-node {
      ${statusCategoryAny};
      text-transform: uppercase;
      padding: 2px 4px;
      width: auto;
      min-width: auto;
      font-size: 9px;
      .react-flow__handle {
        cursor: none;
        background: transparent;
        pointer-events: none;
        border: none;
      }
    }

    &.selected {
      border-width: 2px;
    }

    &.cate-STATUS_CATEGORY_NEW {
      ${statusCategoryNew}
    }

    &.cate-STATUS_CATEGORY_IN_PROGRESS {
      ${statusCategoryInProgress}
    }

    &.cate-STATUS_CATEGORY_FINISHED {
      ${statusCategoryFinished}
    }
  }

  .start-edge {
    .react-flow__edge-textbg {
      fill: #cecece;
    }
  }

  .react-flow__attribution {
    display: none;
  }
`;

export default WorkflowDiagramWrapper;
