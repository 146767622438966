import iProductType from '../../types/product/iProductType';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import TextField from '../frameWork/TextField';
import { getErrorProps, iErrorMap } from '../form/FormError';
import ProductTypeService from '../../services/product/ProductTypeService';
import Toaster from '../common/Toaster';

type iProductTypeEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  productType?: iProductType;
  isDisabled?: boolean;
  onSaved?: (productType: iProductType, isCreated: boolean) => void;
  onClose?: () => void;
};
const ProductTypeEditPopupBtn = ({
  productType,
  onSaved,
  isDisabled,
  onClose,
  ...props
}: iProductTypeEditPopupBtn) => {
  const productTypeId = `${productType?.id || ''}`.trim();
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setModelShowing(false);
    setIsSaving(false);
    setEditingData({});
    setErrorMap({});
    onClose && onClose();
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const data = { ...editingData, ...(productType || {}) };
    if (`${data.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const saveFn = () =>
      productTypeId === ''
        ? ProductTypeService.create(editingData)
        : ProductTypeService.update(productTypeId, editingData);
    setIsSaving(true);
    saveFn()
      .then((saved) => {
        handleClose(setModelShowing);
        onSaved && onSaved(saved, productTypeId === '');
      })
      .catch((e) => {
        setIsSaving(false);
        Toaster.showApiError(e);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={`product-type-edit-popup-${productType?.id || ''}`}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title:
          productTypeId === '' ? (
            <>Creating a new Product Type</>
          ) : (
            <>
              Editing <small>{productType?.name || ''}</small>
            </>
          ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: productTypeId === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <>
            {[
              {
                fieldName: 'name',
                label: 'Name',
                isDisabled,
                isRequired: true,
                value: editingData.name || productType?.name || '',
                testId: 'name',
              },
              {
                fieldName: 'description',
                label: 'Description',
                isDisabled,
                value:
                  editingData.description || productType?.description || '',
                testId: 'description',
              },
            ].map(({ fieldName, ...item }) => {
              return (
                <TextField
                  {...item}
                  {...getErrorProps({
                    error: errorMap,
                    fieldName: fieldName,
                  })}
                  key={fieldName}
                  onChange={(e) => {
                    setEditingData({
                      ...editingData,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      [fieldName]: e.target.value || '',
                    });
                  }}
                />
              );
            })}
          </>
        ),
      })}
    />
  );
};

export default ProductTypeEditPopupBtn;
