import ServiceHelper from '../helper/ServiceHelper';
import iEstatePlanNote from '../../types/estate/iEstatePlanNote';

const endPoint = '/estatePlanNote';
const EstatePlanNoteService = {
  getAll: ServiceHelper.getPaginatedAll<iEstatePlanNote>(endPoint),
  get: ServiceHelper.getById<iEstatePlanNote>(endPoint),
  create: ServiceHelper.create<iEstatePlanNote>(endPoint),
  update: ServiceHelper.updateById<iEstatePlanNote>(endPoint),
  deactivate: ServiceHelper.deactivateById<iEstatePlanNote>(endPoint),
};

export default EstatePlanNoteService;
