import ServiceHelper from '../helper/ServiceHelper';
import iBuildCheck from '../../types/build/iBuildCheck';

const endPoint = '/buildCheck';
const BuildCheckService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildCheck>(endPoint),
  get: ServiceHelper.getById<iBuildCheck>(endPoint),
  create: ServiceHelper.create<iBuildCheck>(endPoint),
  update: ServiceHelper.updateById<iBuildCheck>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildCheck>(endPoint),
};

export default BuildCheckService;
