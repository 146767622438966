import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { URL_SETTINGS_ENTITY_STATUS_TYPES } from '../../helpers/UrlMap';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import EntityStatusTypeService from '../../services/status/EntityStatusTypeService';
import iEntityStatusType from '../../types/status/iEntityStatusType';
import EntityStatusTypeDetailsPanel from '../../components/entityStatus/EntityStatusTypeDetailsPanel';

const EntityStatusTypeDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const navigate = useNavigate();

  return (
    <ComponentWithPreloadData<iEntityStatusType>
      emptyStateProps={{
        description: `Couldn't find requested EntityStatusType with id: ${idStr}.`,
      }}
      getDataFn={() => EntityStatusTypeService.get(idStr)}
    >
      {({ data }) => (
        <EntityStatusTypeDetailsPanel
          onCancel={() => navigate(URL_SETTINGS_ENTITY_STATUS_TYPES)}
          entityStatusType={data}
        />
      )}
    </ComponentWithPreloadData>
  );
};

export default EntityStatusTypeDetailsPage;
