import ComponentWrapper, {
  iComponentWrapper,
} from '../../frameWork/ComponentWrapper';
import iEstatePlanNoteCategory from '../../../types/estate/iEstatePlanNoteCategory';
import iEstate from '../../../types/estate/iEstate';
import iEstatePlanNote from '../../../types/estate/iEstatePlanNote';
import React, { useEffect, useRef, useState } from 'react';
import RichTextEditor, { iEditor } from '../../frameWork/RichTextEditor';
import EstatePlanNoteService from '../../../services/estate/EstatePlanNoteService';
import Toaster from '../../common/Toaster';
import Spinner from '../../frameWork/Spinner';

export type iEstatePlanNoteEditPanel = Omit<
  iComponentWrapper,
  'componentName' | 'children'
> & {
  category: iEstatePlanNoteCategory;
  estate: iEstate;
  onSaved?: (note: iEstatePlanNote, isCreated: boolean) => void;
  isDisabled?: boolean;
};

const EstatePlanNoteEditPanel = ({
  testId,
  className,
  category,
  estate,
  onSaved,
  isDisabled,
}: iEstatePlanNoteEditPanel) => {
  const componentName = 'EstatePlanNoteEditPanel';
  const [note, setNote] = useState<iEstatePlanNote | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef<iEditor | null>(null);

  useEffect(() => {
    const isCancelled = false;

    setIsLoading(true);
    EstatePlanNoteService.getAll({
      where: JSON.stringify({
        estateId: estate.id,
        categoryId: category.id,
        isActive: true,
      }),
      sort: 'createdAt:DESC',
      perPage: 1,
    })
      .then((res) => {
        const notes = res.data || [];
        if (!isCancelled) {
          setNote(notes.length > 0 ? notes[0] : null);
        }
      })
      .catch((err) => {
        if (!isCancelled) {
          Toaster.showApiError(err);
        }
      })
      .finally(() => {
        if (!isCancelled) {
          setIsLoading(false);
        }
      });

    return () => {
      isCancelled;
    };
  }, [estate.id, category.id]);

  const handleSave = () => {
    if (!editorRef.current) {
      return;
    }
    const newData = { notes: editorRef.current.getContent() };
    const noteId = `${note?.id || ''}`.trim();
    const saveFn = () =>
      noteId !== ''
        ? EstatePlanNoteService.update(noteId, newData)
        : EstatePlanNoteService.create({
            ...newData,
            categoryId: category.id,
            estateId: estate.id,
          });
    saveFn()
      .then((res) => {
        onSaved && onSaved(res, noteId === '');
      })
      .catch((err) => {
        Toaster.showApiError(err);
      });
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <RichTextEditor
        label={`${category.name} for ${estate.name}`}
        isDisabled={isDisabled}
        value={`${note?.notes || ''}`}
        onLoaded={(editor) => {
          editorRef.current = editor;
        }}
        onBlur={() => handleSave()}
      />
    );
  };

  return (
    <ComponentWrapper
      testId={testId}
      componentName={componentName}
      className={className}
    >
      {getContent()}
    </ComponentWrapper>
  );
};

export default EstatePlanNoteEditPanel;
