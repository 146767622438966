import moment from 'moment/moment';
import Lozenge from './Lozenge';
import React from 'react';

type iDateStatusDiv = {
  startDate?: string | Date | null;
  endDate?: string | Date | null;
};
const DateStatusDiv = ({ startDate, endDate }: iDateStatusDiv) => {
  const startDateStr = `${startDate || ''}`.trim();
  const endDateStr = `${endDate || ''}`.trim();
  const now = moment();

  let isCurrent = false;
  let isPast = false;
  let isFuture = false;
  if (startDateStr === '' && endDateStr === '') {
    isCurrent = true;
  } else if (startDateStr !== '' && endDateStr === '') {
    isFuture = moment(startDateStr).isAfter(now);
    isCurrent = moment(startDateStr).isSameOrBefore(now);
  } else if (startDateStr === '' && endDateStr !== '') {
    isPast = moment(endDateStr).isBefore(now);
    isCurrent = moment(endDateStr).isSameOrAfter(now);
  } else {
    isFuture = moment(startDateStr).isAfter(now);
    isPast = moment(endDateStr).isBefore(now);
    isCurrent =
      moment(startDateStr).isSameOrBefore(now) &&
      moment(endDateStr).isSameOrAfter(now);
  }
  return (
    <Lozenge
      appearance={isCurrent ? 'success' : isFuture ? 'default' : 'moved'}
    >
      {isCurrent ? 'Current' : isFuture ? 'Future' : isPast ? 'Past' : ''}
    </Lozenge>
  );
};

export default DateStatusDiv;
