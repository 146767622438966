import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { iEdge, iNode, useNodes } from '../../frameWork/ReactFlowRenderer';
import {
  statusCategoryAny,
  statusCategoryNew,
  statusCategoryInProgress,
  statusCategoryFinished,
} from '../diagram/WorkflowDiagramStyle';
import { WFDiagramIds } from '../diagram/WorkflowDiagramHelper';
import Icons from '../../frameWork/Icons';

type iWorkflowTransitDisplay = {
  edge: iEdge;
};
const ANY_NODE_CLASS_NAME = 'any-node';
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.3rem;
  border: 1px solid #efefef;
  margin: 0.4rem 0;

  :hover {
    box-shadow: 0px 0px 4px #cecece;
  }

  .status-div {
    padding: 2px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 10px;

    &.${ANY_NODE_CLASS_NAME} {
      ${statusCategoryAny};
      text-transform: uppercase;
    }

    &.STATUS_CATEGORY_NEW {
      ${statusCategoryNew}
    }
    &.STATUS_CATEGORY_IN_PROGRESS {
      ${statusCategoryInProgress}
    }
    &.STATUS_CATEGORY_FINISHED {
      ${statusCategoryFinished}
    }
  }
`;
const WorkflowTransitDisplay = ({ edge }: iWorkflowTransitDisplay) => {
  const [nodeMap, setNodeMap] = useState<{ [key: string]: iNode }>({});
  const nodes = useNodes();

  useEffect(() => {
    setNodeMap(
      nodes.reduce((map, node) => {
        return {
          ...map,
          [node.id]: node,
        };
      }, {}),
    );
  }, [nodes]);

  const getStatusDiv = (name: string, className: string) => {
    return <div className={`status-div ${className}`}>{name}</div>;
  };

  const getEdgeEndDiv = (edgEnd: string) => {
    if (!(edgEnd in nodeMap)) {
      return null;
    }
    if (edgEnd === WFDiagramIds.NODE_ID_START_NODE) {
      return getStatusDiv('START', ANY_NODE_CLASS_NAME);
    }
    if (edgEnd.startsWith(WFDiagramIds.NODE_ID_ANY_STATUS_PREFIX)) {
      return getStatusDiv('ANY STATUS', ANY_NODE_CLASS_NAME);
    }

    return getStatusDiv(
      nodeMap[edgEnd].data.status?.name || '',
      `${nodeMap[edgEnd].data.status?.Category?.code || ''}`.toUpperCase(),
    );
  };

  return (
    <Wrapper className={'wf-transit-display'}>
      {getEdgeEndDiv(edge.source)}
      <Icons.ArrowRightIcon label={''} />
      {getEdgeEndDiv(edge.target)}
    </Wrapper>
  );
};

export default WorkflowTransitDisplay;
