import ProtectedPage from '../../layouts/ProtectedPage';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import { useParams } from 'react-router-dom';
import iPurchaseOrder from '../../types/purchase/iPurchaseOder';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import PurchaseOrderDetailsPanel from '../../components/purchase/PurchaseOrderDetailsPanel';
import { URL_PURCHASE_ORDERS } from '../../helpers/UrlMap';
import React from 'react';
import { InlineFlex } from '../../components/frameWork/Flex';

const PurchaseOrderDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();

  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iPurchaseOrder>
        emptyStateProps={{
          description: `Couldn't find requested Purchase Order with id: ${idStr}.`,
        }}
        getDataFn={() =>
          PurchaseOrderService.get(idStr, {
            include: 'Supplier,Status',
          })
        }
      >
        {({ data }) => (
          <PurchaseOrderDetailsPanel
            purchaseOrder={data}
            headerProps={{
              children: data.orderNumber,
              breadcrumbItems: [
                {
                  text: `Purchase Orders`,
                  href: URL_PURCHASE_ORDERS,
                },
                {
                  text: `${data.orderNumber || ''}`,
                  component: () => (
                    <InlineFlex className={'align-items-center'}>
                      {data.orderNumber || ''}
                    </InlineFlex>
                  ),
                },
              ],
            }}
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default PurchaseOrderDetailsPage;
