import React from 'react';
import AttachmentsTable from '../../../../asset/AttachmentsTable';
import EntityNames from '../../../../../helpers/EntityNames';
import { AssetTypes } from '../../../../../types/asset/iAsset';
import Heading from '../../../../frameWork/Heading';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';

const ColourSheetList = ({ buildId }: { buildId: string }) => {
  return (
    <AttachmentsTable
      entityId={buildId}
      entityName={EntityNames.Build}
      selectiveColumnKey={SelectiveColKeys.BUILD_COLOUR_SHEET_LIST}
      types={[AssetTypes.COLOUR_SHEETS]}
      allowCreate={true}
      allowDelete={true}
      headerProps={{
        children: <Heading size={'small'}>Colour Sheets</Heading>,
      }}
    />
  );
};

export default ColourSheetList;
