export enum SelectiveColKeys {
  CONTACT_COMPANY_LIST = 'CONTACT_COMPANY_LIST_:type',
  SUBURB_LIST = 'SUBURB_LIST',
  BUILD_STYLE_LIST = 'BUILD_STYLE_LIST',
  BUILD_STYLE_PRICE_LIST = 'BUILD_STYLE_PRICE_LIST',
  BUILD_LIST = 'BUILD_LIST',
  BUILD_NOTE_LIST = 'BUILD_NOTE_LIST',
  BUILD_SUSPENSION_LIST = 'BUILD_SUSPENSION_LIST',
  ATTRIBUTE_LIST = 'ATTRIBUTE_LIST',
  ATTRIBUTE_SET_DETAILS_ATTR_LIST = 'ATTRIBUTE_SET_DETAILS_ATTR_LIST',
  PRODUCT_LIST = 'PRODUCT_LIST',
  CONTRACT_FILE_LIST = 'CONTRACT_FILE_LIST',
  CONTACT_LIST = 'CONTACT_LIST',
  ORDER_PAGE_TEMPLATE = 'ORDER_PAGE_TEMPLATE',
  EMAIL_TEMPLATE_LIST = 'EMAIL_TEMPLATE_LIST',
  BUILD_PLAN_REQUEST_LIST = 'BUILD_PLAN_REQUEST_LIST',
  BUILD_PLAN_CHANGE_LIST = 'BUILD_PLAN_CHANGE_LIST',
  MESSAGE_LIST = 'MESSAGE_LIST',
  // WAREHOUSE_LIST = 'WAREHOUSE_LIST',
  DEVELOPER_GUIDES = 'DEVELOPER_GUIDES',
  ATTACHMENTS_POPUP_TABLE = 'ATTACHMENTS_POPUP_TABLE',
  DEVELOPER_GUIDES_LIST = 'DEVELOPER_GUIDES_LIST',
  PRODUCT_PRICE_LIST = 'PRODUCT_PRICE_LIST',
  BUILD_CHANGE_ITEM_LIST = 'BUILD_CHANGE_ITEM_LIST',
  ENTITY_STATUS_TYPE_LIST = 'ENTITY_STATUS_TYPE_LIST',
  BUILD_COLOUR_NOTE_TEMPLATE_LIST = 'BUILD_COLOUR_NOTE_TEMPLATE_LIST',
  BUILD_COLOUR_NOTE_LIST = 'BUILD_COLOUR_NOTE_LIST',
  FINANCE_SUMMARYY_LIST = 'FINANCE_SUMMARY_LIST',
  PURCHASE_ORDER_LIST = 'PURCHASE_ORDER_LIST',
  APPOINTMENT_LIST = 'APPOINTMENT_LIST',
  BUILD_CHECK_LIST = 'BUILD_CHECK_LIST',
  BUILD_COLOUR_SHEET_LIST = 'BUILD_COLOUR_SHEET_LIST',
  SEQUENCE_NUMBER_TYPE_LIST = 'SEQUENCE_NUMBER_TYPE_LIST',
  BUILD_VARIATION_LIST = 'BUILD_VARIATION_LIST',
  BUILD_VARIATION_ITEM_LIST = 'BUILD_VARIATION_ITEM_LIST',
  BUILD_SURVEYOR_ITEMS = 'BUILD_SURVEYOR_ITEMS',
  PRODUCT_OPTION_TYPE_LIST = 'PRODUCT_OPTION_TYPE_LIST',
}

const getTokenName = () => {
  return process.env.REACT_APP_LOCAL_USER_TOKEN_NAME || 'token';
};

const getToken = () => {
  return localStorage.getItem(getTokenName());
};

const setToken = (newToken: string) => {
  return localStorage.setItem(getTokenName(), newToken);
};

const removeToken = () => {
  return localStorage.removeItem(getTokenName());
};

const getItem = (name: string) => {
  const value = localStorage.getItem(name);
  try {
    return JSON.parse(`${value || ''}`);
  } catch {
    return value;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setItem = (name: string, value: any) => {
  let valueStr = value;
  try {
    valueStr = JSON.stringify(value);
  } catch (err) {
    // console.error('LocalStorageService.setItem', err);
    valueStr = value;
  }

  return localStorage.setItem(name, valueStr);
};

const removeItem = (name: string) => {
  return localStorage.removeItem(name);
};

const LocalStorageService = {
  getToken,
  setToken,
  removeToken,

  setItem,
  getItem,
  removeItem,
};

export default LocalStorageService;
