import iBuild from '../../types/build/iBuild';
import ListComponent, { iListComponent } from '../frameWork/ListComponent';
import iBuildSurveyItem from '../../types/build/iBuildSurveyItem';
import BuildSurveyItemService from '../../services/build/BuildSurveyItemService';
import { iCellParams, iTableColumn } from '../../helpers/DynamicTableHelper';
import EntityStatusLozenge from '../entityStatus/EntityStatusLozenge';
import UserHelper from '../../helpers/UserHelper';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import TextArea from '../frameWork/TextArea';
import React, { useState } from 'react';
import { iEntityFormField } from '../form/EntityEditPanel';
import EntityStatusSelector from '../entityStatus/EntityStatusSelector';
import EntityNames from '../../helpers/EntityNames';
import { iOptionWithData } from '../frameWork/Select';
import iEntityStatus from '../../types/status/iEntityStatus';
import UserSelector from '../user/UserSelector';
import iUser from '../../types/system/iUser';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import tokens from '../frameWork/Tokens';
import Tooltip from '../frameWork/Tooltip';
import useEntityStatusTabsHook from '../hooks/useEntityStatusTabsHook/useEntityStatusTabsHook';
import Spinner from '../frameWork/Spinner';
import MathHelper from '../../helpers/MathHelper';

export type iBuildSurveyItemList = Omit<
  iListComponent<iBuildSurveyItem>,
  'getFn' | 'componentName' | 'deactivateFn' | 'getColumns' | 'renderCreateBtn'
> & {
  build: iBuild;
  allowDelete?: boolean;
  onSaved?: (data: iBuildSurveyItem, isCreated: boolean) => void;
};
const BuildSurveyItemList = ({
  build,
  onSaved,
  ...props
}: iBuildSurveyItemList) => {
  const { renderStatusTabs, state, viewingState } = useEntityStatusTabsHook({
    entityStatusTypeName: EntityNames.BuildSurveyItem,
  });
  const [reloadList, setReloadList] = useState(0);
  const [bsItems, setBsItems] = useState<iBuildSurveyItem[]>([]);
  const getEditBtn = (
    params: ReturnType<typeof useListCrudHook<iBuildSurveyItem>>,
    data?: iBuildSurveyItem,
  ) => {
    return params.renderEntityEditPopBtn({
      editingEntity: data,
      entityName: 'Build Survey Item',
      renderEditBtn: ({ entity, onClick }) => {
        return (
          <Tooltip content={data?.description || ''}>
            <div
              onClick={onClick}
              style={{ maxWidth: '200px', color: tokens('color.link') }}
              className={'cursor-pointer ellipsis'}
              data-testid={`edit-btn-${entity?.id || ''}`}
            >
              {data?.description || ''}
            </div>
          </Tooltip>
        );
      },
      createFn: (data) =>
        BuildSurveyItemService.create({
          ...data,
          buildId: build.id,
        }).then((resp) => {
          if (onSaved) {
            onSaved(resp, true);
          }
          return resp;
        }),
      updateFn: (id, data) =>
        BuildSurveyItemService.update(id, data).then((resp) => {
          if (onSaved) {
            onSaved(resp, false);
          }
          return resp;
        }),
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] => [
        {
          fieldName: 'description',
          label: 'Description',
          testId: 'description',
          isRequired: true,
          isDisabled,
          value: entity?.description || '',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextArea
                {...props}
                {...errorProps}
                placeholder={'Description'}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.value);
                }}
              />
            );
          },
        },
        ...(data
          ? [
              {
                fieldName: 'statusId',
                label: 'Status',
                testId: 'statusId',
                isRequired: true,
                isDisabled,
                value: entity?.statusId || '',
                renderComponent: (fProps, useAsForm, errorProps) => {
                  const { fieldName, ...props } = fProps;
                  return (
                    <EntityStatusSelector
                      entityStatusTypeName={EntityNames.BuildSurveyItem}
                      {...props}
                      {...errorProps}
                      value={props.value as string | undefined}
                      onChange={(
                        option: iOptionWithData<iEntityStatus> | null,
                      ) => {
                        useAsForm.onFieldChange(fieldName, option?.value || '');
                      }}
                    />
                  );
                },
              } as iEntityFormField,
            ]
          : []),
        {
          fieldName: 'followUpInternalUserId',
          label: 'Follow Up Internal',
          testId: 'followUpInternalUserId',
          isDisabled,
          value: entity?.followUpInternalUserId || '',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <UserSelector
                {...props}
                {...errorProps}
                value={props.value as string | undefined}
                onChange={(option: iOptionWithData<iUser> | null) => {
                  useAsForm.onFieldChange(fieldName, option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'followUpExternalId',
          label: 'Follow Up External',
          testId: 'followUpExternalId',
          isDisabled,
          value: entity?.followUpExternalId || '',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <UserSelector
                {...props}
                {...errorProps}
                value={props.value as string | undefined}
                onChange={(option: iOptionWithData<iUser> | null) => {
                  useAsForm.onFieldChange(fieldName, option?.value || '');
                }}
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (
    params: ReturnType<typeof useListCrudHook<iBuildSurveyItem>>,
  ): iTableColumn<iBuildSurveyItem>[] => [
    {
      key: 'details',
      header: 'Description',
      isDefault: true,
      cell: ({ data }: iCellParams<iBuildSurveyItem>) =>
        getEditBtn(params, data),
    },
    {
      key: 'status',
      header: 'Status',
      isDefault: true,
      cell: ({ data }: iCellParams<iBuildSurveyItem>) => (
        <EntityStatusLozenge status={data.Status} />
      ),
    },
    {
      key: 'followByInternal',
      header: 'Follow By Internal',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildSurveyItem>) =>
        UserHelper.getFullName(data.FollowUpInternalUser),
    },
    {
      key: 'followByExternal',
      header: 'Follow By External',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildSurveyItem>) =>
        UserHelper.getFullName(data.FollowUpExternal),
    },
  ];

  if (state.isLoading) {
    return <Spinner />;
  }

  return (
    <ListComponent
      componentName={'BuildSurveyItemList'}
      selectiveColumnKey={SelectiveColKeys.BUILD_SURVEYOR_ITEMS}
      {...props}
      getBottomBar={() =>
        renderStatusTabs({
          onSelectedStatusesChange: () => {
            setReloadList(MathHelper.add(reloadList, 1));
          },
        })
      }
      getActions={() => {
        const finishStatusIds = (state.data.finishedStatuses || []).map(
          (s) => s.id,
        );
        const completedItems = bsItems.filter(
          (bsItem) => finishStatusIds.indexOf(bsItem.statusId || '') >= 0,
        );
        return (
          <small>
            Completed: <b>{completedItems.length}</b> of <b>{bsItems.length}</b>
          </small>
        );
      }}
      getFnExtra={{
        forceReload: reloadList,
      }}
      deactivateFn={(data: iBuildSurveyItem) =>
        BuildSurveyItemService.deactivate(data.id)
      }
      renderCreateBtn={getEditBtn}
      getColumns={getColumns}
      getFn={async () => {
        const results = await BuildSurveyItemService.getAll({
          where: JSON.stringify({
            isActive: true,
            buildId: build.id,
          }),
          sort: 'createdAt:ASC',
          include:
            'CreatedBy,UpdatedBy,Status.Category,FollowUpInternalUser,FollowUpExternal.ContactCompany',
          perPage: 999999999,
        });
        const buildSurveyItems = results.data || [];
        setBsItems(buildSurveyItems);
        return {
          ...results,
          data: buildSurveyItems.filter((item) => {
            const selectedStatusIds = (viewingState.selectedStatuses || []).map(
              (s) => s.id,
            );
            if (selectedStatusIds.length <= 0) {
              return true;
            }
            return (
              selectedStatusIds.indexOf(`${item.statusId || ''}`.trim()) >= 0
            );
          }),
        };
      }}
    />
  );
};

export default BuildSurveyItemList;
