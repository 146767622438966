import { ContactCompanyTypes } from '../types/contact/iContactCompany';

const showCategory = (type: ContactCompanyTypes) => {
  return (
    [ContactCompanyTypes.SUPPLIER, ContactCompanyTypes.CLIENT].indexOf(type) >=
    0
  );
};

const ContactCompanyHelper = {
  showCategory,
};

export default ContactCompanyHelper;
