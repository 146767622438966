import React, { useEffect, useState } from 'react';
import iEntityStatusCategory from '../../../types/status/iEntityStatusCategory';
import WorkflowDiagramHelper from '../diagram/WorkflowDiagramHelper';
import Flex from '../../frameWork/Flex';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import PopupBtn from '../../common/PopupBtn';
import EntityStatusEditPanel from '../../entityStatus/EntityStatusEditPanel';
import WorkflowTransitionEditPopBtn from './WorkflowTransitionEditPopBtn';
import { useEdges, useNodes } from '../../frameWork/ReactFlowRenderer';
import Button from '../../frameWork/Button';
import Icons from '../../frameWork/Icons';

type iWorkflowEditCreateButtons = {
  className?: string;
  state: iWFDiagramState;
};

type iCategoryMap = {
  [key: string]: iEntityStatusCategory;
};
const WorkflowEditCreateButtons = ({
  className,
  state,
}: iWorkflowEditCreateButtons) => {
  const [categoryMap, setCategoryMap] = useState<iCategoryMap>({});
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setCategoryMap(
      state.entityStatusCategories.reduce(
        (map: iCategoryMap, category: iEntityStatusCategory) => {
          return {
            ...map,
            [category.id]: category,
          };
        },
        {},
      ),
    );
  }, [state.entityStatusCategories]);

  const newNewStatusInWorkflow = (data: { [key: string]: string }) => {
    const newEntityStatusData = WorkflowDiagramHelper.getNewEntityStatus(
      `${data.name || ''}`,
      `${data.code || ''}`,
      `${data.description || ''}`,
      categoryMap[data.entityStatusCategoryId],
    );
    const newNode = WorkflowDiagramHelper.getStatusNode(newEntityStatusData, {
      x: Math.round(window.innerWidth / 2) - 200,
      y: Math.round(window.innerHeight / 2),
    });

    const { node, edge } = WorkflowDiagramHelper.getAnyStatusNode(newNode);
    state.setEdges([...edges, edge]);
    state.setNodes([...nodes, newNode, node]);
    return true;
  };

  return (
    <Flex
      data-testid={'wkflow-create-btns'}
      className={`wkflow-edit-create-btns-wrapper ${className || ''} align-items-center`}
    >
      <PopupBtn
        titleId={'create-transition-btn'}
        renderBtn={(onClick) => (
          <Button
            testId={'create-status-btn'}
            appearance={'subtle'}
            iconBefore={Icons.AddIcon}
            onClick={onClick}
          >
            Status
          </Button>
        )}
        modalProps={(setShowingModal) => ({
          shouldScrollInViewport: true,
          title: `Creating a new Status for: ${state.entityStatusType?.entityName}`,
          onClose: () => setShowingModal(false),
          body: (
            <EntityStatusEditPanel
              onCancel={() => setShowingModal(false)}
              onSave={(saving) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                newNewStatusInWorkflow(saving);
                setShowingModal(false);
              }}
            />
          ),
        })}
      />
      <WorkflowTransitionEditPopBtn
        state={state}
        renderBtn={(onClick) => (
          <Button
            testId={'create-transition-btn'}
            appearance={'subtle'}
            iconBefore={Icons.AddIcon}
            onClick={onClick}
          >
            Transition
          </Button>
        )}
      />
    </Flex>
  );
};

export default WorkflowEditCreateButtons;
