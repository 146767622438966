import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React, { useEffect, useState } from 'react';
import iEstate from '../../types/estate/iEstate';
import Flex from '../frameWork/Flex';
import styled from 'styled-components';
import DeveloperGuidesList from '../developerGuides/DeveloperGuidesList';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import EstateService from '../../services/estate/EstateService';
import { iSetShowingModalFn } from '../common/PopupBtn';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import DeveloperGuideEditPopupBtn from '../developerGuides/DeveloperGuideEditPopupBtn';
import MathHelper from '../../helpers/MathHelper';
import EstateDetailsSummaryPanel from './EstateDetailsComponents/EstateDetailsSummaryPanel';
import tokens from '../frameWork/Tokens';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '../frameWork/DropdownMenu';
import DeveloperGuideCategoryTabs from '../developerGuideCategory/DeveloperGuideCategoryTabs';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import Tabs from '../frameWork/Tabs';
import iEstatePlanNoteCategory from '../../types/estate/iEstatePlanNoteCategory';
import EstatePlanNoteCategoryService from '../../services/estate/EstatePlanNoteCategoryService';
import Toaster from '../common/Toaster';
import Spinner from '../frameWork/Spinner';
import EstatePlanNoteEditPanel from './EstateDetailsComponents/EstatePlanNoteEditPanel';

const Wrapper = styled.div`
  .details-summary-panel {
    margin-bottom: ${tokens('space.800', '2rem')};
  }
  .DeveloperGuideCategoryTabs {
    margin-top: ${tokens('space.100', '1rem')};
  }
`;

export type iEstateDetailsPanel = iComponentWithPageHeader & {
  estate: iEstate;
  allowCreate?: boolean;
  allowDelete?: boolean;
  allowEdit?: boolean;
  testId?: string;
  className?: string;
  onEstateUpdated?: (estate: iEstate) => void;
  onEstateDeleted?: (estate: iEstate) => void;
};

enum TabKeys {
  General = 'General',
  GuideLines = 'Guidelines',
  PlanNotes = 'Plan Notes',
}
const EstateDetailPanel = ({
  estate,
  headerProps,
  allowDelete,
  allowCreate,
  allowEdit,
  onEstateUpdated,
  onEstateDeleted,
  testId,
  className,
  ...props
}: iEstateDetailsPanel) => {
  const componentName = 'Estate-details';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [selectedTab, setSelectedTab] = useState<TabKeys>(TabKeys.General);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [forceReloadList, setForceReloadList] = useState(0);
  const [showingCategory, setShowingCategory] =
    useState<iDeveloperGuideCategory | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<iEstatePlanNoteCategory[]>([]);
  useEffect(() => {
    let isCancelled = false;

    setIsLoading(true);
    EstatePlanNoteCategoryService.getAll({
      where: JSON.stringify({
        isActive: true,
      }),
      perPage: 9999999,
      sort: 'sort:ASC',
    })
      .then((res) => {
        if (!isCancelled) {
          setCategories(res.data || []);
        }
      })
      .catch((err) => {
        if (!isCancelled) {
          Toaster.showApiError(err);
        }
      })
      .finally(() => {
        if (!isCancelled) {
          setIsLoading(false);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, [estate.id]);

  const handlePopupClose = (setModelShowing: iSetShowingModalFn) => {
    setModelShowing(false);
  };

  const getCreateBtn = () => {
    if (!allowCreate) {
      return null;
    }
    return (
      <DeveloperGuideEditPopupBtn
        estate={estate}
        developerGuide={undefined}
        category={showingCategory || undefined}
        onSaved={() => setForceReloadList(MathHelper.add(forceReloadList, 1))}
        renderBtn={(onClick) => {
          return getCreateIconBtn({ onClick });
        }}
      />
    );
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <Tabs
        appearance={'tabs'}
        showQuickSelect
        onSelect={(tabKey) => {
          setSelectedTab(tabKey as TabKeys);
        }}
        activeKey={selectedTab}
        tabs={[
          {
            eventKey: TabKeys.General,
            title: TabKeys.General,
            tabContent: (
              <EstateDetailsSummaryPanel
                className={'details-summary-panel'}
                estate={estate}
                isDisabled={allowEdit !== true}
                onUpdated={(updated) =>
                  onEstateUpdated && onEstateUpdated(updated)
                }
              />
            ),
          },
          {
            eventKey: TabKeys.GuideLines,
            title: TabKeys.GuideLines,
            tabContent: (
              <DeveloperGuidesList
                estate={estate}
                allowEdit={allowEdit}
                allowDelete={allowEdit}
                category={showingCategory || undefined}
                forceReload={forceReloadList}
                headerProps={{
                  actions: <></>,
                  children:
                    allowCreate === true ? (
                      <PageTitleWithCreateBtn
                        createBtn={getCreateBtn()}
                        title={
                          <>
                            Developer Guides under <u>{estate.name}</u>
                          </>
                        }
                      />
                    ) : (
                      <>
                        Developer Guides under <u>{estate.name}</u>
                      </>
                    ),
                  bottomBar: (
                    <div>
                      <DeveloperGuideCategoryTabs
                        isCompact
                        onSelected={(selected) => {
                          setShowingCategory(selected);
                          setForceReloadList(
                            MathHelper.add(forceReloadList, 1),
                          );
                        }}
                      />
                    </div>
                  ),
                }}
              />
            ),
          },
          ...categories.map((category) => ({
            eventKey: `${TabKeys.PlanNotes}_${category.id}`,
            title: category.name,
            tabContent: (
              <EstatePlanNoteEditPanel estate={estate} category={category} />
            ),
          })),
        ]}
      />
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <ComponentWithPageHeader
        {...props}
        headerProps={{
          ...headerProps,
          children: headerProps?.children,
          actions: (
            <Flex className={'gap-1 align-items-center'}>
              <DropdownMenu
                placement={'bottom-end'}
                shouldRenderToParent
                trigger={({ triggerRef, ...props }) => {
                  return (
                    <IconButton
                      testId={'options-btn'}
                      {...props}
                      icon={Icons.MoreIcon}
                      ref={triggerRef}
                      label={'more options'}
                      isSelected={false}
                    />
                  );
                }}
              >
                <DropdownItemGroup>
                  {allowDelete === true && (
                    <DropdownItem onClick={() => setShowDeletePopup(true)}>
                      Delete {estate.name || ''}
                    </DropdownItem>
                  )}
                </DropdownItemGroup>
              </DropdownMenu>

              <DeleteConfirmPopupBtn
                onClose={handlePopupClose}
                onCancel={() => {
                  setShowDeletePopup(false);
                }}
                forceShown={showDeletePopup}
                titleId={`delete-${estate.id}`}
                deleteFnc={() => EstateService.deactivate(estate.id)}
                renderBtn={() => null}
                onDeleted={(deleted) =>
                  onEstateDeleted && onEstateDeleted(deleted)
                }
              />
            </Flex>
          ),
        }}
      >
        {getContent()}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};
export default EstateDetailPanel;
