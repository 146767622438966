import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import DeveloperGuidesService from '../../services/developerGuides/DeveloperGuidesService';
import iDeveloperGuide from '../../types/developerGuide/iDeveloperGuide';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import Flex from '../frameWork/Flex';
import { Link } from 'react-router-dom';
import { URL_ESTATE_DETAILS } from '../../helpers/UrlMap';
import Toaster from '../common/Toaster';
import DeveloperGuideEditPopupBtn from './DeveloperGuideEditPopupBtn';
import iEstate from '../../types/estate/iEstate';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import Button, { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import PopupBtn from '../common/PopupBtn';
import styled from 'styled-components';
import tokens from '../frameWork/Tokens';

const JSONWrapper = styled.div`
  padding: ${tokens('space.200')};
  overflow: auto;
  background-color: ${tokens('color.background.accent.gray.subtlest')};
`;

export type iDeveloperGuidesList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowEdit?: boolean;
  estate?: iEstate;
  category?: iDeveloperGuideCategory;
  testId?: string;
  forceReload?: number;
};

const DeveloperGuidesList = ({
  allowDelete,
  allowEdit = true,
  headerProps,
  estate,
  category,
  testId,
  forceReload = 0,
  ...props
}: iDeveloperGuidesList) => {
  const componentName = 'DeveloperGuidesList';
  const testIdStr = `${testId || ''}-${componentName}`;

  const {
    state,
    renderDataTable,
    onRefresh,
    renderDeleteBtn,
    onSetFilter,
    viewingState,
    onSetIsLoading,
  } = useListCrudHook<iDeveloperGuide>({
    sort: `sort:ASC`,
    forceReload,
    ...(`${estate?.id || ''}`.trim() !== '' &&
    `${category?.id || ''}`.trim() !== ''
      ? { perPage: 999999999 }
      : {}),
    getFn: (params) =>
      DeveloperGuidesService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(estate ? { estateId: estate.id } : {}),
          ...(category ? { categoryId: category.id } : {}),
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Estate,Attribute,Category',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getColumns = (): iTableColumn<iDeveloperGuide>[] => [
    ...(estate?.id || ''.trim() !== ''
      ? []
      : [
          {
            key: 'estate',
            header: 'Estate',
            isDefault: true,
            isSelectable: true,
            cell: ({ data }: iCellParams<iDeveloperGuide>) => {
              const estateId = data?.estateId || '';
              if (!allowEdit || estateId === '') {
                return null;
              }
              return (
                <Link to={URL_ESTATE_DETAILS.replace(':id', estateId)}>
                  {data?.Estate?.name || ''}
                </Link>
              );
            },
          },
        ]),
    {
      key: 'attribute',
      header: 'Attribute',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuide>) => {
        return `${data.Attribute?.name || ''}`;
      },
    },
    {
      key: 'category',
      header: 'Category',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuide>) => {
        return `${data.Category?.name || ''}`;
      },
    },
    {
      key: 'validationRules',
      header: 'Validation Rules',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iDeveloperGuide>) => {
        if (!data.validationRules) {
          return null;
        }
        return (
          <PopupBtn
            titleId={'Validation Rules'}
            renderBtn={(onClick) => {
              return (
                <IconButton
                  icon={Icons.EyeOpenIcon}
                  onClick={onClick}
                  label={'Validation Rules'}
                />
              );
            }}
            modalProps={(setModelShowing) => ({
              title: `Validation Rules for ${data.Attribute?.name || ''}`,
              width: 'large',
              body: (
                <JSONWrapper>
                  <pre>{JSON.stringify(data.validationRules, null, 2)}</pre>
                </JSONWrapper>
              ),
              footer: (
                <Button
                  onClick={() => setModelShowing(false)}
                  iconBefore={Icons.CrossIcon}
                >
                  Close
                </Button>
              ),
            })}
          />
        );
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iDeveloperGuide>(
      true,
      true,
    ),
    ...(allowDelete !== true && allowEdit !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iDeveloperGuide>) => {
              return (
                <div className={'text-right'}>
                  {allowEdit === true ? (
                    <DeveloperGuideEditPopupBtn
                      developerGuide={data}
                      onSaved={() => onRefresh()}
                      estate={estate}
                      category={category}
                      renderBtn={(onClick) => {
                        return (
                          <IconButton
                            icon={Icons.EditFilledIcon}
                            onClick={onClick}
                            label={'Edit'}
                            isSelected
                          />
                        );
                      }}
                    />
                  ) : null}
                  {allowDelete === true
                    ? renderDeleteBtn({
                        deletingModel: data,
                        deleteFnc: async () =>
                          DeveloperGuidesService.deactivate(data.id),
                        getDisplayName: (DeveloperGuides) =>
                          DeveloperGuides.estateId,
                      })
                    : null}
                </div>
              );
            },
          },
        ]),
  ];

  const getActions = () => {
    if (headerProps?.actions) {
      return headerProps?.actions;
    }
    return (
      <Flex className={'gap-1 align-items-center'}>
        <SearchTextField
          testId={`${testIdStr}-search-field`}
          placeholder={'Search name, code ...'}
          onSearch={(searchText) => {
            const searchTxt = `${searchText || ''}`.trim();
            onSetFilter({
              ...viewingState.filter,
              ...(searchTxt === ''
                ? {}
                : {
                    name: { [OP_LIKE]: `%${searchTxt}%` },
                  }),
            });
          }}
        />
      </Flex>
    );
  };

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    onSetIsLoading(true);
    const attributes = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = attributes.splice(sourceIndex, 1);
    // Insert it at the destination position
    attributes.splice(targetIndex, 0, movedItem);
    Promise.all(
      attributes.map((attribute, index) => {
        return DeveloperGuidesService.update(attribute.id, { sort: index });
      }),
    )
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        onRefresh();
      });
  };

  return (
    <ComponentWithPageHeader
      {...props}
      headerProps={{
        ...headerProps,
        children: headerProps?.children,
        actions: getActions(),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.DEVELOPER_GUIDES,
        tblProps: {
          testId: 'developerguides-list',
          isRankable:
            allowEdit === true &&
            (state.data.data || []).length > 1 &&
            `${estate?.id || ''}`.trim() !== '' &&
            `${category?.id || ''}`.trim() !== '',
          onRankEnd: (params) =>
            submitReorder(params.sourceIndex, params.destination?.index || 0),
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default DeveloperGuidesList;
