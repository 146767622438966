import ServiceHelper from '../helper/ServiceHelper';
import iFinanceType from '../../types/finance/iFinanceType';

const endPoint = '/financeType';

const FinanceTypeService = {
  getAll: ServiceHelper.getPaginatedAll<iFinanceType>(endPoint),
  get: ServiceHelper.getById<iFinanceType>(endPoint),
  create: ServiceHelper.create<iFinanceType>(endPoint),
  update: ServiceHelper.updateById<iFinanceType>(endPoint),
  deactivate: ServiceHelper.deactivateById<iFinanceType>(endPoint),
};

export default FinanceTypeService;
