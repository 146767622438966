import iEntityStatus from '../../types/status/iEntityStatus';
import ServiceHelper from '../helper/ServiceHelper';
import AppService, { iConfigParams } from '../AppService';
import { AxiosResponse } from 'axios';

const endPoint = '/entityStatus';
const EntityStatusService = {
  getAll: ServiceHelper.getPaginatedAll<iEntityStatus>(endPoint),
  get: ServiceHelper.getById<iEntityStatus>(endPoint),
  create: ServiceHelper.create<iEntityStatus>(endPoint),
  update: ServiceHelper.updateById<iEntityStatus>(endPoint),
  deactivate: ServiceHelper.deactivateById<iEntityStatus>(endPoint),

  getNextStatuses: async (
    id: string,
    params?: iConfigParams,
    config: iConfigParams = {},
  ): Promise<iEntityStatus[]> => {
    return AppService.get(`${endPoint}/${id}/next`, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  },
};

export default EntityStatusService;
