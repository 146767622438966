import iProductType from '../../types/product/iProductType';
import ProductList, { iProductListPanel } from '../product/ProductList';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React, { useState } from 'react';
import ProductCreatePopupBtn from '../product/ProductCreatePopupBtn';
import { URL_PRODUCT_DETAILS } from '../../helpers/UrlMap';
import { useNavigate } from 'react-router-dom';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import ProductTypeEditPopupBtn from './ProductTypeEditPopupBtn';
import ProductTypeService from '../../services/product/ProductTypeService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';

type iProductTypeDetailsPanel = Omit<iProductListPanel, 'productTypeId'> & {
  productType: iProductType;
  onSaved?: (saved: iProductType) => void;
  onDeleted?: (saved: iProductType) => void;
  allowDelete?: boolean;
  allowCreate?: boolean;
  allowEdit?: boolean;
};
const ProductTypeDetailsPanel = ({
  productType,
  allowCreate,
  allowEdit,
  allowDelete,
  headerProps,
  onSaved,
  onDeleted,
}: iProductTypeDetailsPanel) => {
  const navigate = useNavigate();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handlePopupClose = () => {
    setShowEditPopup(false);
    setShowDeletePopup(false);
  };

  const getActions = () => {
    if (!allowEdit && !allowDelete) {
      return <></>;
    }
    return (
      <>
        <DropdownMenu
          placement={'bottom-end'}
          shouldRenderToParent
          trigger={({ triggerRef, ...props }) => {
            return (
              <IconButton
                {...props}
                icon={Icons.MoreIcon}
                ref={triggerRef}
                label={''}
                isSelected={false}
              />
            );
          }}
        >
          <DropdownItemGroup>
            {allowEdit === true && (
              <DropdownItem onClick={() => setShowEditPopup(true)}>
                Edit {productType.name || ''}
              </DropdownItem>
            )}
            {allowDelete === true && (
              <DropdownItem onClick={() => setShowDeletePopup(true)}>
                Delete {productType.name || ''}
              </DropdownItem>
            )}
          </DropdownItemGroup>
        </DropdownMenu>
        <ProductTypeEditPopupBtn
          forceShown={showEditPopup}
          productType={productType}
          onSaved={(saved) => {
            setShowEditPopup(false);
            onSaved && onSaved(saved);
          }}
          renderBtn={() => null}
          onClose={handlePopupClose}
        />
        <DeleteConfirmPopupBtn
          onClose={handlePopupClose}
          onCancel={() => {
            setShowDeletePopup(false);
          }}
          forceShown={showDeletePopup}
          titleId={`delete-${productType.id}`}
          deleteFnc={() => ProductTypeService.deactivate(productType.id)}
          renderBtn={() => null}
          onDeleted={(deleted) => {
            Toaster.showToast(
              `${productType.name} deleted.`,
              TOAST_TYPE_SUCCESS,
            );
            onDeleted && onDeleted(deleted);
          }}
        />
      </>
    );
  };
  return (
    <ProductList
      productTypeId={productType.id}
      headerProps={{
        ...headerProps,
        actions: getActions(),
        children: (
          <PageTitleWithCreateBtn
            createBtn={
              allowCreate === true ? (
                allowCreate === true ? (
                  <ProductCreatePopupBtn
                    productTypeId={productType.id}
                    onSaved={(saved) =>
                      navigate(URL_PRODUCT_DETAILS.replace(':id', saved.id))
                    }
                    renderBtn={(onClick) =>
                      getCreateIconBtn({
                        onClick,
                        testId: `-product-create-btn`,
                      })
                    }
                  />
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            }
            title={headerProps?.children}
          />
        ),
      }}
    />
  );
};

export default ProductTypeDetailsPanel;
