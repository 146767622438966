import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iBuildVariation from '../../types/buildVariation/iBuildVariation';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import Lozenge from '../frameWork/Lozenge';
import { useEffect, useState } from 'react';
import BuildVariationEditPanel from './BuildVariationEditPanel';
import BuildVariationService from '../../services/build/BuildVariationService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import { iErrorMap } from '../form/FormError';
import StringHelper from '../../helpers/StringHelper';
import BuildVariationItemList from './BuildVariationItemList';

type iBuildVariationEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  buildVariation?: iBuildVariation;
  buildId: string;
  onSaved?: (saved: iBuildVariation, isCreated: boolean) => void;
};
const BuildVariationEditPopupBtn = ({
  buildId,
  buildVariation,
  onClose,
  isDisabled,
  onSaved,
  ...props
}: iBuildVariationEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [editingBV, setEditingBV] = useState<iBuildVariation | null>(
    buildVariation || null,
  );

  useEffect(() => {
    setEditingBV(buildVariation || null);
  }, [buildVariation, buildId]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setModelShowing(false);
    setEditingBV(
      `${buildVariation?.id || ''}`.trim() === '' ? null : editingBV,
    );
    onClose && onClose(setModelShowing);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const totalAmtStr = `${editingBV?.totalAmt || ''}`.trim();
    if (totalAmtStr !== '' && !StringHelper.isNumeric(totalAmtStr)) {
      errors.totalAmt = 'Please enter a valid number';
    }
    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const doSave = () => {
    if (!preSave()) {
      return;
    }
    const bvId = `${editingBV?.id || ''}`.trim();
    setIsSaving(true);
    const saveFn = () =>
      bvId === ''
        ? BuildVariationService.create({ ...editingBV, buildId })
        : BuildVariationService.update(bvId, { ...editingBV });
    setIsSaving(true);
    saveFn()
      .then((resp) => {
        setEditingBV(resp);
        Toaster.showToast(
          bvId === ''
            ? `New BV(${resp.variationNumber}) created.`
            : `BV(${resp.variationNumber}) updated.`,
          TOAST_TYPE_SUCCESS,
        );
        onSaved && onSaved(resp, bvId === '');
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={`BuildVariationEditPopupBtn-${buildId}-${editingBV?.id || ''}`}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        width: '70rem',
        title:
          `${editingBV?.id || ''}`.trim() === '' ? (
            <>Creating a new variation</>
          ) : (
            <>
              Editing attribute{' '}
              <Lozenge>{editingBV?.variationNumber || ''}</Lozenge>
            </>
          ),
        onClose: () => handleClose(setShowingModal),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText:
              `${editingBV?.id || ''}`.trim() === ''
                ? 'Create before you can add items'
                : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(),
          },
        }),
        body: (
          <>
            <BuildVariationEditPanel
              buildId={buildId}
              buildVariation={editingBV as iBuildVariation}
              useAsForm={{
                errorMap,
                onFieldChange: (field, value) => {
                  setEditingBV({
                    ...editingBV,
                    [field]: value,
                  } as iBuildVariation);
                },
              }}
            />
            {editingBV && (
              <BuildVariationItemList
                buildVariation={editingBV}
                allowCreate
                allowEdit
                allowDelete
                headerProps={{
                  children: 'Items',
                }}
                onSaved={(savedItem, isCreated) =>
                  onSaved && onSaved(editingBV, isCreated)
                }
              />
            )}
          </>
        ),
      })}
    />
  );
};

export default BuildVariationEditPopupBtn;
