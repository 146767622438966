import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ProductTypeService from '../../services/product/ProductTypeService';
import iProductType from '../../types/product/iProductType';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { OP_LIKE } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';
import { Link, useNavigate } from 'react-router-dom';
import { URL_PRODUCT_TYPE_DETAILS } from '../../helpers/UrlMap';
import ProductTypeEditPopupBtn from './ProductTypeEditPopupBtn';

type iProductTypeList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const ProductTypeList = ({ allowDelete, headerProps }: iProductTypeList) => {
  const testIdStr = 'productType-list';
  const navigate = useNavigate();
  const { renderDataTable, renderDeleteBtn, onSetFilter } =
    useListCrudHook<iProductType>({
      sort: `name:ASC`,
      getFn: (params) =>
        ProductTypeService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = () => {
    return (
      <ProductTypeEditPopupBtn
        onSaved={(saved) => {
          navigate(URL_PRODUCT_TYPE_DETAILS.replace(':id', saved.id));
          return;
        }}
        renderBtn={(onClick) => {
          return getCreateIconBtn({
            onClick,
            testId: `${testIdStr}-create-btn`,
          });
        }}
      />
    );
  };

  const getColumns = (): iTableColumn<iProductType>[] => [
    {
      key: 'name',
      header: 'Name',
      isSortable: true,
      cell: ({ data }: iCellParams<iProductType>) => {
        return (
          <Link to={URL_PRODUCT_TYPE_DETAILS.replace(':id', data.id)}>
            {data?.name || ''}
          </Link>
        );
      },
    },
    {
      key: 'description',
      header: 'Description',
      cell: ({ data }: iCellParams<iProductType>) =>
        `${data?.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iProductType>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iProductType>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      ProductTypeService.deactivate(data.id),
                    getDisplayName: (ProductType) => ProductType.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            placeholder={'Search name ...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(
                searchTxt === ''
                  ? {}
                  : { name: { [OP_LIKE]: `%${searchText}%` } },
              );
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default ProductTypeList;
