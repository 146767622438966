import styled from 'styled-components';
import Logo from '../common/Logo';
import tokens from '../frameWork/Tokens';
import InlineEdit from '../frameWork/InlineEdit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import { useCallback, useEffect, useState } from 'react';
import CompanyService from '../../services/system/CompanyService';
import { userAuthenticated } from '../../redux/reduxers/auth.slice';
import Toaster from '../common/Toaster';
import PageInit from '../../pages/PageInit';
import iCompany from '../../types/system/iCompany';
import AddressEditPanel from '../address/AddressEditPanel';
import MathHelper from '../../helpers/MathHelper';
import iAddress from '../../types/system/iAddress';
import SectionDiv from '../frameWork/SectionDiv';
import TaxBaseSelector from '../tax/TaxBaseSelector';
import { iOptionWithData } from '../frameWork/Select';
import Flex from '../frameWork/Flex';
import iTaxBase from '../../types/tax/iTaxBase';

const Wrapper = styled.div`
  padding: ${tokens('space.100')};

  form {
    width: 500px;
    max-width: 100%;
  }
`;

const CompanyEditPanel = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingCompany, setEditingCompany] = useState<iCompany | null>(null);
  const [version, setVersion] = useState(0);

  const getCompanyDetails = useCallback(async () => {
    const company = await CompanyService.getCompanyInfo();
    const companyId = `${company?.id || ''}`.trim();
    if (companyId === '') {
      return null;
    }
    return CompanyService.get(companyId, {
      include: 'Address',
    });
  }, []);

  useEffect(() => {
    let isCancelled = false;

    setIsLoading(true);
    getCompanyDetails()
      .then((resp) => {
        if (isCancelled) {
          return;
        }
        setEditingCompany(resp);
      })
      .catch((err) => {
        if (isCancelled) {
          return;
        }
        setEditingCompany(null);
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [getCompanyDetails, version]);

  const onChangeField = (fieldName: string, newValue?: string) => {
    const company = editingCompany || {};
    const companyId = 'id' in company ? `${company.id || ''}`.trim() : '';
    const originalValue =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      fieldName in company ? `${company[fieldName]}`.trim() : null;
    if (
      companyId === '' ||
      newValue === undefined ||
      originalValue === newValue
    ) {
      return;
    }

    setIsSaving(true);
    CompanyService.update(companyId, {
      [fieldName]: newValue,
    })
      .then((res) => {
        if (!user) {
          return;
        }
        setEditingCompany(res);
        dispatch(
          userAuthenticated({
            user: {
              ...(user || {}),
              Company: res,
            },
          }),
        );
        setVersion(MathHelper.add(version, 1));
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleAddressSaved = (saved: iAddress, isCreated: boolean) => {
    const companyId = `${editingCompany?.id || ''}`.trim();
    if (isCreated === true && companyId !== '') {
      CompanyService.update(companyId, {
        addressId: saved.id,
      }).then(() => {
        setVersion(MathHelper.add(version, 1));
      });
      return;
    }

    setVersion(MathHelper.add(version, 1));
    return;
  };

  const getContent = () => {
    if (isLoading) {
      return <PageInit />;
    }
    return (
      <>
        <Logo />
        <InlineEdit
          testId={'logoUrl'}
          isDisabled={isSaving}
          onConfirm={(newValue) => onChangeField('logoUrl', newValue)}
          label={'Logo Url'}
          value={editingCompany?.logoUrl || ''}
          readViewFitContainerWidth
        />
        <InlineEdit
          testId={'name'}
          readViewFitContainerWidth
          isDisabled={isSaving}
          onConfirm={(newValue) => onChangeField('name', newValue)}
          label={'Company Name'}
          value={editingCompany?.name || ''}
        />
        <InlineEdit
          testId={'abn'}
          readViewFitContainerWidth
          isDisabled={isSaving}
          onConfirm={(newValue) => onChangeField('abn', newValue)}
          label={'Company ABN'}
          value={editingCompany?.abn || ''}
        />
        <AddressEditPanel
          address={editingCompany?.Address}
          onSaved={(savedAddr, isCreated) =>
            handleAddressSaved(savedAddr, isCreated)
          }
          hideLotAndStreetNo={true}
        />
        <SectionDiv title={'Tax Settings'} className={'margin-top-small'}>
          <Flex className={'flex-wrap gap-1'}>
            <TaxBaseSelector
              value={editingCompany?.soTaxBaseId}
              label={'Default Sales Order Tax Base'}
              onChange={(selected: iOptionWithData<iTaxBase>) => {
                onChangeField('soTaxBaseId', `${selected?.value || ''}`.trim());
              }}
            />
            <TaxBaseSelector
              value={editingCompany?.poTaxBaseId}
              label={'Default Purchase Order Tax Base'}
              onChange={(selected: iOptionWithData<iTaxBase>) => {
                onChangeField('poTaxBaseId', `${selected?.value || ''}`.trim());
              }}
            />
          </Flex>
        </SectionDiv>
      </>
    );
  };

  return <Wrapper>{getContent()}</Wrapper>;
};

export default CompanyEditPanel;
