import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React, { useState } from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import FinanceService from '../../services/system/FinanceService';
import iFinance from '../../types/system/iFinance';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { iOptionWithData } from '../frameWork/Select';
import iBank from '../../types/system/iBank';
import BankSelector from '../bank/BankSelector';
import ContactCompanySelector from '../contact/ContactCompanySelector';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';
import FinanceTypeSelector from '../financeType/FinanceTypeSelector';
import iFinanceType from '../../types/finance/iFinanceType';
import AttachmentsPopupBtn from '../asset/AttachmentsPopupBtn';
import EntityNames from '../../helpers/EntityNames';
import { AssetTypes } from '../../types/asset/iAsset';

type iBuildDetailsFinanceSummary = iComponentWithPageHeader & {
  allowDelete?: boolean;
  onDeleted?: () => void;
};

const BuildDetailsFinanceSummary = ({
  allowDelete,
  headerProps,
  onDeleted,
}: iBuildDetailsFinanceSummary) => {
  const [hasActiveFinance, setHasActiveFinance] = useState(false);
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iFinance>({
      sort: `createdAt:ASC`,
      getFn: (params) =>
        FinanceService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,FinanceType,Broker,Bank',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }).then((result) => {
          setHasActiveFinance(result.data.length > 0);
          return result;
        }),
    });

  const getEditBtn = (FinanceSummary?: iFinance) => {
    return renderEntityEditPopBtn<iFinance>({
      editingEntity: FinanceSummary,
      entityName: 'FinanceSummary',
      createFn: (data) => FinanceService.create(data),
      updateFn: (id, data) => FinanceService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity.FinanceType?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'typeId',
          label: 'FinanceType',
          isDisabled,
          value: entity?.typeId || '',
          isRequired: true,
          testId: 'finance-typeId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <FinanceTypeSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iFinanceType> | null) => {
                  useAsForm.onFieldChange('typeId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'brokerId',
          label: 'Broker',
          isDisabled,
          value: entity?.brokerId || '',
          testId: 'finance-brokerId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <ContactCompanySelector
                {...props}
                {...errorProps}
                contactCompanyType={ContactCompanyTypes.BROKERS}
                onChange={(option: iOptionWithData<iContactCompany> | null) => {
                  useAsForm.onFieldChange('brokerId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'bankEmail',
          label: 'Bank Email',
          isDisabled,
          isRequired: false,
          value: entity?.bankEmail || '',
          testId: 'finance-bankEmail',
        },
        {
          fieldName: 'bankRef',
          label: 'Bank Reference',
          isDisabled,
          isRequired: false,
          value: entity?.bankRef || '',
          testId: 'finance-bankRef',
        },
        {
          fieldName: 'bankId',
          label: 'Bank',
          isDisabled,
          value: entity?.bankId || '',
          testId: 'finance-bankId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <BankSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iBank> | null) => {
                  useAsForm.onFieldChange('bankId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'bankContactName',
          label: 'Bank Contact Name',
          isDisabled,
          isRequired: false,
          value: entity?.bankContactName || '',
          testId: 'finance-bankContactName',
        },
        {
          fieldName: 'bankContactNumber',
          label: 'Bank Contact Number',
          isDisabled,
          isRequired: false,
          value: entity?.bankContactNumber || '',
          testId: 'finance-bankContactNumber',
        },
        {
          fieldName: 'comments',
          label: 'Comments',
          isDisabled,
          isRequired: false,
          value: entity?.comments || '',
          testId: 'finance-comments',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iFinance>[] => [
    {
      key: 'type',
      header: 'Type',
      isDefault: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'broker',
      header: 'Broker',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data?.Broker?.name || ''}`;
      },
    },
    {
      key: 'bank',
      header: 'Bank',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data.Bank?.name || ''}`;
      },
    },
    {
      key: 'bankContactName',
      header: 'Bank Contact Name',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data.bankContactName || ''}`;
      },
    },
    {
      key: 'bankContactNumber',
      header: 'Bank Contact Number',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data.bankContactNumber || ''}`;
      },
    },
    {
      key: 'bankEmail',
      header: 'Bank Email',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data.bankEmail || ''}`;
      },
    },
    {
      key: 'bankRef',
      header: 'Bank Reference',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data.bankRef || ''}`;
      },
    },
    {
      key: 'comments',
      header: 'Comments',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iFinance>) => {
        return `${data.comments || ''}`;
      },
    },
    {
      key: 'financeDocuments',
      header: 'Finance Documents',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iFinance>) => (
        <AttachmentsPopupBtn
          entityId={data.id}
          entityName={EntityNames.Finance}
          types={[AssetTypes.FINANCE]}
          title="Finance Documents"
          testId="financeDocuments"
          key="financeDocuments"
        />
      ),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iFinance>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iFinance>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    onDeleted: onDeleted,
                    deleteFnc: async () => FinanceService.deactivate(data.id),
                    getDisplayName: (Finance) =>
                      Finance?.FinanceType?.name || '',
                  })}
                </div>
              );
            },
          },
        ]),
  ];
  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={hasActiveFinance ? null : getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'Finance-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};
export default BuildDetailsFinanceSummary;
