import ListComponent, { iListComponent } from '../frameWork/ListComponent';
import iBuildColourNoteTemplate from '../../types/build/iBuildColourNoteTemplate';
import BuildColourNoteTemplateService from '../../services/build/BuildColourNoteTemplateService';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import React from 'react';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import TextArea from '../frameWork/TextArea';
import { OP_LIKE } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';

export type iBuildColourNoteTemplateList = Omit<
  iListComponent<iBuildColourNoteTemplate>,
  'componentName' | 'getFn' | 'getColumns' | 'renderCreateBtn'
>;

const BuildColourNoteTemplateList = ({
  allowEdit,
  ...props
}: iBuildColourNoteTemplateList) => {
  const getEditPopBtn = (
    props: ReturnType<typeof useListCrudHook<iBuildColourNoteTemplate>>,
    data?: iBuildColourNoteTemplate,
  ) => {
    return props.renderEntityEditPopBtn({
      editingEntity: data,
      entityName: 'Build Colour Note Template',
      createFn: (data) => BuildColourNoteTemplateService.create(data),
      updateFn: (id, data) => BuildColourNoteTemplateService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => {
        return (
          <a
            onClick={onClick}
            className={'cursor-pointer'}
            data-testid={`edit-btn-${entity?.id || ''}`}
          >
            {entity?.name || ''}
          </a>
        );
      },
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'description',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextArea
                {...props}
                {...errorProps}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.value);
                }}
              />
            );
          },
        },
      ],
    });
  };

  return (
    <ListComponent
      allowEdit={allowEdit}
      componentName={'BuildColourNoteTemplate'}
      selectiveColumnKey={SelectiveColKeys.BUILD_COLOUR_NOTE_TEMPLATE_LIST}
      {...props}
      deactivateFn={async (data: iBuildColourNoteTemplate) =>
        BuildColourNoteTemplateService.deactivate(data.id)
      }
      renderCreateBtn={getEditPopBtn}
      getFn={(params) =>
        BuildColourNoteTemplateService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        })
      }
      getActions={({ onSetFilter }) => {
        return (
          <SearchTextField
            testId={`search-field`}
            placeholder={'Search name ...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(
                searchTxt === ''
                  ? {}
                  : { name: { [OP_LIKE]: `%${searchText}%` } },
              );
            }}
          />
        );
      }}
      getColumns={(renderEntityEditPopBtn) => {
        return [
          {
            key: 'name',
            header: 'Name',
            isDefault: true,
            cell: ({ data }) => {
              return allowEdit === true
                ? getEditPopBtn(renderEntityEditPopBtn, data)
                : data.name;
            },
          },
          {
            key: 'description',
            header: 'Description',
            isDefault: true,
            isSelectable: true,
            cell: ({ data }) => {
              return data.description || '';
            },
          },
        ];
      }}
    />
  );
};

export default BuildColourNoteTemplateList;
