import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iEstatePlanNoteCategory from '../../types/estate/iEstatePlanNoteCategory';
import EstatePlanNoteCategoryService from '../../services/estate/EstatePlanNoteCategoryService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import Toaster from '../common/Toaster';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';
import Flex from '../frameWork/Flex';

export type iEstatePlanNoteCategoryList = iComponentWithPageHeader & {
  allowCreate?: boolean;
  allowDelete?: boolean;
  allowEdit?: boolean;
  isRankable?: boolean;
  testId?: string;
};
const EstatePlanNoteCategoryList = ({
  headerProps,
  allowDelete,
  allowCreate,
  allowEdit,
  isRankable,
  testId,
}: iEstatePlanNoteCategoryList) => {
  const componentName = 'EstatePlanNoteCategoryList';
  const testIdStr = `${testId || ''}-${componentName}`;
  const {
    state,
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetIsLoading,
    onRefresh,
    onSetFilter,
    viewingState,
  } = useListCrudHook<iEstatePlanNoteCategory>({
    sort: `sort:ASC`,
    ...(isRankable === true ? { perPage: 999999999 } : {}),
    getFn: (params) =>
      EstatePlanNoteCategoryService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (estatePlanNoteCategory?: iEstatePlanNoteCategory) => {
    return renderEntityEditPopBtn<iEstatePlanNoteCategory>({
      editingEntity: estatePlanNoteCategory,
      entityName: 'Developer Guide Category',
      createFn: (data) => EstatePlanNoteCategoryService.create(data),
      updateFn: (id, data) => EstatePlanNoteCategoryService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`EstatePlanNoteCategory-${entity?.id || ''}`}
        >
          {entity.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'EstatePlanNoteCategory-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'EstatePlanNoteCategory-description',
        },
      ],
    });
  };

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    onSetIsLoading(true);
    const dgc = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = dgc.splice(sourceIndex, 1);
    // Insert it at the destination position
    dgc.splice(targetIndex, 0, movedItem);
    Promise.all(
      dgc.map((dgc, index) => {
        return EstatePlanNoteCategoryService.update(dgc.id, { sort: index });
      }),
    )
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        onRefresh();
      });
  };

  const getColumns = (): iTableColumn<iEstatePlanNoteCategory>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iEstatePlanNoteCategory>) => {
        return allowEdit === true ? getEditBtn(data) : data.name;
      },
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iEstatePlanNoteCategory>) =>
        `${data.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iEstatePlanNoteCategory>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iEstatePlanNoteCategory>) => {
              return (
                <Flex className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      EstatePlanNoteCategoryService.deactivate(data.id),
                    getDisplayName: (EstatePlanNoteCategory) =>
                      EstatePlanNoteCategory.name,
                  })}
                </Flex>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={allowCreate === true ? getEditBtn() : null}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter({
                ...viewingState.filter,
                ...(searchTxt === ''
                  ? {}
                  : {
                      name: { [OP_LIKE]: `%${searchTxt}%` },
                    }),
              });
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
          isRankable: allowEdit === true && isRankable === true,
          onRankEnd: (params) =>
            submitReorder(params.sourceIndex, params.destination?.index || 0),
        },
      })}
    </ComponentWithPageHeader>
  );
};
export default EstatePlanNoteCategoryList;
