import React, { useEffect, useState } from 'react';
import iBuild from '../../../../../types/build/iBuild';
import PlanRevisionSelector from '../../../../plan/PlanRevisionSelector';
import iPlanRevision from '../../../../../types/plan/iPlanRevision';
import { iOptionWithData } from '../../../../frameWork/Select';
import AttachmentsPopupBtn from '../../../../asset/AttachmentsPopupBtn';
import EntityNames from '../../../../../helpers/EntityNames';
import { AssetTypes } from '../../../../../types/asset/iAsset';
import { Label } from '../../../../frameWork/Form';
import BuildPlanService from '../../../../../services/plan/BuildPlanService';
import iBuildPlan from '../../../../../types/plan/iBuildPlan';
import iBuildPlanChange from '../../../../../types/plan/iBuildPlanChange';
import BuildPlanChangeService from '../../../../../services/plan/BuildPlanChangeService';
import Toaster from '../../../../common/Toaster';
import BuildService from '../../../../../services/build/BuildService';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: inherit;
  align-items: center;
  .flex-display {
    display: flex;
    align-items: center;
    gap: inherit;
  }
`;
type iState = {
  buildPlan?: iBuildPlan;
  buildPlanChange?: iBuildPlanChange;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: true,
};

const BuildDetailsColoursOverviewTopNav = ({
  build,
  onBuildUpdated,
}: {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
}) => {
  const [state, setState] = useState(initialState);

  const buildId = build.id;
  const planRevisionId = build.PlanRevision?.id || '';
  const planRevisionSort = build.PlanRevision?.sort || null;

  useEffect(() => {
    let isCancelled = false;
    const loadBuildPlanChange = async () => {
      try {
        const { data } = await BuildPlanService.getAll({
          where: JSON.stringify({ isActive: true, buildId }),
          include: 'Revision',
          currentPage: 1,
          perPage: 1,
        });
        if (isCancelled) return;
        if (data.length === 0) {
          throw new Error("can't find any plan related to this build job");
        }
        if (data[0].revisionId === data[0].initRevisionId) {
          setState((prevState) => ({
            ...prevState,
            buildPlan: data[0],
            isLoading: false,
          }));
          return;
        }
        const { data: changes } = await BuildPlanChangeService.getAll({
          where: JSON.stringify({
            buildPlanId: data[0].id,
            isActive: true,
            revisionId: data[0].revisionId,
          }),
          currentPage: 1,
          perPage: 1,
        });
        if (changes.length === 0) {
          throw new Error(
            "can't find the latest plan change to this build job",
          );
        }
        setState((prevState) => ({
          ...prevState,
          buildPlan: data[0],
          buildPlanChange: changes[0],
          isLoading: false,
        }));
      } catch (error) {
        Toaster.showApiError(error);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    };

    loadBuildPlanChange();

    return () => {
      isCancelled = true;
    };
  }, [planRevisionId, planRevisionSort]);

  const onPlanRevisionWhenColoursChanged = async (
    option: iOptionWithData<iPlanRevision> | null,
  ) => {
    const updated = await BuildService.update(buildId, {
      planRevisionIdWhenColours: (option?.value || '') as string,
    });
    onBuildUpdated && onBuildUpdated(updated);
  };
  return (
    <Wrapper>
      <div className="flex-display">
        <Label htmlFor="PlanRevisionSelector-planRevisionIdWhenColours">{`Colours Done On: `}</Label>
        <PlanRevisionSelector
          value={build.planRevisionIdWhenColours}
          onChange={onPlanRevisionWhenColoursChanged}
          id={'PlanRevisionSelector-planRevisionIdWhenColours'}
        />
      </div>

      {!state.isLoading && (
        <div className="flex-display">
          <Label htmlFor="received-plans">{`Current Plan ${state.buildPlan?.Revision?.name}: `}</Label>
          <div id={'received-plans'}>
            <AttachmentsPopupBtn
              entityId={
                state.buildPlanChange
                  ? state.buildPlanChange.id
                  : state.buildPlan?.id || ''
              }
              entityName={
                state.buildPlanChange
                  ? EntityNames.BuildPlanChange
                  : EntityNames.BuildPlan
              }
              types={[AssetTypes.BUILD_PLAN_RECEIVED_PLANS]}
              title={`Received ${state.buildPlan?.Revision?.name} Plans`}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default BuildDetailsColoursOverviewTopNav;
