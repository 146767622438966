import ServiceHelper from '../helper/ServiceHelper';
import iWorkflow from '../../types/system/iWorkflow';

const endPoint = '/workflow';

const WorkflowService = {
  getAll: ServiceHelper.getPaginatedAll<iWorkflow>(endPoint),
  get: ServiceHelper.getById<iWorkflow>(endPoint),
  create: ServiceHelper.create<iWorkflow>(endPoint),
  update: ServiceHelper.updateById<iWorkflow>(endPoint),
  deactivate: ServiceHelper.deactivateById<iWorkflow>(endPoint),
};

export default WorkflowService;
