import { Link } from 'react-router-dom';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iEstate from '../../types/estate/iEstate';
import EstateService from '../../services/estate/EstateService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { URL_ESTATE_DETAILS } from '../../helpers/UrlMap';

export type iEstateList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
};

const EstateList = ({ allowDelete, allowCreate, headerProps }: iEstateList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iEstate>({
      sort: `name:ASC`,
      getFn: (params) =>
        EstateService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (Estate?: iEstate) => {
    return renderEntityEditPopBtn<iEstate>({
      editingEntity: Estate,
      entityName: 'Estate',
      createFn: (data) => EstateService.create(data),
      updateFn: (id, data) => EstateService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'Estate-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'Estate-description',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iEstate>[] => [
    {
      key: 'name',
      header: 'Name',
      isSelectable: true,
      cell: ({ data }: iCellParams<iEstate>) => {
        // return getEditBtn(data);
        return (
          <Link
            to={URL_ESTATE_DETAILS.replace(':id', data.id)}
            className="cursor-pointer"
            data-testid={`name-${data?.id || ''}`}
          >
            {data.name}
          </Link>
        );
      },
    },
    {
      key: 'description',
      header: 'Description',
      cell: ({ data }: iCellParams<iEstate>) => `${data?.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iEstate>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iEstate>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => EstateService.deactivate(data.id),
                    getDisplayName: (Estate) => Estate.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={allowCreate === true ? getEditBtn() : null}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'Estate-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default EstateList;
