import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import { Link } from 'react-router-dom';
import EntityStatusTypeService from '../../services/status/EntityStatusTypeService';
import iEntityStatusType from '../../types/status/iEntityStatusType';
import Flex from '../frameWork/Flex';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';
import DynamicTableHelper, {
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { URL_SETTINGS_ENTITY_STATUS_TYPE_DETAILS } from '../../helpers/UrlMap';

type iEntityStatusTypeList = Omit<iComponentWithPageHeader, 'children'> &
  WrapperProps;
const EntityStatusTypeList = ({
  testId,
  className,
  headerProps,
}: iEntityStatusTypeList) => {
  const { testIdStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'EntityStatusTypeList',
    testId,
    className,
  });
  const { renderDataTable, onSetFilter } = useListCrudHook<iEntityStatusType>({
    sort: `entityName:ASC`,
    perPage: 999999999,
    getFn: (params) =>
      EntityStatusTypeService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getActions = () => {
    if (headerProps?.actions) {
      return headerProps?.actions;
    }
    return (
      <Flex className={'gap-1 align-items-center'}>
        <SearchTextField
          testId={`${testIdStr}-search-field`}
          placeholder={'Search name ...'}
          onSearch={(searchText) => {
            const searchTxt = `${searchText || ''}`.trim();
            onSetFilter(
              searchTxt === ''
                ? {}
                : {
                    entityName: { [OP_LIKE]: `%${searchTxt}%` },
                  },
            );
          }}
        />
      </Flex>
    );
  };
  const getColumns = (): iTableColumn<iEntityStatusType>[] => [
    {
      key: 'entityName',
      header: 'Name',
      isDefault: true,
      cell: ({ data }) => (
        <Link
          to={URL_SETTINGS_ENTITY_STATUS_TYPE_DETAILS.replace(':id', data.id)}
        >
          {data.entityName}
        </Link>
      ),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iEntityStatusType>(
      true,
      true,
    ),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        actions: getActions(),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.ENTITY_STATUS_TYPE_LIST,
        tblProps: {
          testId: 'email-template-tbl',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default EntityStatusTypeList;
