import React, { useEffect, useState } from 'react';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import iEntityStatus from '../../../types/status/iEntityStatus';
import {
  iEdge,
  iNode,
  useEdges,
  useNodes,
} from '../../frameWork/ReactFlowRenderer';
import SectionDiv from '../../frameWork/SectionDiv';
import { getErrorProps, iErrorMap } from '../../form/FormError';
import Select from '../../frameWork/Select';
import Flex from '../../frameWork/Flex';
import Button from '../../frameWork/Button';
import Icons from '../../frameWork/Icons';

type iStatusMap = { [key: string]: iEntityStatus };
type iWorkflowStartStatusPanel = {
  editingNode: iNode;
  state: iWFDiagramState;
};
const WorkflowStartStatusPanel = ({
  editingNode,
  state,
}: iWorkflowStartStatusPanel) => {
  const [statusMap, setStatusMap] = useState<iStatusMap>({});
  const [startEdge, setStartEdge] = useState<iEdge | null>(null);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [editingData, setEditingData] = useState<{ [key: string]: string }>({});
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setStatusMap(
      nodes.reduce((map: iStatusMap, node: iNode) => {
        if (!('status' in node.data)) {
          return map;
        }
        return {
          ...map,
          [node.data.status.id]: node.data.status,
        };
      }, {}),
    );
  }, [nodes]);

  useEffect(() => {
    const startEdges = edges.filter((edge) => edge.source === editingNode.id);
    setStartEdge(startEdges.length > 0 ? startEdges[0] : null);
  }, [edges, editingNode.id]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getStatusOptions = () => {
    const statuses = Object.values(statusMap);
    return statuses.map((status: iEntityStatus) => ({
      label: status.name,
      value: status.id,
    }));
  };

  const preCheck = () => {
    const errors: iErrorMap = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...startEdge, ...editingData };
    const initStatusId = `${data.target || ''}`.trim();
    if (initStatusId === '') {
      errors.target = 'Please select a status';
    }
    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const updateTransition = () => {
    if (!preCheck()) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...startEdge, ...editingData };
    const { target } = data;
    const initStatusId = `${target || ''}`.trim();
    if (initStatusId === '') {
      return;
    }

    state.setEdges(
      edges.map((edge) => {
        if (edge.id === startEdge?.id) {
          return { ...edge, target: initStatusId };
        }
        return edge;
      }),
    );
    state.setNodes(
      nodes.map((node) => ({
        ...node,
        selected: false,
      })),
    );
  };

  const getSelectedValue = (fieldName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...startEdge, ...editingData };
    if (!(fieldName in data)) {
      return undefined;
    }
    const id = data[fieldName];
    return id in statusMap
      ? { label: statusMap[id].name, value: id }
      : undefined;
  };

  return (
    <div>
      <SectionDiv title={<h3>Start Status</h3>}>
        <div>
          <small>
            Start Status is when a work or any other entities been created.
          </small>
        </div>
        <p>Please select the initial status below to start the workflow:</p>
      </SectionDiv>
      <Select
        value={getSelectedValue('target')}
        {...getErrorProps({ error: errorMap, fieldName: 'target' })}
        options={getStatusOptions()}
        label={'Initial Status:'}
        isRequired
        onChange={(selected) => {
          setEditingData({
            ...editingData,
            target: `${selected?.value || ''}`,
          });
        }}
      />
      <Flex className={'space-top justify-content-end'}>
        <Button
          appearance={'primary'}
          onClick={() => updateTransition()}
          iconBefore={Icons.SendIcon}
        >
          Update
        </Button>
      </Flex>
    </div>
  );
};

export default WorkflowStartStatusPanel;
