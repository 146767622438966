import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Toaster from '../../../../common/Toaster';
import { iConfigParams } from '../../../../../services/AppService';
import { DatePicker, formatDate } from '../../../../frameWork/DateTimePicker';
import UserSelector from '../../../../user/UserSelector';
import { iOption } from '../../../../frameWork/Select';
import InlineEdit from '../../../../frameWork/InlineEdit';
import Toggle from '../../../../frameWork/Toggle';
import { AssetTypes } from '../../../../../types/asset/iAsset';
import EntityNames from '../../../../../helpers/EntityNames';
import iBuildPlan from '../../../../../types/plan/iBuildPlan';
import BuildPlanService from '../../../../../services/plan/BuildPlanService';
import InlineTextArea from '../../../../frameWork/InlineTextArea';
import PlanRevisionService from '../../../../../services/plan/PlanRevisionService';
import { Label } from '../../../../frameWork/Form';
import Tokens from '../../../../frameWork/Tokens';
import { AttributeSetCodes } from '../../../../../types/attribute/iAttributeSet';
import AttributeSetInputPanel from '../../../../attributeSet/AttributeSetInputPanel';
import { AttributeForSections } from '../../../../../types/attribute/iAttribute';
import entityNames from '../../../../../helpers/EntityNames';
import AttachmentsPopupBtn from '../../../../asset/AttachmentsPopupBtn';
import BuildService from '../../../../../services/build/BuildService';
import iBuild from '../../../../../types/build/iBuild';

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.200', '1rem')};
  .received-div {
    padding: ${Tokens('space.100', '0.5rem')};
    flex-grow: 1;
  }

  .requested-div {
    padding: ${Tokens('space.100', '0.5rem')};
    min-width: 20rem;
    width: 40%;
    max-width: 100%;
  }
  .date-picker {
    max-width: 10rem;
  }
  .margin-top-half {
    margin-top: ${Tokens('space.100', '0.5rem')};
  }
`;

type iState = {
  isLoading: boolean;
  buildPlan?: iBuildPlan;
};
const initialState: iState = {
  isLoading: false,
};

type iBuildDetailsPlanOverview = {
  entityId: string;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsPlansInitialRequest = ({
  entityId,
  isDisabled,
  onBuildUpdated,
}: iBuildDetailsPlanOverview) => {
  const [state, setState] = useState<iState>(initialState);
  useEffect(() => {
    let isCancelled = false;
    const loadTableData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await BuildPlanService.getAll({
          where: JSON.stringify({ isActive: true, buildId: entityId }),
          include: 'InitRevision',
          currentPage: 1,
          perPage: 10,
        });
        if (data.length === 0) {
          const buildPlan: iBuildPlan = await BuildPlanService.create({
            buildId: entityId,
          });
          data.push(buildPlan);
        }
        if (isCancelled) return;
        setState((preState) => ({
          ...preState,
          buildPlan: data[0],
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        Toaster.showApiError(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    loadTableData();
    return () => {
      isCancelled = true;
    };
  }, [entityId]);

  const handleUpdate = (newValues: iConfigParams) => {
    setState({ ...state, isLoading: true });
    BuildPlanService.update(state.buildPlan?.id || '', newValues)
      .then((resp) =>
        setState({
          ...state,
          isLoading: false,
          buildPlan: { ...state.buildPlan, ...resp },
        }),
      )
      .catch((err) => {
        Toaster.showApiError(err);
        setState({ ...state, isLoading: false });
      });
  };

  const postUploadPlan = async () => {
    if (state.buildPlan?.initRevisionId) {
      return;
    }
    const { data: planRevisions } = await PlanRevisionService.getAll({
      where: JSON.stringify({ isActive: true }),
      sort: `sort:ASC`,
      currentPage: 1,
      perPage: 10,
    });
    await BuildPlanService.update(state.buildPlan?.id || '', {
      initRevisionId: planRevisions[0].id,
      revisionId: planRevisions[0].id,
    });
    const newBuild = await BuildService.update(entityId, {
      planRevisionId: planRevisions[0].id,
    });
    onBuildUpdated && onBuildUpdated(newBuild);
  };
  return (
    <div
      data-testid="build-plan-initial-request-wrapper"
      className={state.isLoading === true ? 'is-loading' : ''}
    >
      <Wrapper>
        <div className="requested-div">
          <DatePicker
            className="date-picker"
            testId={'buildPlan-initRequestedDate'}
            label={'Requested Date'}
            isDisabled={isDisabled || state.isLoading}
            value={formatDate(state.buildPlan?.initRequestedDate || '')}
            onChange={(value: string) =>
              handleUpdate({ initRequestedDate: value })
            }
          />
          <UserSelector
            testId={'buildPlan-initRequestedById-selector'}
            appearance={'subtle'}
            value={state.buildPlan?.initRequestedById}
            label={'Requested By'}
            onChange={(selected: iOption) =>
              handleUpdate({ initRequestedById: selected?.data.id })
            }
          />
          <InlineEdit
            testId={'buildPlan-planOfSubNumber'}
            label={'planOfSubNumber'}
            isDisabled={state.isLoading || isDisabled}
            placeHolder={'Plan Of Sub Number'}
            value={state.buildPlan?.planOfSubNumber || ''}
            onConfirm={(subNumbers) =>
              handleUpdate({
                planOfSubNumber:
                  `${subNumbers || ''}`.trim() === '' ? null : subNumbers,
              })
            }
          />
          <InlineEdit
            testId={'buildPlan-smallLotHousingCode'}
            label={'Small Lot Housing Code'}
            isDisabled={state.isLoading || isDisabled}
            placeHolder={'Small Lot Housing Code'}
            value={state.buildPlan?.smallLotHousingCode || ''}
            onConfirm={(changes) =>
              handleUpdate({
                smallLotHousingCode:
                  `${changes || ''}`.trim() === '' ? null : changes,
              })
            }
          />
          <AttributeSetInputPanel
            attributeSetCodes={[AttributeSetCodes.InitBuildPlan]}
            attributeSection={AttributeForSections.PLANS}
            isDisabled={state.isLoading || isDisabled}
            entityId={entityId}
            entityName={entityNames.Build}
          />
        </div>
        <div className="received-div">
          <DatePicker
            className="date-picker"
            testId={'buildPlan-initReceivedDate'}
            label={'Received Date'}
            isDisabled={isDisabled || state.isLoading}
            value={formatDate(state.buildPlan?.initReceivedDate || '')}
            onChange={(value: string) =>
              handleUpdate({ initReceivedDate: value })
            }
          />
          {state.buildPlan?.InitRevision && (
            <InlineEdit
              testId="buildPlan-initRevisionId"
              label={'Revision'}
              isDisabled={true}
              placeHolder={'Revision'}
              value={state.buildPlan?.InitRevision?.name || ''}
              onConfirm={(changes) =>
                handleUpdate({
                  revisionId: `${changes || ''}`.trim() === '' ? null : changes,
                })
              }
            />
          )}

          <div className="received-plans">
            <Label htmlFor="received-plans">
              {state.buildPlan?.InitRevision?.name
                ? `Received ${state.buildPlan?.InitRevision?.name} Plans`
                : 'Received Plans'}
            </Label>
            <div id={'received-plans'}>
              {state.buildPlan?.id && (
                <AttachmentsPopupBtn
                  entityId={state.buildPlan.id}
                  entityName={EntityNames.BuildPlan}
                  types={[AssetTypes.BUILD_PLAN_RECEIVED_PLANS]}
                  title={
                    state.buildPlan?.InitRevision?.name
                      ? `Received ${state.buildPlan?.InitRevision?.name} Plans`
                      : 'Received Plans'
                  }
                  postUploadPlan={postUploadPlan}
                />
              )}
            </div>
          </div>

          <Toggle
            isDisabled={state.isLoading || isDisabled}
            label={'Changes Correct'}
            testId={'buildPlan-isChangesCorrect'}
            isChecked={!!state.buildPlan?.isChangesCorrect}
            onChange={() =>
              handleUpdate({
                isChangesCorrect: !state.buildPlan?.isChangesCorrect,
              })
            }
          />
        </div>
      </Wrapper>
      <div className={'full-width'}>
        <InlineTextArea
          testId={'buildPlan-comments'}
          readViewFitContainerWidth
          label={'Comments'}
          placeHolder={'comments'}
          isDisabled={state.isLoading || isDisabled}
          value={state.buildPlan?.comments || ''}
          onConfirm={(newValue) =>
            handleUpdate({
              comments: newValue,
            })
          }
        />
      </div>
    </div>
  );
};

export default BuildDetailsPlansInitialRequest;
