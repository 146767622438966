import { useCallback, useState } from 'react';
import styled from 'styled-components';
import iEstate from '../../../types/estate/iEstate';
import { DatePicker, formatDate } from '../../frameWork/DateTimePicker';
import Toggle from '../../frameWork/Toggle';
import Toaster from '../../common/Toaster';
import InlineEdit from '../../frameWork/InlineEdit';
import EstateService from '../../../services/estate/EstateService';
import Flex from '../../frameWork/Flex';
import Select, { iOption } from '../../frameWork/Select';
import AttachmentsPopupBtn from '../../asset/AttachmentsPopupBtn';
import EntityNames from '../../../helpers/EntityNames';
import { AssetTypes } from '../../../types/asset/iAsset';
import FormField from '../../frameWork/FormField';

const Wrapper = styled.div`
  form {
    margin-top: 0px !important;
  }
  .planNotes-input-wrapper {
    overflow-y: auto;
    max-height: 180px;
  }
`;
const applicationTypeOptions: iOption[] = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'Portal', value: 'PORTAL' },
];

export type iEstateDetailsSummaryPanel = {
  estate: iEstate;
  isDisabled?: boolean;
  onUpdated: (updated: iEstate) => void;
  className?: string;
};

const EstateDetailsSummaryPanel = ({
  estate,
  onUpdated,
  className,
  isDisabled,
}: iEstateDetailsSummaryPanel) => {
  const testId = 'estate-details-summary';
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (
    fieldName: string,
    newValue: string | boolean | null,
  ) => {
    setIsSaving(true);
    EstateService.update(estate.id, {
      [fieldName]: newValue,
    })
      .then((resp) => {
        onUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  const validateApplicationMethod = useCallback(
    (value: string, applicationType: string | null): string => {
      if (!value) return '';

      if (applicationType === 'EMAIL') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value)
          ? ''
          : 'Please enter a valid email address';
      }

      if (applicationType === 'PORTAL') {
        const urlRegex =
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return urlRegex.test(value) ? '' : 'Please enter a valid URL';
      }

      return '';
    },
    [],
  );

  return (
    <Wrapper
      className={`estate-details-summary ${className || ''}`}
      data-testid={testId}
    >
      <Flex className={'align-items-start gap-1'}>
        <Select
          appearance={'subtle'}
          testId={'application-type'}
          isDisabled={isSaving || isDisabled}
          label={'Application Type'}
          value={applicationTypeOptions.find(
            (option) => option.value === estate.applicationType || '',
          )}
          options={applicationTypeOptions}
          onChange={(selected: iOption | null) => {
            handleUpdate('applicationType', selected?.value as string);
          }}
        />

        <InlineEdit
          testId={'applicationMethod'}
          isDisabled={isSaving || isDisabled}
          label={'Application Method'}
          value={estate.applicationMethod || ''}
          validate={(value) =>
            validateApplicationMethod(value, estate.applicationType || '')
          }
          onConfirm={(value: string) =>
            handleUpdate('applicationMethod', value)
          }
        />

        <FormField
          label={'Guideline files'}
          render={() => {
            return (
              <div>
                <AttachmentsPopupBtn
                  entityId={estate.id}
                  entityName={EntityNames.Estate}
                  types={[AssetTypes.ESTATE_GUILDLINES]}
                  title={'Guild Line Files'}
                />
              </div>
            );
          }}
        />
        <DatePicker
          testId={'expiry-date'}
          isDisabled={isSaving || isDisabled}
          label={'Expiry Date'}
          value={formatDate(estate.expiryDate || '')}
          onChange={(value: string) =>
            handleUpdate('expiryDate', value.trim() === '' ? null : value)
          }
        />
      </Flex>
      <div>
        <InlineEdit
          readViewFitContainerWidth
          testId={'description'}
          isDisabled={isSaving || isDisabled}
          label={'Description'}
          value={estate.description || ''}
          onConfirm={(value: string) => handleUpdate('description', value)}
        />
      </div>
      <Flex className={'gap-025'}>
        {[
          {
            label: 'DA Required?',
            fieldName: 'daRequirementNeeded',
            isChecked: estate.daRequirementNeeded === true,
          },
          {
            label: 'DA Approved?',
            fieldName: 'daApprovalRequired',
            isChecked: estate.daApprovalRequired === true,
          },
          {
            label: 'MCP',
            fieldName: 'mcpRequired',
            isChecked: estate.mcpRequired === true,
          },
          {
            label: 'Small House Code',
            fieldName: 'smallHouseCode',
            isChecked: estate.smallHouseCode === true,
          },
          {
            label: '173 Agreement',
            fieldName: 'agreement173',
            isChecked: estate.agreement173 === true,
          },
        ].map((item) => {
          return (
            <Toggle
              key={item.fieldName}
              separatedLines
              isDisabled={isSaving || isDisabled}
              label={item.label}
              testId={item.fieldName}
              isChecked={item.isChecked}
              onChange={() => handleUpdate(item.fieldName, !item.isChecked)}
            />
          );
        })}
      </Flex>
    </Wrapper>
  );
};

export default EstateDetailsSummaryPanel;
