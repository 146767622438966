import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableTree, {
  Cell,
  Header,
  Headers,
  Row,
  Rows,
} from '../../frameWork/TableTree';
import iProduct from '../../../types/product/iProduct';
import Toaster from '../../common/Toaster';
import Button from '../../frameWork/Button';
import SpinWhenLoading from '../../warehouseLocation/SpinWhenLoading';
import EntityEditPopupBtn from '../../form/EntityEditPopupBtn';
import DeleteBtn from '../../warehouseLocation/DeleteBtn';
import MoreIconPopup from '../../warehouseLocation/MoreIconPopup';
import iProductOption from '../../../types/product/iProductOption';
import ProductOptionService from '../../../services/product/ProductOptionService';
import arrayToTree from 'array-to-tree';
import UtilsService from '../../../services/UtilsService';
import ComponentWithPageHeader from '../../common/ComponentWithPageHeader';
import { iPageHeader } from '../../frameWork/PageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../../common/PageTitleWithCreateBtn';
import ProductOptionTypeSelector from '../ProductOptionTypeSelector';
import { iOptionWithData } from '../../frameWork/Select';
import iProductOptionType from '../../../types/product/iProductOptionType';
import DeleteBtnNotAllowed from '../../warehouseLocation/DeleteBtnNotAllowed';
import {
  MOVE_WARNING_MESSAGE,
  PopUpContentContainer,
} from '../../warehouseLocation/Warehouse.constants';
import _ from 'lodash';
import ProductOptionSelector from '../ProductOptionSelector';
import SectionMessage from '../../frameWork/SectionMessage';
import Toggle from '../../frameWork/Toggle';

const OptionsListWrapper = styled.div`
  div[role='row'] {
    align-items: center;
    min-height: 60px;
  }
`;
export const OPTION_DELETE_INVALID_TEXT = (
  <span>
    This product option owns children options.
    <p> Please clear them up before you delete it</p>
  </span>
);
export const OPTION_MOVE = 'Move this option';

export type iProductDetailsOptionsPanel = {
  productId: string;
  isDisabled?: boolean;
  onSaved?: (saved: iProduct) => void;
  className?: string;
  testId?: string;
  headerProps?: iPageHeader;
};

type iState = {
  items: Array<iProductOption>;
  isLoading: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  items: [],
  isLoading: true,
  isConfirming: false,
};
const LARGE_PER_PAGE = 100;
export type iProductOptionUI = iProductOption & {
  children?: Array<iProductOptionUI>;
  hasChild?: boolean;
};

const ProductDetailsOptionsPanel = ({
  testId,
  className,
  productId,
  headerProps,
}: iProductDetailsOptionsPanel) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    const loadOptions = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        // Replace with your actual service call
        const { data } = await ProductOptionService.getAll({
          where: JSON.stringify({ isActive: true, parentId: null }),
          currentPage: 1,
          perPage: LARGE_PER_PAGE,
          include: 'ProductOptionType',
        });
        if (isCancelled) return;
        setState((preState) => ({
          ...preState,
          items: data,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        Toaster.showApiError(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    loadOptions();
    return () => {
      isCancelled = true;
    };
  }, [productId]);

  const afterCreate = (saved: iProductOption) => {
    const removeIfExist = state.items.filter(
      (element: iProductOption) => element.id !== saved.id,
    );

    setState({
      ...state,
      items: [
        ...removeIfExist.map((i: iProductOption) =>
          i.id === saved.parentId ? { ...i, hasChild: true } : i,
        ),
        saved,
      ],
    });
  };

  const expandItem = async (parentItem: iProductOptionUI) => {
    // if (parentItem.children && parentItem.children.length > 0) return;
    setState({ ...state, isConfirming: true });
    try {
      const { data }: { data: iProductOption[] } =
        await ProductOptionService.getAll({
          where: JSON.stringify({ isActive: true, parentId: parentItem.id }),
          currentPage: 1,
          perPage: LARGE_PER_PAGE,
          include: 'ProductOptionType',
        });
      const diff = data.filter((d: iProductOption) => {
        if (!parentItem.children || parentItem.children.length === 0) {
          return true;
        }
        return !parentItem.children.find(
          (p: iProductOptionUI) => p.id === d.id,
        );
      });
      setState({
        ...state,
        items: [...state.items, ...diff],
        isConfirming: false,
      });
    } catch (error) {
      Toaster.showApiError(error);
      setState({ ...state, isConfirming: false });
    }
  };

  const getEditBtn = (item?: iProductOptionUI, isEditing = false) => {
    return (
      <EntityEditPopupBtn<iProductOptionUI>
        entityName={isEditing || !item ? 'Option' : 'Sub Option'}
        entity={isEditing ? item : undefined}
        onSaved={(saved) => afterCreate(saved)}
        createFn={async (data) => {
          const newCreated = await ProductOptionService.create({
            ...data,
            parentId: item?.id,
            productId,
          });
          return await ProductOptionService.get(newCreated.id, {
            include: 'ProductOptionType',
          });
        }}
        updateFn={async (id, data) => {
          await ProductOptionService.update(id, data);
          return await ProductOptionService.get(id, {
            include: 'ProductOptionType',
          });
        }}
        renderBtn={
          isEditing
            ? (onClick) => (
                <a
                  onClick={onClick}
                  className={'cursor-pointer'}
                  data-testid={`name-${item?.id || ''}`}
                >
                  {item?.name}
                </a>
              )
            : item
              ? (onClick) => (
                  <Button onClick={onClick}>{'Create a sub option'}</Button>
                )
              : (onClick) =>
                  getCreateIconBtn({
                    onClick: onClick,
                    label: `Create a new option`,
                    isTooltipDisabled: false,
                  })
        }
        getFormFields={({ entity, isDisabled }) => [
          {
            fieldName: 'name',
            label: 'Name',
            isDisabled,
            isRequired: true,
            value: entity?.name || '',
            testId: 'ProductOption-name',
          },
          {
            fieldName: 'productOptionTypeId',
            label: 'Option Type',
            isDisabled,
            value: entity?.productOptionTypeId || '',
            isRequired: true,
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <ProductOptionTypeSelector
                  {...props}
                  {...errorProps}
                  testId={'ProductOption-productOptionTypeId'}
                  isDisabled={isDisabled}
                  onChange={(event: iOptionWithData<iProductOptionType>) => {
                    useAsForm.onFieldChange(props.fieldName, event.value);
                  }}
                />
              );
            },
          },
          {
            fieldName: 'priceVariance',
            label: 'Price Variance',
            isDisabled,
            isRequired: true,
            value: entity?.priceVariance || '',
            testId: 'ProductOption-priceVariance',
          },
        ]}
      />
    );
  };

  const getDeleteBtn = (item: iProductOptionUI) => {
    if (item.hasChild || (item.children && item.children.length !== 0)) {
      return <DeleteBtnNotAllowed deleteInvalid={OPTION_DELETE_INVALID_TEXT} />;
    }
    return (
      <DeleteBtn
        deletingModel={item}
        getDisplayName={(item) => item.name}
        deleteFn={() => ProductOptionService.deactivate(item.id)}
        onDeleted={() =>
          setState({
            ...state,
            isConfirming: false,
            items: state.items.filter((w: iProductOption) => w.id !== item.id),
          })
        }
      />
    );
  };

  const afterMove = async (
    origin: iProductOption,
    moveReloaded: iProductOption,
  ) => {
    try {
      let newItems = [...state.items];
      //  scenario: from's parent does not have any child after move
      if (origin.parentId) {
        const reloadFromParent: iProductOption = await ProductOptionService.get(
          origin.parentId,
          { include: 'ProductOptionType' },
        );
        newItems = newItems.map((w: iProductOption) =>
          w.id === reloadFromParent.id ? reloadFromParent : w,
        );
      }
      setState({
        ...state,
        items: newItems.map((w: iProductOption) =>
          w.id === moveReloaded.id ? moveReloaded : w,
        ),
      });
    } catch (error) {
      Toaster.showApiError(error);
    }
  };

  const getMoveBtn = (item: iProductOptionUI) => {
    return (
      <EntityEditPopupBtn<iProductOptionUI>
        getPopupTitle={() => `Move Option ${item.name}`}
        entityName={`Move Option ${item.name}`}
        entity={item}
        onSaved={async (saved) => await afterMove(item, saved)}
        updateFn={async (id, data) => {
          const moved = await ProductOptionService.move({
            fromOptionId: item.id,
            toOptionId: _.get(data, 'asRoot') ? null : _.get(data, 'moveTo'),
          });
          return await ProductOptionService.get(moved.id, {
            include: 'ProductOptionType',
          });
        }}
        btnTxt={OPTION_MOVE}
        getFormFields={({ entity, isDisabled }) => [
          {
            fieldName: 'warningText',
            label: 'Warning Text',
            isDisabled,
            value: '',
            renderComponent: () => {
              return (
                <SectionMessage allowClose={false} appearance="warning">
                  {MOVE_WARNING_MESSAGE}
                </SectionMessage>
              );
            },
          },
          {
            fieldName: 'asRoot',
            label: 'Move to the root',
            isDisabled,
            value: !!_.get(entity, 'asRoot'),
            testId: 'ProductOption-move-asRoot',
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <Toggle
                  {...props}
                  {...errorProps}
                  separatedLines
                  value={'asRoot'}
                  isChecked={_.get(entity, 'asRoot')}
                  onChange={(event) => {
                    useAsForm.onFieldChange('asRoot', event.target.checked);
                  }}
                />
              );
            },
          },
          {
            fieldName: 'moveTo',
            label: 'Move Under',
            isDisabled,
            value: _.get(entity, 'moveTo') || '',
            testId: 'ProductOption-moveTo',
            isWatching: true,
            renderComponent: (props, useAsForm, errorProps, entity) => {
              return (
                !_.get(entity, 'asRoot') && (
                  <ProductOptionSelector
                    {...props}
                    {...errorProps}
                    onChange={(
                      option: iOptionWithData<iProductOption> | null,
                    ) => {
                      useAsForm.onFieldChange('moveTo', option?.value || '');
                    }}
                  />
                )
              );
            },
          },
        ]}
      />
    );
  };

  if (state.isLoading) {
    return <SpinWhenLoading />;
  }

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            title={headerProps?.children}
            createBtn={getEditBtn()}
          />
        ),
      }}
    >
      <OptionsListWrapper className={className} data-testid={testId}>
        <TableTree>
          <Headers>
            <Header width="35%">Name</Header>
            <Header width="35%">Option Type</Header>
            <Header width="20%">Price Variance</Header>
            <Header width="10%" />
          </Headers>
          <Rows
            items={arrayToTree(
              state.items.sort((a: iProductOption, b: iProductOption) =>
                UtilsService.compareStr(a.createdAt, b.createdAt),
              ),
              { parentProperty: 'parentId' },
            )}
            render={(option: iProductOptionUI) => (
              <Row
                expandLabel="Expand"
                collapseLabel="Collapse"
                itemId={option.id}
                onExpand={expandItem}
                items={option.children}
                hasChildren={
                  option.hasChild ||
                  (option.children && option.children.length > 0)
                }
              >
                <Cell singleLine>{getEditBtn(option, true)}</Cell>
                <Cell singleLine>{option.ProductOptionType?.name}</Cell>
                <Cell singleLine>
                  {UtilsService.formatIntoCurrency(option.priceVariance)}
                </Cell>
                <Cell singleLine>
                  <MoreIconPopup testId={`${option.id}-popup-trigger-btn`}>
                    <PopUpContentContainer>
                      {getEditBtn(option)}
                      {getMoveBtn(option)}
                      {getDeleteBtn(option)}
                    </PopUpContentContainer>
                  </MoreIconPopup>
                </Cell>
              </Row>
            )}
          />
        </TableTree>
      </OptionsListWrapper>
    </ComponentWithPageHeader>
  );
};

export default ProductDetailsOptionsPanel;
