import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WorkflowEditStatusPanel from './WorkflowEditStatusPanel';
import WorkflowStartStatusPanel from './WorkflowStartStatusPanel';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import { iNode, useNodes } from '../../frameWork/ReactFlowRenderer';
import tokens from '../../frameWork/Tokens';
import { WFDiagramIds } from '../diagram/WorkflowDiagramHelper';
import { RightSidebar } from '../../frameWork/PageLayout';
import SideNavigation, {
  NavigationContent,
} from '../../frameWork/SideNavigation';
import EmptyState from '../../frameWork/EmptyState';

const Wrapper = styled.div`
  margin-left: 1rem;
  [aria-label='right-nav'] {
    background-color: rgb(250, 251, 252) !important;
    height: calc(
      100vh -
        (
          ${tokens('font.lineHeight.400', '140px')} +
            ${tokens('font.lineHeight.200', '140px')}
        )
    );
  }
  .right-nav-content {
    padding: 1rem;
  }

  [data-testid='entity-status-edit-panel'] {
    padding: 0px;
    > div {
      margin: 0px;
    }
  }
`;
type iWorkflowEditRightMenu = {
  state: iWFDiagramState;
};
const WorkflowEditRightMenu = ({ state }: iWorkflowEditRightMenu) => {
  const [selectedNode, setSelectedNode] = useState<iNode | null>(null);
  const nodes = useNodes();

  useEffect(() => {
    const selectedNodes = nodes.filter((node) => node.selected === true);
    setSelectedNode(selectedNodes.length <= 0 ? null : selectedNodes[0]);
  }, [nodes]);

  const getContent = () => {
    if (selectedNode === null) {
      return (
        <>
          <EmptyState
            header={'Select a status'}
            description={
              <small>
                Workflows represent your process and control how people progress
                their work.
                <br />
                Select a status to reveal more details.
              </small>
            }
            primaryAction={null}
          />
        </>
      );
    }

    if (selectedNode.id === WFDiagramIds.NODE_ID_START_NODE) {
      return (
        <WorkflowStartStatusPanel editingNode={selectedNode} state={state} />
      );
    }

    return <WorkflowEditStatusPanel editingNode={selectedNode} state={state} />;
  };

  return (
    <Wrapper className={'right-side-menu'}>
      <RightSidebar width={330}>
        <SideNavigation label={'right-nav'}>
          <NavigationContent>
            <div className={'right-nav-content'}>{getContent()}</div>
          </NavigationContent>
        </SideNavigation>
      </RightSidebar>
    </Wrapper>
  );
};

export default WorkflowEditRightMenu;
