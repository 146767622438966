import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import InlineEdit from '../frameWork/InlineEdit';
import Toaster from '../common/Toaster';
import Toggle from '../frameWork/Toggle';
import Flex from '../frameWork/Flex';
import { Label } from '../frameWork/Form';
import ModifyBy from '../common/ModifyBy';
import tokens from '../frameWork/Tokens';
import { iParams } from '../../services/AppService';
import { iConfigParams } from '../../services/AppService';
import WarehouseLocationService from '../../services/warehouseLocation/WarehouseLocationService';
import ItemsLocationList from './ItemsLocationList';

export type iWarehouseLocationDetails = iComponentWithPageHeader & {
  warehouseLocation: iWarehouseLocation;
  onSaved?: (saved: iWarehouseLocation) => void;
  testId?: string;
};

const defaultFacilityLocationIds = {
  lostAndFoundLocationId: undefined,
  defaultInwardsLocationId: undefined,
  defaultOutwardsLocationId: undefined,
};

type iState = {
  isLoading: boolean;
  idNameMap?: iParams;
};

const initialState: iState = {
  isLoading: false,
};

const Wrapper = styled.div`
  .details-col {
    max-width: 100%;
    width: 300px;
  }

  form > div {
    margin-block-start: 0;
  }

  label {
    margin-block-start: ${tokens('space.100', '8px')};
  }

  .comment-div {
    width: 620px;
    max-width: 100%;
  }

  .address-div {
    width: 820px;

    .address-col {
      max-width: 100%;
      width: 400px;
    }
  }

  .modified-div {
    width: 460px;
    max-width: 100%;

    .details-col {
      width: 300px;
    }
  }

  .activities {
    margin-top: ${tokens('space.1000', '5rem')};
    min-height: 10rem;
  }
`;

const WarehouseLocationDetails = ({
  warehouseLocation,
  onSaved,
  headerProps,
  testId,
}: iWarehouseLocationDetails) => {
  const { Facility } = warehouseLocation;
  const {
    lostAndFoundLocationId,
    defaultInwardsLocationId,
    defaultOutwardsLocationId,
  } = Facility || defaultFacilityLocationIds;
  const [, setState] = useState(initialState);
  const testIdStr = `warehouseLocationDetails-${testId || ''}`;
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdated = (newDataMap: iConfigParams) => {
    const filteredMap: iConfigParams = Object.keys(newDataMap)
      .filter(
        (key) =>
          newDataMap[key] !== undefined &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          newDataMap[key] !== warehouseLocation[key],
      )
      .reduce((map, key) => {
        return {
          ...map,
          [key]: newDataMap[key],
        };
      }, {});
    if (Object.keys(filteredMap).length <= 0) {
      return;
    }

    setIsSaving(true);
    WarehouseLocationService.update(warehouseLocation.id, filteredMap)
      .then((resp) => {
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    let isCancelled = false;
    const fetchLocationPathName = async () => {
      if (
        typeof lostAndFoundLocationId === 'undefined' ||
        typeof defaultInwardsLocationId === 'undefined' ||
        typeof defaultOutwardsLocationId === 'undefined'
      )
        return;
      try {
        const ids = [
          defaultInwardsLocationId,
          defaultOutwardsLocationId,
          lostAndFoundLocationId,
        ];
        const stringIds = JSON.stringify(ids);
        const { idNameMap } =
          await WarehouseLocationService.getWarehouseLocationPathNames(
            stringIds,
          );
        if (isCancelled) return;
        setState((prevState) => ({
          ...prevState,
          idNameMap,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchLocationPathName();
    return () => {
      isCancelled = true;
    };
  }, [
    lostAndFoundLocationId,
    defaultInwardsLocationId,
    defaultOutwardsLocationId,
  ]);

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      <Wrapper data-testid={testIdStr}>
        <Flex className={'gap-1 flex-wrap'}>
          {[
            {
              key: 'name',
              label: 'Name',
              isRequired: true,
              value: warehouseLocation.name,
              placeholder: `The name of this ${warehouseLocation.name}`,
            },
            {
              key: 'code',
              label: 'Code',
              value: `${warehouseLocation.locationCode || ''}`.trim(),
              placeholder: `The code of this ${warehouseLocation.locationCode}`,
            },
            {
              key: 'description',
              label: 'Description',
              value: `${warehouseLocation.description || ''}`.trim(),
              placeholder: `The description`,
            },
          ].map((item) => {
            return (
              <div key={item.key} className={'details-col'}>
                <InlineEdit
                  readViewFitContainerWidth
                  isDisabled={isSaving}
                  testId={item.key}
                  label={item.label}
                  isRequired={item.isRequired}
                  placeHolder={item.placeholder}
                  value={item.value}
                  onConfirm={(value) => handleUpdated({ [item.key]: value })}
                />
              </div>
            );
          })}
          <Flex className={'gap-1 modified-div'}>
            <div className={'details-col'}>
              <Label htmlFor={''}>Created</Label>
              <small>
                <ModifyBy
                  by={warehouseLocation.CreatedBy}
                  at={warehouseLocation.createdAt}
                />
              </small>
            </div>

            <div className={'details-col'}>
              <Label htmlFor={''}>Updated</Label>
              <small>
                <ModifyBy
                  by={warehouseLocation.UpdatedBy}
                  at={warehouseLocation.updatedAt}
                />
              </small>
            </div>
          </Flex>
        </Flex>

        <Flex className={'gap-1 flex-wrap'}>
          <div>
            <Toggle
              separatedLines
              testId={'Warehouse-allowParts'}
              label={`Allow product transfer in`}
              isChecked={warehouseLocation.allowParts === true}
              onChange={() =>
                handleUpdated({
                  allowParts:
                    warehouseLocation.allowParts === true ? false : true,
                })
              }
            />
          </div>
        </Flex>

        {/* <Tabs
          className={'activities'}
          tabs={[
            ...(warehouseLocation. === WarehouseType.PRODUCT
              ? [
                  {
                    eventKey: 'products',
                    title: 'Products',
                    tabContent: (
                      <ProductList
                        className={warehouseLocation.Product.id}
                        allowDelete={false}
                        allowCreate={false}
                        showHeader={false}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        /> */}

        <ItemsLocationList
          warehouseLocationId={warehouseLocation.id}
          title={`${warehouseLocation.productsCount || 0} product(s) stored here`}
        />
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default WarehouseLocationDetails;
