import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iBuildChangeItem from '../../types/build/iBuildChangeItem';
import BuildChangeItemService from '../../services/build/BuildChangeItemService';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import EntityStatusSelector from '../entityStatus/EntityStatusSelector';
import EntityNames from '../../helpers/EntityNames';
import Toaster from '../common/Toaster';
import { iOptionWithData } from '../frameWork/Select';
import iEntityStatus from '../../types/status/iEntityStatus';

export type iBuildChangeItemList = WrapperProps &
  iComponentWithPageHeader & {
    buildId: string;
    allowEdit?: boolean;
    allowDelete?: boolean;
  };

const BuildChangeItemList = ({
  className,
  testId,
  buildId,
  headerProps,
  allowEdit,
  allowDelete,
}: iBuildChangeItemList) => {
  const { testIdStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'BuildChangeItemList',
    testId,
    className,
  });
  const [isSaving, setIsSaving] = React.useState(false);

  const { renderDataTable, renderDeleteBtn, onRefresh } =
    useListCrudHook<iBuildChangeItem>({
      sort: `createdAt:DESC`,
      perPage: 999999999,
      getFn: (params) => {
        return BuildChangeItemService.getAll({
          where: JSON.stringify({
            isActive: true,
            buildId,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,Status',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        });
      },
    });

  const handleUpdate = (id: string, newValues: { [key: string]: string }) => {
    setIsSaving(true);
    BuildChangeItemService.update(id, newValues)
      .then(() => {
        onRefresh();
      })
      .catch((e) => {
        Toaster.showApiError(e);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getColumns = (): iTableColumn<iBuildChangeItem>[] => {
    return [
      {
        key: 'name',
        header: 'Description',
        isDefault: true,
        cell: ({ data }) => {
          return data.name;
        },
      },
      {
        key: 'oldValue',
        header: 'Old Value',
        isDefault: true,
        cell: ({ data }) => {
          return data.oldValue;
        },
      },
      {
        key: 'newValue',
        header: 'New Value',
        isDefault: true,
        cell: ({ data }) => {
          return data.newValue;
        },
      },
      {
        key: 'status',
        header: 'Status',
        isDefault: true,
        cell: ({ data }) => {
          return allowEdit === true ? (
            <EntityStatusSelector
              testId={`${data.id}-status-selector`}
              value={data.statusId}
              isDisabled={isSaving}
              onChange={(selected: iOptionWithData<iEntityStatus>) => {
                handleUpdate(data.id, {
                  statusId: `${selected.value}`,
                });
              }}
              entityStatusTypeName={EntityNames.BuildChangeItem}
            />
          ) : (
            data.Status?.name || ''
          );
        },
      },
      ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildChangeItem>(),
      ...(allowDelete !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              isDefault: true,
              cell: ({ data }: iCellParams<iBuildChangeItem>) => {
                return (
                  <div className={'text-right'}>
                    {renderDeleteBtn({
                      deletingModel: data,
                      deleteFnc: async () =>
                        BuildChangeItemService.deactivate(data.id),
                      getDisplayName: (item) => item.name,
                    })}
                  </div>
                );
              },
            },
          ]),
    ];
  };

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      {renderDataTable({
        selectiveColumnKey: SelectiveColKeys.BUILD_CHANGE_ITEM_LIST,
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildChangeItemList;
