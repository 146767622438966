import Icons from '../../../frameWork/Icons';
import Flex from '../../../frameWork/Flex';
import iBuild from '../../../../types/build/iBuild';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import WazeMap from '../../../waze/WazeMap';
import EntityNames from '../../../../helpers/EntityNames';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import BuildDetailsPermitPrePermitPanel from './Components/BuildDetailsPermitPrePermitPanel';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';
import BuildDetailsPermitDAPanel from './Components/BuildDetailsPermitDAPanel';
import EstatePlanNoteTabPanel from '../../../estate/EstatePlanNoteTabPanel';
import { Link } from 'react-router-dom';
import { URL_ESTATE_DETAILS } from '../../../../helpers/UrlMap';
import BuildDetailsPermitSiteInspectionPanel from './Components/BuildDetailsPermitSiteInspectionPanel';
import BuildSurveyItemList from '../../../buildSurvey/BuildSurveyItemList';

export enum BuildDetailsPermitKeys {
  PRE_PERMIT = 'Pre-Permit',
  PERMIT_INFO = 'Permit Info',
  MAP = 'Map',
  REPORT_AND_CONSENT = 'Report & Consent',
  DA = 'DA',
  DEVELOPER_GUIDELINES = 'Developer Guidelines',
  BUILDING_SURVEYOR = 'Building Surveyor',
  PRE_PERMIT_CHECKLIST = 'Pre-Permit Checklist',
  SITE_INSPECTIONS = 'Site Inspections',
  SITE_INSPECTION_PHOTOS = 'Site Inspection Photos',
  CLIENT_PHOTOS = 'Client Photos',
}

const getKey = (
  key: string,
  build: iBuild,
  attributeSection: AttributeForSections,
) => `${attributeSection}-${build.id}-${key}`;

type iGetBuildDetailsPermitMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
  attributeSection: AttributeForSections;
};

const getBuildDetailsPermitMenuItemsMap = ({
  build,
  attributeSection,
  isDisabled,
  onBuildUpdated,
}: iGetBuildDetailsPermitMenuItemsMap): iMenuItemsMap => {
  const commonProps = {
    attributeSection,
    isDisabled,
    entityId: build.id,
    entityName: EntityNames.Build,
  };
  return {
    [BuildDetailsPermitKeys.PRE_PERMIT]: {
      name: BuildDetailsPermitKeys.PRE_PERMIT,
      icon: <Icons.OverviewIcon label={BuildDetailsPermitKeys.PRE_PERMIT} />,
      component: (
        <BuildDetailsPermitPrePermitPanel
          isDisabled={isDisabled}
          attributeSection={attributeSection}
          build={build}
          onBuildUpdated={onBuildUpdated}
          key={getKey(
            BuildDetailsPermitKeys.PRE_PERMIT,
            build,
            attributeSection,
          )}
        />
      ),
    },
    [BuildDetailsPermitKeys.PERMIT_INFO]: {
      name: BuildDetailsPermitKeys.PERMIT_INFO,
      icon: (
        <Icons.InformationIcon label={BuildDetailsPermitKeys.PERMIT_INFO} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.CouncilPermit,
            AttributeSetCodes.PropertyInfoPermit,
            AttributeSetCodes.LPDPermit,
            AttributeSetCodes.SewerPermit,
            AttributeSetCodes.InfrastructureLevyPermit,
            AttributeSetCodes.PropertyTitle,
            AttributeSetCodes.PlanningPermit,
            AttributeSetCodes.EnergyRatingPermit,
            AttributeSetCodes.WaterConsent,
            AttributeSetCodes.Insurance,
            AttributeSetCodes.WaterAuthority,
            AttributeSetCodes.GrinderPump,
            AttributeSetCodes.WaterMeter,
            AttributeSetCodes.SewerTie,
            AttributeSetCodes.BuildingPermit,
            AttributeSetCodes.Site,
          ]}
          key={getKey(
            BuildDetailsPermitKeys.PERMIT_INFO,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsPermitKeys.MAP]: {
      name: BuildDetailsPermitKeys.MAP,
      icon: <Icons.LocationIcon label={BuildDetailsPermitKeys.MAP} />,
      component: (
        <div key={getKey(BuildDetailsPermitKeys.MAP, build, attributeSection)}>
          {build.Address && <WazeMap address={build.Address} />}
        </div>
      ),
    },
    [BuildDetailsPermitKeys.REPORT_AND_CONSENT]: {
      name: BuildDetailsPermitKeys.REPORT_AND_CONSENT,
      icon: (
        <Icons.AlertIcon label={BuildDetailsPermitKeys.REPORT_AND_CONSENT} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.BuildingConsent]}
          key={getKey(
            BuildDetailsPermitKeys.REPORT_AND_CONSENT,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsPermitKeys.DA]: {
      name: BuildDetailsPermitKeys.DA,
      icon: <Icons.BulletListIcon label={BuildDetailsPermitKeys.DA} />,
      component: (
        <BuildDetailsPermitDAPanel
          isDisabled={isDisabled}
          attributeSection={attributeSection}
          build={build}
          onBuildUpdated={onBuildUpdated}
          key={getKey(BuildDetailsPermitKeys.DA, build, attributeSection)}
        />
      ),
    },
    [BuildDetailsPermitKeys.DEVELOPER_GUIDELINES]: {
      name: BuildDetailsPermitKeys.DEVELOPER_GUIDELINES,
      icon: (
        <Icons.ScorecardIcon
          label={BuildDetailsPermitKeys.DEVELOPER_GUIDELINES}
        />
      ),
      topNav:
        `${build.estateId || ''}`.trim() === '' ? null : (
          <Flex className="gap-05 align-items-center">
            <div>Estate:</div>
            <Link
              to={URL_ESTATE_DETAILS.replace(
                ':id',
                `${build.estateId || ''}`.trim(),
              )}
            >
              {build?.Estate?.name || ''}
            </Link>
          </Flex>
        ),
      component: (
        <EstatePlanNoteTabPanel
          estateId={build.estateId || ''}
          isDisabled={true}
          key={getKey(
            BuildDetailsPermitKeys.DEVELOPER_GUIDELINES,
            build,
            attributeSection,
          )}
        />
      ),
    },
    [BuildDetailsPermitKeys.BUILDING_SURVEYOR]: {
      name: BuildDetailsPermitKeys.BUILDING_SURVEYOR,
      icon: (
        <Icons.ScalesIcon label={BuildDetailsPermitKeys.BUILDING_SURVEYOR} />
      ),
      component: (
        <BuildSurveyItemList
          headerProps={{
            children: <h6>Building Surveyor Items</h6>,
          }}
          build={build}
          allowEdit={!isDisabled}
          allowCreate={!isDisabled}
          allowDelete={!isDisabled}
          key={getKey(
            BuildDetailsPermitKeys.BUILDING_SURVEYOR,
            build,
            attributeSection,
          )}
        />
      ),
    },
    [BuildDetailsPermitKeys.PRE_PERMIT_CHECKLIST]: {
      name: BuildDetailsPermitKeys.PRE_PERMIT_CHECKLIST,
      icon: (
        <Icons.ListChecklistIcon
          label={BuildDetailsPermitKeys.PRE_PERMIT_CHECKLIST}
        />
      ),
      component: (
        <div
          key={getKey(
            BuildDetailsPermitKeys.PRE_PERMIT_CHECKLIST,
            build,
            attributeSection,
          )}
          {...commonProps}
        >
          {BuildDetailsPermitKeys.PRE_PERMIT_CHECKLIST}
        </div>
      ),
    },
    [BuildDetailsPermitKeys.SITE_INSPECTIONS]: {
      name: BuildDetailsPermitKeys.SITE_INSPECTIONS,
      icon: (
        <Icons.SearchIcon label={BuildDetailsPermitKeys.SITE_INSPECTIONS} />
      ),
      component: (
        <BuildDetailsPermitSiteInspectionPanel
          build={build}
          attributeSection={AttributeForSections.PERMIT}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
          key={getKey(
            BuildDetailsPermitKeys.SITE_INSPECTIONS,
            build,
            attributeSection,
          )}
        />
      ),
    },
    [BuildDetailsPermitKeys.SITE_INSPECTION_PHOTOS]: {
      name: BuildDetailsPermitKeys.SITE_INSPECTION_PHOTOS,
      icon: (
        <Icons.FolderOpenIcon
          label={BuildDetailsPermitKeys.SITE_INSPECTION_PHOTOS}
        />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.SiteInspectionPhotos]}
          key={getKey(
            BuildDetailsPermitKeys.SITE_INSPECTION_PHOTOS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsPermitKeys.CLIENT_PHOTOS]: {
      name: BuildDetailsPermitKeys.CLIENT_PHOTOS,
      icon: (
        <Icons.FolderOpenIcon label={BuildDetailsPermitKeys.CLIENT_PHOTOS} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.ClientPhotos]}
          key={getKey(
            BuildDetailsPermitKeys.SITE_INSPECTION_PHOTOS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
  };
};

const BuildDetailsPermitHelper = {
  getBuildDetailsPermitMenuItemsMap,
};
export default BuildDetailsPermitHelper;
