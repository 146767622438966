import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import TextField from '../frameWork/TextField';
import Toaster from '../common/Toaster';
import iProductPricePrice from '../../types/product/iProductPrice';
import ProductPricePriceService from '../../services/product/ProductPriceService';
import { iOption } from '../frameWork/Select';
import Flex from '../frameWork/Flex';
import { DatePicker } from '../frameWork/DateTimePicker';
import { getErrorProps, iErrorMap } from '../form/FormError';
import moment from 'moment';
import StringHelper from '../../helpers/StringHelper';
import ProductSelector from './ProductSelector';

export type iProductPriceEditPopupBtn = Omit<
  iPopupBtn,
  'titleId' | 'modalProps'
> & {
  isDisabled?: boolean;
  productId?: string;
  productPrice?: iProductPricePrice;
  onSaved?: (saved: iProductPricePrice, isCreated: boolean) => void;
};
const ProductPriceEditPopupBtn = ({
  productPrice,
  productId,
  isDisabled = false,
  onSaved,
  ...props
}: iProductPriceEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const productPriceId = `${productPrice?.id || ''}`.trim();

  const handleClose = (setShowingModal: iSetShowingModalFn) => {
    setEditingData({});
    setErrorMap({});
    setIsSaving(false);
    setShowingModal(false);
  };

  const preCheck = () => {
    const errors: iErrorMap = {};

    const checkingData = {
      productId,
      ...productPrice,
      ...editingData,
    };
    const selectedProductId = `${checkingData.productId || ''}`.trim();
    if (selectedProductId === '') {
      errors.productId = ['ProductId is required.'];
    }
    const priceString = `${checkingData.price || ''}`.trim();
    if (priceString === '') {
      errors.price = ['Price is required.'];
    } else if (!StringHelper.isNumeric(priceString)) {
      errors.price = ['Price needs to be a number.'];
    }

    const startDateString = `${checkingData.startDate || ''}`.trim();
    if (startDateString === '') {
      errors.startDate = ['Start Date is required.'];
    }

    const endDateString = `${checkingData.endDate || ''}`.trim();
    if (
      endDateString !== '' &&
      moment(endDateString).isBefore(moment(startDateString))
    ) {
      errors.endDate = ['End Date needs to be same of later than Start Date.'];
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    if (!preCheck()) {
      return;
    }
    setIsSaving(true);
    const submittingData = {
      ...editingData,
      ...(`${productId || ''}`.trim() === '' ? {} : { productId }),
    };

    const saveFnc = () =>
      productPriceId === ''
        ? ProductPricePriceService.create(submittingData)
        : ProductPricePriceService.update(productPriceId, submittingData);

    saveFnc()
      .then((resp) => {
        handleClose(setShowingModal);
        onSaved && onSaved(resp, productPriceId === '');
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <PopupBtn
      titleId={'ProductPricePrice-create-popup'}
      testId={'ProductPricePrice-create-popup-btn'}
      shouldFitContainer
      {...props}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title:
          productPriceId === '' ? `Creating a new price` : 'Updating a price',
        onClose: () => handleClose(setShowingModal),
        body: (
          <>
            <ProductSelector
              label={'Product'}
              testId={'product-selector'}
              isRequired
              {...getErrorProps({ error: errorMap, fieldName: 'productId' })}
              value={
                editingData.productId || productPrice?.productId || productId
              }
              isDisabled={
                `${productId || ''}`.trim() !== '' || isSaving || isDisabled
              }
              onChange={(selected: iOption) => {
                setEditingData({
                  ...editingData,
                  productId: `${selected.value || ''}`,
                });
              }}
            />

            <TextField
              isRequired
              testId={'price'}
              label={`Price`}
              placeholder={`The price. like 12304.3`}
              isDisabled={isSaving || isDisabled}
              value={editingData.price || productPrice?.price || ''}
              {...getErrorProps({ error: errorMap, fieldName: 'price' })}
              elemBeforeInput={<div style={{ padding: '0 0 0 5px' }}>$</div>}
              onChange={(event) =>
                setEditingData({
                  ...editingData,
                  price:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                })
              }
            />

            <Flex className={'align-items-start gap-1'}>
              <DatePicker
                label={'Start Date'}
                testId={'startDate'}
                isRequired
                value={editingData.startDate || productPrice?.startDate || ''}
                {...getErrorProps({ error: errorMap, fieldName: 'startDate' })}
                onChange={(selected) => {
                  setEditingData({
                    ...editingData,
                    startDate:
                      `${selected || ''}`.trim() !== ''
                        ? moment(selected).startOf('day').toISOString()
                        : '',
                  });
                }}
              />

              <DatePicker
                label={'End Date'}
                testId={'endDate'}
                value={editingData.endDate || productPrice?.endDate || ''}
                {...getErrorProps({ error: errorMap, fieldName: 'endDate' })}
                onChange={(selected) => {
                  setEditingData({
                    ...editingData,
                    endDate:
                      `${selected || ''}`.trim() !== ''
                        ? moment(selected).endOf('day').toISOString()
                        : '',
                  });
                }}
              />
            </Flex>

            <TextField
              testId={'comments'}
              label={`Comments`}
              placeholder={`Some comments`}
              isDisabled={isSaving || isDisabled}
              value={editingData.comments || productPrice?.comments || ''}
              onChange={(event) =>
                setEditingData({
                  ...editingData,
                  comments:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                })
              }
            />
          </>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: productPriceId === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default ProductPriceEditPopupBtn;
