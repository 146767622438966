import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import iEntityStatus from '../../types/status/iEntityStatus';
import React, { useState } from 'react';
import TextField from '../frameWork/TextField';
import { getErrorProps, iErrorMap } from '../form/FormError';
import EntityStatusCategorySelector from './EntityStatusCategorySelector';
import { iOptionWithData } from '../frameWork/Select';
import Flex from '../frameWork/Flex';
import iEntityStatusCategory from '../../types/status/iEntityStatusCategory';
import styled from 'styled-components';
import { getFooterWithBtns } from '../common/PopupModal';
import tokens from '../frameWork/Tokens';
import Icons from '../frameWork/Icons';
import UtilsService from '../../services/UtilsService';

const Wrapper = styled.div`
  padding-bottom: ${tokens('space.200')};
  .btns {
    margin-top: ${tokens('space.200')};
  }
  .inputs-wrapper {
    > div {
      min-width: 270px;
    }
  }
`;

type iEntityStatusEditPanel = WrapperProps & {
  entityStatus?: iEntityStatus;
  isDisabled?: boolean;
  onCancel?: () => void;
  onSave?: (saving: iEntityStatus) => void;
  isInlineSubmit?: boolean;
};
const EntityStatusEditPanel = ({
  entityStatus,
  testId,
  isDisabled,
  className,
  onCancel,
  onSave,
  isInlineSubmit = false,
}: iEntityStatusEditPanel) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'EntityStatusEditPanel',
    testId,
    className,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [editData, setEditData] = useState<any>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const preCheck = (extra = {}) => {
    const errors: iErrorMap = {};
    const submittingData = { ...entityStatus, ...editData, ...extra };
    if (`${submittingData.name || ''}`.trim() === '') {
      errors.name = 'Name is required';
    }
    if (`${submittingData.code || ''}`.trim() === '') {
      errors.code = 'Code is required';
    }
    if (`${submittingData.entityStatusCategoryId || ''}`.trim() === '') {
      errors.entityStatusCategoryId = 'Category is required';
    }
    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = (extra = {}) => {
    if (!preCheck(extra)) {
      return;
    }
    const submittingData = { ...entityStatus, ...editData, ...extra };
    setEditData({});
    onSave && onSave(submittingData as iEntityStatus);
  };

  const handleOnCancel = () => {
    setEditData({});
    onCancel && onCancel();
  };

  return (
    <Wrapper data-testid={testIdStr} className={classNameStr}>
      <small>Statuses capture the stages of your working process.</small>
      <Flex className="flex-wrap gap-05 inputs-wrapper">
        {[
          {
            name: 'Name',
            fieldName: 'name',
            isRequired: true,
            value:
              editData.name === undefined
                ? entityStatus?.name || ''
                : editData.name,
          },
          {
            name: 'Code',
            fieldName: 'code',
            isRequired: true,
            value:
              editData.code === undefined
                ? entityStatus?.code || ''
                : editData.code,
          },
          {
            name: 'Description',
            fieldName: 'description',
            isRequired: false,
            value:
              editData.description === undefined
                ? entityStatus?.description || ''
                : editData.description,
          },
        ].map((item) => (
          <TextField
            key={item.fieldName}
            label={item.name}
            testId={item.fieldName}
            isDisabled={isDisabled}
            value={item.value}
            isRequired={item.isRequired}
            {...getErrorProps({
              error: errorMap,
              fieldName: item.fieldName,
            })}
            onChange={(event) => {
              setEditData({
                ...editData,
                [item.fieldName]:
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  event.target.value || '',
              });
            }}
            onKeyDown={(event) => {
              UtilsService.handleEnterKeyPressed(event, () => {
                if (isInlineSubmit && entityStatus) {
                  handleSave();
                }
              });
            }}
            onBlur={() => {
              if (isInlineSubmit && entityStatus) {
                handleSave();
              }
            }}
          />
        ))}
        <EntityStatusCategorySelector
          label={'Category'}
          isDisabled={isDisabled}
          isRequired
          value={
            editData.entityStatusCategoryId ||
            entityStatus?.entityStatusCategoryId
          }
          {...getErrorProps({
            error: errorMap,
            fieldName: 'entityStatusCategoryId',
          })}
          onChange={(selected: iOptionWithData<iEntityStatusCategory>) => {
            const newData = {
              entityStatusCategoryId: `${selected.value}`,
              Category: selected.data,
            };
            if (isInlineSubmit && entityStatus) {
              handleSave(newData);
              return;
            }
            setEditData(newData);
          }}
        />
      </Flex>
      {isInlineSubmit === true ? null : (
        <Flex className="justify-content-end gap-05 btns">
          {getFooterWithBtns({
            actionBtnProps: {
              btnText: 'Save',
              isDisabled,
              iconBefore: Icons.CheckIcon,
              onClick: () => handleSave(),
            },
            ...(onCancel
              ? {
                  cancelBtnProps: {
                    isDisabled,
                    onClick: () => handleOnCancel(),
                    btnText: 'Cancel',
                  },
                }
              : {
                  cancelBtn: <></>,
                }),
          })}
        </Flex>
      )}
    </Wrapper>
  );
};

export default EntityStatusEditPanel;
