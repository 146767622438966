import SettingsPage from '../../layouts/SettingsPage';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import MathHelper from '../../helpers/MathHelper';
import { URL_SETTINGS_DEVELOPER_GUIDE_CATEGORY } from '../../helpers/UrlMap';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import Flex from '../../components/frameWork/Flex';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import DeveloperGuideCategoryService from '../../services/developerGuideCategory/DeveloperGuideCategoryService';
import DeveloperGuideCategoryDetailsPanel from '../../components/developerGuideCategory/DeveloperGuideCategoryDetailsPanel';

const DeveloperGuideCategoryDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [forceReload, setForceReload] = useState(0);

  return (
    <ComponentWithPreloadData<iDeveloperGuideCategory>
      forceReload={forceReload}
      emptyStateProps={{
        description: `Couldn't find requested Template with id: ${idStr}.`,
      }}
      getDataFn={() =>
        DeveloperGuideCategoryService.get(idStr, {
          include: 'CreatedBy,UpdatedBy',
        })
      }
    >
      {({ data }) => (
        <SettingsPage
          title={data.name || ''}
          headerProps={{
            className: 'no-bottom-margin',
          }}
          showTitle={false}
          extraBreadcrumbs={[
            {
              text: 'Developer Guide Categories',
              href: URL_SETTINGS_DEVELOPER_GUIDE_CATEGORY,
            },
          ]}
        >
          <DeveloperGuideCategoryDetailsPanel
            allowDelete
            allowEdit
            allowCreate
            onSaved={() => setForceReload(MathHelper.add(forceReload, 1))}
            category={data}
            headerProps={{
              children: (
                <Flex className={'align-items-end gap-1'}>
                  <div>{data.name || ''}</div>
                </Flex>
              ),
              className: 'no-top-margin',
            }}
          />
        </SettingsPage>
      )}
    </ComponentWithPreloadData>
  );
};

export default DeveloperGuideCategoryDetailsPage;
