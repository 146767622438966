import React, { useEffect, useReducer, useState } from 'react';
import {
  Action,
  ActionKind,
  getInitDataState,
  iDataState,
  iViewingState,
  useEntityStatusTabsHookReducer,
} from './useEntityStatusTabsHookReducer';
import Toaster from '../../common/Toaster';
import EntityNames from '../../../helpers/EntityNames';
import EntityStatusTypeService from '../../../services/status/EntityStatusTypeService';
import EntityStatusService from '../../../services/status/EntityStatusService';
import { EntityStatusCategoryCodes } from '../../../types/status/iEntityStatusCategory';
import MathHelper from '../../../helpers/MathHelper';
import Spinner from '../../frameWork/Spinner';
import Button, { ButtonGroup } from '../../frameWork/Button';
import iEntityStatus from '../../../types/status/iEntityStatus';

type iRenderStatusTabs = {
  onSelectedStatusesChange?: (selectedStatuses: iEntityStatus[]) => void;
};

const PAGE_SIZE = 99999999;
type iUseEntityStatusTabsHook = {
  forceReload?: number;
  entityStatusTypeName: EntityNames;
  showAllBtn?: boolean;
};
const useEntityStatusTabsHook = ({
  forceReload = 0,
  entityStatusTypeName,
  showAllBtn = true,
}: iUseEntityStatusTabsHook) => {
  const [state, dispatch] = useReducer<React.Reducer<iDataState, Action>>(
    useEntityStatusTabsHookReducer,
    getInitDataState(),
  );
  const [viewingState, setViewingState] = useState<iViewingState>({
    version: 1,
  });

  useEffect(() => {
    const getData = async () => {
      const types =
        (
          await EntityStatusTypeService.getAll({
            where: JSON.stringify({
              isActive: true,
              entityName: entityStatusTypeName,
            }),
          })
        ).data || [];
      const type = types.length > 0 ? types[0] : null;
      if (!type) {
        return;
      }
      const entityStatuses =
        (
          await EntityStatusService.getAll({
            where: JSON.stringify({
              isActive: true,
              entityStatusTypeId: type.id,
            }),
            sort: 'sortOrder:ASC',
            include: 'Category',
            perPage: PAGE_SIZE,
          })
        ).data || [];
      return {
        newStatuses: entityStatuses.filter(
          (s) => s.Category?.code === EntityStatusCategoryCodes.NEW,
        ),
        wipStatuses: entityStatuses.filter(
          (s) => s.Category?.code === EntityStatusCategoryCodes.IN_PROGRESS,
        ),
        finishedStatuses: entityStatuses.filter(
          (s) => s.Category?.code === EntityStatusCategoryCodes.FINISHED,
        ),
        statuses: entityStatuses,
      };
    };

    let isCanceled = false;
    dispatch({ type: ActionKind.Loading, payload: {} });

    getData()
      .then((res) => {
        if (isCanceled) {
          return;
        }
        setViewingState((prevState) => ({
          ...prevState,
          selectedStatuses: [
            ...(res?.newStatuses || []),
            ...(res?.wipStatuses || []),
          ],
        }));
        dispatch({ type: ActionKind.Loaded, payload: { data: res } });
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
        dispatch({ type: ActionKind.Loaded, payload: {} });
      });

    return () => {
      isCanceled = true;
    };
  }, [entityStatusTypeName, forceReload, viewingState.version]);

  const onRefresh = () => {
    setViewingState((prevState) => ({
      ...prevState,
      currentPage: 1,
      version: MathHelper.add(prevState.version, 1),
    }));
  };

  const getAllTab = (props: iRenderStatusTabs = {}) => {
    if (showAllBtn !== true) {
      return null;
    }
    const selectedStatusIds = (viewingState.selectedStatuses || []).map(
      (s) => s.id,
    );
    // const allStatuses = state.data.statuses || [];
    const isAllSelected = selectedStatusIds.length <= 0;
    return (
      <Button
        spacing={'compact'}
        appearance={isAllSelected ? 'primary' : 'default'}
        onClick={() => {
          setViewingState({
            ...viewingState,
            selectedStatuses: [],
          });
          props.onSelectedStatusesChange && props.onSelectedStatusesChange([]);
        }}
      >
        All
      </Button>
    );
  };

  const renderStatusTabs = (props: iRenderStatusTabs = {}) => {
    if (state.isLoading) {
      return <Spinner />;
    }

    const selectedStatusIds = (viewingState.selectedStatuses || []).map(
      (s) => s.id,
    );
    return (
      <ButtonGroup>
        {getAllTab(props)}
        {[
          ...state.data.newStatuses,
          ...state.data.wipStatuses,
          ...state.data.finishedStatuses,
        ].map((status) => {
          const isStatusSelected = selectedStatusIds.indexOf(status.id) >= 0;
          return (
            <Button
              key={status.id}
              spacing={'compact'}
              appearance={isStatusSelected ? 'primary' : 'default'}
              onClick={() => {
                const newSelectedStatuses = isStatusSelected
                  ? (viewingState.selectedStatuses || []).filter(
                      (stat) => stat.id !== status.id,
                    )
                  : [...(viewingState.selectedStatuses || []), status];
                setViewingState({
                  ...viewingState,
                  selectedStatuses: newSelectedStatuses,
                });
                props.onSelectedStatusesChange &&
                  props.onSelectedStatusesChange(newSelectedStatuses);
              }}
            >
              {status.name || ''}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  };

  return {
    state,
    viewingState,
    onRefresh,
    renderStatusTabs,
  };
};

export default useEntityStatusTabsHook;
