import ProtectedPage from '../../layouts/ProtectedPage';
import EstatePlanNoteCategoryList from '../../components/estate/EstatePlanNoteCategoryList';

const EstatePlanNoteCategoriesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Estate Plan Note Categories`,
      }}
    >
      {(headerProps) => (
        <EstatePlanNoteCategoryList
          allowDelete
          allowEdit
          allowCreate
          isRankable
          headerProps={headerProps}
        />
      )}
    </ProtectedPage>
  );
};

export default EstatePlanNoteCategoriesPage;
