import ServiceHelper from '../helper/ServiceHelper';
import iEstate from '../../types/estate/iEstate';

const endPoint = '/estate';

const EstateService = {
  getAll: ServiceHelper.getPaginatedAll<iEstate>(endPoint),
  get: ServiceHelper.getById<iEstate>(endPoint),
  create: ServiceHelper.create<iEstate>(endPoint),
  update: ServiceHelper.updateById<iEstate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iEstate>(endPoint),
};

export default EstateService;
