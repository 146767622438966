import { useEffect, useReducer } from 'react';
import EntityNames from '../../../helpers/EntityNames';
import WorkflowDiagramReducer, {
  WKDiagramActionKind,
  WKDiagramInitialState,
} from './WorkflowDiagramReducer';
import WorkflowService from '../../../services/system/WorkflowService';
import EntityStatusTypeService from '../../../services/status/EntityStatusTypeService';
import EntityStatusService from '../../../services/status/EntityStatusService';
import WorkflowDiagramHelper from './WorkflowDiagramHelper';
import EntityStatusCategoryService from '../../../services/status/EntityStatusCategoryService';
import Toaster from '../../common/Toaster';
import iWorkflow from '../../../types/system/iWorkflow';
import { iEdge, iNode } from '../../frameWork/ReactFlowRenderer';

// eslint-disable-next-line @typescript-eslint/ban-types
const useWorkflowDiagramHook = (
  entityStatusTypeId: string,
  setNodes: (nodes?: iNode[]) => void,
  setEdges: (edges?: iEdge[]) => void,
) => {
  const [state, dispatch] = useReducer(
    WorkflowDiagramReducer,
    WKDiagramInitialState,
  );

  useEffect(() => {
    let isCancelled = false;
    dispatch({ type: WKDiagramActionKind.Loading });
    Promise.all([
      EntityStatusTypeService.get(entityStatusTypeId),
      WorkflowService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityId: entityStatusTypeId,
          entityName: EntityNames.EntityStatusType,
        }),
        perPage: 1,
      }),
      EntityStatusService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityStatusTypeId: entityStatusTypeId,
        }),
        include: 'Category',
        perPage: 999999,
      }),
      EntityStatusCategoryService.getAll({
        where: JSON.stringify({
          isActive: true,
        }),
        perPage: 999999,
      }),
    ])
      .then((res) => {
        if (isCancelled) {
          return;
        }
        const workflow = res[1].data[0] || null;
        const entityStatuses = res[2].data || [];
        const { nodes, edges } = WorkflowDiagramHelper.initDiagram(
          entityStatuses,
          workflow,
        );
        setNodes(nodes);
        setEdges(edges);
        dispatch({
          type: WKDiagramActionKind.Update,
          payload: {
            errors: [],
            initials: { nodes: nodes || [], edges: edges || [] },
            setNodes,
            setEdges,
            entityStatusTypeId,
            isLoading: false,
            workflow,
            entityStatusType: res[0],
            entityStatusCategories: res[3].data || [],
            entityStatuses,
          },
        });
      })
      .catch((error) => {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(error);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        dispatch({ type: WKDiagramActionKind.Loaded });
      });
    return () => {
      isCancelled = true;
    };
  }, [entityStatusTypeId, setNodes, setEdges]);

  const setWorkflow = (newWorkFlow: iWorkflow) => {
    dispatch({
      type: WKDiagramActionKind.Update,
      payload: {
        ...state,
        workflow: newWorkFlow,
      },
    });
  };

  const setErrors = (errors: string[]) => {
    dispatch({
      type: WKDiagramActionKind.Update,
      payload: {
        ...state,
        errors,
      },
    });
  };

  return {
    state,
    setErrors,
    setWorkflow,
  };
};

export default useWorkflowDiagramHook;
