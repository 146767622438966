import ProtectedPage from '../../layouts/ProtectedPage';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import EstateService from '../../services/estate/EstateService';
import iEstate from '../../types/estate/iEstate';
import { URL_ESTATE } from '../../helpers/UrlMap';
import MathHelper from '../../helpers/MathHelper';
import EstateDetailPanel from '../../components/estate/EstateDetailPanel';

const EstateDetailPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [forceReload, setForceReload] = useState(0);
  const navigate = useNavigate();

  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iEstate>
        forceReload={forceReload}
        emptyStateProps={{
          description: `Couldn't find requested Estate with id: ${idStr}.`,
        }}
        getDataFn={() =>
          EstateService.get(idStr, {
            include: 'CreatedBy,UpdatedBy',
          })
        }
      >
        {({ data: estate }) => (
          <EstateDetailPanel
            headerProps={{
              children: estate?.name || '',
              breadcrumbItems: [
                {
                  text: 'Estates',
                  href: URL_ESTATE,
                },
                {
                  text: estate?.name || '',
                },
              ],
            }}
            estate={estate}
            onEstateUpdated={() =>
              setForceReload(MathHelper.add(forceReload, 1))
            }
            onEstateDeleted={() => navigate(URL_ESTATE)}
            allowCreate
            allowDelete
            allowEdit
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};
export default EstateDetailPage;
