import { getErrorProps } from '../form/FormError';
import styled from 'styled-components';
import { iUseAsForm } from '../form/EntityEditPanel';
import Flex from '../frameWork/Flex';
import Tokens from '../frameWork/Tokens';
import AttributeSelector from '../attribute/AttributeSelector';
import DeveloperGuideCategorySelector from '../developerGuideCategory/DeveloperGuideCaergorySelector';
import { iOptionWithData } from '../frameWork/Select';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import iAttributes from '../../types/attribute/iAttribute';
import iEstate from '../../types/estate/iEstate';
import EstateSelector from '../estate/EstateSelector';
import iDeveloperGuide from '../../types/developerGuide/iDeveloperGuide';
import DeveloperGuideRuleEditPanel from './DeveloperGuideRuleEditPanel';

const Wrapper = styled.div`
  .general-row > * {
    width: 40%;
    margin-bottom: ${Tokens('space.200', '1rem')};
  }
`;

export type iDeveloperGuideEditPanel = {
  estate?: iEstate;
  developerGuide?: iDeveloperGuide;
  developerGuideCategory?: iDeveloperGuideCategory;
  isDisabled?: boolean;
  useAsForm: iUseAsForm;
};

const DeveloperGuideEditPanel = ({
  estate,
  isDisabled = false,
  developerGuide,
  developerGuideCategory,
  useAsForm,
}: iDeveloperGuideEditPanel) => {
  return (
    <Wrapper>
      <Flex
        className={
          'general-row justify-content-start align-items-flex-start gap-1'
        }
      >
        <EstateSelector
          isRequired={true}
          label={'Estate'}
          {...getErrorProps({
            fieldName: 'estateId',
            error: useAsForm.errorMap,
          })}
          onChange={(est: iOptionWithData<iEstate>) => {
            useAsForm.onFieldChange('estateId', est?.value || '');
          }}
          testId={'estate-selector'}
          value={estate?.id}
          isDisabled={
            isDisabled === true || `${estate?.id || ''}`.trim() !== ''
          }
        />
        <DeveloperGuideCategorySelector
          isRequired={true}
          label={'Category'}
          {...getErrorProps({
            fieldName: 'categoryId',
            error: useAsForm.errorMap,
          })}
          testId={'category-selector'}
          value={developerGuide?.categoryId || developerGuideCategory?.id}
          isDisabled={
            isDisabled === true ||
            `${developerGuideCategory?.id || ''}`.trim() !== ''
          }
          onChange={(
            option: iOptionWithData<iDeveloperGuideCategory> | null,
          ) => {
            useAsForm.onFieldChange('categoryId', option?.value || '');
          }}
        />
        <AttributeSelector
          label={'Attribute'}
          {...getErrorProps({
            fieldName: 'attributeId',
            error: useAsForm.errorMap,
          })}
          value={developerGuide?.attributeId}
          testId={'attribute-selector'}
          isDisabled={isDisabled}
          onChange={(option: iOptionWithData<iAttributes> | null) => {
            useAsForm.onFieldChange('attributeId', option?.value || '');
          }}
        />
      </Flex>
      <DeveloperGuideRuleEditPanel
        attributeId={developerGuide?.attributeId}
        developerGuide={developerGuide}
        useAsForm={useAsForm}
      />
    </Wrapper>
  );
};
export default DeveloperGuideEditPanel;
