import { iConfigParams } from '../../../services/AppService';
import iEntityStatus from '../../../types/status/iEntityStatus';

type iEntityStatusData = {
  newStatuses: iEntityStatus[];
  wipStatuses: iEntityStatus[];
  finishedStatuses: iEntityStatus[];
  statuses: iEntityStatus[];
};

export interface iDataState {
  data: iEntityStatusData;
  isLoading: boolean;
}

export const getInitDataState = (): iDataState => {
  return {
    data: {
      newStatuses: [],
      wipStatuses: [],
      finishedStatuses: [],
      statuses: [],
    },
    isLoading: false,
  };
};

export interface iViewingState {
  sort?: string;
  filter?: iConfigParams;
  version: number;
  selectedStatuses?: iEntityStatus[];
}

export enum ActionKind {
  Loading = 'LOADING',
  Loaded = 'LOADED',
}

export type Action = {
  type: ActionKind;
  payload: {
    data?: iEntityStatusData;
    filter?: iConfigParams;
  };
};

export const useEntityStatusTabsHookReducer = (
  state: iDataState,
  action: Action,
): iDataState => {
  switch (action.type) {
    case ActionKind.Loading:
      return { ...state, isLoading: true };
    case ActionKind.Loaded:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data ? action.payload.data : state.data,
      };
    default:
      return state;
  }
};
