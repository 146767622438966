import iBuild from '../../../../../types/build/iBuild';
import { AttributeForSections } from '../../../../../types/attribute/iAttribute';
import ComponentPropsHelper, {
  WrapperProps,
} from '../../../../frameWork/ComponentPropsHelper';
import styled from 'styled-components';
import AttributeSetInputPanel from '../../../../attributeSet/AttributeSetInputPanel';
import { AttributeSetCodes } from '../../../../../types/attribute/iAttributeSet';
import EntityNames from '../../../../../helpers/EntityNames';
import CityCouncilSelector from '../../../../cityCouncil/CityCouncilSelector';
import { iOptionWithData } from '../../../../frameWork/Select';
import iCityCouncil from '../../../../../types/misc/iCityCouncil';

const Wrapper = styled.div``;
type iBuildDetailsPermitSiteInspectionPanel = WrapperProps & {
  build: iBuild;
  attributeSection: AttributeForSections;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};
const BuildDetailsPermitSiteInspectionPanel = ({
  testId,
  className,
  build,
  isDisabled,
  attributeSection,
  onBuildUpdated,
}: iBuildDetailsPermitSiteInspectionPanel) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'BuildDetailsPermitSiteInspectionPanel',
    testId,
    className,
  });
  return (
    <Wrapper data-testid={testIdStr} className={classNameStr}>
      <AttributeSetInputPanel
        attributeSetCodes={[AttributeSetCodes.SiteInspection]}
        attributeSection={attributeSection}
        isDisabled={isDisabled}
        entityId={build.id}
        entityName={EntityNames.Build}
      />
      <CityCouncilSelector
        isDisabled
        value={build.cityCouncilId || ''}
        label={'City Council'}
        onChange={(selected: iOptionWithData<iCityCouncil>) => {
          onBuildUpdated &&
            onBuildUpdated({
              cityCouncilId: selected?.value,
            } as iBuild);
        }}
      />
      <AttributeSetInputPanel
        attributeSetCodes={[
          // AttributeSetCodes.AssetProtection,
          // AttributeSetCodes.Bollard,
          AttributeSetCodes.PreSiteInspection,
        ]}
        attributeSection={attributeSection}
        isDisabled={isDisabled}
        entityId={build.id}
        entityName={EntityNames.Build}
      />
    </Wrapper>
  );
};

export default BuildDetailsPermitSiteInspectionPanel;
