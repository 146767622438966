import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Spinner from '../frameWork/Spinner';
import iAddress from '../../types/system/iAddress';
import AddressHelper from '../../helpers/AddressHelper';
import OpenStreetMapService, {
  iCoords,
} from '../../services/address/OpenStreetMapService';
import Toaster from '../common/Toaster';
import UtilsService from '../../services/UtilsService';

const Wrapper = styled.div`
  iframe {
    min-height: 50rem;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export type iWazeMap = WrapperProps & {
  address: iAddress;
};

const WazeMap = ({ address, testId, className }: iWazeMap) => {
  const { classNameStr, testIdStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'WazeMap',
    testId,
    className,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [coords, setCoords] = useState<iCoords | null>(null);
  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    const getCoords = async () => {
      setIsLoading(true);
      try {
        const [streetCoors, lotCoors, streetNameCoors, suburbCoors] =
          await Promise.all([
            OpenStreetMapService.getLatAndLong(
              AddressHelper.getFullStreetAddress(address),
            ),
            OpenStreetMapService.getLatAndLong(
              AddressHelper.getFullLotAddress(address),
            ),
            OpenStreetMapService.getLatAndLong(
              AddressHelper.getFullAddress(address, false, false),
            ),
            OpenStreetMapService.getLatAndLong(
              AddressHelper.getFullAddress(address, false, false, false),
            ),
          ]);

        if (isCancelled) {
          return;
        }

        if (streetCoors.length > 0) {
          setCoords(streetCoors[0]);
          setIsLoading(false);
          return;
        }

        if (lotCoors.length > 0) {
          setCoords(lotCoors[0]);
          setIsLoading(false);
          return;
        }

        if (streetNameCoors.length > 0) {
          setCoords(streetNameCoors[0]);
          setIsLoading(false);
          return;
        }

        if (suburbCoors.length > 0) {
          setCoords(suburbCoors[0]);
          setIsLoading(false);
          return;
        }

        setCoords(null);
        setFallback(true);
        setIsLoading(false);
      } catch (err) {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(err);
        setIsLoading(false);
        setFallback(true);
      }
    };

    getCoords();
    return () => {
      isCancelled = true;
    };
  }, [address]);

  const getIFrameUrl = (coord?: iCoords | null) => {
    return `https://embed.waze.com/iframe${UtilsService.getUrlParams({
      zoom: 17,
      ...(coord
        ? {
            pin: 1,
            lat: coord.lat,
            lon: coord.lon,
          }
        : {}),
    })}`;
  };

  const getIframe = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (!coords) {
      return <h4>Address Not Found.</h4>;
    }

    return (
      <iframe
        data-testid={`${testIdStr}-iframe`}
        src={coords && !fallback ? getIFrameUrl(coords) : getIFrameUrl()}
        allowFullScreen
        title={AddressHelper.getFullAddress(address)}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    );
  };

  return (
    <Wrapper className={classNameStr} data-testid={testIdStr}>
      {getIframe()}
    </Wrapper>
  );
};

export default WazeMap;
