import { iOptionWithData } from '../frameWork/Select';
import iProduct from '../../types/product/iProduct';
import InlineEdit from '../frameWork/InlineEdit';
import { iComponentWithPageHeader } from '../common/ComponentWithPageHeader';
import ComponentWithPageHeader from '../common/ComponentWithPageHeader';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import PurchaseOrderItemService from '../../services/purchase/PurchaseOrderItemService';
import iPurchaseOrderItem from '../../types/purchase/iPurchaseOderItem';
import UtilsService from '../../services/UtilsService';
import { iTableColumn, iCellParams } from '../../helpers/DynamicTableHelper';
import ProductSelector from '../product/ProductSelector';

// import { SelectiveColKeys } from '../../services/LocalStorageService';

type iPurchaseOrderItemListProps = iComponentWithPageHeader & {
  allowDelete?: boolean;
  orderId?: string;
};

const PurchaseOrderItemList = ({
  headerProps,
  allowDelete,
  orderId,
}: iPurchaseOrderItemListProps) => {
  const { renderDataTable, renderDeleteBtn, onRefresh } =
    useListCrudHook<iPurchaseOrderItem>({
      getFn: (params) =>
        PurchaseOrderItemService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(orderId ? { orderId } : {}),
          }),
          include: 'Product,TaxRate',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 100,
        }),
    });
  const columns: iTableColumn<iPurchaseOrderItem>[] = [
    {
      key: 'productCode',
      header: 'Product Code / SKU',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) => (
        <div className="text-blue-600">
          {data.Product?.productCode || data.supplierProductCode || ''}
        </div>
      ),
    },
    {
      key: 'supplierProductCode',
      header: 'Supplier P/Code',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        data.supplierProductCode || '',
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        data.Product?.shortDescription || data.description || '',
    },
    {
      key: 'qty',
      header: 'Quantity',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) => (
        <InlineEdit
          testId={`qty-inline-edit-${data.id}`}
          value={String(data.qty || 0)}
          isDisabled={false}
          onConfirm={async (newValue: string) => {
            const newQty = parseFloat(newValue);
            if (!isNaN(newQty) && newQty !== data.qty) {
              await PurchaseOrderItemService.update(data.id, { qty: newQty });
              onRefresh();
            }
          }}
        />
      ),
    },
    {
      key: 'receivedQty',
      header: "Recv'd Qty",
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        `${data.receivedQty || 0}`,
    },
    {
      key: 'unitPrice',
      header: 'Unit Price',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        `$${data.unitPrice || 0}`,
    },
    {
      key: 'discountPercentage',
      header: 'Disc %',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        `${data.discountPercentage || 0} %`,
    },
    {
      key: 'tax',
      header: 'Tax',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        data.TaxRate?.name || 'GST',
    },
    {
      key: 'totalPrice',
      header: 'Total (Inc GST)',
      isDefault: true,
      cell: ({ data }: iCellParams<iPurchaseOrderItem>) =>
        UtilsService.formatIntoCurrency(data.totalPrice || 0),
    },
    ...(allowDelete
      ? [
          {
            key: 'actions',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iPurchaseOrderItem>) => (
              <div
                className="text-right"
                data-testid={`delete-icon-btn-${data.id}`}
              >
                {renderDeleteBtn({
                  deletingModel: data,
                  deleteFnc: async () =>
                    PurchaseOrderItemService.deactivate(data.id),
                  getDisplayName: (item) =>
                    item.supplierProductCode || 'Purchase Item',
                })}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      {renderDataTable({
        columns,
        // selectiveColumnKey: SelectiveColKeys.PURCHASE_ORDER_LIST,
        // showPageSizer: true,
        tblProps: {
          testId: 'purchase-order-item-table',
        },
      })}

      <div className="selector-div mt-4 w-[300px]">
        <ProductSelector
          className="selector"
          testId="product-selector"
          isClearable
          placeholder="Search productCode/name..."
          value={null}
          onChange={async (selected: iOptionWithData<iProduct> | null) => {
            if (!selected?.data || !orderId) return;
            const selectedProduct = selected.data;

            await PurchaseOrderItemService.create({
              orderId,
              productId: selectedProduct.id,
              qty: 0,
            });

            onRefresh();
          }}
        />
      </div>
    </ComponentWithPageHeader>
  );
};

export default PurchaseOrderItemList;
