import ProtectedPage from '../../layouts/ProtectedPage';
import PurchaseOrderList from '../../components/purchase/PurchaseOrderList';

const PurchaseOrdersPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: 'Purchase Orders',
      }}
    >
      {(headerProps) => (
        <PurchaseOrderList headerProps={headerProps} allowDelete />
      )}
    </ProtectedPage>
  );
};

export default PurchaseOrdersPage;
