import iBuild from '../../../../../types/build/iBuild';
import { AttributeForSections } from '../../../../../types/attribute/iAttribute';
import styled from 'styled-components';
import ComponentPropsHelper, {
  WrapperProps,
} from '../../../../frameWork/ComponentPropsHelper';
import EstateSelector from '../../../../estate/EstateSelector';
import { iOptionWithData } from '../../../../frameWork/Select';
import iEstate from '../../../../../types/estate/iEstate';
import BuildService from '../../../../../services/build/BuildService';
import { useState } from 'react';
import Toaster from '../../../../common/Toaster';
import CityCouncilSelector from '../../../../cityCouncil/CityCouncilSelector';
import iCityCouncil from '../../../../../types/misc/iCityCouncil';
import { iConfigParams } from '../../../../../services/AppService';
import ContactCompanySelector from '../../../../contact/ContactCompanySelector';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../../../../types/contact/iContactCompany';
import GasTypeSelector from '../../../../gasType/GasTypeSelector';
import iGasType from '../../../../../types/system/iGasType';
import { DatePicker } from '../../../../frameWork/DateTimePicker';
import SectionDiv from '../../../../frameWork/SectionDiv';
import FormField from '../../../../frameWork/FormField';
import TextField from '../../../../frameWork/TextField';
import Flex from '../../../../frameWork/Flex';
import UtilsService from '../../../../../services/UtilsService';
import BalRateSelector from '../../../../balRate/BalRateSelector';
import iBalRate from '../../../../../types/system/iBalRate';
import EnergyRateSelector from '../../../../energyRate/EnergyRateSelector';
import iEnergyRate from '../../../../../types/system/iEnergyRate';

const Wrapper = styled.div`
  .form-field-wrapper {
    .form-field-wrapper {
      margin-top: 0px;
    }
  }
`;
type iBuildDetailsPermitPrePermitPanel = WrapperProps & {
  build: iBuild;
  attributeSection: AttributeForSections;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};
const BuildDetailsPermitPrePermitPanel = ({
  build,
  isDisabled,
  testId,
  className,
  onBuildUpdated,
}: iBuildDetailsPermitPrePermitPanel) => {
  const [isSaving, setIsSaving] = useState(false);
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'BuildDetailsPermitPrePermitPanel',
    testId,
    className,
  });

  const updateBuild = (newValue: iConfigParams) => {
    BuildService.update(build.id, newValue)
      .then((resp) => {
        onBuildUpdated && onBuildUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getDateField = (
    label: string,
    fieldName: string,
    date: Date | string | null,
    enabled = false,
  ) => {
    const dateString = `${date || ''}`.trim();
    return (
      <DatePicker
        label={label}
        isDisabled={!enabled}
        value={dateString === '' ? undefined : dateString}
        onChange={(date) => updateBuild({ [fieldName]: date })}
      />
    );
  };

  return (
    <Wrapper className={classNameStr} data-testid={testIdStr}>
      <EstateSelector
        value={build.estateId}
        isDisabled={isDisabled || isSaving}
        isLoading={isSaving}
        label={'Estate'}
        onChange={(selected: iOptionWithData<iEstate>) =>
          updateBuild({ estateId: selected.value })
        }
      />

      <CityCouncilSelector
        value={build.cityCouncilId}
        isDisabled={isDisabled || isSaving}
        isLoading={isSaving}
        label={'Council Shire'}
        onChange={(selected: iOptionWithData<iCityCouncil>) =>
          updateBuild({ cityCouncilId: selected.value })
        }
      />

      <ContactCompanySelector
        contactCompanyType={ContactCompanyTypes.ELECTRICITY_SUPPLIER}
        value={build.electricityAuthorityId}
        isDisabled={isDisabled || isSaving}
        isLoading={isSaving}
        label={'Electricity Supplier'}
        onChange={(selected: iOptionWithData<iContactCompany>) =>
          updateBuild({ electricityAuthorityId: selected.value })
        }
      />

      <GasTypeSelector
        value={build.gasTypeId}
        isDisabled={isDisabled || isSaving}
        isLoading={isSaving}
        label={'Gas Type'}
        onChange={(selected: iOptionWithData<iGasType>) =>
          updateBuild({ gasTypeId: selected.value })
        }
      />

      <ContactCompanySelector
        contactCompanyType={ContactCompanyTypes.GAS_AUTHORITY}
        value={build.gasAuthorityId}
        isDisabled={isDisabled || isSaving}
        isLoading={isSaving}
        label={'Gas Supplier'}
        onChange={(selected: iOptionWithData<iContactCompany>) =>
          updateBuild({ gasAuthorityId: selected.value })
        }
      />

      <SectionDiv title={'Dates'}>
        <Flex className={'gap-05 flex-wrap'}>
          {getDateField(
            'Est. Start Date',
            'estStartDate',
            build.estStartDate,
            !isDisabled && !isSaving,
          )}
          {getDateField(
            'Est. Finish Date',
            'estFinishDate',
            build.estFinishDate,
            !isDisabled && !isSaving,
          )}
          {getDateField(
            'Actual Start Date',
            'actualStartDate',
            build.actualStartDate,
            !isDisabled && !isSaving,
          )}
          {getDateField(
            'Est. Comp. Actual Date',
            'estFinishDateFromActualStartDate',
            build.estFinishDateFromActualStartDate,
            !isDisabled && !isSaving,
          )}
        </Flex>
      </SectionDiv>

      <SectionDiv title={'Contract'}>
        <Flex className={'gap-05 flex-wrap'}>
          {getDateField(
            'Contract Date',
            'contractDate',
            build.contractDate,
            !isDisabled && !isSaving,
          )}
          <FormField
            render={() => (
              <TextField
                label={'Contract Days'}
                isDisabled={isDisabled || isSaving}
                value={build.contractDays || ''}
              />
            )}
          />
          <FormField
            render={() => (
              <TextField
                label={'Contract Price'}
                isDisabled={isDisabled || isSaving}
                value={
                  UtilsService.formatIntoCurrency(build.contractPrice) || ''
                }
              />
            )}
          />
        </Flex>
      </SectionDiv>

      <SectionDiv title={'BAL Report'}>
        <Flex className={'gap-05 flex-wrap'}>
          <BalRateSelector
            value={build.balRateId}
            label={'BAL Rating'}
            isDisabled={isDisabled || isSaving}
            onChange={(selected: iOptionWithData<iBalRate>) => {
              updateBuild({ balRateId: selected.value });
            }}
          />
        </Flex>
      </SectionDiv>

      <SectionDiv title={'Energy'}>
        <Flex className={'gap-05 flex-wrap'}>
          <EnergyRateSelector
            isDisabled={isDisabled || isSaving}
            value={build.energyRateId}
            label={'House Energy Rating'}
            onChange={(selected: iOptionWithData<iEnergyRate>) => {
              updateBuild({ energyRateId: selected.value });
            }}
          />
        </Flex>
      </SectionDiv>
    </Wrapper>
  );
};

export default BuildDetailsPermitPrePermitPanel;
