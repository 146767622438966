import ServiceHelper from '../helper/ServiceHelper';
import iSequenceNumberType from '../../types/system/iSequenceNumberType';
import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const endPoint = '/sequenceNumberType';

const SequenceNumberTypeService = {
  getAll: ServiceHelper.getPaginatedAll<iSequenceNumberType>(endPoint),
  get: ServiceHelper.getById<iSequenceNumberType>(endPoint),
  create: ServiceHelper.create<iSequenceNumberType>(endPoint),
  update: ServiceHelper.updateById<iSequenceNumberType>(endPoint),

  getAvailTypes: async (
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ): Promise<{ [key: string]: boolean }[]> => {
    return AppService.get(`${endPoint}/availTypes`, params, config).then(
      ({ data }: AxiosResponse) => data,
    );
  },
};

export default SequenceNumberTypeService;
