import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iPurchaseOrder from '../../types/purchase/iPurchaseOder';
import PurchaseOrderDetailsComponent from './PurchaseOrderDetailsComponent';
import PurchaseOrderItemList from './PurchaseOrderItemList';

type iPurchaseOrderDetailsPanel = iComponentWithPageHeader & {
  purchaseOrder: iPurchaseOrder;
  onSaved?: (saved: iPurchaseOrder) => void;
  isDisabled?: boolean;
  testId?: string;
};

const PurchaseOrderDetailsPanel = ({
  purchaseOrder,
  onSaved,
  isDisabled,
  testId,
  headerProps,
}: iPurchaseOrderDetailsPanel) => {
  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      <PurchaseOrderDetailsComponent
        purchaseOrder={purchaseOrder}
        onSaved={onSaved}
        isDisabled={isDisabled}
        testId={testId}
      />
      <PurchaseOrderItemList
        orderId={purchaseOrder.id}
        allowDelete={!isDisabled}
      />
    </ComponentWithPageHeader>
  );
};

export default PurchaseOrderDetailsPanel;
