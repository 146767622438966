import SettingsPage from '../../layouts/SettingsPage';
import SequenceNumberTypeList from '../../components/settings/SequenceNumberTypeList';

const SequenceNumberTypesPage = () => {
  return (
    <SettingsPage title={'Sequence Number Types'}>
      {(headerProps) => (
        <SequenceNumberTypeList
          allowEdit
          allowCreate
          headerProps={headerProps}
        />
      )}
    </SettingsPage>
  );
};

export default SequenceNumberTypesPage;
