import ServiceHelper from '../helper/ServiceHelper';
import iEntityStatusCategory from '../../types/status/iEntityStatusCategory';

const endPoint = '/entityStatusCategory';
const EntityStatusCategoryService = {
  getAll: ServiceHelper.getPaginatedAll<iEntityStatusCategory>(endPoint),
  get: ServiceHelper.getById<iEntityStatusCategory>(endPoint),
};

export default EntityStatusCategoryService;
