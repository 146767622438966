import ProductOptionTypeList from '../../components/product/ProductOptionTypeList';
import ProtectedPage from '../../layouts/ProtectedPage';

const ProductOptionTypesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Product Option Types`,
      }}
    >
      {(headerProps) => (
        <ProductOptionTypeList
          allowDelete
          allowCreate
          allowEdit
          headerProps={headerProps}
        />
      )}
    </ProtectedPage>
  );
};

export default ProductOptionTypesPage;
