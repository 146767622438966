import React from 'react';
import useListCrudHook from '../../../../hooks/useListCrudHook/useListCrudHook';
import BuildCheckService from '../../../../../services/build/BuildCheckService';
import iBuildCheck from '../../../../../types/build/iBuildCheck';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../../../../helpers/DynamicTableHelper';
import ModifyBy from '../../../../common/ModifyBy';
import Flex from '../../../../frameWork/Flex';
import TextArea from '../../../../frameWork/TextArea';
import EntityStatusSelector from '../../../../entityStatus/EntityStatusSelector';
import iEntityStatus from '../../../../../types/status/iEntityStatus';
import { iOptionWithData } from '../../../../frameWork/Select';
import { iEntityFormField } from '../../../../form/EntityEditPanel';
import Icons from '../../../../frameWork/Icons';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../../../../common/PageTitleWithCreateBtn';
import moment from 'moment';
import UserSelector from '../../../../user/UserSelector';
import iUser from '../../../../../types/system/iUser';
import DateTimePicker from '../../../../frameWork/DateTimePicker';
import RelationTypes from '../../../../../helpers/RelationTypes';
import ComponentWithPageHeader from '../../../../common/ComponentWithPageHeader';
import Heading from '../../../../frameWork/Heading';
import EntityNames from '../../../../../helpers/EntityNames';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';
import EntityStatusLozenge from '../../../../entityStatus/EntityStatusLozenge';

type iBuildCheckList = {
  buildId: string;
  type: RelationTypes;
  allowEdit: boolean;
  allowDelete: boolean;
  isDisabled?: boolean;
  onDeleted?: (deleted: iBuildCheck) => void;
  onSaved?: (saved: iBuildCheck, isCreated: boolean) => void;
};

const BuildCheckList = ({
  buildId,
  type,
  allowEdit,
  allowDelete,
  isDisabled,
  onDeleted,
  onSaved,
}: iBuildCheckList) => {
  const testIdStr = 'buildCheck-list';

  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iBuildCheck>({
      sort: 'checkAt:DESC',
      getFn: (params) =>
        BuildCheckService.getAll({
          where: JSON.stringify({
            buildId,
            type,
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,CheckBy,EntityStatus.Category',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getColumns = (): iTableColumn<iBuildCheck>[] => [
    {
      key: 'checkedBy',
      header: 'Check',
      cell: ({ data }: iCellParams<iBuildCheck>) => (
        <ModifyBy by={data?.CheckBy} at={data?.checkAt} />
      ),
      isDefault: true,
    },

    {
      key: 'status',
      header: 'Status',
      cell: ({ data }: iCellParams<iBuildCheck>) => (
        <EntityStatusLozenge status={data.EntityStatus} />
      ),
      isDefault: true,
    },
    {
      key: 'comments',
      header: 'Comments',
      cell: ({ data }: iCellParams<iBuildCheck>) => data?.comments || '',
      isDefault: true,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildCheck>(),
    {
      key: 'btns',
      header: '',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildCheck>) => {
        return (
          <Flex className={'justify-content-end gap-025'}>
            {allowEdit && getEditBtn(data)}
            {allowDelete &&
              renderDeleteBtn({
                deletingModel: data,
                deleteFnc: async () => BuildCheckService.deactivate(data.id),
                getDisplayName: (item) => item.CheckBy?.firstName || '',
                onDeleted: (deleted: iBuildCheck) => {
                  onDeleted && onDeleted(deleted);
                },
              })}
          </Flex>
        );
      },
    },
  ];

  const getEditBtn = (buildCheck?: iBuildCheck) => {
    return renderEntityEditPopBtn<iBuildCheck>({
      editingEntity: buildCheck,
      entityName: 'BuildCheck',
      isDisabled,
      onSaved,
      createFn: (data) =>
        BuildCheckService.create({
          ...data,
          buildId,
          type,
        }),
      updateFn: (id, data) => BuildCheckService.update(id, data),
      renderEditBtn: ({ entity, onClick }) =>
        getCreateIconBtn({
          onClick: onClick,
          appearance: 'subtle',
          isTooltipDisabled: false,
          testId: `edit-btn-${entity.id}`,
          label: 'update',
          icon: () => <Icons.EditFilledIcon size="small" label={''} />,
        }),
      defaultValues: { checkAt: moment().toISOString() },
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] => {
        if (buildCheck)
          return [
            {
              fieldName: 'entityStatusId',
              label: 'Status',
              isDisabled,
              isRequired: true,
              value: entity?.entityStatusId || '',
              testId: 'BuildCheck-entityStatusId',
              renderComponent: (fProps, useAsForm, errorProps) => {
                const { fieldName, ...props } = fProps;
                return (
                  <EntityStatusSelector
                    {...props}
                    {...errorProps}
                    entityStatusTypeName={EntityNames.BuildCheck}
                    onChange={(
                      option: iOptionWithData<iEntityStatus> | null,
                    ) => {
                      useAsForm.onFieldChange(fieldName, option?.value || '');
                    }}
                  />
                );
              },
            },
            {
              fieldName: 'comments',
              label: 'Comments',
              isDisabled,
              value: entity?.comments || '',
              testId: 'BuildCheck-comments',
              renderComponent: (fProps, useAsForm, errorProps) => {
                const { fieldName, ...props } = fProps;
                return (
                  <TextArea
                    {...props}
                    {...errorProps}
                    value={props.value as string | undefined}
                    onChange={(event) => {
                      useAsForm.onFieldChange(fieldName, event.target.value);
                    }}
                  />
                );
              },
            },
          ];
        return [
          {
            fieldName: 'checkById',
            label: 'Check By',
            isDisabled,
            isRequired: true,
            value: entity?.checkById || '',
            testId: 'BuildCheck-checkById',
            renderComponent: (fProps, useAsForm, errorProps) => {
              const { fieldName, ...props } = fProps;
              return (
                <UserSelector
                  {...props}
                  {...errorProps}
                  onChange={(option: iOptionWithData<iUser> | null) => {
                    useAsForm.onFieldChange(fieldName, option?.value || '');
                  }}
                />
              );
            },
          },
          {
            fieldName: 'checkAt',
            label: 'Check At',
            isDisabled,
            isRequired: true,
            value: entity?.checkAt || '',
            testId: 'BuildCheck-checkAt',
            renderComponent: (fProps, useAsForm, errorProps) => {
              const { fieldName, ...props } = fProps;
              return (
                <DateTimePicker
                  {...props}
                  {...errorProps}
                  value={props.value as string}
                  onChange={(selected) => {
                    useAsForm.onFieldChange(
                      fieldName,
                      `${selected || ''}`.trim() === ''
                        ? null
                        : moment(`${selected || ''}`.trim()).toISOString(),
                    );
                  }}
                />
              );
            },
          },
          {
            fieldName: 'comments',
            label: 'Comments',
            isDisabled,
            value: entity?.comments || '',
            testId: 'BuildCheck-comments',
            renderComponent: (fProps, useAsForm, errorProps) => {
              const { fieldName, ...props } = fProps;
              return (
                <TextArea
                  {...props}
                  {...errorProps}
                  value={props.value as string | undefined}
                  onChange={(event) => {
                    useAsForm.onFieldChange(fieldName, event.target.value);
                  }}
                />
              );
            },
          },
        ];
      },
    });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={<Heading size={'small'}>Build Check</Heading>}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.BUILD_CHECK_LIST,
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildCheckList;
