import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import Tabs from '../frameWork/Tabs';
import { useEffect, useState } from 'react';
import iEstatePlanNoteCategory from '../../types/estate/iEstatePlanNoteCategory';
import EstatePlanNoteCategoryService from '../../services/estate/EstatePlanNoteCategoryService';
import PageInit from '../../pages/PageInit';
import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import Toaster from '../common/Toaster';
import RichTextEditor from '../frameWork/RichTextEditor';
import EstatePlanNoteService from '../../services/estate/EstatePlanNoteService';
import iEstatePlanNote from '../../types/estate/iEstatePlanNote';

type iNotesMap = { [key: string]: iEstatePlanNote };
type iEstatePlanNoteTabPanel = iComponentWithPageHeader &
  WrapperProps & {
    estateId: string;
    isDisabled?: boolean;
  };
const EstatePlanNoteTabPanel = ({
  estateId,
  isDisabled,
  testId,
  className,
  headerProps,
}: iEstatePlanNoteTabPanel) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'EstatePlanNoteTabPanel',
    testId,
    className,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<iEstatePlanNoteCategory[]>([]);
  const [notesMap, setNotesMap] = useState<iNotesMap>({});
  useEffect(() => {
    if (`${estateId || ''}`.trim() == '') {
      setCategories([]);
      setNotesMap({});
      return;
    }
    let isCancelled = false;
    setIsLoading(true);
    Promise.all([
      EstatePlanNoteCategoryService.getAll({
        where: JSON.stringify({
          isActive: true,
        }),
        perPage: 9999999,
      }),
      EstatePlanNoteService.getAll({
        where: JSON.stringify({
          isActive: true,
          estateId: estateId,
        }),
        perPage: 9999999,
      }),
    ])
      .then((response) => {
        if (!isCancelled) {
          setCategories(response[0].data);
          setNotesMap(
            (response[1].data || []).reduce((map, note) => {
              map[note.categoryId || ''] = note;
              return map;
            }, {} as iNotesMap),
          );
        }
      })
      .catch((error) => {
        if (!isCancelled) {
          Toaster.showApiError(error);
        }
      })
      .finally(() => {
        if (!isCancelled) {
          setIsLoading(false);
        }
      });
    return () => {
      isCancelled = true;
    };
  }, [estateId]);

  const getContent = () => {
    if (isLoading) {
      return <PageInit />;
    }
    return (
      <Tabs
        tabs={categories.map((category) => ({
          eventKey: category.id,
          title: category.name,
          tabContent: (
            <RichTextEditor
              isDisabled={isDisabled}
              value={`${category.id in notesMap ? notesMap[category.id].notes || '' : ''}`}
            />
          ),
        }))}
      />
    );
  };

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      <div data-testid={testIdStr} className={classNameStr}>
        {getContent()}
      </div>
    </ComponentWithPageHeader>
  );
};

export default EstatePlanNoteTabPanel;
