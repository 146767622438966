import { useEffect, useState } from 'react';
import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import iEntityStatus from '../../types/status/iEntityStatus';
import EntityStatusService from '../../services/status/EntityStatusService';
import EntityStatusTypeService from '../../services/status/EntityStatusTypeService';
import Toaster from '../common/Toaster';
import iEntityStatusType from '../../types/status/iEntityStatusType';
import Select, { iOptionWithData } from '../frameWork/Select';
import EntityNames from '../../helpers/EntityNames';
import EntityStatusLozenge from './EntityStatusLozenge';

export type iEntityStatusSelector = iPreloadAsyncSelectProps & {
  entityStatusTypeName: EntityNames;
};

type iState = {
  entityStatusType?: iEntityStatusType;
  isLoading: boolean;
};
const initialState: iState = {
  isLoading: true,
};
const EntityStatusSelector = ({
  entityStatusTypeName,
  ...props
}: iEntityStatusSelector) => {
  const [state, setState] = useState<iState>(initialState);
  useEffect(() => {
    let isCancelled = false;
    const loadEntityStatusType = async () => {
      try {
        const { data } = await EntityStatusTypeService.getAll({
          where: JSON.stringify({
            isActive: true,
            entityName: entityStatusTypeName,
          }),
          currentPage: 1,
          perPage: 1,
        });
        if (data.length === 0) {
          throw new Error('No status type found for ' + entityStatusTypeName);
        }
        if (isCancelled) return;
        setState((preState) => ({
          ...preState,
          entityStatusType: data[0],
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        Toaster.showApiError(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    loadEntityStatusType();
    return () => {
      isCancelled = true;
    };
  }, [entityStatusTypeName]);

  // need this to re-render
  if (state.isLoading) return <Select isLoading={state.isLoading} />;
  if (`${state.entityStatusType?.id || ''}`.trim() === '') {
    return <div>No EntityTpye found for ({entityStatusTypeName}).</div>;
  }
  return (
    <PreloadedAsyncSelector<iEntityStatus>
      placeholder={'Select a Status...'}
      {...props}
      formatOptionLabel={(data: iOptionWithData<iEntityStatus>) => (
        <EntityStatusLozenge status={data.data} />
      )}
      getFn={async (data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        const currentValue = `${props.value || ''}`.trim();
        const [statusList, nextStatuses] = await Promise.all([
          EntityStatusService.getAll({
            ...params,
            sort: 'sortOrder:ASC',
            include: 'Category',
            where: JSON.stringify({
              isActive: true,
              entityStatusTypeId: state.entityStatusType?.id,
              ...(searchTxtStr === ''
                ? {}
                : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
            }),
          }),
          currentValue === ''
            ? []
            : EntityStatusService.getNextStatuses(currentValue),
        ]);

        const nextStatusesIds = nextStatuses.map((s) => s.id);
        return {
          ...statusList,
          data: (statusList.data || []).filter(
            (s) => nextStatusesIds.indexOf(s.id) >= 0,
          ),
        };
      }}
      getValuesFn={(values: string[]) =>
        EntityStatusService.getAll({
          where: JSON.stringify({ id: values }),
          include: 'Category',
          perPage: values.length,
        })
      }
    />
  );
};

export default EntityStatusSelector;
