import EstateList from '../../components/estate/EstateList';
import ProtectedPage from '../../layouts/ProtectedPage';

const EstatesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Estates`,
      }}
    >
      {(headerProps) => (
        <EstateList
          headerProps={headerProps}
          allowCreate={true}
          allowDelete={true}
        />
      )}
    </ProtectedPage>
  );
};

export default EstatesPage;
