import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { Content, LeftSidebar } from '../frameWork/PageLayout';
import SideNavigation, {
  LinkItem,
  NavigationContent,
  Section,
} from '../frameWork/SideNavigation';
import Flex from '../frameWork/Flex';
import Select from '../frameWork/Select';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import { setBuildDetailsSubSelectedTab } from '../../redux/reduxers/app.slice';

const MIN_LEFT_SIDE_BAR_WIDTH = 150;
const Wrapper = styled.div`
  .content {
    width: 100%;
    margin-left: ${Tokens('space.150', '16px')};
    padding: ${Tokens('space.150', '16px')};

    .top-nav-row {
      margin-bottom: ${Tokens('space.300', '2rem')};
      .quick-nav-selector {
        margin-block-start: 0;
        min-height: 1rem;
        min-width: 10rem;
        background-color: ${Tokens('color.background.accent.gray.subtlest')};
      }
    }
    .attribute-value-single-set,
    .AttributeValueEditTable {
      margin-top: ${Tokens('space.150', '16px')};
    }
  }
`;

export type iMenuItemsMap = {
  [key: string]: {
    name: string;
    icon: ReactNode;
    component: ReactNode;
    topNav?: ReactNode;
  };
};

export type iDetailsPanelWithSubMenuPanel = {
  className?: string;
  testId?: string;
  menuItemsMap: iMenuItemsMap;
};
const DetailsPanelWithSubMenuPanel = ({
  className,
  testId,
  menuItemsMap,
}: iDetailsPanelWithSubMenuPanel) => {
  const testIdStr = `${testId || ''}-details-panel-with-sub-menu`;
  const defaultKey = `${Object.keys(menuItemsMap)[0] || ''}`.trim();
  const { buildDetailsSubSelectedTab } = useSelector((s: RootState) => s.app);
  const dispatch = useDispatch();

  const selectedKey =
    `${buildDetailsSubSelectedTab || ''}`.trim() === ''
      ? defaultKey
      : `${buildDetailsSubSelectedTab || ''}`.trim();

  const handleSelectedMenuKeyChange = (key: string) => {
    dispatch(
      setBuildDetailsSubSelectedTab({
        buildDetailsSubSelectedTab: key,
      }),
    );
  };

  const getSideBar = () => {
    return (
      <LeftSidebar
        isFixed={false}
        testId={'details-panel-with-sub-menu-left'}
        width={MIN_LEFT_SIDE_BAR_WIDTH}
      >
        <SideNavigation
          label={'details-panel-with-sub-menu-leftMenu'}
          testId={`${testIdStr}-sideNavigation`}
        >
          <NavigationContent
            testId="navigation-content"
            className="nav-content"
          >
            <Section isList>
              {Object.entries(menuItemsMap).map(([key, item]) => (
                <LinkItem
                  key={key}
                  testId={key}
                  iconBefore={item.icon}
                  isSelected={key === selectedKey}
                  onClick={() => handleSelectedMenuKeyChange(key)}
                >
                  {item.name}
                </LinkItem>
              ))}
            </Section>
          </NavigationContent>
        </SideNavigation>
      </LeftSidebar>
    );
  };

  const getMenuItemFromKey = (key: string) => {
    if (!(key in menuItemsMap)) {
      return null;
    }
    return menuItemsMap[key];
  };

  const getTopNavRow = () => {
    const selectedMenuItem = getMenuItemFromKey(selectedKey);
    return (
      <Flex className={'top-nav-row align-items-center gap-1'}>
        <Select
          testId={'top-menu-dropdown'}
          className={'quick-nav-selector'}
          appearance={'subtle'}
          size={'small'}
          autoFocus={false}
          value={
            !selectedMenuItem
              ? undefined
              : {
                  label: selectedMenuItem.name,
                  value: selectedKey,
                  data: selectedMenuItem,
                }
          }
          formatOptionLabel={(data) => {
            return (
              <Flex className={'align-items-center gap-1'}>
                {data.data.icon} <div>{data.data.name}</div>
              </Flex>
            );
          }}
          options={Object.entries(menuItemsMap).map(([key, item]) => {
            return {
              label: item.name,
              value: key,
              data: item,
            };
          })}
          onChange={(option) => {
            handleSelectedMenuKeyChange(option?.value || defaultKey);
          }}
        />
        {selectedMenuItem?.topNav}
      </Flex>
    );
  };

  const getViewingContent = () => {
    const selectedMenuItem = getMenuItemFromKey(selectedKey);
    if (!selectedMenuItem) {
      return null;
    }

    return (
      <div className={'viewing-content'}>{selectedMenuItem.component}</div>
    );
  };

  return (
    <Wrapper
      className={`details-panel-with-sub-menu ${className || ''}`}
      data-testid={`${testIdStr}-wrapper`}
    >
      <Content>
        {getSideBar()}
        <div className={'content'}>
          {getTopNavRow()}
          {getViewingContent()}
        </div>
      </Content>
    </Wrapper>
  );
};

export default DetailsPanelWithSubMenuPanel;
