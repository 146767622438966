import { getDefaultAdvBtn } from './AdvanceSearchPanel';

type iAdvSearchBtn = {
  showAdvSearchPanel: boolean;
  setShowAdvSearchPanel: (showAdvSearchPanel: boolean) => void;
  className?: string;
  testId?: string;
};
const AdvSearchBtn = ({
  setShowAdvSearchPanel,
  showAdvSearchPanel,
  className,
}: iAdvSearchBtn) => {
  return getDefaultAdvBtn({
    onClick: () => setShowAdvSearchPanel(!showAdvSearchPanel),
    className,
    isShowingAdv: showAdvSearchPanel,
  });
};

export default AdvSearchBtn;
