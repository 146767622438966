import iAttribute, {
  AttributeForSections,
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import iAttributeValue from '../../../types/attribute/iAttributeValue';
import StringHelper from '../../../helpers/StringHelper';
import AttributeSettingsHelper, {
  AttrSettingsFieldNames,
  iEditLogic,
  ThenActions,
  WhenActions,
} from './AttributeSettingsHelper';
import * as _ from 'lodash';
import EntityNames from '../../../helpers/EntityNames';
import AttributeService from '../../../services/attribute/AttributeService';
import AttributeValueService from '../../../services/attribute/AttributeValueService';
import AttributeItemService from '../../../services/attribute/AttributeItemService';
import { iAttributeItemWithValueMap } from '../../../types/attribute/iAttributeItem';
import iPaginatedResult from '../../../types/iPaginatedResult';
import ProductDiv from '../../product/ProductDiv';
import ContactCompanyDiv from '../../contact/ContactCompanyDiv';
import React from 'react';
import AttributeSetService from '../../../services/attribute/AttributeSetService';
import iAttributeSet from '../../../types/attribute/iAttributeSet';
import moment from 'moment';
import HouseAreaDiv from '../../houseArea/HouseAreaDiv';
import Icons from '../../frameWork/Icons';
import AttributeBuildSizeDiv from './AttributeBuildSizeDiv';
import AttributeAliasValueDiv from '../AttributeAliasValueDiv';
import EmailPopupBtn from '../../message/EmailPopupBtn';
import { AssetTypes } from '../../../types/asset/iAsset';
import AttachmentsPopupBtn from '../../asset/AttachmentsPopupBtn';
import UserDiv from '../../user/UserDiv';

export type iAttValid = {
  attribute: iAttribute;
  attributeSection: AttributeForSections;
  attributeValue?: iAttributeValue | { value: string };
};

const getRequiredAttributeErrors = (props: iAttValid) => {
  const selectedValue = `${props.attributeValue?.value || ''}`.trim();
  const isRequired = AttributeSettingsHelper.isAttributeRequiredForSection(
    props.attribute,
    props.attributeSection,
  );
  return isRequired && selectedValue === ''
    ? { [props.attribute.id]: [`${props.attribute.name} is required.`] }
    : {};
};

const getNumberAttributeErrors = (props: iAttValid) => {
  const selectedValue = `${props.attributeValue?.value || ''}`.trim();
  const isEditable = AttributeSettingsHelper.isAttributeEditableForSection(
    props.attribute,
    props.attributeSection,
  );
  const isNumeric = StringHelper.isNumeric(selectedValue);
  return isEditable &&
    selectedValue !== '' &&
    props.attribute.type === AttributeTypes.INPUT_NUMBER &&
    !isNumeric
    ? {
        [props.attribute.id]: [`${props.attribute.name} needs to be a number.`],
      }
    : {};
};

const getAttributeValueErrors = (props: iAttValid) => {
  const requiredErrors = getRequiredAttributeErrors(props);
  const numbersErrors = getNumberAttributeErrors(props);
  return {
    ...requiredErrors,
    ...numbersErrors,
  };
};

const enforceAnAttributeIsRequired = (
  attribute: iAttribute,
  attributeSection: AttributeForSections,
  isRequired: boolean,
) => {
  if (!isRequired) {
    return attribute;
  }
  const actionObj = AttributeSettingsHelper.getForActionObj(attribute) || {};
  return {
    ...attribute,
    settings: {
      ...(attribute?.settings || {}),
      [AttrSettingsFieldNames.forSectionsMap]: {
        ...actionObj,
        [attributeSection]: {
          ...(attributeSection in actionObj ? actionObj[attributeSection] : {}),
          isRequired: isRequired,
        },
      },
    },
  };
};

const getRelatedLogics = (
  attribute: iAttribute,
  attrValuesFromDB: iAttributeValue[],
  thenAction: ThenActions,
  attributeItem?: iAttributeItemWithValueMap,
): iEditLogic[] => {
  const editLogics = AttributeSettingsHelper.getEditLogic(attribute);
  return editLogics
    .filter((editLogic) => editLogic.then?.type === thenAction)
    .filter((editLogic) => {
      const whens = editLogic.when || [];
      for (let i = 0; i < whens.length; i++) {
        const when = whens[i];
        const whenAttrId = `${when.attribute?.id || ''}`.trim();
        if (!when.attribute || whenAttrId === '') {
          continue;
        }
        const newValues: iAttributeValue[] = Object.values(
          attributeItem?.valuesMap || {},
        ).map((newVal) => ({
          ...newVal,
          itemId: `${attributeItem?.id || ''}`.trim(),
          attributeSetCode: attributeItem?.attributeSetCode || '',
        }));
        const relatedAttrValues = [...attrValuesFromDB, ...newValues].filter(
          (attrValueFromDB) => {
            // if the attributeValuesFromDB is in a different attributeSet
            if (
              `${attributeItem?.attributeSetCode || ''}`.trim() !==
              `${attrValueFromDB?.attributeSetCode || ''}`.trim()
            ) {
              // check whether the attribute is what we are triggering
              if (attrValueFromDB.attributeId !== whenAttrId) {
                return false;
              }
              // whether it has a true value
              if (`${attrValueFromDB.value || ''}` === '') {
                return false;
              }

              return true;
            }
            // if the attributeValuesFromDB is in a same attributeSet,
            // then whether it's the same item
            return (
              `${attributeItem?.id || ''}`.trim() ===
              `${attrValueFromDB.itemId || ''}`.trim()
            );
          },
        );

        if (
          when.type === WhenActions.AttrValueProvided &&
          relatedAttrValues.length > 0
        ) {
          return true;
        }

        const relatedValues = relatedAttrValues.map(
          (attrValueFromDB) => attrValueFromDB.value,
        );
        const targetValues = when.attrValues || [];
        if (
          when.type === WhenActions.AttrValueIs &&
          targetValues.length > 0 &&
          // all targetValues entries are into relatedValues
          _.difference(targetValues, relatedValues).length === 0
        ) {
          return true;
        }
      }
      return false;
    });
};

const checkAttributeIsRequiredByEditLogic = (
  attribute: iAttribute,
  attrValuesFromDB: iAttributeValue[],
  attributeItem?: iAttributeItemWithValueMap,
): boolean => {
  const requiredLogics = getRelatedLogics(
    attribute,
    attrValuesFromDB,
    ThenActions.AttrIsRequired,
    attributeItem,
  );
  return requiredLogics.length > 0;
};

const getAttrValueFromEditLogic = (
  attribute: iAttribute,
  attrValuesFromDB: iAttributeValue[],
  attributeItem?: iAttributeItemWithValueMap,
): string | null => {
  const editLogics = getRelatedLogics(
    attribute,
    attrValuesFromDB,
    ThenActions.AttrValueWillBe,
    attributeItem,
  );
  const values =
    editLogics.length <= 0 ? [] : editLogics[0].then?.attrValues || [];
  return values.length > 0 ? values[0] : null;
};

const getLimitedOptionValuesFromEditLogic = (
  attribute: iAttribute,
  attrValuesFromDB: iAttributeValue[],
  attributeItem?: iAttributeItemWithValueMap,
): string[] | null => {
  const editLogics = getRelatedLogics(
    attribute,
    attrValuesFromDB,
    ThenActions.AttrLimitValues,
    attributeItem,
  );
  return editLogics.length <= 0 ? [] : editLogics[0].then?.attrValues || [];
};

const displayValue = (
  entityId: string,
  entityName: EntityNames,
  attribute: iAttribute,
  attValue?: iAttributeValue,
  isRequired?: boolean,
  placeHolder = 'Click here to ...',
) => {
  const value = `${attValue?.value || ''}`.trim();
  if (
    value === '' &&
    [
      AttributeTypes.CHECKBOX,
      AttributeTypes.FILE_UPLOADER,
      AttributeTypes.BUIlD_SIZE_CHANGER,
      AttributeTypes.ATTRIBUTE_ALIAS,
      AttributeTypes.EMAIL_BTN,
    ].indexOf(attribute?.type as AttributeTypes) < 0
  ) {
    return placeHolder;
  }
  switch (attribute.type) {
    case AttributeTypes.CHECKBOX: {
      return value === 'Y' ? (
        <Icons.CheckboxCheckedIcon label={''} />
      ) : (
        <Icons.CheckboxUncheckedIcon label={''} />
      );
    }
    case AttributeTypes.PRODUCT_DROPDOWN: {
      return (
        <ProductDiv
          productId={value}
          showPicture={attribute.settings?.showSelectedPic === true}
        />
      );
    }
    case AttributeTypes.FILE_UPLOADER: {
      return (
        <AttachmentsPopupBtn
          entityName={EntityNames.Attribute}
          entityId={attribute.id}
          types={[AssetTypes.ATTRIBUTE_VALUES]}
          title={attribute.name}
        />
      );
    }
    case AttributeTypes.SUPPLIER_DROPDOWN: {
      return <ContactCompanyDiv id={value} />;
    }
    case AttributeTypes.USER_DROPDOWN: {
      return <UserDiv id={value} />;
    }
    case AttributeTypes.HOUSE_AREA_DROPDOWN: {
      return <HouseAreaDiv id={value} />;
    }
    case AttributeTypes.INPUT_DATE_PICKER: {
      return moment.utc(value).format('DD/MM/YYYY');
    }
    case AttributeTypes.BUIlD_SIZE_CHANGER: {
      const fieldName =
        AttributeSettingsHelper.getBuildSizeFieldName(attribute);
      if (fieldName === '') {
        return null;
      }
      return (
        <AttributeBuildSizeDiv
          entityName={entityName as EntityNames.Build | EntityNames.BuildStyle}
          entityId={entityId}
          fieldName={fieldName}
          isRequired={isRequired}
          className={'inner-view'}
          placeHolder={'Click here to ...'}
        />
      );
    }
    case AttributeTypes.ATTRIBUTE_ALIAS: {
      return (
        <AttributeAliasValueDiv
          className={'inner-view'}
          entityName={entityName as EntityNames.Build | EntityNames.BuildStyle}
          entityId={entityId}
          targetAttribute={attribute}
        />
      );
    }
    case AttributeTypes.EMAIL_BTN: {
      return (
        <EmailPopupBtn
          showLogs
          buildId={entityId}
          attributeId={attribute.id}
          iconBefore={Icons.SendIcon}
          btnTxt={'Email'}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          modalProps={{ width: '90%', maxWidth: '800px' }}
        />
      );
    }
    default: {
      return value;
    }
  }
};

type iAttributeItemValueMap = {
  [key: string]: { [index: string]: iAttributeValue };
};
type iGetDataForInputPanel = {
  entityId: string;
  entityName: EntityNames;
  attributeSetCode: string;
  providedAttrSet?: iAttributeSet;
  attributeId?: string;
};
const getDataForInputPanel = async ({
  entityId,
  entityName,
  attributeSetCode,
  providedAttrSet,
  attributeId,
}: iGetDataForInputPanel): Promise<{
  attributes: iAttribute[];
  attributeSet: iAttributeSet | null;
  itemsWithValueMap: iPaginatedResult<iAttributeItemWithValueMap>;
}> => {
  const searchingAttrId = `${attributeId || ''}`.trim();
  const perPage = 999999;
  const result = await Promise.all([
    AttributeItemService.getAll({
      where: JSON.stringify({
        isActive: true,
        entityId,
        entityName,
        attributeSetCode,
      }),
      include: 'HouseArea',
      perPage,
    }),
    AttributeValueService.getAllFromEntity(entityName, entityId, {
      where: JSON.stringify({
        isActive: true,
        attributeSetCode,
        ...(searchingAttrId === '' ? {} : { attributeId: searchingAttrId }),
      }),
      include: 'Item.HouseArea',
      perPage,
    }),
    AttributeService.getAll({
      where: JSON.stringify({
        isActive: true,
        attributeSetCode,
        ...(searchingAttrId === '' ? {} : { id: searchingAttrId }),
      }),
      sort: 'sort:ASC',
      perPage,
    }),
    ...(providedAttrSet
      ? []
      : [
          AttributeSetService.getAll({
            where: JSON.stringify({
              code: attributeSetCode,
            }),
          }),
        ]),
  ]);
  const attItems = result[0].data || [];
  const attValuesMap: iAttributeItemValueMap = (result[1].data || []).reduce(
    (map, attValue) => {
      const attrItemId = attValue.itemId;
      const attributeId = attValue.attributeId;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const valueMap = attrItemId in map ? map[attrItemId] : {};
      return {
        ...map,
        [attrItemId]: {
          ...valueMap,
          [attributeId]: attValue,
        },
      };
    },
    {},
  );
  const attributeSets = providedAttrSet
    ? [providedAttrSet]
    : result[3].data || [];
  return {
    attributes: result[2].data || [],
    attributeSet: attributeSets.length > 0 ? attributeSets[0] : null,
    itemsWithValueMap: {
      ...result[0],
      data: attItems.map((data) => {
        return {
          ...data,
          valuesMap: data.id in attValuesMap ? attValuesMap[data.id] : {},
        };
      }),
    },
  };
};

const AttributeInputHelper = {
  getAttributeValueErrors,
  enforceAnAttributeIsRequired,
  checkAttributeIsRequiredByEditLogic,
  getAttrValueFromEditLogic,
  getLimitedOptionValuesFromEditLogic,
  getDataForInputPanel,
  displayValue,
};

export default AttributeInputHelper;
