import iPurchaseOrder from '../../types/purchase/iPurchaseOder';
import ServiceHelper from '../helper/ServiceHelper';

const endPoint = '/purchaseOrder';

const PurchaseOrderService = {
  getAll: ServiceHelper.getPaginatedAll<iPurchaseOrder>(endPoint),
  get: ServiceHelper.getById<iPurchaseOrder>(endPoint),
  create: ServiceHelper.create<iPurchaseOrder>(endPoint),
  update: ServiceHelper.updateById<iPurchaseOrder>(endPoint),
  deactivate: ServiceHelper.deactivateById<iPurchaseOrder>(endPoint),
};

export default PurchaseOrderService;
