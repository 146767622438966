import iEntityStatusType from '../../../types/status/iEntityStatusType';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import {
  TopNavigation,
  Content,
  Main,
  PageLayout,
} from '../../frameWork/PageLayout';
import Navigation from '../../frameWork/Navigation';
import ComponentPropsHelper from '../../frameWork/ComponentPropsHelper';

const Wrapper = styled.div``;
export type iWorkflowEditLayout = {
  entityStatusType: iEntityStatusType;
  rightSideBar: ReactNode;
  children: ReactNode;
  updateBtns: ReactElement;
  errorDisplay: ReactElement;
  createButtons: ReactElement;
  testId?: string;
  className?: string;
};
const WorkflowEditLayout = ({
  entityStatusType,
  updateBtns,
  children,
  errorDisplay,
  rightSideBar,
  createButtons,
  testId,
  className,
}: iWorkflowEditLayout) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'WorkflowEditLayout',
    testId,
    className,
  });
  const getHome = () => {
    return <div>Editing workflow for: {entityStatusType.entityName}</div>;
  };

  return (
    <Wrapper className={classNameStr} data-testid={testIdStr}>
      <PageLayout>
        <TopNavigation>
          <Navigation
            label={`Workflow Editor for: ${entityStatusType.entityName}`}
            primaryItems={[]}
            renderProductHome={getHome}
            renderNotifications={() => errorDisplay}
            renderProfile={() => updateBtns}
            renderCreate={() => createButtons}
          />
        </TopNavigation>
        <Content>
          <Main>{children}</Main>
          {rightSideBar}
        </Content>
      </PageLayout>
    </Wrapper>
  );
};

export default WorkflowEditLayout;
