import ServiceHelper from '../helper/ServiceHelper';
import iItemLocation from '../../types/warehouse/iItemLocation';

const endPoint = '/itemLocation';
const ItemLocationService = {
  getAll: ServiceHelper.getPaginatedAll<iItemLocation>(endPoint),
  get: ServiceHelper.getById<iItemLocation>(endPoint),
  create: ServiceHelper.create<iItemLocation>(endPoint),
  update: ServiceHelper.updateById<iItemLocation>(endPoint),
  deactivate: ServiceHelper.deactivateById<iItemLocation>(endPoint),
};

export default ItemLocationService;
