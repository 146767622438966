import iBuild from '../../../../../types/build/iBuild';
import { AttributeForSections } from '../../../../../types/attribute/iAttribute';
import styled from 'styled-components';
import ComponentPropsHelper, {
  WrapperProps,
} from '../../../../frameWork/ComponentPropsHelper';
import FormField from '../../../../frameWork/FormField';
import Flex from '../../../../frameWork/Flex';
import DynamicTableHelper from '../../../../../helpers/DynamicTableHelper';
import { ViewWrapper } from '../../../../frameWork/InlineEdit';
import Lozenge from '../../../../frameWork/Lozenge';

const Wrapper = styled.div`
  .form-field-wrapper {
    .form-field-wrapper {
      margin-top: 0px;
    }
  }
`;
type iBuildDetailsPermitDAPanel = WrapperProps & {
  build: iBuild;
  attributeSection: AttributeForSections;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};
const BuildDetailsPermitDAPanel = ({
  build,
  testId,
  className,
}: iBuildDetailsPermitDAPanel) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'BuildDetailsPermitDAPanel',
    testId,
    className,
  });

  // const updateBuild = (newValue: iConfigParams) => {
  //   BuildService.update(build.id, newValue)
  //     .then((resp) => {
  //       onBuildUpdated && onBuildUpdated(resp);
  //     })
  //     .catch((err) => {
  //       Toaster.showApiError(err);
  //     })
  //     .finally(() => {
  //       setIsSaving(false);
  //     });
  // };

  return (
    <Wrapper className={classNameStr} data-testid={testIdStr}>
      <Flex className={'gap-05 flex-wrap'}>
        <FormField
          label={'DA Required'}
          render={() => (
            <ViewWrapper className={'cursor-auto'}>
              {DynamicTableHelper.getCheckedIcon(
                build.Estate?.isDARequired === true,
                'DA Required',
                true,
              )}
            </ViewWrapper>
          )}
        />
        <FormField
          label={'DA Status'}
          render={() => (
            <ViewWrapper className={'cursor-auto'}>
              <Lozenge>DA Status</Lozenge>
            </ViewWrapper>
          )}
        />
        <FormField
          label={'DA Approved Date'}
          render={() => (
            <ViewWrapper className={'cursor-auto'}>
              DA Approved Date
            </ViewWrapper>
          )}
        />
      </Flex>
    </Wrapper>
  );
};

export default BuildDetailsPermitDAPanel;
