import ProtectedPage from '../../layouts/ProtectedPage';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import iContactCompany from '../../types/contact/iContactCompany';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import ContactCompanyDetailsPanel from '../../components/contact/ContactCompanyDetailsPanel';
import { URL_CONTACT_COMPANY_LIST } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';
import React, { useState } from 'react';
import { BreadcrumbsItemProps } from '../../components/frameWork/Breadcrumbs';
import Lozenge from '../../components/frameWork/Lozenge';
import MathHelper from '../../helpers/MathHelper';

const ContactCompanyDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const navigate = useNavigate();
  const [version, setVersion] = useState(0);

  const getListBreadCrumbs = (
    contactCompany: iContactCompany,
  ): BreadcrumbsItemProps | null => {
    const type = `${contactCompany.type || ''}`.trim();
    if (type === '') {
      return null;
    }

    return {
      text: `${ContactCompanyService.getHumanReadableType(type)} List`,
      href: URL_CONTACT_COMPANY_LIST.replace(':type', type),
    };
  };

  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iContactCompany>
        forceReload={version}
        emptyStateProps={{
          description: `Couldn't find requested Contact Company with id: ${idStr}.`,
        }}
        getDataFn={() =>
          ContactCompanyService.get(idStr, {
            include: 'CreatedBy,UpdatedBy,Address,PostalAddress,Categories',
          })
        }
      >
        {({ data: contactCompany }) => {
          const listBreadCrumbs = getListBreadCrumbs(contactCompany);
          const breadcrumbItems =
            listBreadCrumbs === null
              ? []
              : [
                  listBreadCrumbs,
                  {
                    text: `${contactCompany.name}`,
                    component: () => (
                      <InlineFlex className={'align-items-center'}>
                        {contactCompany.name}
                      </InlineFlex>
                    ),
                  },
                ];
          return (
            <ContactCompanyDetailsPanel
              contactCompany={contactCompany}
              allowDelete
              onSaved={() => setVersion(MathHelper.add(version, 1))}
              onDeleted={() =>
                navigate(
                  URL_CONTACT_COMPANY_LIST.replace(
                    ':type',
                    contactCompany?.type || '',
                  ),
                )
              }
              headerProps={{
                children: (
                  <>
                    {contactCompany.name}{' '}
                    <Lozenge>{contactCompany.type}</Lozenge>
                  </>
                ),
                breadcrumbItems,
              }}
            />
          );
        }}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default ContactCompanyDetailsPage;
