import iPurchaseOrder from '../../types/purchase/iPurchaseOder';
import styled from 'styled-components';
import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import Flex from '../frameWork/Flex';
import FormField from '../frameWork/FormField';
import { Link } from 'react-router-dom';
import { URL_CONTACT_COMPANY_DETAILS } from '../../helpers/UrlMap';
import InlineEdit, { ViewWrapper } from '../frameWork/InlineEdit';
import { useEffect, useState } from 'react';
import PurchaseOrderService from '../../services/purchase/PurchaseOrderService';
import { iConfigParams } from '../../services/AppService';
import Toaster from '../common/Toaster';
import { DatePicker } from '../frameWork/DateTimePicker';
import moment from 'moment';
import InlineTextArea from '../frameWork/InlineTextArea';

const Wrapper = styled.div`
  .form-field-wrapper {
    min-width: 10rem;
  }
  .notes-wrapper {
    max-width: calc(100% - 2rem);
  }
  form {
    margin-top: 0px;
  }
`;
export type iPurchaseOrderDetailsComponent = WrapperProps & {
  purchaseOrder: iPurchaseOrder;
  testId?: string;
  className?: string;
  isDisabled?: boolean;
  onSaved?: (saved: iPurchaseOrder) => void;
};
const PurchaseOrderDetailsComponent = ({
  purchaseOrder,
  testId,
  className,
  isDisabled,
  onSaved,
}: iPurchaseOrderDetailsComponent) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editing, setEditing] = useState({ ...purchaseOrder });
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'PurchaseOrderDetailsComponent',
    testId,
    className,
  });

  useEffect(() => {
    setEditing(purchaseOrder);
  }, [JSON.stringify(purchaseOrder)]);

  const handleSave = (data: iConfigParams) => {
    if (Object.keys(data).length === 0) return;
    setIsSaving(true);
    PurchaseOrderService.update(purchaseOrder.id, data)
      .then((updated) => {
        setEditing({
          ...editing,
          ...updated,
        });
        onSaved && onSaved(updated);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <Wrapper data-testid={testIdStr} className={classNameStr}>
      <Flex className={'gap-1'}>
        <FormField
          testId={'supplierName'}
          label={'Supplier'}
          render={() => (
            <ViewWrapper className={'cursor-auto'}>
              <Link
                to={URL_CONTACT_COMPANY_DETAILS.replace(
                  ':id',
                  editing.supplierId,
                )}
              >
                {editing.Supplier?.name || ''}
              </Link>
            </ViewWrapper>
          )}
        />
        <FormField
          testId={'email'}
          label={'Email'}
          render={() => (
            <ViewWrapper className={'cursor-auto'}>
              {editing.Supplier?.email || ''}
            </ViewWrapper>
          )}
        />
        <FormField
          testId={'contactNumber'}
          label={'Contact Number'}
          render={() => (
            <ViewWrapper className={'cursor-auto'}>
              {editing.Supplier?.contactNumber || ''}
            </ViewWrapper>
          )}
        />
        <InlineEdit
          testId={'supplierRef'}
          label={'Reference'}
          isDisabled={isDisabled || isSaving}
          value={editing.supplierRef || ''}
          onConfirm={(newValue) => {
            handleSave({ supplierRef: newValue });
          }}
        />
      </Flex>
      <Flex className={'gap-1'}>
        <DatePicker
          testId={'orderDate'}
          appearance={'subtle'}
          value={
            editing.orderDate ? moment(editing.orderDate).toISOString() : ''
          }
          isDisabled={isDisabled || isSaving}
          label={'Order Date'}
          onChange={(date) => {
            handleSave({
              orderDate: moment(date).startOf('day').utc().toISOString(),
            });
          }}
        />
        <DatePicker
          testId={'eta'}
          appearance={'subtle'}
          isDisabled={isDisabled || isSaving}
          value={editing.eta ? moment(editing.eta).toISOString() : ''}
          label={'ETA'}
          onChange={(date) => {
            handleSave({
              eta: moment(date).startOf('day').utc().toISOString(),
            });
          }}
        />
        <div className={'notes-wrapper'}>
          <InlineTextArea
            testId={'notes'}
            label={'Notes'}
            isDisabled={isDisabled || isSaving}
            readViewFitContainerWidth
            value={editing.notes || ''}
            onConfirm={(newValue) => {
              handleSave({ notes: newValue });
            }}
          />
        </div>
      </Flex>
    </Wrapper>
    // <>
    //   <Breadcrumb>
    //     <Link to={URL_PURCHASE_ORDERS}>Purchase Orders</Link> /{' '}
    //     <span>PO: {purchaseOrder.orderNumber}</span>
    //   </Breadcrumb>
    //
    //   <Header>
    //     <span>Purchase Order: {purchaseOrder.orderNumber}</span>
    //     <StatusTag>{purchaseOrder.Status?.name || 'N/A'}</StatusTag>
    //   </Header>
    //
    //   <Row>
    //     <Field>
    //       <Label>Supplier:</Label>
    //       <SupplierText>{purchaseOrder.Supplier?.name}</SupplierText>
    //     </Field>
    //     <Field>
    //       <Label>Email:</Label>
    //       <span>{purchaseOrder.email}</span>
    //     </Field>
    //     <Field>
    //       <Label>Phone:</Label>
    //       <span>{purchaseOrder.phone}</span>
    //     </Field>
    //     <Field>
    //       <Label>Reference:</Label>
    //       <input type="text" value={purchaseOrder.supplierRef || ''} readOnly />
    //     </Field>
    //   </Row>
    //
    //   <Row>
    //     <Field>
    //       <Label>Order Date:</Label>
    //       <DatePicker
    //         selected={orderDate}
    //         onChange={(date) => setOrderDate(date)}
    //         dateFormat="yyyy-MM-dd"
    //         isClearable
    //       />
    //     </Field>
    //     <Field>
    //       <Label>ETA:</Label>
    //       <DatePicker
    //         selected={eta}
    //         onChange={(date) => setEta(date)}
    //         dateFormat="yyyy-MM-dd"
    //         isClearable
    //       />
    //     </Field>
    //     <Field>
    //       <Label>Notes:</Label>
    //       <textarea value={purchaseOrder.notes || ''} readOnly />
    //     </Field>
    //   </Row>
    // </>
  );
};

export default PurchaseOrderDetailsComponent;
