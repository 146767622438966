import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import React, { useEffect, useState } from 'react';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../../common/PopupBtn';
import {
  iEdge,
  iNode,
  useEdges,
  useNodes,
} from '../../frameWork/ReactFlowRenderer';
import WorkflowDiagramHelper, {
  WFDiagramIds,
} from '../diagram/WorkflowDiagramHelper';
import styled from 'styled-components';
import iEntityStatus from '../../../types/status/iEntityStatus';
import WorkflowTransitDisplay from './WorkflowTransitDisplay';
import TextField from '../../frameWork/TextField';
import { getFooterWithBtns } from '../../common/PopupModal';
import Icons from '../../frameWork/Icons';
import Flex from '../../frameWork/Flex';
import { getErrorProps, iErrorMap } from '../../form/FormError';
import Select from '../../frameWork/Select';
import DeleteConfirmPopupBtn from '../../common/DeleteConfirmPopupBtn';
import Button from '../../frameWork/Button';

const Wrapper = styled.div`
  [aria-label='arrow-icon'] {
    margin-top: 30px;
  }
  .transit-name-wrapper {
    > div {
      width: 100%;
    }
  }
`;

type iStatusMap = { [key: string]: iEntityStatus };
type iWorkflowTransitionEditPopBtn = Omit<iPopupBtn, 'titleId'> & {
  state: iWFDiagramState;
  edge?: iEdge;
  onCancel?: () => void;
};
const WorkflowTransitionEditPopBtn = ({
  state,
  edge,
  onCancel,
  ...props
}: iWorkflowTransitionEditPopBtn) => {
  const [isAnyStatusEdge, setIsAnyStatusEdge] = useState(false);
  const [statusMap, setStatusMap] = useState<iStatusMap>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [editingData, setEditingData] = useState<iEdge | null>(
    edge ? { ...edge } : null,
  );
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setStatusMap(
      nodes.reduce((map, node: iNode) => {
        if (!('status' in node.data)) {
          return map;
        }
        return {
          ...map,
          [node.data.status.id]: node.data.status,
        };
      }, {}),
    );
  }, [nodes]);

  useEffect(() => {
    setIsAnyStatusEdge(
      `${edge?.source || ''}`.startsWith(
        WFDiagramIds.NODE_ID_ANY_STATUS_PREFIX,
      ),
    );
  }, [edge]);

  const getStatusOptions = () => {
    const statuses = Object.values(statusMap);
    return statuses.map((status: iEntityStatus) => ({
      label: status.name,
      value: status.id,
      data: status,
    }));
  };

  const resetForm = () => {
    setErrorMap({});
    setEditingData(null);
  };

  const handleCancel = (setShowingModal: iSetShowingModalFn) => {
    setShowingModal(false);
    resetForm();
    onCancel && onCancel();
  };

  const preCheck = () => {
    const errors: iErrorMap = {};
    const { source, target } = editingData || {};
    if (`${source || ''}`.trim() === '') {
      errors.source = 'Please select a "From Status".';
    }
    if (`${target || ''}`.trim() === '') {
      errors.target = 'Please select a "To Status".';
    }
    if (`${source || ''}`.trim() === `${target || ''}`.trim()) {
      errors.target = '"From Status" and "To Status" can NOT be the same.';
    }

    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const saveTransition = (setShowingModal: iSetShowingModalFn) => {
    if (!preCheck()) {
      return;
    }
    const { source, target, label } = editingData || {};
    if (isAnyStatusEdge === true) {
      state.setEdges(
        edges.map((edg) => {
          if (edg.id === edge?.id) {
            return {
              ...edg,
              label,
              selected: false,
            };
          }
          return edg;
        }),
      );
      setShowingModal(false);
      resetForm();
      return;
    }

    if (
      !source ||
      !target ||
      !(source in statusMap) ||
      !(target in statusMap)
    ) {
      return;
    }

    state.setEdges([
      ...edges.filter((edg) => edg.id !== edge?.id),
      WorkflowDiagramHelper.getStatusEdge(
        statusMap[source],
        statusMap[target],
        label ? `${label || ''}`.trim() : undefined,
      ),
    ]);
    setShowingModal(false);
    resetForm();
  };

  const getSelectedValue = (fieldName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...edge, ...editingData };
    if (!(fieldName in data)) {
      return undefined;
    }
    const id = data[fieldName];
    return id in statusMap
      ? { label: statusMap[id].name, value: id }
      : undefined;
  };

  const getTransitEditForm = () => {
    if (edge && isAnyStatusEdge === true) {
      return <WorkflowTransitDisplay edge={edge} />;
    }
    return (
      <Flex className={'transit-name-wrapper'}>
        <Select
          options={getStatusOptions()}
          label={'From Status'}
          value={getSelectedValue('source')}
          {...getErrorProps({ error: errorMap, fieldName: 'source' })}
          onChange={(selected) => {
            setEditingData({
              ...editingData,
              source: `${selected?.value || ''}`,
            } as iEdge);
          }}
        />
        <Icons.ArrowRightIcon label={'arrow-icon'} />
        <Select
          value={getSelectedValue('target')}
          {...getErrorProps({ error: errorMap, fieldName: 'target' })}
          options={getStatusOptions()}
          label={'To Status'}
          onChange={(selected) => {
            setEditingData({
              ...editingData,
              target: `${selected?.value || ''}`,
            } as iEdge);
          }}
        />
      </Flex>
    );
  };

  const getModalBoy = () => {
    return (
      <Wrapper>
        <p>
          Transitions connect statuses. They represent actions people take to
          move statuses through your workflow.
        </p>
        {getTransitEditForm()}
        <TextField
          label={'Transition Name:'}
          value={
            editingData ? `${editingData?.label || ''}` : `${edge?.label || ''}`
          }
          onChange={(e) => {
            setEditingData({
              ...editingData,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              label: `${e.target.value || ''}`,
            } as iEdge);
          }}
        />
      </Wrapper>
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={`transition-popup-btn-${edge?.id || ''}`}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: edge
          ? `Editing transition: ${edge.label || ''}`
          : 'Creating a transition',
        onClose: () => {
          handleCancel(setShowingModal);
          props.onClose && props.onClose(setShowingModal);
        },
        body: getModalBoy(),
        footer: (
          <Flex className={'justify-content-between full-width'}>
            {edge ? (
              <DeleteConfirmPopupBtn
                titleId={`delete-edge-${edge?.id || ''}`}
                deleteFnc={async () => {
                  state.setEdges(edges.filter((edg) => edg.id !== edge?.id));
                  if (isAnyStatusEdge === true) {
                    state.setNodes(
                      nodes.filter((node) => node.id !== edge?.source),
                    );
                  }
                }}
                onDeleted={() => {
                  setShowingModal(false);
                  resetForm();
                }}
                renderBtn={(onClick) => {
                  return (
                    <Button
                      onClick={onClick}
                      appearance={'warning'}
                      iconBefore={Icons.TrashIcon}
                    >
                      Delete
                    </Button>
                  );
                }}
              />
            ) : (
              <div />
            )}
            <div>
              {getFooterWithBtns({
                actionBtnProps: {
                  btnText: edge ? 'Update' : 'Create',
                  iconBefore: Icons.CheckIcon,
                  onClick: () => saveTransition(setShowingModal),
                },
                cancelBtnProps: {
                  btnText: 'Cancel',
                  iconBefore: Icons.CrossIcon,
                  onClick: () => handleCancel(setShowingModal),
                },
              })}
            </div>
          </Flex>
        ),
      })}
    />
  );
};

export default WorkflowTransitionEditPopBtn;
