import iBaseType from '../iBaseType';

export enum EntityStatusCategoryCodes {
  NEW = 'STATUS_CATEGORY_NEW',
  IN_PROGRESS = 'STATUS_CATEGORY_IN_PROGRESS',
  FINISHED = 'STATUS_CATEGORY_FINISHED',
}

type iEntityStatusCategory = iBaseType & {
  name: string;
  code: string;
};

export default iEntityStatusCategory;
