import iAppointment from '../../types/build/iAppointment';
import ServiceHelper from '../helper/ServiceHelper';

const endPoint = '/appointment';
const AppointmentService = {
  getAll: ServiceHelper.getPaginatedAll<iAppointment>(endPoint),
  get: ServiceHelper.getById<iAppointment>(endPoint),
  create: ServiceHelper.create<iAppointment>(endPoint),
  update: ServiceHelper.updateById<iAppointment>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAppointment>(endPoint),
};

export default AppointmentService;
