import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsColoursOverview from './BuildDetailsColoursComponents/BuildDetailsColoursOverview';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import EntityNames from '../../../../helpers/EntityNames';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';
import BuildDetailsColoursOverviewTopNav from './BuildDetailsColoursComponents/BuildDetailsColoursOverviewTopNav';
import BuildChangeItemList from '../../BuildChangeItemList';
import BuildColourNoteList from '../../../buildColourNote/BuildColourNoteList';
import BuildVariationList from '../../../buildVariation/BuildVariationList';

export enum BuildDetailsColoursKeys {
  OVERVIEW = 'Summary',
  CABINETS = 'Internal - Cabinets',
  INTERNAL_WALL_FLOOR = 'Internal - Wall & Floor',
  INTERNAL_OTHER = 'Internal - Other',
  EXTERNAL = 'External',
  PHOTOS = 'Photos',
  NOTES = 'Notes',
  CHANGES = 'Changes',
  VARIATIONS = 'Variations',
}

const getKey = (
  key: string,
  build: iBuild,
  attributeSection: AttributeForSections,
) => `${attributeSection}-${build.id}-${key}`;

type iGetBuildDetailsColoursMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
  attributeSection: AttributeForSections;
};

const getMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
  attributeSection,
}: iGetBuildDetailsColoursMenuItemsMap): iMenuItemsMap => {
  const commonProps = {
    attributeSection,
    isDisabled,
    entityId: build.id,
    entityName: EntityNames.Build,
  };
  return {
    [BuildDetailsColoursKeys.OVERVIEW]: {
      name: BuildDetailsColoursKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsColoursKeys.OVERVIEW} />,
      component: (
        <BuildDetailsColoursOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
      topNav: (
        <BuildDetailsColoursOverviewTopNav
          build={build}
          onBuildUpdated={onBuildUpdated}
        />
      ),
    },
    [BuildDetailsColoursKeys.CABINETS]: {
      name: BuildDetailsColoursKeys.CABINETS,
      icon: <Icons.QueuesIcon label={BuildDetailsColoursKeys.CABINETS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.Cabinet,
            AttributeSetCodes.CabinetKitchen,
            AttributeSetCodes.Vanity,
            AttributeSetCodes.Pantry,
            AttributeSetCodes.CabinetPantry,
          ]}
          key={getKey(
            BuildDetailsColoursKeys.CABINETS,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR]: {
      name: BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR,
      icon: (
        <Icons.AppSwitcherIcon
          label={BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR}
        />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.Flooring,
            AttributeSetCodes.WallTiles,
            AttributeSetCodes.TilingGeneral,
            AttributeSetCodes.Carpet,
            AttributeSetCodes.BorderTiles,
            AttributeSetCodes.Niche,
          ]}
          key={getKey(
            BuildDetailsColoursKeys.INTERNAL_WALL_FLOOR,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.INTERNAL_OTHER]: {
      name: BuildDetailsColoursKeys.INTERNAL_OTHER,
      icon: (
        <Icons.MediaServicesFilterIcon
          label={BuildDetailsColoursKeys.INTERNAL_OTHER}
        />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.ElectricalExtra,
            AttributeSetCodes.DoorsGeneral,
            AttributeSetCodes.BlindsGeneral,
            AttributeSetCodes.ShowerScreen,
            AttributeSetCodes.SplashBack,
            AttributeSetCodes.PaintingInternal,
          ]}
          key={getKey(
            BuildDetailsColoursKeys.INTERNAL_OTHER,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.EXTERNAL]: {
      name: BuildDetailsColoursKeys.EXTERNAL,
      icon: (
        <Icons.BitbucketReposIcon label={BuildDetailsColoursKeys.EXTERNAL} />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.Bricks,
            AttributeSetCodes.WindowGeneral,
            AttributeSetCodes.Roof,
            AttributeSetCodes.RenderLocation,
            AttributeSetCodes.RenderGeneral,
            AttributeSetCodes.WaterTank,
            AttributeSetCodes.ContractBricks,
            AttributeSetCodes.WindowInFills,
            AttributeSetCodes.GarageDoor,
            AttributeSetCodes.FrontDoor,
            AttributeSetCodes.BackDoor,
            AttributeSetCodes.FasciaAndGutter,
            AttributeSetCodes.PaintingExternal,
            AttributeSetCodes.PaintingExtra,
            AttributeSetCodes.ConcreteGeneral,
            AttributeSetCodes.Window,
          ]}
          key={getKey(
            BuildDetailsColoursKeys.EXTERNAL,
            build,
            attributeSection,
          )}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.PHOTOS]: {
      name: BuildDetailsColoursKeys.PHOTOS,
      icon: <Icons.ImageIcon label={BuildDetailsColoursKeys.PHOTOS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[AttributeSetCodes.ColorPhotos]}
          key={getKey(BuildDetailsColoursKeys.PHOTOS, build, attributeSection)}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsColoursKeys.NOTES]: {
      name: BuildDetailsColoursKeys.NOTES,
      icon: (
        <Icons.MediaServicesAnnotateIcon
          label={BuildDetailsColoursKeys.NOTES}
        />
      ),
      component: (
        <BuildColourNoteList
          perPage={99999999}
          build={build}
          allowEdit
          allowCreate
          allowDelete
          headerProps={{
            children: <h6>Notes</h6>,
          }}
        />
      ),
    },
    [BuildDetailsColoursKeys.CHANGES]: {
      name: BuildDetailsColoursKeys.CHANGES,
      icon: (
        <Icons.EditorNumberListIcon label={BuildDetailsColoursKeys.CHANGES} />
      ),
      component: <BuildChangeItemList buildId={build.id} allowEdit />,
    },
    [BuildDetailsColoursKeys.VARIATIONS]: {
      name: BuildDetailsColoursKeys.VARIATIONS,
      icon: (
        <Icons.BitbucketPullrequestsIcon
          label={BuildDetailsColoursKeys.VARIATIONS}
        />
      ),
      component: (
        <BuildVariationList
          buildId={build.id}
          allowEdit
          allowDelete
          allowCreate
          headerProps={{
            children: <h6>Variations</h6>,
          }}
        />
      ),
    },
  };
};

const BuildDetailsColoursHelper = {
  getMenuItemsMap,
};
export default BuildDetailsColoursHelper;
