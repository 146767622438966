import ProductTypeSelector from '../productType/ProductTypeSelector';
import { iOptionWithData } from '../frameWork/Select';
import iProductType from '../../types/product/iProductType';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iProduct from '../../types/product/iProduct';
import { getErrorProps, iErrorMap } from '../form/FormError';
import TextField from '../frameWork/TextField';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import ProductService from '../../services/product/ProductService';
import Toaster from '../common/Toaster';

type iProductCreatePopupBtn = Omit<iPopupBtn, 'titleId' | 'modalProps'> & {
  onSaved: (saved: iProduct) => void;
  productTypeId?: string;
  isDisabled?: boolean;
};
const ProductCreatePopupBtn = ({
  onSaved,
  productTypeId,
  isDisabled,
  ...props
}: iProductCreatePopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setShowingModal: iSetShowingModalFn) => {
    setEditingData({});
    setErrorMap({});
    setIsSaving(false);
    setShowingModal(false);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    if (`${editingData.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    if (`${editingData.productCode || ''}`.trim() === '') {
      errors.productCode = ['Product Code is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    setIsSaving(true);
    ProductService.create({
      ...editingData,
      ...(`${productTypeId || ''}`.trim() === ''
        ? {}
        : { typeId: `${productTypeId || ''}`.trim() }),
    })
      .then((resp) => {
        handleClose(setShowingModal);
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err.message);
      });
  };

  return (
    <PopupBtn
      titleId={'Product-create-popup'}
      testId={'Product-create-popup-btn'}
      shouldFitContainer
      {...props}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: 'Create New Product',
        onClose: () => handleClose(setShowingModal),
        body: (
          <>
            <ProductTypeSelector
              label={'Product Type'}
              isDisabled={isDisabled || `${productTypeId || ''}`.trim() !== ''}
              value={editingData.typeId || productTypeId}
              onChange={(selected: iOptionWithData<iProductType>) => {
                setEditingData({
                  ...editingData,
                  typeId: selected?.value,
                });
              }}
            />
            {[
              {
                fieldName: 'productCode',
                label: 'Product Code',
                isRequired: true,
                isDisabled,
                value: editingData.productCode || '',
                testId: 'productCode',
              },
              {
                fieldName: 'name',
                label: 'Name',
                isDisabled,
                isRequired: true,
                value: editingData?.name || '',
                testId: 'name',
              },
            ].map(({ fieldName, ...item }) => {
              return (
                <TextField
                  {...item}
                  {...getErrorProps({
                    error: errorMap,
                    fieldName: fieldName,
                  })}
                  key={fieldName}
                  onChange={(e) => {
                    setEditingData({
                      ...editingData,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      [fieldName]: e.target.value || '',
                    });
                  }}
                />
              );
            })}
          </>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Create',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default ProductCreatePopupBtn;
