import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iEmailTemplate from '../../types/email/iEmailTemplate';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import EmailTemplateService from '../../services/email/EmailTemplateService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { Link, useNavigate } from 'react-router-dom';
import { URL_SETTINGS_EMAIL_TEMPLATE_DETAILS } from '../../helpers/UrlMap';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import Flex from '../frameWork/Flex';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';

export type iEmailTemplateList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
};
const EmailTemplateList = ({
  headerProps,
  allowCreate,
  allowDelete,
}: iEmailTemplateList) => {
  const testIdStr = 'email-template-list';
  const navigate = useNavigate();
  const {
    renderDataTable,
    renderEntityEditPopBtn,
    renderDeleteBtn,
    onSetFilter,
  } = useListCrudHook<iEmailTemplate>({
    sort: `name:ASC`,
    getFn: (params) =>
      EmailTemplateService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getCreateBtn = () => {
    if (allowCreate !== true) {
      return <></>;
    }
    return renderEntityEditPopBtn<iEmailTemplate>({
      entityName: 'Email Template',
      onSaved: (saved) => {
        navigate(URL_SETTINGS_EMAIL_TEMPLATE_DETAILS.replace(':id', saved.id));
      },
      createFn: async (data) => {
        return EmailTemplateService.create(data);
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // it shouldn't even call updateFn.
      updateFn: async () => null,
      getFormFields: ({ entity, isDisabled }) => {
        return [
          {
            fieldName: 'name',
            label: 'Name',
            isDisabled,
            isRequired: true,
            value: entity?.name || '',
            testId: 'name',
          },
          {
            fieldName: 'description',
            label: 'Description',
            isDisabled,
            value: entity?.description || '',
            testId: 'description',
          },
        ];
      },
    });
  };

  const getColumns = (): iTableColumn<iEmailTemplate>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      isSortable: true,
      cell: ({ data }) => (
        <Link to={URL_SETTINGS_EMAIL_TEMPLATE_DETAILS.replace(':id', data.id)}>
          {data.name || ''}
        </Link>
      ),
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => data.description || '',
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iEmailTemplate>(
      true,
      true,
    ),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            isSelectable: false,
            cell: ({ data }: iCellParams<iEmailTemplate>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    getDisplayName: (data) => `Email Template: ${data.name}`,
                    deleteFnc: () => EmailTemplateService.deactivate(data.id),
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  const getActions = () => {
    if (headerProps?.actions) {
      return headerProps?.actions;
    }
    return (
      <Flex className={'gap-1 align-items-center'}>
        <SearchTextField
          testId={`${testIdStr}-search-field`}
          placeholder={'Search name ...'}
          onSearch={(searchText) => {
            const searchTxt = `${searchText || ''}`.trim();
            onSetFilter({
              ...(searchTxt === ''
                ? {}
                : {
                    name: { [OP_LIKE]: `%${searchTxt}%` },
                  }),
            });
          }}
        />
      </Flex>
    );
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getCreateBtn()}
            title={headerProps?.children}
          />
        ),
        actions: getActions(),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.EMAIL_TEMPLATE_LIST,
        tblProps: {
          testId: 'email-template-tbl',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default EmailTemplateList;
