import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import DeveloperGuideCategoryService from '../../services/developerGuideCategory/DeveloperGuideCategoryService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import Toaster from '../common/Toaster';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';
import Flex from '../frameWork/Flex';
import { Link } from 'react-router-dom';
import { URL_SETTINGS_DEVELOPER_GUIDE_CATEGORY_DETAILS } from '../../helpers/UrlMap';

export type iDeveloperGuideCategoryList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowEdit?: boolean;
  isRankable?: boolean;
  forceReload?: number;
  testId?: string;
};
const DeveloperGuideCategoryList = ({
  headerProps,
  allowDelete,
  allowEdit = true,
  isRankable = true,
  forceReload = 0,
  testId,
}: iDeveloperGuideCategoryList) => {
  const componentName = 'DeveloperGuideCategoryList';
  const testIdStr = `${testId || ''}-${componentName}`;
  const {
    state,
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetIsLoading,
    onRefresh,
    onSetFilter,
    viewingState,
  } = useListCrudHook<iDeveloperGuideCategory>({
    sort: `sort:ASC`,
    forceReload,
    ...(isRankable === true ? { perPage: 999999999 } : {}),
    getFn: (params) =>
      DeveloperGuideCategoryService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (developerGuideCategory?: iDeveloperGuideCategory) => {
    if (!allowEdit) return null;
    return renderEntityEditPopBtn<iDeveloperGuideCategory>({
      editingEntity: developerGuideCategory,
      entityName: 'Developer Guide Category',
      createFn: (data) => DeveloperGuideCategoryService.create(data),
      updateFn: (id, data) => DeveloperGuideCategoryService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`Developerguidecategory-${entity?.id || ''}`}
        >
          {entity.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'DeveloperGuideCategory-name',
        },
        {
          fieldName: 'code',
          label: 'Code',
          isDisabled,
          isRequired: true,
          value: entity?.code || '',
          testId: 'DeveloperGuideCategory-code',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'DeveloperGuideCategory-description',
        },
      ],
    });
  };

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    onSetIsLoading(true);
    const dgc = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = dgc.splice(sourceIndex, 1);
    // Insert it at the destination position
    dgc.splice(targetIndex, 0, movedItem);
    Promise.all(
      dgc.map((dgc, index) => {
        return DeveloperGuideCategoryService.update(dgc.id, { sort: index });
      }),
    )
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        onRefresh();
      });
  };

  const getColumns = (): iTableColumn<iDeveloperGuideCategory>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iDeveloperGuideCategory>) => {
        return (
          <Link
            to={URL_SETTINGS_DEVELOPER_GUIDE_CATEGORY_DETAILS.replace(
              ':id',
              data.id,
            )}
          >
            {data.name}
          </Link>
        );
      },
    },
    {
      key: 'code',
      header: 'Code',
      isDefault: true,
      isSelectable: false,
      // cell: ({ data }: iCellParams<iDeveloperGuideCategory>) => {
      //   return getEditBtn(data);
      // },
      cell: ({ data }: iCellParams<iDeveloperGuideCategory>) =>
        `${data.code || ''}`,
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iDeveloperGuideCategory>) =>
        `${data.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iDeveloperGuideCategory>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iDeveloperGuideCategory>) => {
              return (
                <Flex className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      DeveloperGuideCategoryService.deactivate(data.id),
                    getDisplayName: (developerGuideCategory) =>
                      developerGuideCategory.name,
                  })}
                </Flex>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter({
                ...viewingState.filter,
                ...(searchTxt === ''
                  ? {}
                  : {
                      name: { [OP_LIKE]: `%${searchTxt}%` },
                    }),
              });
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
          isRankable: allowEdit === true && isRankable === true,
          onRankEnd: (params) =>
            submitReorder(params.sourceIndex, params.destination?.index || 0),
        },
      })}
    </ComponentWithPageHeader>
  );
};
export default DeveloperGuideCategoryList;
