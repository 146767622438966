import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import iPlanRevision from '../../types/plan/iPlanRevision';
import PlanRevisionService from '../../services/plan/PlanRevisionService';

export type iPlanRevisionSelector = iPreloadAsyncSelectProps;
const PlanRevisionSelector = (props: iPlanRevisionSelector) => {
  return (
    <PreloadedAsyncSelector<iPlanRevision>
      placeholder={'Select a Plan Revision ...'}
      {...props}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return PlanRevisionService.getAll({
          ...params,
          sort: 'sort:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        PlanRevisionService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default PlanRevisionSelector;
