import ServiceHelper from '../helper/ServiceHelper';
import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';
import AppService from '../AppService';
import * as _ from 'lodash';
import { iParams } from '../AppService';

const endPoint = '/warehouseLocation';

const getProductsUnderWarehouse = (path: string) => {
  return AppService.get('/product', {
    include: 'warehouseLocations,measurement',
    like: `warehouseLocations.path:${path}`,
  }).then(({ data }) => data);
};

// eslint-disable-next-line
const moveWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation/move', postData).then(
    ({ data }) => data,
  );
};

const getWarehouseLocationPathNames = (ids: string) => {
  return AppService.get(`${endPoint}/names`, {
    ids,
  }).then(({ data }) => data);
};

const isWIPWarehouseLocation = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}/isWIP`).then(
    ({ data }) => data,
  );
};

const getHumanReadableType = (warehouseLocation: string) => {
  return warehouseLocation
    .split('_')
    .map((name) => _.capitalize(name))
    .join(' ');
};

const getWarehouseListAsyncSearch = (config?: iParams) => {
  return AppService.get('/warehouseLocation', {
    ...config,
    currentPage: 10,
    perPage: 1,
  }).then(({ data }) => data);
};

const WarehouseLocationService = {
  getAll: ServiceHelper.getPaginatedAll<iWarehouseLocation>(endPoint),
  get: ServiceHelper.getById<iWarehouseLocation>(endPoint),
  create: ServiceHelper.create<iWarehouseLocation>(endPoint),
  update: ServiceHelper.updateById<iWarehouseLocation>(endPoint),
  deactivate: ServiceHelper.deactivateById<iWarehouseLocation>(endPoint),
  getProductsUnderWarehouse,
  getWarehouseLocationPathNames,
  moveWarehouse,
  isWIPWarehouseLocation,
  getHumanReadableType,
  getWarehouseListAsyncSearch,
};

export default WarehouseLocationService;
