import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import ComponentPropsHelper, { WrapperProps } from './ComponentPropsHelper';
import useListCrudHook, {
  iGetFn,
  iUseListCrudHook,
} from '../hooks/useListCrudHook/useListCrudHook';
import iPaginatedResult from '../../types/iPaginatedResult';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React, { ReactElement, ReactNode } from 'react';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { iDynamicTable } from './DynamicTable';
import iBaseType from '../../types/iBaseType';
import { SelectiveColKeys } from '../../services/LocalStorageService';

type ListComponentProps<T extends iBaseType> = iComponentWithPageHeader &
  WrapperProps & {
    perPage?: number;
    selectiveColumnKey?: SelectiveColKeys;
    allowDelete?: boolean;
    allowEdit?: boolean;
    allowCreate?: boolean;
    getFn: (props?: iGetFn) => Promise<iPaginatedResult<T>>;
    deactivateFn?: (data: T) => Promise<T>;
    getDisplayName?: (data: T) => string;
    getActions?: (props: ReturnType<typeof useListCrudHook<T>>) => ReactElement;
    getBottomBar?: (
      props: ReturnType<typeof useListCrudHook<T>>,
    ) => ReactElement;
    getColumns: (
      params: ReturnType<typeof useListCrudHook<T>>,
    ) => iTableColumn<T>[];
    componentName: string;
    tblProps?: iDynamicTable;
    renderCreateBtn?: (
      params: ReturnType<typeof useListCrudHook<T>>,
    ) => ReactNode;
    getFnExtra?: Omit<iUseListCrudHook<T>, 'getFn' | 'perPage'>;
  };
export type BaseListComponentProps<T extends iBaseType> = Omit<
  ListComponentProps<T>,
  'allowDelete' | 'deactivateFn'
>;
export type ListComponentDeactivation<T extends iBaseType> =
  | {
      allowDelete?: false;
      deactivateFn?: (data: T) => Promise<T>;
    }
  | {
      allowDelete: true;
      deactivateFn: (data: T) => Promise<T>;
    };
export type iListComponent<T extends iBaseType> = BaseListComponentProps<T> &
  ListComponentDeactivation<T>;

const ListComponent = <T extends iBaseType>({
  headerProps,
  getActions,
  getBottomBar,
  selectiveColumnKey,
  allowCreate = false,
  allowDelete = false,
  getColumns,
  componentName,
  tblProps,
  getFn,
  deactivateFn,
  getDisplayName,
  renderCreateBtn,
  testId,
  className,
  perPage,
  getFnExtra,
}: iListComponent<T>) => {
  const { testIdStr, classNameStr } = ComponentPropsHelper.getWrapperProps({
    componentName: componentName,
    testId,
    className,
  });
  const hookProps = useListCrudHook<T>({
    getFn: getFn,
    perPage,
    ...getFnExtra,
  });

  const getTableColumns = (): iTableColumn<T>[] => {
    return [
      ...getColumns(hookProps),
      ...DynamicTableHelper.getCreatedAndUpdatedColumns<T>(),
      ...(allowDelete !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              isDefault: true,
              cell: ({ data }: iCellParams<T>) => {
                return (
                  <div className={'text-right'}>
                    {hookProps.renderDeleteBtn({
                      deletingModel: data,
                      deleteFnc: async () => deactivateFn && deactivateFn(data),
                      getDisplayName,
                    })}
                  </div>
                );
              },
            },
          ]),
    ];
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        actions: getActions && getActions(hookProps),
        bottomBar: getBottomBar && getBottomBar(hookProps),
        children:
          allowCreate === true ? (
            <PageTitleWithCreateBtn
              createBtn={renderCreateBtn && renderCreateBtn(hookProps)}
              title={headerProps?.children}
            />
          ) : (
            headerProps?.children
          ),
      }}
    >
      {hookProps.renderDataTable({
        columns: getTableColumns(),
        selectiveColumnKey,
        tblProps: {
          testId: testIdStr,
          className: classNameStr,
          ...tblProps,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default ListComponent;
