import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';

export type iContactCompanySelector = iPreloadAsyncSelectProps & {
  contactCompanyType: ContactCompanyTypes;
};
const ContactCompanySelector = ({
  contactCompanyType,
  ...props
}: iContactCompanySelector) => {
  return (
    <PreloadedAsyncSelector<iContactCompany>
      {...props}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return ContactCompanyService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            type: contactCompanyType,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        ContactCompanyService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default ContactCompanySelector;
