import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import ContactCompanyCategoryService from '../../services/contact/ContactCompanyCategoryService';
import iContactCompanyCategory from '../../types/contact/iContactCompanyCategory';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE } from '../../services/ServiceHelper';
import { ContactCompanyTypes } from '../../types/contact/iContactCompany';

type iContactCompanyCategoryList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
  testId?: string;
  contactCompanyType: ContactCompanyTypes;
};

const ContactCompanyCategoryList = ({
  allowDelete,
  allowCreate,
  headerProps,
  contactCompanyType,
  testId,
}: iContactCompanyCategoryList) => {
  const componentName = 'ContactCompanyCategoryList';
  const testIdStr = `${componentName}-${testId || ''}`;
  const {
    renderDataTable,
    renderDeleteBtn,
    onSetFilter,
    renderEntityEditPopBtn,
  } = useListCrudHook<iContactCompanyCategory>({
    sort: `name:ASC`,
    getFn: (params) =>
      ContactCompanyCategoryService.getAll({
        where: JSON.stringify({
          isActive: true,
          type: contactCompanyType,
          ...(params?.filter || {}),
        }),
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (category?: iContactCompanyCategory) => {
    return renderEntityEditPopBtn<iContactCompanyCategory>({
      editingEntity: category,
      entityName: 'Contact Company Category',
      createFn: (data) =>
        ContactCompanyCategoryService.create({
          ...data,
          type: contactCompanyType,
        }),
      updateFn: (id, data) => ContactCompanyCategoryService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'category-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'category-description',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iContactCompanyCategory>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSortable: true,
      cell: ({ data }) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => {
        return data.description || '';
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iContactCompanyCategory>(
      true,
      true,
    ),
    ...(allowDelete === true
      ? [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iContactCompanyCategory>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    getDisplayName: () => `${data.name}`,
                    deleteFnc: () =>
                      ContactCompanyCategoryService.deactivate(data.id),
                  })}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            title={headerProps?.children}
            createBtn={allowCreate === true ? getEditBtn() : <></>}
          />
        ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            placeholder={'Search name...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(
                searchTxt === ''
                  ? {}
                  : {
                      name: { [OP_LIKE]: `%${searchText}%` },
                    },
              );
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: componentName,
        },
        showPageSizer: true,
      })}
    </ComponentWithPageHeader>
  );
};

export default ContactCompanyCategoryList;
