import React, { useEffect, useState } from 'react';
import WorkflowTransitDisplay from './WorkflowTransitDisplay';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import {
  iEdge,
  iNode,
  useEdges,
  useNodes,
} from '../../frameWork/ReactFlowRenderer';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../../common/PopupBtn';
import { getFooterWithBtns } from '../../common/PopupModal';

type iWorkflowDeleteStatusPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  deletingNode: iNode;
  state: iWFDiagramState;
};
const WorkflowDeleteStatusPopupBtn = ({
  deletingNode,
  state,
  ...props
}: iWorkflowDeleteStatusPopupBtn) => {
  const [relatedEdges, setRelatedEdges] = useState<iEdge[]>([]);
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setRelatedEdges(
      edges.filter(
        (edge: iEdge) =>
          edge.source === deletingNode.id || edge.target === deletingNode.id,
      ),
    );
  }, [edges, deletingNode.id]);

  const deleteNode = (setShowingModal: iSetShowingModalFn) => {
    const relatedEdgeIds = relatedEdges.map((edge) => edge.id);
    state.setNodes(
      nodes.filter(
        (node: iNode) =>
          node.id !== deletingNode.id && node.parentNode !== deletingNode.id,
      ),
    );
    state.setEdges(edges.filter((edge) => relatedEdgeIds.indexOf(edge.id) < 0));
    setShowingModal(false);
  };

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getAllRelatedEdges = () => {
    if (relatedEdges.length <= 0) {
      return null;
    }
    return (
      <div>
        and all its transitions:
        {relatedEdges.map((edge) => (
          <WorkflowTransitDisplay edge={edge} key={edge.id} />
        ))}
      </div>
    );
  };

  const getModalBody = () => {
    return (
      <div>
        <p>
          Are you sure to delete status <b>{deletingNode.data.label}</b>?
        </p>
        {getAllRelatedEdges()}
      </div>
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={`node-popup-btn-${deletingNode.id || ''}`}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: 'Are you sure?',
        body: getModalBody(),
        footer: getFooterWithBtns({
          actionBtnProps: {
            btnText: 'Delete',
            onClick: () => deleteNode(setShowingModal),
          },
          cancelBtnProps: {
            onClick: () => setShowingModal(false),
          },
        }),
      })}
    />
  );
};

export default WorkflowDeleteStatusPopupBtn;
