import iDeveloperGuide from '../../types/developerGuide/iDeveloperGuide';
import { iUseAsForm } from '../form/EntityEditPanel';
import ComponentWrapper, {
  iComponentWrapper,
} from '../frameWork/ComponentWrapper';
import { useEffect, useState } from 'react';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import AttributeService from '../../services/attribute/AttributeService';
import Toaster from '../common/Toaster';
import Flex from '../frameWork/Flex';
import TextField from '../frameWork/TextField';
import {
  iDropdownRule,
  iInputNumberRule,
  iValidationRule,
} from '../../helpers/ValidationRuleHelper';
import Select, { convertSameValueToOption } from '../frameWork/Select';
import AttributeSettingsHelper from '../attribute/components/AttributeSettingsHelper';
import { getErrorProps } from '../form/FormError';

type iDeveloperGuideRuleEditPanel = Omit<
  iComponentWrapper,
  'componentName' | 'children'
> & {
  developerGuide?: iDeveloperGuide;
  useAsForm?: iUseAsForm;
  attributeId?: string;
};
const DeveloperGuideRuleEditPanel = ({
  attributeId,
  className,
  developerGuide,
  testId,
  useAsForm,
}: iDeveloperGuideRuleEditPanel) => {
  const componentName = 'DeveloperGuideRuleEditPanel';
  const [, setIsLoading] = useState(false);
  const [attribute, setAttribute] = useState<iAttribute | null>(null);

  useEffect(() => {
    const dgAttributeId = `${developerGuide?.attributeId || ''}`.trim();
    const attrId = dgAttributeId === '' ? attributeId || '' : dgAttributeId;

    let isCancelled = false;
    setIsLoading(true);
    AttributeService.get(attrId)
      .then((attr) => {
        if (!isCancelled) {
          setAttribute(attr);
        }
      })
      .catch((err) => {
        if (!isCancelled) {
          Toaster.showApiError(err);
        }
      })
      .finally(() => {
        if (!isCancelled) {
          setIsLoading(false);
        }
      });

    return () => {
      isCancelled = true;
    };
  }, [developerGuide, attributeId]);

  const getEditPanel = () => {
    if (!attribute) {
      return null;
    }
    const rules: iValidationRule = developerGuide?.validationRules || {};
    switch (attribute.type) {
      case AttributeTypes.INPUT_NUMBER: {
        const rule: iInputNumberRule = rules[attribute.type] || {};
        return (
          <Flex className={'gap-025'}>
            {[
              {
                label: 'Min',
                value: rule.min,
                fieldName: 'min',
                placeHolder: 'Minimum Value',
              },
              {
                label: 'Max',
                value: rule.max,
                fieldName: 'max',
                placeHolder: 'Maximum Value',
              },
            ].map((item) => {
              return (
                <TextField
                  testId={item.fieldName}
                  key={item.fieldName}
                  label={item.label}
                  value={item.value}
                  placeholder={item.placeHolder}
                  {...getErrorProps({
                    error: useAsForm?.errorMap,
                    fieldName: `rules_${attribute.type}_${item.fieldName}`,
                  })}
                  onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    const newValue = `${event.target.value || ''}`.trim();
                    useAsForm &&
                      useAsForm.onFieldChange('validationRules', {
                        [attribute?.type]: {
                          ...rule,
                          [item.fieldName]:
                            newValue === '' ? undefined : newValue,
                        },
                      });
                  }}
                />
              );
            })}
          </Flex>
        );
      }
      case AttributeTypes.DROPDOWN: {
        const rule: iDropdownRule = rules[attribute.type] || {};
        const options = AttributeSettingsHelper.getDropdownOptions(attribute);
        return (
          <div>
            {[
              {
                label: 'Only allowed options',
                fieldName: 'onlyAllowOptions',
                helperMsg: 'Only above options will be allowed',
                values: rule.onlyAllowOptions || [],
              },
              {
                label: 'Forbid options',
                fieldName: 'forbidOptions',
                values: rule.forbidOptions || [],
                helperMsg:
                  'All other options will be allowed expected above options',
              },
            ].map((item) => {
              return (
                <Select
                  isClearable
                  isMulti
                  testId={item.fieldName}
                  key={item.fieldName}
                  label={item.label}
                  {...getErrorProps({
                    error: useAsForm?.errorMap,
                    fieldName: `rules_${attribute.type}_${item.fieldName}`,
                  })}
                  options={options.map((option) =>
                    convertSameValueToOption(option),
                  )}
                  value={item.values.map((option) =>
                    convertSameValueToOption(option),
                  )}
                  onChange={(selected) => {
                    useAsForm &&
                      useAsForm.onFieldChange('validationRules', {
                        [attribute?.type]: {
                          ...rule,
                          [item.fieldName]: selected
                            ? selected.map((o) => o.value)
                            : undefined,
                        },
                      });
                  }}
                  helperMsg={item.helperMsg}
                />
              );
            })}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <ComponentWrapper
      componentName={componentName}
      className={className}
      testId={testId}
    >
      {getEditPanel()}
    </ComponentWrapper>
  );
};

export default DeveloperGuideRuleEditPanel;
