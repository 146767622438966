import React, { useEffect, useState } from 'react';
import WorkflowTransitDisplay from './WorkflowTransitDisplay';
import { iWFDiagramState } from '../diagram/WorkflowDiagramReducer';
import iEntityStatusCategory from '../../../types/status/iEntityStatusCategory';
import WorkflowDiagramHelper, {
  WFDiagramIds,
} from '../diagram/WorkflowDiagramHelper';
import SectionDiv from '../../frameWork/SectionDiv';
import {
  iEdge,
  iNode,
  useEdges,
  useNodes,
} from '../../frameWork/ReactFlowRenderer';
import Toggle from '../../frameWork/Toggle';
import EntityStatusEditPanel from '../../entityStatus/EntityStatusEditPanel';
import WorkflowDeleteStatusPopupBtn from './WorkflowDeleteStatusPopupBtn';
import Button from '../../frameWork/Button';
import Icons from '../../frameWork/Icons';

type iCategoryMap = {
  [key: string]: iEntityStatusCategory;
};
type iWorkflowEditStatusPanel = {
  editingNode: iNode;
  state: iWFDiagramState;
};
const WorkflowEditStatusPanel = ({
  editingNode,
  state,
}: iWorkflowEditStatusPanel) => {
  const [categoryMap, setCategoryMap] = useState<iCategoryMap>({});
  const [relatedEdges, setRelatedEdges] = useState<iEdge[]>([]);
  const nodes = useNodes();
  const edges = useEdges();

  useEffect(() => {
    setCategoryMap(
      state.entityStatusCategories.reduce(
        (map: iCategoryMap, category: iEntityStatusCategory) => {
          return {
            ...map,
            [category.id]: category,
          };
        },
        {},
      ),
    );
  }, [state.entityStatusCategories]);

  useEffect(() => {
    setRelatedEdges(
      edges.filter(
        (edge: iEdge) =>
          edge.source === editingNode.id || edge.target === editingNode.id,
      ),
    );
  }, [edges, editingNode.id]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateNode = (data: { [key: string]: string }) => {
    const entityStatus = editingNode.data.status || {};
    const newEntityStatusData = {
      ...entityStatus,
      ...data,
      entityStatusCategory: categoryMap[data.entityStatusCategoryId],
    };
    state.setNodes(
      nodes.map((node: iNode) => {
        if (node.data.status?.id === editingNode.data.status.id) {
          return {
            ...WorkflowDiagramHelper.getStatusNode(
              newEntityStatusData,
              editingNode.position,
            ),
            selected: true,
          };
        }
        return node;
      }),
    );
    return true;
  };

  const getTransitFromAnyStatus = () => {
    return relatedEdges.filter((edge) =>
      edge.source
        .toLowerCase()
        .startsWith(WFDiagramIds.NODE_ID_ANY_STATUS_PREFIX),
    );
  };

  const setEditingEdge = (edge: iEdge) => {
    return state.setEdges(
      edges.map((edg) => {
        if (edg.id !== edge.id) {
          return edg;
        }
        return {
          ...edg,
          selected: true,
        };
      }),
    );
  };

  const getEdgesPanel = () => {
    if (relatedEdges.length <= 0) {
      return null;
    }
    return (
      <>
        {relatedEdges.map((relatedEdge) => {
          return (
            <div
              key={relatedEdge.id}
              onClick={() => {
                setEditingEdge(relatedEdge);
              }}
              style={{ cursor: 'pointer' }}
            >
              <WorkflowTransitDisplay edge={relatedEdge} />
            </div>
          );
        })}
      </>
    );
  };

  const toggleFromAnyStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      const anyStatusTransIds = getTransitFromAnyStatus().map(
        (edge) => edge.id,
      );
      const anyStatusIds = getTransitFromAnyStatus().map((edge) => edge.source);
      state.setEdges(
        edges.filter((edge) => anyStatusTransIds.indexOf(edge.id) < 0),
      );
      state.setNodes(nodes.filter((node) => anyStatusIds.indexOf(node.id) < 0));
      return;
    }
    const { node, edge } = WorkflowDiagramHelper.getAnyStatusNode(editingNode);
    state.setEdges([...edges, edge]);
    state.setNodes([...nodes, node]);
  };

  return (
    <div>
      <h3>Status</h3>
      <EntityStatusEditPanel
        entityStatus={editingNode.data.status}
        isInlineSubmit
        onSave={(saving) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          updateNode(saving);
        }}
      />

      <SectionDiv title={<h5>Transitions</h5>}>
        <small>
          Transitions connect statuses. They represent actions people take to
          move job status through this workflow.
        </small>
        <Toggle
          isChecked={getTransitFromAnyStatus().length > 0}
          onChange={toggleFromAnyStatus}
          label={'Allow any status to move to this one'}
        />

        {getEdgesPanel()}
      </SectionDiv>

      <SectionDiv title={<h5>Danger Zone</h5>}>
        <div>
          <small>
            You can delete this status: <b>{editingNode.data.label}</b>
          </small>
        </div>
        <WorkflowDeleteStatusPopupBtn
          deletingNode={editingNode}
          state={state}
          renderBtn={(onClick) => (
            <Button
              appearance={'warning'}
              onClick={onClick}
              iconBefore={Icons.TrashIcon}
            >
              Delete
            </Button>
          )}
        />
      </SectionDiv>
    </div>
  );
};

export default WorkflowEditStatusPanel;
