import iBaseType from '../iBaseType';

export enum AttributeSetCodes {
  Appliances = 'Appliances',
  CavitySlider = 'cavity_slider',
  InternalDoor = 'internal_door',
  FrontDoor = 'front_door',
  BackDoor = 'back_door',
  GarageExternalDoor = 'garage_external_door',
  GarageInternalDoor = 'garage_internal_door',
  DoorLock = 'door_lock',
  InternalSizes = 'internal_sizes',
  GarageDoor = 'garage_door',
  Window = 'window',
  Cabinet = 'cabinet',
  Vanity = 'vanity',
  PreformedVanity = 'preformed_vanity',
  CabinetGeneral = 'cabinet_general',
  CabinetPantry = 'cabinet_pantry',
  Concrete = 'concrete',
  ConcreteGeneral = 'concrete_general',
  PowerPoint = 'power_point',
  ElectricalGeneral = 'electrical_general',
  ExteriorLight = 'exterior_light',
  InternalLight = 'internal_light',
  Flooring = 'flooring',
  Pier = 'pier',
  WaterTank = 'water_tank',
  Fencing = 'fencing',
  FencingWing = 'fencing_wing',
  FencingGeneral = 'fencing_general',
  Niche = 'niche',
  TilingGeneral = 'tiling_general',
  WallTiles = 'wall_tiles',
  BorderTiles = 'border_tiles',
  SplashBack = 'splash_back',
  Bathroom = 'bathroom',
  Slab = 'Slab',
  SlabNotes = 'slab_notes',
  SiteCut = 'site_cut',
  SiteBin = 'site_bin',
  WindowGeneral = 'window_general',
  DoorsGeneral = 'doors_general',
  SkyLight = 'sky_light',
  RetainingWall = 'retaining_wall',
  TempFencing = 'temp_fencing',
  Pest = 'pest',
  SetOut = 'set_out',
  Stumps = 'stumps',
  WindowsNotes = 'windows_notes',
  Roof = 'roof',
  SolarPower = 'SolarPower',
  SolarPanel = 'SolarPanel',
  Exterior = 'Exterior',
  FasciaAndGutter = 'fascia_and_gutter',
  Bricks = 'Bricks',
  ContractBricks = 'ContractBricks',
  SteelGeneral = 'SteelGeneral',
  Lintel = 'Lintel',
  CeilingFan = 'ceiling_fan',
  ExhaustFan = 'exhaust_fan',
  Antenna = 'antenna',
  AntennaPoint = 'antenna_point',
  Switch = 'switch',
  PhonePoint = 'phone_point',
  DataPoint = 'data_point',
  SmokeAlarm = 'smoke_alarm',
  ElectricalExtra = 'electrical_extra',
  PlumbingGeneral = 'plumbing_general',
  GardenTap = 'garden_tap',
  HotWaterService = 'hot_water_service',
  HeatingCollingGeneral = 'heating_cooling_general',
  AirConSplitSystem = 'air_con_split_system',
  DuctedHeating = 'ducted_heating',
  EvaporativeCooling = 'evaporative_cooling',
  RefrigerativeCooling = 'refrigerative_cooling',
  InvertedHeatingCooling = 'inverted_heating_cooling',
  Kitchen = 'kitchen',
  Laundry = 'laundry',
  Pantry = 'pantry',
  SlabAlfresco = 'slab_alfresco',
  SlabPorch = 'slab_porch',
  InitBuildPlan = 'init_build_plan',
  SlabAngleRepose = 'slab_angle_repose',
  SlabBoredPiers = 'slab_bored_piers',
  SlabEggShellDrain = 'slab_egg_shell_drain',
  CabinetKitchen = 'cabinet_kitchen',
  Insulation = 'insulation',
  FixingPlaster = 'fixing_plaster',
  FixingGeneral = 'fixing_general',
  FixingParchCementSheet = 'fixing_parch_cement_sheet',
  FixingGeneral2 = 'fixing_general2',
  FixingCladding = 'fixing_cladding',
  PaintingInternal = 'painting_internal',
  PaintingExternal = 'painting_external',
  PaintingExtra = 'painting_extra',
  RenderGeneral = 'render_general',
  RenderLocation = 'render_location',
  LetterBox = 'letter_box',
  ClothesLine = 'clothes_line',
  AlfrescoFlooring = 'alfresco_flooring',
  FlyScreen = 'fly_screen',
  SecurityDoor = 'security_door',
  OtherFixingGeneral = 'other_fixing_general',
  OtherFixing = 'other_fixing',
  OtherFixingExtra = 'other_fixing_extra',
  BlindsGeneral = 'blinds_general',
  Landscaping = 'landscaping',
  RobeGeneral = 'robe_general',
  RobAndLinen = 'rob_and_linen',
  BulkHead = 'bulk_head',
  SoilTest = 'soil_test',
  SoilTestAdditionalOrders = 'soil_test_additional_orders',
  Engineering = 'engineering',
  PropertyTitle = 'property_title',
  Site = 'site',
  Bollard = 'bollard',
  ColorPhotos = 'color_photos',
  ShowerScreen = 'shower_screen',
  Carpet = 'carpet',
  Eave = 'eave',
  WindowInFills = 'window_in_fills',
  CouncilPermit = 'council_permit',
  PropertyInfoPermit = 'property_info_permit',
  LPDPermit = 'lpd_permit',
  SewerPermit = 'sewer_permit',
  InfrastructureLevyPermit = 'infrastructure_levy_permit',
  EnergyRatingPermit = 'energy_rating_permit',
  PlanningPermit = 'planning_permit',
  WaterConsent = 'water_consent',
  Insurance = 'insurance',
  WaterAuthority = 'water_authority',
  GrinderPump = 'grinder_pump',
  WaterMeter = 'water_meter',
  SewerTie = 'sewer_tie',
  BuildingPermit = 'building_permit',
  BuildingConsent = 'building_consent',
  SiteInspectionPhotos = 'site_inspection_photos',
  ClientPhotos = 'client_photos',
  SiteInspection = 'site_inspection',
  AssetProtection = 'asset_protection',
  PreSiteInspection = 'pre_site_inspection',
}

type iAttributeSet = iBaseType & {
  name: string;
  code: string;
  description: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
};

export default iAttributeSet;
