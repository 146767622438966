import ListComponent, {
  BaseListComponentProps,
} from '../frameWork/ListComponent';
import iSequenceNumberType from '../../types/system/iSequenceNumberType';
import { iTableColumn } from '../../helpers/DynamicTableHelper';
import SequenceNumberTypeService from '../../services/system/SequenceNumberTypeService';
import * as _ from 'lodash';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import { iEntityFormField } from '../form/EntityEditPanel';
import React from 'react';
import TextField from '../frameWork/TextField';
import Lozenge from '../frameWork/Lozenge';
import StringHelper from '../../helpers/StringHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import Select from '../frameWork/Select';

export type iSequenceNumberTypeList = Omit<
  BaseListComponentProps<iSequenceNumberType>,
  'componentName' | 'getColumns' | 'getFn'
>;
const SequenceNumberTypeList = (props: iSequenceNumberTypeList) => {
  const [availableTypes, setAvailableTypes] = React.useState<string[]>([]);

  const getEditBtn = (
    params: ReturnType<typeof useListCrudHook<iSequenceNumberType>>,
    data?: iSequenceNumberType,
  ) => {
    return params.renderEntityEditPopBtn<iSequenceNumberType>({
      editingEntity: data,
      entityName: 'Sequence Number Type',
      isDisabled: props.allowEdit === false,
      getPopupTitle: (data?: iSequenceNumberType) => {
        if (`${data?.id || ''}`.trim() == '') {
          return 'Creating a new sequence number type';
        }
        return `Editing: ${data?.type || ''}`;
      },
      createFn: (data) => SequenceNumberTypeService.create(data),
      updateFn: (id, data) => SequenceNumberTypeService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => {
        return (
          <a onClick={onClick} className={'cursor-pointer'}>
            {entity.type || ''}
          </a>
        );
      },
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] =>
        [
          ...(`${entity?.id || ''}`.trim() === ''
            ? [
                {
                  fieldName: 'type',
                  label: 'Type',
                  isRequired: true,
                  isDisabled,
                  value: entity?.type || '',
                  renderComponent: (fProps, useAsForm, errorProps) => {
                    const { fieldName, ...props } = fProps;
                    return (
                      <Select
                        {...props}
                        {...errorProps}
                        value={
                          props.value
                            ? { label: props.value, value: props.value }
                            : undefined
                        }
                        options={availableTypes.map((t) => ({
                          label: t,
                          value: t,
                        }))}
                        onChange={(selected) => {
                          useAsForm.onFieldChange(
                            fieldName,
                            `${selected?.value || ''}`.trim(),
                          );
                        }}
                      />
                    );
                  },
                } as iEntityFormField,
              ]
            : []),
          ...[
            {
              fieldName: 'length',
              isRequired: true,
              label: 'Length',
              isValid: (value: string) => {
                if (!StringHelper.isNumeric(value)) {
                  return {
                    isValid: false,
                    errMessages: ['Length must be numeric'],
                  };
                }
                return { isValid: true, errMessages: [] };
              },
            },
            {
              fieldName: 'prefix',
              label: 'Prefix',
            },
            {
              fieldName: 'postfix',
              label: 'Postfix',
            },
          ].map((item) => ({
            fieldName: item.fieldName,
            label: item.label,
            isDisabled,
            isRequired: item.isRequired,
            testId: item.fieldName,
            isValid: item.isValid,
            value:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              item.fieldName in (entity || {}) ? entity[item.fieldName] : '',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            renderComponent: (fProps, useAsForm, errorProps) => {
              const { fieldName, ...props } = fProps;
              return (
                <TextField
                  {...props}
                  {...errorProps}
                  value={props.value as string}
                  onChange={(event) => {
                    useAsForm.onFieldChange(
                      fieldName,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      `${event.target.value || ''}`.trim(),
                    );
                  }}
                />
              );
            },
          })),
        ] as iEntityFormField[],
    });
  };

  const getColumns = (
    params: ReturnType<typeof useListCrudHook<iSequenceNumberType>>,
  ): iTableColumn<iSequenceNumberType>[] => [
    {
      key: 'type',
      header: 'Type',
      isDefault: true,
      cell: ({ data }) =>
        props.allowEdit === true ? getEditBtn(params, data) : data.type,
    },
    {
      key: 'prefix',
      header: 'Prefix',
      isDefault: true,
      cell: ({ data }) => data.prefix || '',
    },
    {
      key: 'length',
      header: 'Length',
      isDefault: true,
      cell: ({ data }) => data.length,
    },
    {
      key: 'postfix',
      header: 'Postfix',
      isDefault: true,
      cell: ({ data }) => data.postfix || '',
    },
    {
      key: 'sample',
      header: 'Sample',
      isDefault: true,
      cell: ({ data }) => (
        <Lozenge>
          {[
            `${data.prefix || ''}`.trim() === ''
              ? ''
              : `${`${data.prefix || ''}`.trim()}-`,
            _.padStart('1', data.length, '0'),
            `${data.postfix || ''}`.trim() === ''
              ? ''
              : `-${`${data.postfix || ''}`.trim()}`,
          ]
            .join('')
            .trim()}
        </Lozenge>
      ),
    },
  ];
  return (
    <ListComponent<iSequenceNumberType>
      componentName={'SequenceNumberTypeList'}
      selectiveColumnKey={SelectiveColKeys.SEQUENCE_NUMBER_TYPE_LIST}
      {...props}
      allowDelete={false}
      getColumns={getColumns}
      renderCreateBtn={(params) => {
        if (availableTypes.length === 0) {
          return <></>;
        }
        return getEditBtn(params);
      }}
      getFn={(params) => {
        return Promise.all([
          SequenceNumberTypeService.getAll({
            where: JSON.stringify({
              isActive: true,
              ...(params?.filter || {}),
            }),
            include: 'CreatedBy,UpdatedBy',
            currentPage: params?.currentPage || 1,
            perPage: params?.perPage || 10,
            ...(params?.sort ? { sort: params.sort } : {}),
          }),
          SequenceNumberTypeService.getAvailTypes(),
        ]).then((values) => {
          const availTypes = values[1] || {};
          setAvailableTypes(
            Object.keys(availTypes).filter(
              (key: string) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                availTypes[key] === true,
            ),
          );
          return values[0];
        });
      }}
    />
  );
};

export default SequenceNumberTypeList;
