import ServiceHelper from '../helper/ServiceHelper';
import iBuildSurveyItem from '../../types/build/iBuildSurveyItem';

const endPoint = '/buildSurveyItem';
const BuildSurveyItemService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildSurveyItem>(endPoint),
  get: ServiceHelper.getById<iBuildSurveyItem>(endPoint),
  create: ServiceHelper.create<iBuildSurveyItem>(endPoint),
  update: ServiceHelper.updateById<iBuildSurveyItem>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildSurveyItem>(endPoint),
};

export default BuildSurveyItemService;
