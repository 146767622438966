import SettingsPage from '../../layouts/SettingsPage';
import EntityStatusTypeList from '../../components/entityStatus/EntityStatusTypeList';

const EntityStatusTypesPage = () => {
  return (
    <SettingsPage title={'Status Types'}>
      {(headerProps) => <EntityStatusTypeList headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default EntityStatusTypesPage;
