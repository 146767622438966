import iProduct from '../../types/product/iProduct';
import ComponentWrapper from '../frameWork/ComponentWrapper';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ProductPriceService from '../../services/product/ProductPriceService';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import iProductPrice from '../../types/product/iProductPrice';
import ProductPriceEditPopupBtn from './ProductPriceEditPopupBtn';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import ComponentPropsHelper, {
  WrapperProps,
} from '../frameWork/ComponentPropsHelper';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import UtilsService from '../../services/UtilsService';
import DateStatusDiv from '../frameWork/DateStatusDiv';
import EntityNames from '../../helpers/EntityNames';

type iProductPriceListPanel = iComponentWithPageHeader &
  WrapperProps & {
    product: iProduct;
    allowDelete?: boolean;
    allowEdit?: boolean;
    allowCreate?: boolean;
    onSaved?: (saved: iProductPrice, isCreated: boolean) => void;
  };
const ProductPriceList = ({
  product,
  testId,
  className,
  headerProps,
  allowCreate,
  allowEdit,
  allowDelete,
  onSaved,
}: iProductPriceListPanel) => {
  const { testIdStr, componentName } = ComponentPropsHelper.getWrapperProps({
    componentName: 'ProductPriceListPanel',
    className,
    testId,
  });
  const {
    state,
    onRefreshWhenCreated,
    onRefresh,
    renderDataTable,
    renderDeleteBtn,
  } = useListCrudHook({
    sort: `startDate:DESC`,
    getFn: (params) => {
      return ProductPriceService.getAll({
        where: JSON.stringify({
          isActive: true,
          productId: product.id,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Product',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      });
    },
  });

  const getEditBtn = (price?: iProductPrice) => {
    return (
      <ProductPriceEditPopupBtn
        productPrice={price}
        productId={product.id}
        renderBtn={(onClick) => {
          const priceId = `${price?.id || ''}`.trim();
          if (priceId === '') {
            return getCreateIconBtn({
              onClick,
              title: price ? 'Edit' : 'Create',
            });
          }

          return (
            <a
              onClick={onClick}
              className={'cursor-pointer'}
              data-testid={`edit-${priceId}`}
            >
              {DynamicTableHelper.displayDateCell(price?.startDate || null)}
            </a>
          );
        }}
        onSaved={(saved, isCreated) => {
          if (isCreated === true) {
            onRefreshWhenCreated();
          } else {
            onRefresh();
          }
          onSaved && onSaved(saved, isCreated);
        }}
      />
    );
  };

  const getColumns = (): iTableColumn<iProductPrice>[] => {
    return [
      {
        key: 'startDate',
        header: 'Start Date',
        isDefault: true,
        cell: ({ data }: iCellParams<iProductPrice>) => {
          if (allowEdit !== true) {
            return DynamicTableHelper.displayDateCell(data.startDate);
          }
          return getEditBtn(data);
        },
      },
      {
        key: 'endDate',
        header: 'End Date',
        isDefault: true,
        cell: ({ data }: iCellParams<iProductPrice>) => {
          return DynamicTableHelper.displayDateCell(data.endDate);
        },
      },
      {
        key: 'price',
        header: 'Price',
        isDefault: true,
        isSelectable: true,
        cell: ({ data }: iCellParams<iProductPrice>) => {
          return UtilsService.formatIntoCurrency(data.price);
        },
      },
      {
        key: 'status',
        header: 'Status',
        isDefault: true,
        isSelectable: true,
        cell: ({ data }: iCellParams<iProductPrice>) => {
          return (
            <DateStatusDiv startDate={data.startDate} endDate={data.endDate} />
          );
        },
      },
      {
        key: 'comments',
        header: 'Comments',
        isDefault: true,
        isSelectable: true,
        cell: ({ data }: iCellParams<iProductPrice>) => {
          return `${data.comments || ''}`;
        },
      },
      ...DynamicTableHelper.getCreatedAndUpdatedColumns<iProductPrice>(),
      ...(allowDelete !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              isDefault: true,
              cell: ({ data }: iCellParams<iProductPrice>) => {
                return (
                  <div className={'text-right'}>
                    {renderDeleteBtn({
                      deletingModel: data,
                      deleteFnc: async () =>
                        ProductPriceService.deactivate(data.id),
                      getDisplayName: (price: iProductPrice) =>
                        `${EntityNames.BuildStylePrice} (Price=${UtilsService.formatIntoCurrency(price.price)})`,
                    })}
                  </div>
                );
              },
            },
          ]),
    ];
  };

  const getTitle = () => {
    if (headerProps?.children) {
      return headerProps?.children;
    }
    return `${state.data.total} product price(s)`;
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children:
          allowCreate === true ? (
            <PageTitleWithCreateBtn
              createBtn={getEditBtn()}
              title={getTitle()}
            />
          ) : (
            getTitle()
          ),
      }}
    >
      <ComponentWrapper
        componentName={componentName}
        testId={testId}
        className={className}
        isLoading={state.isLoading}
      >
        {renderDataTable({
          selectiveColumnKey: SelectiveColKeys.BUILD_STYLE_PRICE_LIST,
          columns: getColumns(),
          showPageSizer: true,
          tblProps: {
            testId: testIdStr,
          },
        })}
      </ComponentWrapper>
    </ComponentWithPageHeader>
  );
};

export default ProductPriceList;
