import iItemLocation from '../../types/warehouse/iItemLocation';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ItemLocationService from '../../services/warehouseLocation/ItemLocationService';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import { iCellParams, iTableColumn } from '../../helpers/DynamicTableHelper';
import SearchTextField from '../frameWork/SearchTextField';
import { ITEM_LOCATION_COLUMNS } from './Warehouse.constants';

type iItemLocationList = iComponentWithPageHeader & {
  warehouseLocationId?: string;
  isIndependentPage?: boolean;
  title?: string;
};

const ItemLocationList = ({
  warehouseLocationId,
  title,
  ...headerProps
}: iItemLocationList) => {
  const testIdStr = 'itemLocation-list';
  const { renderDataTable, onSetFilter } = useListCrudHook<iItemLocation>({
    sort: `createdAt:ASC`,
    getFn: (params) =>
      ItemLocationService.getAll({
        where: JSON.stringify({
          isActive: true,
          warehouseLocationId,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Product,WarehouseLocation',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getColumns = (): iTableColumn<iItemLocation>[] => {
    return ITEM_LOCATION_COLUMNS.map((column) => {
      return {
        key: column.key,
        header: column.name,
        cell: ({ data }: iCellParams<iItemLocation>) => {
          return column.cell({ data });
        },
      };
    });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: <h4>{title}</h4>,
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            placeholder={'Search product ...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(searchTxt === '' ? {} : {});
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};
export default ItemLocationList;
