import ServiceHelper from '../helper/ServiceHelper';
import iBuildColourNoteTemplate from '../../types/build/iBuildColourNoteTemplate';

const endPoint = '/buildColourNoteTemplate';
const BuildColourNoteTemplateService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildColourNoteTemplate>(endPoint),
  get: ServiceHelper.getById<iBuildColourNoteTemplate>(endPoint),
  create: ServiceHelper.create<iBuildColourNoteTemplate>(endPoint),
  update: ServiceHelper.updateById<iBuildColourNoteTemplate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildColourNoteTemplate>(endPoint),
};

export default BuildColourNoteTemplateService;
