import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iBuildColourNote from '../../types/build/iBuildColourNote';
import BuildColourNoteTemplateSelector from './BuildColourNoteTemplateSelector';
import { useEffect, useState } from 'react';
import { getErrorProps, iErrorMap } from '../form/FormError';
import { iConfigParams } from '../../services/AppService';
import Toaster from '../common/Toaster';
import BuildColourNoteService from '../../services/build/BuildColourNoteService';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { iOptionWithData } from '../frameWork/Select';
import TextArea from '../frameWork/TextArea';
import iBuildColourNoteTemplate from '../../types/build/iBuildColourNoteTemplate';
import MathHelper from '../../helpers/MathHelper';
import TextField from '../frameWork/TextField';
import StringHelper from '../../helpers/StringHelper';
import EntityStatusSelector from '../entityStatus/EntityStatusSelector';
import EntityNames from '../../helpers/EntityNames';
import iEntityStatus from '../../types/status/iEntityStatus';

type iBuildColourNoteEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  buildId: string;
  buildColourNote?: iBuildColourNote;
  onSaved?: (data: iBuildColourNote, isCreated: boolean) => void;
};
const BuildColourNoteEditPopupBtn = ({
  buildId,
  buildColourNote,
  onSaved,
  modalProps,
  isDisabled,
  ...props
}: iBuildColourNoteEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [reloadData, setReloadData] = useState(0);
  const buildColourNoteId = `${buildColourNote?.id || ''}`.trim();

  useEffect(() => {
    if (buildColourNoteId === '') {
      setEditingData({});
      return;
    }

    setEditingData({ ...buildColourNote });
  }, [buildColourNote, buildColourNoteId, reloadData]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setIsSaving(false);
    setErrorMap({});
    setReloadData(MathHelper.add(reloadData, 1));
    setModelShowing(false);
  };

  const onFieldChange = (newValue: { [key: string]: string | null }) => {
    setEditingData({ ...editingData, ...newValue });
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    [
      {
        fieldName: 'templateId',
        errorMsg: 'Template is required',
      },
      {
        fieldName: 'notes',
        errorMsg: 'Notes is required',
      },
    ].forEach((item) => {
      const content =
        `${item.fieldName in editingData ? editingData[item.fieldName] : ''}`.trim();
      if (content === '') {
        errors[item.fieldName] = [item.errorMsg];
      }
    });

    const costToClient = `${editingData.costToClient || ''}`
      .trim()
      .replaceAll('$', '')
      .replaceAll(',', '');

    if (costToClient !== '' && !StringHelper.isNumeric(costToClient)) {
      errors.costToClient = ['Cost to Client must be a number'];
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (preSave() !== true) {
      return;
    }

    const { costToClient, ...submittingData } = editingData;
    const costToClientNumber = StringHelper.convertCurrencyToNumber(
      `${costToClient || ''}`.trim(),
    );
    setIsSaving(true);
    const data = {
      ...submittingData,
      ...(costToClientNumber !== ''
        ? { costToClient: costToClientNumber }
        : {}),
      buildId,
    };
    const savingFn = () =>
      buildColourNoteId === ''
        ? BuildColourNoteService.create(data)
        : BuildColourNoteService.update(buildColourNoteId, data);
    savingFn()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, buildColourNoteId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showToast(err);
      });
  };
  return (
    <PopupBtn
      {...props}
      titleId={`BuildColourNoteEditPopupBtn-${buildId}`}
      modalProps={(setModelShowing) => ({
        width: '760px',
        shouldScrollInViewport: true,
        title: buildColourNoteId === '' ? 'Create Note' : 'Update Note',
        ...modalProps,
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: isDisabled
            ? undefined
            : {
                isDisabled: Object.keys(editingData || {}).length <= 0,
                isLoading: isSaving,
                iconBefore: Icons.SendIcon,
                btnText: 'Save',
                testId: `${props.testId || ''}-saveBtn`,
                onClick: () => doSave(setModelShowing),
              },
        }),
        body: (
          <>
            <BuildColourNoteTemplateSelector
              label={'Template'}
              testId={'template-selector'}
              isRequired
              isDisabled={isDisabled || buildColourNoteId !== ''}
              value={editingData.templateId}
              onChange={(selected: iOptionWithData<iBuildColourNoteTemplate>) =>
                onFieldChange({
                  templateId: `${selected.value || ''}`,
                  name: `${selected.data?.name || ''}`,
                  notes: `${selected.data?.description || ''}`,
                })
              }
              {...getErrorProps({ error: errorMap, fieldName: 'templateId' })}
            />
            <TextArea
              label={'Notes'}
              testId={'notes'}
              isDisabled={isDisabled}
              value={editingData.notes}
              {...getErrorProps({ error: errorMap, fieldName: 'notes' })}
              onChange={(event) => {
                onFieldChange({ notes: `${event.target.value || ''}` });
              }}
            />
            {buildColourNoteId === '' ? null : (
              <>
                <EntityStatusSelector
                  isRequired
                  testId={'approval-status-selector'}
                  isDisabled={isDisabled}
                  value={editingData.approvalStatusId}
                  entityStatusTypeName={
                    EntityNames.BuildColourNote_ApprovalStatus
                  }
                  label={'Address To'}
                  onChange={(selected: iOptionWithData<iEntityStatus>) => {
                    onFieldChange({
                      approvalStatusId: `${selected.value || ''}`,
                    });
                  }}
                />
                <EntityStatusSelector
                  isRequired
                  testId={'status-selector'}
                  isDisabled={isDisabled}
                  value={editingData.statusId}
                  entityStatusTypeName={EntityNames.BuildColourNote_Status}
                  label={'Status'}
                  onChange={(selected: iOptionWithData<iEntityStatus>) => {
                    onFieldChange({ statusId: `${selected.value || ''}` });
                  }}
                />
              </>
            )}
            <TextField
              label={'Cost to Client'}
              testId={'costToClient'}
              isDisabled={isDisabled}
              elemBeforeInput={<span className={'padding-left-sm'}>$</span>}
              value={editingData.costToClient || ''}
              {...getErrorProps({ error: errorMap, fieldName: 'costToClient' })}
              onChange={(event) => {
                onFieldChange({
                  costToClient:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                });
              }}
            />
          </>
        ),
      })}
    />
  );
};

export default BuildColourNoteEditPopupBtn;
