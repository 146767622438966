import React, { useCallback } from 'react';
import WorkflowDiagramWrapper from './WorkflowDiagramStyle';
import WorkflowDiagramHelper from './WorkflowDiagramHelper';
import ReactFlow, {
  iConnection,
  iEdge,
  iEdgeChange,
  iNode,
  iNodeChange,
  Controls,
  MiniMap,
  addEdge,
} from '../../frameWork/ReactFlowRenderer';

export type iWorkflowDiagram = {
  setEdges: (edges: iEdge[] | ((edges: iEdge[]) => iEdge[])) => void;
  setNodes: (nodes: iNode[] | ((nodes: iNode[]) => iNode[])) => void;
  onNodesChange: (nodesChange: iNodeChange[]) => void;
  onEdgesChange: (edgesChange: iEdgeChange[]) => void;
  nodes: iNode[];
  edges: iEdge[];
};

const WorkflowDiagram = ({
  setEdges,
  onEdgesChange,
  onNodesChange,
  nodes,
  edges,
}: iWorkflowDiagram) => {
  const onConnect = useCallback(
    (connection: iConnection) =>
      setEdges((eds: iEdge[]) => addEdge(connection, eds)),
    [setEdges],
  );

  return (
    <WorkflowDiagramWrapper className={'workflow-diagram'}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={WorkflowDiagramHelper.getWorkFlowEdge()}
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
    </WorkflowDiagramWrapper>
  );
};

export default WorkflowDiagram;
