import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import entityNames from '../../../../helpers/EntityNames';
import EntityNames from '../../../../helpers/EntityNames';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';
import SectionDiv from '../../../frameWork/SectionDiv';
import Tabs from '../../../frameWork/Tabs';
import BuildStoreyHeightsEditPanel from '../../../houseStorey/BuildStoreyHeightsEditPanel';
import FormField from '../../../frameWork/FormField';
import { ViewWrapper } from '../../../frameWork/InlineEdit';
import PacksForABuild from '../../../buildPack/PacksForABuild';
import BuildSizeEditPanel from '../../BuildSizeEditPanel';
import { sizeFields } from '../../../../types/build/iBuildStyle';
import Heading from '../../../frameWork/Heading';

export enum BuildDetailsOrdersKeys {
  SLAB = 'Slab',
  FRAME = 'Frame & Doors',
  WINDOWS = 'Windows',
  BRICKS = 'Bricks & Roof Plumbing',
  ELECTRICAL = 'Electrical',
  PLUMBING = 'Plumbing',
  CABINETS = 'Cabinets',
  FIXINGS = 'Fixings',
  PAINTING = 'Painting',
  FLOOR_WALL_COVER = 'Floor / Wall Cover',
  COMPLETION_WORKS = 'Completion Works',
  PLANS = 'Plans',
  ORDER_NOTES = 'Notes',
  START_TO_LOCKUP = 'Start to Lockup',
  LOCKUP_TO_COMPLETION = 'Lockup To Completion',
}

const getKey = (
  key: string,
  build: iBuild,
  attributeSection: AttributeForSections,
) => `${attributeSection}-${build.id}-${key}`;

type iGetBuildDetailsOrdersMenuItemsMap = {
  build: iBuild;
  attributeSection: AttributeForSections;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getBuildDetailsOrdersMenuItemsMap = ({
  build,
  attributeSection,
  isDisabled = false,
}: iGetBuildDetailsOrdersMenuItemsMap): iMenuItemsMap => {
  const commonProps = {
    attributeSection,
    isDisabled,
    entityId: build.id,
    entityName: entityNames.Build,
  };
  return {
    [BuildDetailsOrdersKeys.SLAB]: {
      name: BuildDetailsOrdersKeys.SLAB,
      icon: <Icons.EditorUnderlineIcon label={BuildDetailsOrdersKeys.SLAB} />,
      component: (
        <>
          <AttributeSetInputPanel
            attributeSetCodes={[
              AttributeSetCodes.Slab,
              AttributeSetCodes.SlabNotes,
              AttributeSetCodes.SiteCut,
              AttributeSetCodes.RetainingWall,
              AttributeSetCodes.TempFencing,
              AttributeSetCodes.Pest,
              AttributeSetCodes.SiteBin,
              AttributeSetCodes.SetOut,
              AttributeSetCodes.Stumps,
              AttributeSetCodes.SlabAngleRepose,
              AttributeSetCodes.SlabBoredPiers,
              AttributeSetCodes.SlabEggShellDrain,
              AttributeSetCodes.SlabAlfresco,
              AttributeSetCodes.SlabPorch,
            ]}
            {...commonProps}
          />

          <SectionDiv title={'Sizes'}>
            <BuildSizeEditPanel
              entityId={build.id}
              entityName={entityNames.Build}
              isDisabled={true}
              showingFieldNames={sizeFields}
              noneEmptyFieldNames={sizeFields}
              showTotal
            />
          </SectionDiv>
        </>
      ),
    },
    [BuildDetailsOrdersKeys.FRAME]: {
      name: BuildDetailsOrdersKeys.FRAME,
      icon: <Icons.MenuIcon label={BuildDetailsOrdersKeys.FRAME} />,
      component: (
        <>
          <BuildStoreyHeightsEditPanel
            entityName={EntityNames.Build}
            entityId={build.id}
            showStoreySelector={false}
          />
          <AttributeSetInputPanel
            attributeSetCodes={[
              AttributeSetCodes.DoorsGeneral,
              AttributeSetCodes.FrontDoor,
              AttributeSetCodes.BackDoor,
              AttributeSetCodes.GarageExternalDoor,
              AttributeSetCodes.GarageInternalDoor,
              AttributeSetCodes.DoorLock,
              AttributeSetCodes.InternalDoor,
              AttributeSetCodes.CavitySlider,
              AttributeSetCodes.SkyLight,
              AttributeSetCodes.InternalSizes,
            ]}
            {...commonProps}
          />
        </>
      ),
    },
    [BuildDetailsOrdersKeys.WINDOWS]: {
      name: BuildDetailsOrdersKeys.WINDOWS,
      icon: (
        <Icons.EditorLayoutThreeWithSidebarsIcon
          label={BuildDetailsOrdersKeys.WINDOWS}
        />
      ),
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.WindowGeneral,
            AttributeSetCodes.Window,
            AttributeSetCodes.WindowsNotes,
          ]}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsOrdersKeys.BRICKS]: {
      name: BuildDetailsOrdersKeys.BRICKS,
      icon: <Icons.RoadmapIcon label={BuildDetailsOrdersKeys.BRICKS} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.Roof,
            AttributeSetCodes.FasciaAndGutter,
            AttributeSetCodes.Exterior,
            AttributeSetCodes.SolarPower,
            AttributeSetCodes.Bricks,
            AttributeSetCodes.ContractBricks,
            AttributeSetCodes.Pier,
            AttributeSetCodes.SteelGeneral,
            AttributeSetCodes.Lintel,
          ]}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsOrdersKeys.ELECTRICAL]: {
      name: BuildDetailsOrdersKeys.ELECTRICAL,
      icon: <Icons.LightbulbIcon label={BuildDetailsOrdersKeys.ELECTRICAL} />,
      component: (
        <AttributeSetInputPanel
          attributeSetCodes={[
            AttributeSetCodes.ElectricalGeneral,
            AttributeSetCodes.InternalLight,
            AttributeSetCodes.ExteriorLight,
            AttributeSetCodes.PowerPoint,
            AttributeSetCodes.Switch,
            AttributeSetCodes.ExhaustFan,
            AttributeSetCodes.CeilingFan,
            AttributeSetCodes.Antenna,
            AttributeSetCodes.AntennaPoint,
            AttributeSetCodes.PhonePoint,
            AttributeSetCodes.DataPoint,
            AttributeSetCodes.SmokeAlarm,
            AttributeSetCodes.ElectricalExtra,
          ]}
          {...commonProps}
        />
      ),
    },
    [BuildDetailsOrdersKeys.PLUMBING]: {
      name: BuildDetailsOrdersKeys.PLUMBING,
      icon: (
        <Icons.BitbucketCompareIcon label={BuildDetailsOrdersKeys.PLUMBING} />
      ),
      component: (
        <>
          <Tabs
            key={BuildDetailsOrdersKeys.PLUMBING}
            tabs={[
              {
                eventKey: 'general',
                title: 'General',
                tabContent: (
                  <>
                    <SectionDiv>
                      <FormField
                        label={'Gas Type'}
                        render={() => (
                          <ViewWrapper
                            className={
                              `${build.GasType?.name || ''}`.trim() === ''
                                ? 'disabled-view'
                                : ''
                            }
                          >
                            {build.GasType?.name || 'No value yet'}
                          </ViewWrapper>
                        )}
                      />
                    </SectionDiv>
                    <SectionDiv>
                      <AttributeSetInputPanel
                        attributeSetCodes={[
                          AttributeSetCodes.Bollard,
                          AttributeSetCodes.PlumbingGeneral,
                          AttributeSetCodes.GardenTap,
                          AttributeSetCodes.HotWaterService,
                          AttributeSetCodes.SolarPanel,
                          AttributeSetCodes.HeatingCollingGeneral,
                          AttributeSetCodes.DuctedHeating,
                          AttributeSetCodes.EvaporativeCooling,
                          AttributeSetCodes.RefrigerativeCooling,
                          AttributeSetCodes.InvertedHeatingCooling,
                          AttributeSetCodes.AirConSplitSystem,
                        ]}
                        {...commonProps}
                      />
                    </SectionDiv>
                  </>
                ),
              },
              {
                eventKey: 'Kitchen & Laundry',
                title: 'Kitchen & Laundry',
                tabContent: (
                  <AttributeSetInputPanel
                    attributeSetCodes={[
                      AttributeSetCodes.Kitchen,
                      AttributeSetCodes.Laundry,
                      AttributeSetCodes.Pantry,
                    ]}
                    {...commonProps}
                  />
                ),
              },
              {
                eventKey: 'Bathrooms',
                title: 'Bathrooms',
                tabContent: (
                  <AttributeSetInputPanel
                    attributeSetCodes={[AttributeSetCodes.Bathroom]}
                    {...commonProps}
                  />
                ),
              },
              {
                eventKey: 'Rainwater Tank',
                title: 'Rainwater Tank',
                tabContent: (
                  <AttributeSetInputPanel
                    attributeSetCodes={[AttributeSetCodes.WaterTank]}
                    {...commonProps}
                  />
                ),
              },
            ]}
          />
        </>
      ),
    },
    [BuildDetailsOrdersKeys.CABINETS]: {
      name: BuildDetailsOrdersKeys.CABINETS,
      icon: <Icons.QueuesIcon label={BuildDetailsOrdersKeys.CABINETS} />,
      component: (
        <Tabs
          key={BuildDetailsOrdersKeys.CABINETS}
          tabs={[
            {
              eventKey: 'Cabinets',
              title: 'Cabinets',
              tabContent: (
                <>
                  <AttributeSetInputPanel
                    key={getKey(
                      BuildDetailsOrdersKeys.CABINETS,
                      build,
                      attributeSection,
                    )}
                    attributeSetCodes={[
                      AttributeSetCodes.CabinetGeneral,
                      AttributeSetCodes.Cabinet,
                      AttributeSetCodes.PreformedVanity,
                      AttributeSetCodes.Vanity,
                      AttributeSetCodes.CabinetKitchen,
                      AttributeSetCodes.Appliances,
                      AttributeSetCodes.CabinetPantry,
                    ]}
                    {...commonProps}
                  />
                  <PacksForABuild
                    key={getKey(
                      `${BuildDetailsOrdersKeys.CABINETS}-packs`,
                      build,
                      attributeSection,
                    )}
                    buildId={build.id}
                    headerProps={{
                      disableTitleStyles: true,
                      children: <Heading size={'small'}>Packs</Heading>,
                    }}
                  />
                </>
              ),
            },
            {
              eventKey: 'Robes & Linen',
              title: 'Robes & Linen',
              tabContent: (
                <AttributeSetInputPanel
                  key={getKey(
                    `${BuildDetailsOrdersKeys.CABINETS}-robs-and-linen`,
                    build,
                    attributeSection,
                  )}
                  attributeSetCodes={[
                    AttributeSetCodes.RobeGeneral,
                    AttributeSetCodes.RobAndLinen,
                  ]}
                  {...commonProps}
                />
              ),
            },
          ]}
        />
      ),
    },
    [BuildDetailsOrdersKeys.FIXINGS]: {
      name: BuildDetailsOrdersKeys.FIXINGS,
      icon: <Icons.NutsAndBoltsIcon label={BuildDetailsOrdersKeys.FIXINGS} />,
      component: (
        <>
          <BuildStoreyHeightsEditPanel
            entityName={EntityNames.Build}
            entityId={build.id}
            isDisabled={true}
          />
          <AttributeSetInputPanel
            attributeSetCodes={[
              AttributeSetCodes.Insulation,
              AttributeSetCodes.FixingPlaster,
              AttributeSetCodes.BulkHead,
              AttributeSetCodes.FixingGeneral,
              AttributeSetCodes.Eave,
              AttributeSetCodes.FixingParchCementSheet,
              AttributeSetCodes.FixingGeneral2,
              AttributeSetCodes.WindowInFills,
              AttributeSetCodes.OtherFixingExtra,
              AttributeSetCodes.FixingCladding,
            ]}
            {...commonProps}
          />
        </>
      ),
    },
    [BuildDetailsOrdersKeys.PAINTING]: {
      name: BuildDetailsOrdersKeys.PAINTING,
      icon: <Icons.PaintingIcon label={BuildDetailsOrdersKeys.PAINTING} />,
      component: (
        <>
          <AttributeSetInputPanel
            attributeSetCodes={[
              AttributeSetCodes.PaintingInternal,
              AttributeSetCodes.PaintingExternal,
              AttributeSetCodes.PaintingExtra,
            ]}
            {...commonProps}
          />
        </>
      ),
    },
    [BuildDetailsOrdersKeys.FLOOR_WALL_COVER]: {
      name: BuildDetailsOrdersKeys.FLOOR_WALL_COVER,
      icon: (
        <Icons.BitbucketSourceIcon
          label={BuildDetailsOrdersKeys.FLOOR_WALL_COVER}
        />
      ),
      component: (
        <>
          <AttributeSetInputPanel
            attributeSetCodes={[
              AttributeSetCodes.TilingGeneral,
              AttributeSetCodes.ShowerScreen,
              AttributeSetCodes.SplashBack,
              AttributeSetCodes.Carpet,
              AttributeSetCodes.Flooring,
              AttributeSetCodes.WallTiles,
              AttributeSetCodes.BorderTiles,
            ]}
            {...commonProps}
          />
        </>
      ),
    },
    [BuildDetailsOrdersKeys.COMPLETION_WORKS]: {
      name: BuildDetailsOrdersKeys.COMPLETION_WORKS,
      icon: (
        <Icons.PremiumIcon label={BuildDetailsOrdersKeys.COMPLETION_WORKS} />
      ),
      component: (
        <>
          <AttributeSetInputPanel
            attributeSetCodes={[
              AttributeSetCodes.RenderGeneral,
              AttributeSetCodes.RenderLocation,
              AttributeSetCodes.LetterBox,
              AttributeSetCodes.ClothesLine,
              AttributeSetCodes.GarageDoor,
              AttributeSetCodes.ConcreteGeneral,
              AttributeSetCodes.Concrete,
              AttributeSetCodes.AlfrescoFlooring,
              AttributeSetCodes.FlyScreen,
              AttributeSetCodes.SecurityDoor,
              AttributeSetCodes.OtherFixingGeneral,
              AttributeSetCodes.OtherFixing,
              AttributeSetCodes.BlindsGeneral,
              AttributeSetCodes.Landscaping,
              AttributeSetCodes.FencingGeneral,
              AttributeSetCodes.Fencing,
              AttributeSetCodes.FencingWing,
            ]}
            {...commonProps}
          />
          <PacksForABuild
            buildId={build.id}
            headerProps={{
              children: 'Packs',
            }}
          />
        </>
      ),
    },
  };
};

const BuildDetailsOrdersHelper = {
  getBuildDetailsOrdersMenuItemsMap,
};
export default BuildDetailsOrdersHelper;
