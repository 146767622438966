import ServiceHelper from '../helper/ServiceHelper';
import iProductOption from '../../types/product/iProductOption';

const endPoint = '/productOption';
const ProductOptionService = {
  ...ServiceHelper.generateCrud<iProductOption>(endPoint),
  move: ServiceHelper.create<iProductOption>(`${endPoint}/move`),
};

export default ProductOptionService;
