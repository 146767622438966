import {
  Edge as OriginalEdge,
  Node as OriginalNode,
  ReactFlowProvider as OriginalReactFlowProvider,
  useEdgesState as OriginalUseEdgesState,
  useNodesState as OriginalUseNodesState,
  useNodes as OriginalUseNodes,
  useEdges as OriginalUseEdges,
  addEdge as OriginalAddEdge,
  Connection as OriginalConnection,
  Controls as OriginalControls,
  EdgeChange as OriginalEdgeChange,
  MiniMap as OriginalMiniMap,
  NodeChange as OriginalNodeChange,
  default as ReactFlow,
  useStore as OriginalUseStore,
  getBezierPath as OriginalGetBezierPath,
  EdgeProps as OriginalEdgeProps,
  Position as OriginalPosition,
  getEdgeCenter as OriginalGetEdgeCenter,
} from 'react-flow-renderer';

export type iEdge = OriginalEdge;
export type iNode = OriginalNode;
export type iConnection = OriginalConnection;
export type iEdgeChange = OriginalEdgeChange;
export type iNodeChange = OriginalNodeChange;
export type iEdgeProps = OriginalEdgeProps;
export const Position = OriginalPosition;

export const ReactFlowProvider = OriginalReactFlowProvider;

export const useNodesState = OriginalUseNodesState;
export const useEdgesState = OriginalUseEdgesState;

export const useNodes = OriginalUseNodes;
export const useEdges = OriginalUseEdges;

export const addEdge = OriginalAddEdge;
export const Controls = OriginalControls;
export const MiniMap = OriginalMiniMap;

export const useStore = OriginalUseStore;
export const getBezierPath = OriginalGetBezierPath;
export const getEdgeCenter = OriginalGetEdgeCenter;

export default ReactFlow;
