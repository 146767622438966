import ProductService from '../../../services/product/ProductService';
import Toaster from '../../common/Toaster';
import iProduct from '../../../types/product/iProduct';
import { useState } from 'react';
import styled from 'styled-components';
import Flex from '../../frameWork/Flex';
import { Label } from '../../frameWork/Form';
import AttributeSetSelector from '../../attributeSet/AttributeSetSelector';
import { iOption } from '../../frameWork/Select';
import { iConfigParams } from '../../../services/AppService';

const Wrapper = styled.div``;

export type iProductDetailsAttributesPanel = {
  product: iProduct;
  isDisabled?: boolean;
  onSaved?: (saved: iProduct) => void;
  className?: string;
  testId?: string;
};
const ProductDetailsAttributesPanel = ({
  testId,
  isDisabled,
  onSaved,
  className,
  product,
}: iProductDetailsAttributesPanel) => {
  const componentName = 'product-details-attributes';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (data: iConfigParams) => {
    setIsSaving(true);
    ProductService.update(product.id, data)
      .then((resp) => {
        setIsSaving(false);
        onSaved && onSaved(resp);
      })
      .catch((error) => {
        setIsSaving(false);
        Toaster.showApiError(error);
      });
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      <Flex className="justify-content-start gap-1 align-items-center">
        <Label htmlFor={''}>Attribute Set</Label>
        <AttributeSetSelector
          testId={`${testIdStr}-attribute-set`}
          value={product.attributeSetCode}
          isDisabled={isSaving || isDisabled}
          onChange={(option: iOption) =>
            handleUpdate({
              attributeSetCode: option === null ? null : option.data.code,
            })
          }
        />
      </Flex>
    </Wrapper>
  );
};

export default ProductDetailsAttributesPanel;
