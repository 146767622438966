import SettingsPage from '../../layouts/SettingsPage';
import EnergyRateList from '../../components/energyRate/EnergyRateList';

const EnergyRatesPage = () => {
  return (
    <SettingsPage title={'Energy Ratings'}>
      {(headerProps) => (
        <EnergyRateList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default EnergyRatesPage;
