import iPurchaseOrderItem from '../../types/purchase/iPurchaseOderItem';
import ServiceHelper from '../helper/ServiceHelper';

const endPoint = '/purchaseOrderItem';

const PurchaseOrderItemService = {
  getAll: ServiceHelper.getPaginatedAll<iPurchaseOrderItem>(endPoint),
  get: ServiceHelper.getById<iPurchaseOrderItem>(endPoint),
  create: ServiceHelper.create<iPurchaseOrderItem>(endPoint),
  update: ServiceHelper.updateById<iPurchaseOrderItem>(endPoint),
  deactivate: ServiceHelper.deactivateById<iPurchaseOrderItem>(endPoint),
};

export default PurchaseOrderItemService;
