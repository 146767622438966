import iEntityStatusType from '../../types/status/iEntityStatusType';
import ServiceHelper from '../helper/ServiceHelper';

const endPoint = '/entityStatusType';
const EntityStatusTypeService = {
  getAll: ServiceHelper.getPaginatedAll<iEntityStatusType>(endPoint),
  get: ServiceHelper.getById<iEntityStatusType>(endPoint),
  update: ServiceHelper.updateById<iEntityStatusType>(endPoint),
};

export default EntityStatusTypeService;
