import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import iAddress from '../../types/system/iAddress';
import { ReactNode, useEffect, useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { iErrorMap } from '../form/FormError';
import AddressService from '../../services/system/AddressService';
import Toaster from '../common/Toaster';
import AddressEditPanel from './AddressEditPanel';

export type iAddressEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  address?: iAddress;
  isDisabled?: boolean;
  onSaved?: (saved: iAddress, isCreated: boolean) => void;
  getPopupTitle?: (address?: iAddress | null) => ReactNode;
  allowUpdatedCode?: boolean;
};

const AddressEditPopupBtn = ({
  address,
  isDisabled = false,
  onSaved,
  onClose,
  getPopupTitle,
  ...props
}: iAddressEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({
    address: address || {},
  });
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const addressId = `${address?.id || ''}`.trim();

  useEffect(() => {
    setEditingData({
      address: address || {},
    });
  }, [address]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
    onClose && onClose(setModelShowing);
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    const mergedData = {
      ...address,
      ...editingData.address,
    };

    const saveFn = () =>
      addressId === ''
        ? AddressService.create(mergedData)
        : AddressService.update(addressId, mergedData);
    setIsSaving(true);
    saveFn()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, addressId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const getBody = () => {
    const mergedAddress = {
      ...(address || {}),
      ...(editingData.address || {}),
    };
    return (
      <AddressEditPanel
        testId={'new-address'}
        address={mergedAddress}
        useAsForm={{
          errorMap,
          onFieldChange: (fieldName, value) => {
            setEditingData({
              ...editingData,
              address: {
                ...editingData.address,
                [fieldName]: value,
              },
            });
          },
        }}
      />
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AddressEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: getPopupTitle ? (
          getPopupTitle(address)
        ) : (
          <>
            {addressId === '' ? 'Create' : 'Update'} Address{' '}
            <small>{address?.name}</small>
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: getBody(),
      })}
    />
  );
};

export default AddressEditPopupBtn;
