import Lozenge from '../frameWork/Lozenge';
import iEntityStatus from '../../types/status/iEntityStatus';
import { EntityStatusCategoryCodes } from '../../types/status/iEntityStatusCategory';

type iEntityStatusLozenge = {
  status?: iEntityStatus | null;
  testId?: string;
};
const EntityStatusLozenge = ({ status, testId }: iEntityStatusLozenge) => {
  const getAppearance = () => {
    const statusType = status?.Category?.code || '';
    switch (statusType) {
      case EntityStatusCategoryCodes.FINISHED: {
        return 'success';
      }
      case EntityStatusCategoryCodes.IN_PROGRESS: {
        return 'inprogress';
      }
      default: {
        return 'default';
      }
    }
  };
  return (
    <Lozenge appearance={getAppearance()} testId={testId}>
      {status?.name || ''}
    </Lozenge>
  );
};

export default EntityStatusLozenge;
