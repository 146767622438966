import PopupBtn, { iModelProps, iSetShowingModalFn } from '../common/PopupBtn';
import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import Flex from './Flex';

import {
  iDynamicBaseType,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import LocalStorageService from '../../services/LocalStorageService';
import Checkbox from './Checkbox';
import Icons from './Icons';
import { getDefaultBtn, getFooterWithBtns } from '../common/PopupModal';
import MathHelper from '../../helpers/MathHelper';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';

export type iDynamicTableColSelectBtn<T extends iDynamicBaseType> = {
  cols: iTableColumn<T>[];
  selectiveColumnKey: string;
  onChanged: (selectedColKeys: string[]) => void;
  title?: string;
  testId?: string;
  modalProps?: iModelProps & { width?: string };
};
const DynamicTableColSelectBtn = <T extends iDynamicBaseType>({
  title,
  cols,
  testId,
  selectiveColumnKey,
  onChanged,
  modalProps,
}: iDynamicTableColSelectBtn<T>) => {
  const [selectedColKeys, setSelectedColKeys] = useState<string[]>([]);
  const selectiveCols = cols.filter(
    (col) => col.isSelectable === true || col.isDefault === true,
  );
  const [version, setVersion] = useState(0);

  useEffect(() => {
    const localSelectedKeys = LocalStorageService.getItem(selectiveColumnKey);
    setSelectedColKeys(
      `${selectiveColumnKey}`.trim() === '' || localSelectedKeys === null
        ? cols.filter((col) => col.isDefault === true).map((col) => col.key)
        : localSelectedKeys,
    );
  }, [version, selectiveColumnKey]);

  const groupedSelectiveCols = selectiveCols.reduce(
    (map: { [key: string]: iTableColumn<T>[] }, col) => {
      const group: string = `${col.group || 'Default'}`.trim();
      const previousCols = group in map ? map[group] : [];
      return {
        ...map,
        [group]: _.uniqBy([...previousCols, col], (col) => col.key),
      };
    },
    {},
  );

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setVersion(MathHelper.add(version, 1));
    setModelShowing(false);
  };

  if (selectiveCols.length === 0) {
    return null;
  }

  return (
    <PopupBtn
      titleId={'DynamicTableColSelectBtn'}
      appearance={'subtle'}
      iconBefore={Icons.SettingsIcon}
      btnTxt={'Columns'}
      modalProps={(setModelShowing) => ({
        ...modalProps,
        onClose: () => handleClose(setModelShowing),
        shouldScrollInViewport: true,
        title: title || 'Please select columns to show:',
        body: (
          <Flex className={'flex-wrap gap-1'}>
            {Object.keys(groupedSelectiveCols).map((groupName) => {
              return (
                <div key={groupName}>
                  <div>{groupName}</div>
                  {groupedSelectiveCols[groupName].map((col) => {
                    const colName =
                      typeof col.header === 'function'
                        ? col.header({ col })
                        : col.header;
                    if (colName === '') {
                      return null;
                    }
                    return (
                      <Checkbox
                        label={<>{colName}</>}
                        key={col.key}
                        isDisabled={col.isSelectable !== true}
                        isChecked={selectedColKeys.indexOf(col.key) >= 0}
                        onChange={() => {
                          const newSelectedKeys =
                            selectedColKeys.indexOf(col.key) >= 0
                              ? selectedColKeys.filter((key) => col.key !== key)
                              : _.uniq([...selectedColKeys, col.key]);
                          setSelectedColKeys(newSelectedKeys);
                        }}
                      />
                    );
                  })}
                </div>
              );
            })}
          </Flex>
        ),
        footer: getFooterWithBtns({
          actionBtn: getDefaultBtn({
            btnText: 'OK',
            appearance: 'primary',
            testId: `DynamicTableColSelectBtn-okBtn-${testId || ''}`,
            iconBefore: Icons.CheckIcon,
            onClick: () => {
              LocalStorageService.setItem(selectiveColumnKey, selectedColKeys);
              handleClose(setModelShowing);
              onChanged(selectedColKeys);
            },
          }),
          cancelBtn: (
            <DeleteConfirmPopupBtn
              title={'You are about to reset all columns to default'}
              message={
                'You are about to reset all columns to default, and this process can not be reversed.'
              }
              titleId={`DynamicTableColSelectBtn-restoreBtn-${selectiveColumnKey}`}
              deleteFnc={async () => {
                LocalStorageService.removeItem(selectiveColumnKey);
              }}
              onDeleted={() => {
                handleClose(setModelShowing);
                onChanged(selectedColKeys);
              }}
              actionBtnProps={{
                btnText: 'Restore Default',
                iconBefore: Icons.RefreshIcon,
              }}
              renderBtn={(onClick) =>
                getDefaultBtn({
                  btnText: 'Restore Default',
                  appearance: 'subtle',
                  testId: `DynamicTableColSelectBtn-restoreBtn-${testId || ''}`,
                  iconBefore: Icons.RefreshIcon,
                  onClick,
                })
              }
            />
          ),
        }),
      })}
    />
  );
};

export default DynamicTableColSelectBtn;
