import styled from 'styled-components';
import EntityNames from '../../../helpers/EntityNames';
import iAttribute, {
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AttributeValueService from '../../../services/attribute/AttributeValueService';
import Spinner from '../../frameWork/Spinner';
import Toaster from '../../common/Toaster';
import AttributeSettingsHelper from './AttributeSettingsHelper';
import iAttributeValue from '../../../types/attribute/iAttributeValue';
import AttributeInputHelper from './AttributeInputHelper';
import { iAttributeItemWithValueMap } from '../../../types/attribute/iAttributeItem';
import AttributeService from '../../../services/attribute/AttributeService';

const Wrapper = styled.div``;

type iAttributeValueInputWrapperChildren = {
  isRequiredByAttrCondition: boolean;
  valueByAttrCondition: string | null;
  limitedOptionValues: string[] | null;
  aliasTargetAttr?: iAttribute | null;
  aliasTargetAttrItem?: iAttributeItemWithValueMap | null;
};
export type iAttributeValueInputWrapper = {
  entityId: string;
  entityName: EntityNames;
  attribute: iAttribute;
  attributeItem?: iAttributeItemWithValueMap;
  forceReload?: number;
  children: (props: iAttributeValueInputWrapperChildren) => ReactNode;
};
const AttributeValueInputWrapper = ({
  children,
  entityId,
  entityName,
  attribute,
  attributeItem,
  forceReload = 0,
}: iAttributeValueInputWrapper) => {
  const [isLoading, setIsLoading] = useState(true);
  const editingLogics = AttributeSettingsHelper.getEditLogic(attribute);
  const [attValuesInDB, setAttValuesInDB] = useState<iAttributeValue[]>([]);
  const [aliasTargetAttr, setAliasTargetAttr] = useState<iAttribute | null>(
    null,
  );
  const [aliasTargetAttrItem, setAliasTargetAttrItem] =
    useState<iAttributeItemWithValueMap | null>(null);

  const getData = useCallback(async () => {
    const relatedAttributes: iAttribute[] = editingLogics.reduce(
      (arr: iAttribute[], editingLogic) => {
        return [
          ...arr,
          ...((editingLogic.when || [])
            .map((when) => when.attribute)
            .filter(
              (whenAttribute) =>
                whenAttribute && `${whenAttribute?.id || ''}`.trim() !== '',
            ) as iAttribute[]),
        ];
      },
      [],
    );
    if (relatedAttributes.length <= 0) {
      return;
    }

    return AttributeValueService.getAllFromEntity(entityName, entityId, {
      where: JSON.stringify({
        isActive: true,
        attributeId: relatedAttributes.map(
          (relatedAttribute) => relatedAttribute.id,
        ),
      }),
      perPage: 99999999,
    });
  }, []);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    const aliasTargetAttrConf =
      AttributeSettingsHelper.getAliasTargetAttrConf(attribute);
    const targetAttributeId =
      `${aliasTargetAttrConf?.attributeId || ''}`.trim();
    Promise.all([
      getData(),
      attribute.type !== AttributeTypes.ATTRIBUTE_ALIAS ||
      targetAttributeId === ''
        ? null
        : AttributeService.get(targetAttributeId),
      targetAttributeId === ''
        ? null
        : AttributeValueService.getAllFromEntity(entityName, entityId, {
            where: JSON.stringify({
              isActive: true,
              attributeId: targetAttributeId,
            }),
            perPage: 99999999,
            include: 'Item.HouseArea',
          }),
    ])
      .then((resp) => {
        if (isCanceled || !resp) {
          return;
        }
        setAttValuesInDB(resp[0]?.data || []);
        setAliasTargetAttr(resp[1] || null);
        const aliasItem: iAttributeItemWithValueMap[] = (
          resp[2]?.data || []
        ).map((attrVal) => {
          return {
            ...(attrVal.Item || {}),
            valuesMap: {
              [attrVal.attributeId]: attrVal,
            },
          } as iAttributeItemWithValueMap;
        });
        setAliasTargetAttrItem(aliasItem.length > 0 ? aliasItem[0] : null);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [entityName, entityId, getData, forceReload]);

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    return children({
      aliasTargetAttr,
      aliasTargetAttrItem,
      isRequiredByAttrCondition:
        AttributeInputHelper.checkAttributeIsRequiredByEditLogic(
          attribute,
          attValuesInDB,
          attributeItem,
        ),
      valueByAttrCondition: AttributeInputHelper.getAttrValueFromEditLogic(
        attribute,
        attValuesInDB,
        attributeItem,
      ),
      limitedOptionValues:
        AttributeInputHelper.getLimitedOptionValuesFromEditLogic(
          attribute,
          attValuesInDB,
          attributeItem,
        ),
    });
  };
  return <Wrapper>{getContent()}</Wrapper>;
};

export default AttributeValueInputWrapper;
