import iAttribute from '../../types/attribute/iAttribute';
import EntityNames from '../../helpers/EntityNames';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import AttributeInputHelper from './components/AttributeInputHelper';
import { useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import { AttributeSetCodes } from '../../types/attribute/iAttributeSet';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import Flex from '../frameWork/Flex';
import { ViewWrapper } from '../frameWork/InlineEdit';
import ComponentPropsHelper from '../frameWork/ComponentPropsHelper';
import Spinner from '../frameWork/Spinner';

export type iAttributeAliasValueDiv = {
  targetAttribute: iAttribute;
  entityName: EntityNames;
  entityId: string;
  testId?: string;
  className?: string;
  placeHolder?: string;
};
const AttributeAliasValueDiv = ({
  testId,
  className,
  targetAttribute,
  entityId,
  entityName,
  placeHolder = 'Click here to ...',
}: iAttributeAliasValueDiv) => {
  const { classNameStr, testIdStr } = ComponentPropsHelper.getWrapperProps({
    componentName: 'AttributeAliasValueDiv',
    testId,
    className,
  });
  const [sourceAttribute, setSourceAttribute] = useState<iAttribute | null>(
    null,
  );
  const [sourceAttrItemsWithValueMap, setSourceAttrItemsWithValueMap] =
    useState<iAttributeItemWithValueMap[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { attributeId, attributeSetCode } =
    AttributeSettingsHelper.getAliasTargetAttrConf(targetAttribute);
  const sourceAttrId = `${attributeId || ''}`.trim();
  const sourceAttrSetCode = `${attributeSetCode || ''}`.trim();

  useEffect(() => {
    if (sourceAttrId === '') {
      setSourceAttribute(null);
      return;
    }

    let isCancelled = false;
    setIsLoading(true);
    AttributeInputHelper.getDataForInputPanel({
      entityId,
      entityName,
      attributeSetCode: sourceAttrSetCode as AttributeSetCodes,
      attributeId: sourceAttrId,
    })
      .then((resp) => {
        if (isCancelled) {
          return;
        }
        const { attributes, itemsWithValueMap } = resp;
        setSourceAttribute(attributes.length > 0 ? attributes[0] : null);
        setSourceAttrItemsWithValueMap(itemsWithValueMap.data || []);
      })
      .catch((err) => {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [entityName, entityId, sourceAttrId, sourceAttrSetCode]);

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (sourceAttrId === '' || sourceAttrSetCode === '' || !sourceAttribute) {
      return null;
    }
    return (
      <Flex>
        {sourceAttrItemsWithValueMap.length <= 0
          ? placeHolder
          : sourceAttrItemsWithValueMap.map((sourceAttrItemWithValueMap) => {
              const valuesMap = sourceAttrItemWithValueMap.valuesMap || {};
              if (!(sourceAttrId in valuesMap)) {
                return null;
              }
              const attributeValue = valuesMap[sourceAttrId];
              return (
                <div key={sourceAttrItemWithValueMap.id}>
                  {AttributeInputHelper.displayValue(
                    entityId,
                    entityName,
                    sourceAttribute,
                    attributeValue,
                  )}
                </div>
              );
            })}
      </Flex>
    );
  };

  return (
    <ViewWrapper
      className={`${classNameStr} ${sourceAttrItemsWithValueMap.length <= 0 ? 'default-view' : ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </ViewWrapper>
  );
};

export default AttributeAliasValueDiv;
