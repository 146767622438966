import ServiceHelper from '../helper/ServiceHelper';
import iEnergyRate from '../../types/system/iEnergyRate';

const endPoint = '/energyRate';

const EnergyRateService = {
  getAll: ServiceHelper.getPaginatedAll<iEnergyRate>(endPoint),
  get: ServiceHelper.getById<iEnergyRate>(endPoint),
  create: ServiceHelper.create<iEnergyRate>(endPoint),
  update: ServiceHelper.updateById<iEnergyRate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iEnergyRate>(endPoint),
};

export default EnergyRateService;
