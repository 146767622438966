import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';
import ContactCompanyCreatePopupBtn from './ContactCompanyCreatePopupBtn';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import AddressHelper from '../../helpers/AddressHelper';
import { URL_CONTACT_COMPANY_DETAILS } from '../../helpers/UrlMap';
import SearchTextField from '../frameWork/SearchTextField';
import Flex from '../frameWork/Flex';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import { Link, useNavigate } from 'react-router-dom';
import AdvSearchBtn from '../frameWork/AdvSearchBtn';
import ContactCompanyCategorySelector from './ContactCompanyCategorySelector';
import { iOptionWithData } from '../frameWork/Select';
import iContactCompanyCategory from '../../types/contact/iContactCompanyCategory';
import ContactCompanyHelper from '../../helpers/ContactCompanyHelper';
import AdvanceSearchPanel from '../frameWork/AdvanceSearchPanel';

type iContactCompanyList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  contactCompanyType: ContactCompanyTypes;
  testId?: string;
};

const ContactCompanyList = ({
  allowDelete,
  contactCompanyType,
  headerProps,
  testId,
}: iContactCompanyList) => {
  const [showAdvSearchPanel, setShowAdvSearchPanel] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState<{
    [key: string]: string | string[];
  }>({});

  const navigate = useNavigate();
  const { renderDataTable, renderDeleteBtn, onSetFilter, onRefresh } =
    useListCrudHook<iContactCompany>({
      sort: `name:ASC`,
      getFn: (params) =>
        ContactCompanyService.getAll({
          where: JSON.stringify({
            isActive: true,
            type: contactCompanyType,
            ...(params?.filter || {}),
            ...(showAdvSearchPanel ? searchCriteria : {}),
          }),
          include: 'CreatedBy,UpdatedBy,Address,PostalAddress,Categories',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getColumns = (): iTableColumn<iContactCompany>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSortable: true,
      cell: ({ data }) => {
        return (
          <Link to={URL_CONTACT_COMPANY_DETAILS.replace(':id', data.id)}>
            {`${data.name}`.trim()}
          </Link>
        );
      },
    },
    ...(ContactCompanyHelper.showCategory(contactCompanyType)
      ? [
          {
            key: 'categoryId',
            header: 'Category',
            isDefault: true,
            isSelectable: true,
            cell: ({ data }: { data: iContactCompany }) => {
              return data.Category?.name || '';
            },
          },
        ]
      : []),
    {
      key: 'isCompany',
      header: 'isCompany?',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => {
        return DynamicTableHelper.getCheckedIcon(
          data.isCompany,
          'Is a company',
        );
      },
    },
    {
      key: 'contactNumber',
      header: 'Contact No.',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }) => {
        const contactNo = `${data.contactNumber || ''}`.trim();
        return contactNo === '' ? null : (
          <a href={`tel:${contactNo}`} target={'_blank'} rel="noreferrer">
            {contactNo}
          </a>
        );
      },
    },
    {
      key: 'email',
      header: 'Email',
      isDefault: true,
      isSelectable: true,
      isSortable: true,
      cell: ({ data }) => {
        const email = `${data.email || ''}`.trim();
        return email === '' ? null : (
          <a href={`mailto:${email}`} target={'_blank'} rel="noreferrer">
            {email}
          </a>
        );
      },
    },
    {
      key: 'abn',
      header: 'A.B.N.',
      isSelectable: true,
      cell: ({ data }) => {
        return `${data.abn || ''}`.trim();
      },
    },
    {
      key: 'acn',
      header: 'A.C.N.',
      isSelectable: true,
      cell: ({ data }) => {
        return `${data.acn || ''}`.trim();
      },
    },
    {
      key: 'address',
      header: 'Address',
      isSelectable: true,
      cell: ({ data }) => {
        return AddressHelper.getFullAddress(data.Address);
      },
    },
    {
      key: 'postalAddress',
      header: 'Postal Address',
      isSelectable: true,
      cell: ({ data }) => {
        return AddressHelper.getFullAddress(data.PostalAddress);
      },
    },
    {
      key: 'comments',
      header: 'Comments',
      isSelectable: true,
      cell: ({ data }) => {
        return `${data.comments || ''}`.trim();
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iContactCompany>(
      true,
      true,
    ),
    ...(allowDelete === true
      ? [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iContactCompany>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    getDisplayName: () =>
                      `${data.name} [${contactCompanyType}]`,
                    deleteFnc: () => ContactCompanyService.deactivate(data.id),
                  })}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const getAdvancedSearchPanel = () => {
    if (showAdvSearchPanel !== true) {
      return <></>;
    }
    return (
      <AdvanceSearchPanel>
        <ContactCompanyCategorySelector
          style={{ minWidth: '250px' }}
          label={'Category'}
          isCloseable
          isMulti
          contactCompanyType={contactCompanyType}
          values={searchCriteria.categoryId}
          onChange={(
            selected: iOptionWithData<iContactCompanyCategory>[] | null,
          ) => {
            if (!selected || selected.length <= 0) {
              setSearchCriteria({});
              onRefresh();
              return;
            }
            setSearchCriteria({
              categoryId: selected.map((c) => `${c.value || ''}`),
            });
            onRefresh();
          }}
        />
      </AdvanceSearchPanel>
    );
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            title={headerProps?.children}
            createBtn={
              <ContactCompanyCreatePopupBtn
                showCategorySelector={ContactCompanyHelper.showCategory(
                  contactCompanyType,
                )}
                contactCompanyType={contactCompanyType}
                renderBtn={(onClick) =>
                  getCreateIconBtn({
                    testId: `ContactCompanyCreatePopupBtn-${testId || ''}`,
                    onClick,
                    label: `Create a ${contactCompanyType.toLowerCase()}`,
                  })
                }
                onCreated={(created) =>
                  navigate(
                    URL_CONTACT_COMPANY_DETAILS.replace(':id', created.id),
                  )
                }
              />
            }
          />
        ),
        actions: (
          <Flex className={'gap-1 align-items-center'}>
            {ContactCompanyHelper.showCategory(contactCompanyType) === true && (
              <AdvSearchBtn
                showAdvSearchPanel={showAdvSearchPanel}
                setShowAdvSearchPanel={setShowAdvSearchPanel}
              />
            )}
            <SearchTextField
              testId={'search-field'}
              placeholder={'Search name, email or phone...'}
              onSearch={(searchText) => {
                const searchTxt = `${searchText || ''}`.trim();
                onSetFilter(
                  searchTxt === ''
                    ? {}
                    : {
                        [OP_OR]: [
                          { name: { [OP_LIKE]: `%${searchText}%` } },
                          { email: { [OP_LIKE]: `%${searchText}%` } },
                          { contactNumber: { [OP_LIKE]: `%${searchText}%` } },
                          { abn: { [OP_LIKE]: `%${searchText}%` } },
                          { acn: { [OP_LIKE]: `%${searchText}%` } },
                        ],
                      },
                );
              }}
            />
          </Flex>
        ),
        bottomBar: getAdvancedSearchPanel(),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.CONTACT_COMPANY_LIST.replace(
          ':type',
          contactCompanyType,
        ),
        tblProps: {
          testId: 'contactCompany-list',
        },
        showPageSizer: true,
      })}
    </ComponentWithPageHeader>
  );
};

export default ContactCompanyList;
