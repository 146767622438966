import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import { iOptionWithData } from '../frameWork/Select';
import iEntityStatusCategory from '../../types/status/iEntityStatusCategory';
import EntityStatusCategoryService from '../../services/status/EntityStatusCategoryService';

export type iEntityStatusCategorySelector = iPreloadAsyncSelectProps;
const EntityStatusCategorySelector = (props: iEntityStatusCategorySelector) => {
  return (
    <PreloadedAsyncSelector<iEntityStatusCategory>
      placeholder={'Select an Category ...'}
      {...props}
      optionsUniqBy={(data: iEntityStatusCategory) => data.id}
      formatOptionLabel={(data: iOptionWithData<iEntityStatusCategory>) => (
        <>
          <div>{data.data.name}</div>
        </>
      )}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return EntityStatusCategoryService.getAll({
          ...params,
          sort: 'name:DESC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        EntityStatusCategoryService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default EntityStatusCategorySelector;
