export type WrapperProps = {
  className?: string;
  testId?: string;
};

type iGetWrapperProps = {
  componentName: string;
  className?: string;
  testId?: string;
};
const getWrapperProps = ({
  componentName,
  className,
  testId,
}: iGetWrapperProps) => {
  return {
    classNameStr: [componentName.trim(), `${className || ''}`.trim()]
      .filter((str) => str.trim() !== '')
      .join(' ')
      .trim(),
    testIdStr: [`${testId || ''}`.trim(), componentName.trim()]
      .map((str) => str.trim())
      .join('-')
      .trim(),
    componentName,
  };
};

const ComponentPropsHelper = {
  getWrapperProps,
};

export default ComponentPropsHelper;
