import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import DeveloperGuideCategoryService from '../../services/developerGuideCategory/DeveloperGuideCategoryService';

import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';
import Tabs, { iTabs } from '../frameWork/Tabs';
import Spinner from '../frameWork/Spinner';

type iDeveloperGuideCategoryTabs = Omit<iTabs, 'tabs' | 'onSelect'> & {
  selectedCategoryId?: string;
  onSelected?: (category: iDeveloperGuideCategory | null) => void;
};
const DeveloperGuideCategoryTabs = ({
  testId,
  className,
  onSelected,
  ...props
}: iDeveloperGuideCategoryTabs) => {
  const componentName = 'DeveloperGuideCategoryTabs';
  const testIdStr = `${testId || ''}-${componentName}`;
  const { state } = useListCrudHook<iDeveloperGuideCategory>({
    getFn: () =>
      DeveloperGuideCategoryService.getAll({
        where: JSON.stringify({
          isActive: true,
        }),
        perPage: 99999,
        sort: 'sort:ASC',
      }),
  });

  if (state.isLoading === true) {
    return <Spinner />;
  }

  return (
    <Tabs
      {...props}
      className={`${className || ''} ${componentName}`}
      testId={testIdStr}
      onSelect={(eventKey: string | null) => {
        const selectedCategory = state.data?.data?.find(
          (category) => category.id === eventKey,
        );
        if (onSelected) {
          onSelected(selectedCategory ? selectedCategory : null);
        }
      }}
      tabs={[
        {
          eventKey: 'all',
          title: 'ALL',
          tabContent: null,
        },
        ...(state.data?.data || []).map((category) => ({
          eventKey: category.id,
          title: category.name,
          tabContent: null,
        })),
      ]}
    />
  );
};

export default DeveloperGuideCategoryTabs;
