import BuildColourNoteTemplatesList from '../../components/buildColourNote/BuildColourNoteTemplateList';
import SettingsPage from '../../layouts/SettingsPage';
import React from 'react';

const BuildColourNoteTemplatesPage = () => {
  return (
    <SettingsPage title={'Build Colour Note Templates'}>
      {(headerProps) => (
        <BuildColourNoteTemplatesList
          allowDelete
          allowCreate
          allowEdit
          headerProps={headerProps}
        />
      )}
    </SettingsPage>
  );
};

export default BuildColourNoteTemplatesPage;
