import axios, { AxiosRequestConfig } from 'axios';
import UtilsService from '../UtilsService';

export type iCoords = {
  lat: number;
  lon: number;
};
const getLatAndLong = (
  query: string,
  params = {},
  config: AxiosRequestConfig = {},
): Promise<iCoords[]> => {
  const urlParams = UtilsService.getUrlParams({
    format: 'json',
    ...params,
  });
  return axios
    .get(
      `https://nominatim.openstreetmap.org/search${urlParams}&q=${encodeURIComponent(query).replace(/%20/g, '+')}`,
      config,
    )
    .then((resp) => resp.data);
};

const OpenStreetMapService = {
  getLatAndLong,
};

export default OpenStreetMapService;
