import ListComponent, {
  BaseListComponentProps,
} from '../frameWork/ListComponent';
import iProductOptionType from '../../types/product/iProductOptionType';
import ProductOptionTypeService from '../../services/product/ProductOptionTypeService';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import { iTableColumn } from '../../helpers/DynamicTableHelper';
import { iEntityFormField } from '../form/EntityEditPanel';
import TextArea from '../frameWork/TextArea';
import { SelectiveColKeys } from '../../services/LocalStorageService';

export type iProductOptionTypeList = Omit<
  BaseListComponentProps<iProductOptionType>,
  'getFn' | 'getColumns' | 'componentName' | 'deactivateFn'
> & {
  allowDelete?: boolean;
  onSaved?: (data: iProductOptionType, isCreated: boolean) => void;
};

const ProductOptionTypeList = ({ ...props }: iProductOptionTypeList) => {
  const getEditBtn = (
    params: ReturnType<typeof useListCrudHook<iProductOptionType>>,
    data?: iProductOptionType,
  ) => {
    return params.renderEntityEditPopBtn({
      editingEntity: data,
      entityName: 'Product Option Type',
      createFn: (data) =>
        ProductOptionTypeService.create(data).then((data) => {
          if (props.onSaved) {
            props.onSaved(data, true);
          }
          return data;
        }),
      updateFn: (id, data) =>
        ProductOptionTypeService.update(id, data).then((data) => {
          if (props.onSaved) {
            props.onSaved(data, false);
          }
          return data;
        }),
      getPopupTitle: (data?: iProductOptionType) => {
        if (`${data?.id || ''}`.trim() == '') {
          return 'Creating a new Product Option Type';
        }
        return `Editing Product Option Type: ${data?.name || ''}`;
      },
      renderEditBtn: ({ entity, onClick }) => {
        return (
          <a
            onClick={onClick}
            className={'cursor-pointer'}
            data-testid={`edit-btn-${entity?.id || ''}`}
          >
            {data?.name || ''}
          </a>
        );
      },
      getFormFields: ({ entity, isDisabled }): iEntityFormField[] => [
        {
          fieldName: 'name',
          label: 'Name',
          testId: 'name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
        },
        {
          fieldName: 'description',
          label: 'Description',
          testId: 'description',
          isDisabled,
          value: entity?.description || '',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <TextArea
                {...props}
                {...errorProps}
                placeholder={'Description'}
                value={props.value as string | undefined}
                onChange={(event) => {
                  useAsForm.onFieldChange(fieldName, event.target.value);
                }}
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (
    params: ReturnType<typeof useListCrudHook<iProductOptionType>>,
  ): iTableColumn<iProductOptionType>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }) => getEditBtn(params, data),
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }) => data.description || '',
    },
  ];

  return (
    <ListComponent<iProductOptionType>
      selectiveColumnKey={SelectiveColKeys.PRODUCT_OPTION_TYPE_LIST}
      {...props}
      componentName={'ProductOptionTypeList'}
      getFnExtra={{
        sort: 'createdAt:DESC',
      }}
      getColumns={getColumns}
      deactivateFn={async (data) =>
        ProductOptionTypeService.deactivate(data.id)
      }
      renderCreateBtn={getEditBtn}
      getFn={(params) => {
        return ProductOptionTypeService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        });
      }}
    />
  );
};

export default ProductOptionTypeList;
