import React, { useCallback } from 'react';
import {
  useStore,
  getBezierPath,
  iEdgeProps,
  Position,
  iNode,
  getEdgeCenter,
} from '../../frameWork/ReactFlowRenderer';

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
const getNodeIntersection = (intersectionNode: iNode, targetNode: iNode) => {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    position: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.position;

  const w = Math.round((intersectionNodeWidth || 0) / 2);
  const h = Math.round((intersectionNodeHeight || 0) / 2);

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
};

// returns the position (top,right,bottom or right) passed node compared to the intersection point
const getEdgePosition = (
  node: iNode,
  intersectionPoint: { x: number; y: number },
) => {
  const n = { ...node.position, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + (n.width || 150) - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + (n.height || 39) - 1) {
    return Position.Bottom;
  }

  return Position.Top;
};

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source: iNode, target: iNode) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
    elevateEdgesOnSelect: true,
  };
}

const WorkflowDiagramEdge = ({
  id,
  source,
  target,
  markerEnd,
  style,
  label,
}: iEdgeProps) => {
  const labelWidth = 38;
  const labelHeight = 20;
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source]),
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target]),
  );
  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
  );
  const edgePath = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  // const onEdgeClick = (evt: any, id: string) => {
  //   evt.stopPropagation();
  //   alert(`remove ${id}`);
  // };

  const getLabel = () => {
    if (`${label || ''}`.trim() === '') {
      return null;
    }
    return (
      <g
        transform={`translate(${edgeCenterX} ${edgeCenterY})`}
        className="react-flow__edge-textwrapper"
      >
        <rect
          width={labelWidth}
          x={0 - labelWidth / 2}
          y={0 - labelHeight / 2}
          height={labelHeight}
          className="react-flow__edge-textbg cursor-pointer"
          rx="2"
          ry="2"
        />
        <text className="react-flow__edge-text" dy="0.3em">
          {label}
        </text>
      </g>
    );
  };

  return (
    <g className="react-flow__connection">
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
      />
      {getLabel()}
      {/* <foreignObject*/}
      {/*  width={120}*/}
      {/*  height={24}*/}
      {/*  x={edgeCenterX - 120 / 2}*/}
      {/*  y={edgeCenterY - 34 / 2}*/}
      {/*  className="edgebutton-foreignobject"*/}
      {/*  requiredExtensions="http://www.w3.org/1999/xhtml"*/}
      {/* >*/}
      {/*  <body>*/}
      {/*    <div className="edgebutton" onClick={event => onEdgeClick(event, id)}>*/}
      {/*      {label}*/}
      {/*    </div>*/}
      {/*  </body>*/}
      {/* </foreignObject>*/}
    </g>
  );
};

export default WorkflowDiagramEdge;
