import ServiceHelper from '../helper/ServiceHelper';
import iTaxBase from '../../types/tax/iTaxBase';

const endPoint = '/taxBase';

const TaxBaseService = {
  getAll: ServiceHelper.getPaginatedAll<iTaxBase>(endPoint),
};

export default TaxBaseService;
